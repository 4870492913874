import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import PretendardText from "../../atoms/pretendardText";
import MuktaText from "../../atoms/muktaText";
import { route } from "../../../routes/route";
import PrimaryBtn02 from "../../atoms/btn/primaryBtn02";
import useDisplay from "../../../hooks/useDisplay";

function PriceBox({
  style,
  title,
  subTitle,
  mValue,
  yValue,
  guide,
  useList,
  onClick,
  boxStyle,
  isBest,
  containerStyle,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const history = useHistory();

  return (
    <>
      <div
        style={{
          background: isSmall
            ? "rgba(230, 230, 230, 0.2)"
            : "linear-gradient(rgba(230, 230, 230, 0.2) ,rgba(230, 230, 230, 0))",
          padding: "2px",
          marginRight: isSmall ? 0 : 30,
          marginBottom: isSmall ? 30 : 0,
          borderRadius: 10,
          alignSelf: "flex-end",
          width: "100%",
          ...style,
        }}
      >
        {isBest ? (
          <img
            src="/assets/images/payment/best.png"
            style={{
              position: "absolute",
              top: -21,
              right: 7,
              width: 91,
              height: 96,
            }}
          />
        ) : null}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: isSmall ? "100%" : 366,
            height: isSmall ? 531 : 621,
            padding: isSmall ? "40px 13px 0px 12px" : "50px 30px 0px 30px",
            background: "linear-gradient(rgba(29, 29, 29), rgba(24, 24, 24))",
            borderRadius: 10,
            ...boxStyle,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderBottom: "1px solid #333333",
              ...containerStyle,
            }}
          >
            <MuktaText
              style={{
                fontWeight: "700",
                fontSize: isSmall ? "24px" : "36px",
                lineHeight: isSmall ? "38.4px" : "54px",
                color: "#fff",
                marginBottom: isSmall ? 10 : 8,
              }}
            >
              {title}
            </MuktaText>
            <PretendardText
              style={{
                fontWeight: "400",
                fontSize: isSmall ? "14px" : "16px",
                lineHeight: isSmall ? "21px" : "24px",
                color: "#BABABA",
                marginBottom: isSmall ? 20 : 32,
              }}
            >
              {subTitle}
            </PretendardText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                marginBottom: isSmall ? 10 : 8,
              }}
            >
              <PretendardText
                style={{
                  fontWeight: "700",
                  fontSize: isSmall ? "24px" : "36px",
                  lineHeight: isSmall ? "33.6px" : "50.4px",
                  color: "#fff",
                }}
              >
                ₩ {Number(mValue).toLocaleString("ko-KR")}
              </PretendardText>
              <PretendardText
                style={{
                  fontWeight: "700",
                  fontSize: isSmall ? "12px" : "18px",
                  lineHeight: isSmall ? "16.8px" : "25.2px",
                  color: "#fff",
                  marginBottom: isSmall ? 4 : 8,
                }}
              >
                /월
              </PretendardText>
            </div>

            <PretendardText
              style={{
                fontWeight: "400",
                fontSize: isSmall ? "16px" : "18px",
                lineHeight: isSmall ? "24px" : "28.8px",
                color: "#fff",
                marginBottom: isSmall ? 40 : 55,
              }}
            >
              연 {Number(yValue).toLocaleString("ko-KR")}원
            </PretendardText>

            <PrimaryBtn02
              active={isBest}
              onClick={onClick}
              title={"구독하기"}
              style={{
                marginBottom: 20,
              }}
            />
          </div>

          <div
            style={{
              marginTop: 20,
            }}
          >
            <PretendardText
              style={{
                fontWeight: "400",
                fontSize: isSmall ? "14px" : "16px",
                lineHeight: isSmall ? "21px" : "24px",
                color: "#fff",
                marginBottom: isSmall ? 8 : 7,
              }}
            >
              {guide}
            </PretendardText>

            {useList.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 9,
                  }}
                >
                  <img
                    src="/assets/icons/icon_check.png"
                    style={{
                      height: 15,
                      width: 15,
                      marginRight: 4,
                    }}
                  />
                  <PretendardText
                    style={{
                      fontWeight: "400",
                      fontSize: isSmall ? "14px" : "16px",
                      lineHeight: isSmall ? "21px" : "24px",
                      color: "#fff",
                    }}
                  >
                    {item}
                  </PretendardText>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default PriceBox;
