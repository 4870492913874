import { Route, Switch, Redirect } from "react-router-dom";

import { route } from "./route";
import useDisplay from "../hooks/useDisplay";
import Home from "../pages/home";
import Bestock from "../pages/bestock/bestock";
import Matching from "../pages/matching/matching";

import Recommend from "../pages/recommend/recommend";
import Video from "../pages/video/video";
import VideoDetail from "../pages/video/videoDetail";
import Account from "../pages/user/account";
import Library from "../pages/user/library";
import PayHistory from "../pages/user/payHistory";
import Subscribe from "../pages/user/subscribe";

import Payment from "../pages/payment/payment";
import Charge from "../pages/payment/charge";

import Privacy from "../pages/terms/privacy";
import Terms from "../pages/terms/terms";
import Licenses from "../pages/terms/license";
import Copyright from "../pages/terms/copyright";

import Notice from "../pages/notice/notice";
import NoticeDetail from "../pages/notice/noticeDetail";
import Faq from "../pages/faq/faq";
import Contact from "../pages/contact/contact";
import VideoStreaming from "../pages/video/videoStreaming";
import VideoTemp from "../pages/video/videoTemp";

const Router = () => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <Switch>
      <Route exact={true} path={"/"} component={Home} />
      <Route path={route.home} component={Home} />
      <Route path={route.bestock} component={Bestock} />
      <Route path={route.matching + "/:page"} component={Matching} />

      <Route path={route.videoDetail + "/:id"} component={VideoDetail} />

      <Route path={route.recommend} component={Recommend} />

      <Route path={route.account} component={Account} />
      <Route path={route.library + "/:page"} component={Library} />
      <Route path={route.payHistory + "/:page"} component={PayHistory} />
      <Route path={route.subscribe + "/:page"} component={Subscribe} />

      <Route path={route.payment} component={Payment} />
      <Route path={route.charge + "/:id"} component={Charge} />

      <Route path={route.privacy} component={Privacy} />
      <Route path={route.terms} component={Terms} />
      <Route path={route.licenses} component={Licenses} />
      <Route path={route.copyright} component={Copyright} />

      <Route path={route.notice + "/:page"} component={Notice} />
      <Route path={route.notice_detail + "/:id"} component={NoticeDetail} />

      <Route path={route.faq + "/:page"} component={Faq} />

      <Route path={route.contact} component={Contact} />

      <Route path={route.videoStreaming} component={VideoStreaming} />
      <Route path={route.videoTemp + "/:id"} component={VideoTemp} />

      {/* Not Found */}
      <Route component={() => <Redirect to={route.home} />} />
    </Switch>
  );
};

export default Router;
