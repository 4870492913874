import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { findPwModalState } from "../../recoil/modalState";
import PretendardText from "../../components/atoms/pretendardText";
import TitleLabel from "../../components/atoms/text/titleLabel";
import TextInput from "../../components/atoms/input/textInput";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import useDisplay from "../../hooks/useDisplay";
import Footer from "../../components/organisms/footer";
import NavM from "../../components/organisms/navM";
import useModal from "../../hooks/useModal";
import { nullCheck } from "../../utils/check";
import { regExpression } from "../../utils/reg-expresstion";
import { errorHandler } from "../../utils/error-handler";
import UserApi from "../../api/user";
import { toast } from "react-toastify";

function FindPwModal() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const modal = useModal();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.getElementById("container").style.cssText = `
          height: 950px;          
          overflow: hidden;
          background: #151515;
          `;
    return () => {
      document.getElementById("container").style.cssText = `
      min-height: 100vh;
      overflow: auto;
      background: #151515;
      `;
    };
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [emailErrorM, setEmailErrorM] = useState("");

  const emailCheck = useMemo(() => {
    if (!nullCheck(email) && !regExpression.email.test(email)) {
      setEmailErrorM("올바른 형식을 입력해 주세요.");
      return false;
    }
    if (nullCheck(email)) {
      setEmailErrorM("");
      return false;
    }
    setEmailErrorM("");
    return true;
  }, [email]);

  const nextFunc = async () => {
    try {
      const params = {
        name: name,
        email: email,
      };
      const response = await UserApi.sendEmailCode(params);
      sessionStorage.setItem("random", response.data.data.randomNumber);
      sessionStorage.setItem("name", name);
      sessionStorage.setItem("email", email);
      modal.open("code");
    } catch (error) {
      if (error.response.status === 404) {
        return toast(`정보를 찾을 수 없습니다.`);
      }
      errorHandler(error);
    }
  };

  return (
    <>
      {isMobile ? (
        <div>
          <div
            style={{
              background: "#151515",
              width: "100vw",
              height: "950px",
              zIndex: 10000,
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
              overflow: "auto",
            }}
          >
            <NavM />

            <div
              style={{
                minHeight: "531px",
                width: "100%",
                padding: "0px 20px",
                marginBottom: "70px",
              }}
            >
              <div
                style={{
                  marginTop: "120px",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  비밀번호 찾기
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#00E4BB",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    marginTop: "5px",
                  }}
                >
                  가입 정보를 입력해주세요.
                </PretendardText>
              </div>

              <TitleLabel title={"이름"} isRequired={true}></TitleLabel>
              <TextInput
                placeholder={"가입하신 이름을 입력하세요."}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                maxLength={20}
                onBlur={() => {
                  setName(name.trim());
                }}
              ></TextInput>

              <TitleLabel title={"이메일"} isRequired={true}></TitleLabel>
              <TextInput
                placeholder={"가입하신 이메일을 입력하세요."}
                errorText={emailErrorM}
                isError={!nullCheck(emailErrorM)}
                onChange={(e) => {
                  setEmail(e.target.value.trim());
                }}
                value={email}
                maxLength={50}
              ></TextInput>

              <PrimaryBtn
                title={"다음"}
                disabled={!emailCheck}
                style={{
                  width: "100%",
                  height: "48px",
                  marginTop: "40px",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => {
                  nextFunc();
                }}
              ></PrimaryBtn>
            </div>

            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              background: "rgba(30, 30, 30, 0.50)",
              backdropFilter: "blur(32.5px)",
              width: "100vw",
              height: "100vh",
              zIndex: 10000,
              display: "flex",
              position: "fixed",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
            }}
          ></div>
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 10001,
            }}
          >
            <div
              style={{
                width: "520px",
                height: "100%",
                borderRadius: "15px",
                background: "#151515",
                boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.20)",
                padding: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  비밀번호 찾기
                </PretendardText>
                <img
                  src="/assets/icons/icon_close.svg"
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.close();
                    sessionStorage.clear();
                  }}
                />
              </div>

              <PretendardText
                style={{
                  color: "#00E4BB",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  marginTop: "10px",
                }}
              >
                가입 정보를 입력해주세요.
              </PretendardText>

              <div
                style={{
                  height: "1px",
                  marginTop: "25px",
                  background: "#939393",
                }}
              ></div>

              <TitleLabel title={"이름"} isRequired={true}></TitleLabel>
              <TextInput
                placeholder={"가입하신 이름을 입력하세요."}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                maxLength={20}
                onBlur={() => {
                  setName(name.trim());
                }}
              ></TextInput>

              <TitleLabel title={"이메일"} isRequired={true}></TitleLabel>
              <TextInput
                placeholder={"가입하신 이메일을 입력하세요."}
                errorText={emailErrorM}
                isError={!nullCheck(emailErrorM)}
                onChange={(e) => {
                  setEmail(e.target.value.trim());
                }}
                value={email}
                maxLength={50}
              ></TextInput>

              <PrimaryBtn
                title={"다음"}
                disabled={!emailCheck}
                style={{
                  width: "100%",
                  height: "48px",
                  marginTop: "40px",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => {
                  nextFunc();
                }}
              ></PrimaryBtn>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FindPwModal;
