import React, { useState } from "react";
import { useEffect } from "react";
import PretendardText from "../../components/atoms/pretendardText";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import useDisplay from "../../hooks/useDisplay";
import Footer from "../../components/organisms/footer";
import NavM from "../../components/organisms/navM";
import useModal from "../../hooks/useModal";
import { errorHandler } from "../../utils/error-handler";
import UserApi from "../../api/user";

function FindEmailCompleteModal() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const modal = useModal();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.getElementById("container").style.cssText = `
          height: 810px;          
          overflow: hidden;
          background: #151515;
          `;
    return () => {
      document.getElementById("container").style.cssText = `
      min-height: 100vh;
      overflow: auto;
      background: #151515;
      `;
    };
  }, []);

  useEffect(() => {
    findEmailFunc();
  }, []);

  const findEmailFunc = async () => {
    try {
      const params = {
        name: sessionStorage.getItem("name"),
        phoneNumber: sessionStorage.getItem("phoneNumber"),
      };
      const response = await UserApi.findEmail(params);
      setAccountList(response.data.data);
      sessionStorage.clear();
    } catch (error) {
      errorHandler(error);
    }
  };

  const [accountList, setAccountList] = useState([]);

  const emailFormat = (email, joinType) => {
    const account = email.split("@")[0];
    const domain = email.split("@")[1];
    return account.substr(0, account.length - 2) + "**@" + domain + `${joinType === "NORMAL" ? "" : "_" + joinType}`;
  };

  return (
    <>
      {isMobile ? (
        <div>
          <div
            style={{
              background: "#151515",
              width: "100vw",
              height: "810px",
              zIndex: 10000,
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
              overflow: "auto",
            }}
          >
            <NavM />

            <div
              style={{
                minHeight: "400px",
                width: "100%",
                padding: "0px 20px",
                marginBottom: "70px",
              }}
            >
              <div
                style={{
                  marginTop: "120px",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  이메일 찾기
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#00E4BB",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    marginTop: "5px",
                  }}
                >
                  가입된 이메일 정보는 아래와 같습니다.
                </PretendardText>
              </div>

              {accountList.map((item, index) => {
                return (
                  <PretendardText
                    key={index}
                    style={{
                      height: "69.21px",
                      borderRadius: "10px",
                      background: "#333333",
                      color: "#FFFFFF",
                      fontSize: "18px",
                      fontWeight: "400",
                      lineHeight: "28.8px",
                      marginTop: "31.88px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {emailFormat(item.email, item.joinType)}
                  </PretendardText>
                );
              })}

              <PrimaryBtn
                title={"로그인하기"}
                style={{
                  width: "100%",
                  height: "48px",
                  marginTop: "40px",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => {
                  modal.open("login");
                }}
              ></PrimaryBtn>
            </div>

            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              background: "rgba(30, 30, 30, 0.50)",
              backdropFilter: "blur(32.5px)",
              width: "100vw",
              height: "100vh",
              zIndex: 10000,
              display: "flex",
              position: "fixed",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
            }}
          ></div>
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 10001,
            }}
          >
            <div
              style={{
                width: "520px",
                height: "100%",
                borderRadius: "15px",
                background: "#151515",
                boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.20)",
                padding: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  이메일 찾기
                </PretendardText>
                <img
                  src="/assets/icons/icon_close.svg"
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.close();
                  }}
                />
              </div>

              <PretendardText
                style={{
                  color: "#00E4BB",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  marginTop: "10px",
                }}
              >
                가입된 이메일 정보는 아래와 같습니다.
              </PretendardText>

              {accountList.map((item, index) => {
                return (
                  <PretendardText
                    key={index}
                    style={{
                      height: "69.21px",
                      borderRadius: "10px",
                      background: "#333333",
                      color: "#FFFFFF",
                      fontSize: "18px",
                      fontWeight: "400",
                      lineHeight: "28.8px",
                      marginTop: "31.88px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {emailFormat(item.email, item.joinType)}
                  </PretendardText>
                );
              })}

              <PrimaryBtn
                title={"로그인하기"}
                style={{
                  width: "100%",
                  height: "48px",
                  marginTop: "40px",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => {
                  modal.open("login");
                }}
              ></PrimaryBtn>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FindEmailCompleteModal;
