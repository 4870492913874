import React, { useRef, useState, useEffect } from "react";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useModal from "./hooks/useModal";

import "react-toastify/dist/ReactToastify.min.css";
import Router from "./routes/router";
import useDisplay from "./hooks/useDisplay";
import { BrowserRouter } from "react-router-dom";
import LoginModal from "./pages/common/loginModal";
import Container from "./components/atoms/layout/container";
import Nav from "./components/organisms/nav";
import Footer from "./components/organisms/footer";
import JoinModal from "./pages/common/joinModal";
import FindPwModal from "./pages/common/findPwModal";
import CodeModal from "./pages/common/codeModal";
import FindEmailModal from "./pages/common/findEmailModal";
import FindEmailCompleteModal from "./pages/common/findEmailCompleteModal";
import ChangePwModal from "./pages/common/changePwModal";
import NavM from "./components/organisms/navM";
import LeaveModal from "./components/templates/modal/leaveModal";
import FieldModal from "./pages/common/fieldModal";
import FilterApi from "./api/filter";
import { ko } from "./utils/ko";
import {
  menu01StateAtom,
  menu02StateAtom,
  menu03StateAtom,
  menu04StateAtom,
  menu05StateAtom,
  menu06StateAtom,
} from "./recoil/menuState";
import { loginState } from "./recoil/loginState";
import CommonApi from "./api/common";

function App() {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const modal = useModal();

  const setMenu01StateAtom = useSetRecoilState(menu01StateAtom);
  const setMenu02StateAtom = useSetRecoilState(menu02StateAtom);
  const setMenu03StateAtom = useSetRecoilState(menu03StateAtom);
  const setMenu04StateAtom = useSetRecoilState(menu04StateAtom);
  const setMenu05StateAtom = useSetRecoilState(menu05StateAtom);
  const setMenu06StateAtom = useSetRecoilState(menu06StateAtom);

  useEffect(() => {
    window.scrollTo(0, 0);

    getFilterInfo();
  }, []);

  const getFilterInfo = async () => {
    setMenu01StateAtom({
      title: "무드",
      value: "1",
      subMenus: await getFilterInfoAPI("MOOD"),
    });
    setMenu02StateAtom({
      title: "지역",
      value: "2",
      subMenus: await getFilterInfoAPI("LOCATION"),
    });
    setMenu03StateAtom({
      title: "주제",
      value: "3",
      subMenus: await getFilterInfoAPI("SUBJECT"),
    });
    setMenu04StateAtom({
      title: "해상도",
      value: "4",
      subMenus: await getFilterInfoAPI("RESOLUTION"),
    });
    setMenu05StateAtom({
      title: "촬영기법",
      value: "5",
      subMenus: await getFilterInfoAPI("TECHNIC"),
    });
    setMenu06StateAtom({
      title: "속도",
      value: "6",
      subMenus: await getFilterInfoAPI("SPEED"),
    });
  };

  const getFilterInfoAPI = async (type) => {
    try {
      const dataTemp = (await FilterApi.getFilter({ filterType: type })).data.data;

      let tempData = dataTemp.map((item) => {
        return {
          title: item.title,
          value: item.id,
        };
      });

      return tempData;
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const setLoginState = useSetRecoilState(loginState);

  useEffect(() => {
    setLoginState({
      id: sessionStorage.getItem("id"),
      token: sessionStorage.getItem("token"),
      name: sessionStorage.getItem("name"),
      fileUrl: sessionStorage.getItem("fileUrl"),
    });
  }, []);

  // 방문자 등록
  let visitFlag = false;
  const visitFunc = async () => {
    try {
      if (visitFlag) {
        return;
      }
      await CommonApi.visit();
      visitFlag = true;
    } catch (error) {}
  };

  useEffect(() => {
    visitFunc();
  }, []);

  const socialLoginCancelFunc = () => {
    // window.location.href = "/bestock/home";
    toast("ggggg");
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Router />

        {modal.state === "login" ? <LoginModal /> : null}
        {modal.state === "join" ? <JoinModal socialLoginCancelFunc={socialLoginCancelFunc} /> : null}
        {modal.state === "field" ? <FieldModal socialLoginCancelFunc={socialLoginCancelFunc} /> : null}
        {modal.state === "findPw" ? <FindPwModal /> : null}
        {modal.state === "code" ? <CodeModal /> : null}
        {modal.state === "changePw" ? <ChangePwModal /> : null}
        {modal.state === "findEmail" ? <FindEmailModal /> : null}
        {modal.state === "findEmailComplete" ? <FindEmailCompleteModal /> : null}
        {modal.state === "leave" ? <LeaveModal /> : null}
      </BrowserRouter>

      <ToastContainer className="toast" position="top-center" autoClose={2000} hideProgressBar={true} />
    </div>
  );
}

export default App;
