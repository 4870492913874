import React from "react";
import MypageSide from "../../components/organisms/mypageSide";
import PretendardText from "../../components/atoms/pretendardText";
import { errorHandler } from "../../utils/error-handler";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { route } from "../../routes/route";
import Pagination from "../../components/organisms/pagination";
import WhiteBtn from "../../components/atoms/btn/whiteBtn";
import DateInput from "../../components/atoms/input/dateInput";
import FilterBtn from "../../components/atoms/btn/filterBtn";
import useDisplay from "../../hooks/useDisplay";
import ScrollContainer from "react-indiana-drag-scroll";
import UserApi from "./../../api/user";
import moment from "moment";
import styled from "styled-components";
import SubscribeCancelModal from "../../components/templates/modal/subscribeCancelModal";
import useModal from "../../hooks/useModal";
import Container from "../../components/atoms/layout/container";
import Nav from "../../components/organisms/nav";
import NavM from "../../components/organisms/navM";
import Footer from "../../components/organisms/footer";
import { toast } from "react-toastify";

function Subscribe() {
  const modal = useModal();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const history = useHistory();
  const location = useLocation();

  const [list, setList] = useState([]);
  const [data, setData] = useState({});
  const [periodFilter, setPeriodFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  //페이지네이션
  const { page } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 10, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  useEffect(() => {
    if (!sessionStorage.getItem("id")) {
      history.push(route.home);
      modal.open("login");
      return;
    }
    setPeriodFilter(
      searchParams.get("period") ? searchParams.get("period") : ""
    );
    setStartDate(
      searchParams.get("startDate") ? searchParams.get("startDate") : ""
    );
    setEndDate(searchParams.get("endDate") ? searchParams.get("endDate") : "");
    getList();
    getData();
  }, [location]);

  const getList = async () => {
    try {
      const data = {
        page: page - 1,
        size: pagination.size,
        // periodFilter: searchParams.get("period"),
        startDate: searchParams.get("startDate"),
        endDate: searchParams.get("endDate"),
      };

      let list = (await UserApi.getSubscribeList(data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const getData = async () => {
    try {
      let data = (await UserApi.getSubscribe()).data.data;
      if (!data) {
        setData(null);
      } else {
        setData({ ...data, resolutions: data.resolutions?.split(",") });
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const searchFunc = () => {
    if (moment(startDate).isAfter(moment(endDate))) {
      return toast("조회 기간을 다시 확인하십시오.");
    }
    history.replace(
      `${route.subscribe}/1?period=${periodFilter}&startDate=${startDate}&endDate=${endDate}`
    );
    setSearchBarOpen(false);
  };

  const filterFunc = (period) => {
    setPeriodFilter(period);
    const today = moment();
    const filterEndDate = moment().format("YYYY-MM-DD");
    switch (period) {
      case "":
        setStartDate("");
        setEndDate("");
        break;
      case "WEEK":
        const filterStartDate1 = today
          .subtract(1, "weeks")
          .format("YYYY-MM-DD");
        setStartDate(filterStartDate1);
        setEndDate(filterEndDate);
        history.replace(
          `${route.subscribe}/1?period=${period}&startDate=${filterStartDate1}&endDate=${filterEndDate}`
        );
        break;
      case "MONTH":
        const filterStartDate2 = today
          .subtract(1, "months")
          .format("YYYY-MM-DD");
        setStartDate(filterStartDate2);
        setEndDate(filterEndDate);
        history.replace(
          `${route.subscribe}/1?period=${period}&startDate=${filterStartDate2}&endDate=${filterEndDate}`
        );
        break;
      case "THREE_MONTH":
        const filterStartDate3 = today
          .subtract(3, "months")
          .format("YYYY-MM-DD");
        setStartDate(filterStartDate3);
        setEndDate(filterEndDate);
        history.replace(
          `${route.subscribe}/1?period=${period}&startDate=${filterStartDate3}&endDate=${filterEndDate}`
        );
        break;
      default:
        break;
    }
    setSearchBarOpen(false);
  };

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}

        {isMobile ? (
          <>
            <div
              style={{
                padding: "0px 20px",
                paddingTop: "70px",
              }}
            >
              <PretendardText
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#00E4BB",
                  textAlign: "center",
                }}
              >
                My page
              </PretendardText>
              <PretendardText
                style={{
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "36px",
                  color: "#FFFFFF",
                  textAlign: "center",
                  marginTop: "4px",
                }}
              >
                마이페이지
              </PretendardText>
            </div>
            <MypageSide></MypageSide>

            <div
              style={{
                height: "1px",
                background: "#333333",
              }}
            ></div>
            <div
              style={{
                padding: "0px 20px",
                marginTop: "40px",
              }}
            >
              <PretendardText
                style={{
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "28.8px",
                  color: "#FFFFFF",
                }}
              >
                사용중인 구독
              </PretendardText>
              {data?.subscribeStatus === "ONGOING" ? (
                <div
                  style={{
                    marginTop: "20px",
                    width: "335px",
                    height: "468px",
                    background: "#1E1E1E",
                    border: "2px solid #00E4BB",
                    borderRadius: "10px",
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "36px",
                        color: "#FFFFFF",
                      }}
                    >
                      {data.planTypeTitle}
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "16.8px",
                        color: "#151515",
                        width: "56px",
                        height: "27px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#BFDB38",
                        borderRadius: "100px",
                      }}
                    >
                      구독중
                    </PretendardText>
                  </div>
                  <PretendardText
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#FFFFFF",
                      marginTop: "15px",
                    }}
                  >
                    {data.planName}
                  </PretendardText>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                      marginTop: "10px",
                    }}
                  >
                    {data.resolutions?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <img src="/assets/icons/icon_check.svg" alt="" />
                          <PretendardText
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#FFFFFF",
                            }}
                          >
                            HD
                          </PretendardText>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      height: "1px",
                      marginTop: "30px",
                      background: "#565656",
                    }}
                  ></div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div>
                      <PretendardText
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#BABABA",
                        }}
                      >
                        서비스 사용 기간
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#BABABA",
                        }}
                      >
                        {moment(data.startDate).format("YYYY-MM-DD")} ~{" "}
                        {moment(data.endDate).format("YYYY-MM-DD")}
                      </PretendardText>
                    </div>
                    <div>
                      <PretendardText
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#BABABA",
                        }}
                      >
                        다음 결제일
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#BABABA",
                        }}
                      >
                        {data.nextDate}
                      </PretendardText>
                    </div>
                    <div>
                      <PretendardText
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#BABABA",
                        }}
                      >
                        결제 예정 금액
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#BABABA",
                        }}
                      >
                        {data.payAmount.toLocaleString("ko-KR")}/
                        {data.isMonthly ? "월" : "년"}
                      </PretendardText>
                    </div>
                    <div>
                      <PretendardText
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#BABABA",
                        }}
                      >
                        다운로드 횟수
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#BABABA",
                        }}
                      >
                        <span style={{ color: "#F1F1F1" }}>
                          {data.downloadCount}
                        </span>{" "}
                        / 총 {data.downloadLimit}
                      </PretendardText>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontWeight: "400",
                        fontSize: "13px",
                        lineHeight: "18.2px",
                        color: "#939393",
                      }}
                    >
                      구독 해지를 원하시나요?{" "}
                      <span
                        style={{
                          textDecorationLine: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => setModalOpen(true)}
                      >
                        구독해지
                      </span>
                    </PretendardText>
                  </div>
                </div>
              ) : (
                <div>
                  <PretendardText
                    style={{
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#939393",
                      margin: "100px 0px",
                      textAlign: "center",
                    }}
                  >
                    사용중인 구독이 없습니다.
                  </PretendardText>
                </div>
              )}

              <div
                style={{
                  marginTop: "52px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <PretendardText
                  style={{
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "28.8px",
                    color: "#FFFFFF",
                  }}
                >
                  전체 이용내역({list.length})
                </PretendardText>
                <img
                  src="/assets/icons/icon_table_search_m.svg"
                  alt=""
                  onClick={() => setSearchBarOpen(!searchBarOpen)}
                />
                {searchBarOpen ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "39px",
                      width: "100%",
                      height: "207px",
                      borderRadius: "5px",
                      backgroundColor: "#333",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #565656",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <FilterBtnM
                        active={periodFilter === ""}
                        onClick={() => {
                          filterFunc("");
                        }}
                      >
                        전체
                      </FilterBtnM>
                      <FilterBtnM
                        active={periodFilter === "WEEK"}
                        onClick={() => {
                          filterFunc("WEEK");
                        }}
                      >
                        1주일
                      </FilterBtnM>
                      <FilterBtnM
                        active={periodFilter === "MONTH"}
                        onClick={() => {
                          filterFunc("MONTH");
                        }}
                      >
                        1개월
                      </FilterBtnM>
                      <FilterBtnM
                        active={periodFilter === "THREE_MONTH"}
                        onClick={() => {
                          filterFunc("THREE_MONTH");
                        }}
                      >
                        3개월
                      </FilterBtnM>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        marginTop: "15px",
                      }}
                    >
                      <DateInput
                        style={{
                          width: "143px",
                          paddingLeft: "10px",
                          borderColor: "#565656",
                        }}
                        iconStyle={{
                          top: "11px",
                          right: "8px",
                        }}
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                          setPeriodFilter("");
                        }}
                      ></DateInput>
                      <div
                        style={{
                          height: "1px",
                          width: "12px",
                          background: "#939393",
                        }}
                      ></div>
                      <DateInput
                        style={{
                          width: "143px",
                          paddingLeft: "10px",
                          borderColor: "#565656",
                        }}
                        iconStyle={{
                          top: "11px",
                          right: "8px",
                        }}
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                          setPeriodFilter("");
                        }}
                      ></DateInput>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                    >
                      <WhiteBtn
                        title={"검색"}
                        style={{
                          color: "#1e1e1e",
                          width: "117px",
                          height: "48px",
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "24px",
                        }}
                        onClick={() => {
                          searchFunc();
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div
              style={{ width: "100vw", marginTop: "30px", overflowX: "auto" }}
            >
              <ScrollContainer
                className="scroll-container"
                hideScrollbars={false}
              >
                <div
                  style={{
                    width: "1170px",
                    height: "704px",
                    marginBottom: "30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 30px",
                      gap: "10px",
                      borderTop: "2px solid #565656",
                      borderBottom: "1px solid #565656",
                      height: "64px",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                        width: "231px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      결제일자
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                        width: "143px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      요금제명
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                        width: "184px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      다운로드 횟수
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                        width: "120px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      결제주기
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                        width: "288px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      서비스 이용 기간
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                        width: "93px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      상태
                    </PretendardText>
                  </div>

                  {list.length === 0 ? (
                    <div>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#939393",
                          margin: "100px 0px",
                          textAlign: "center",
                        }}
                      >
                        이용내역이 없습니다.
                      </PretendardText>
                    </div>
                  ) : (
                    <>
                      {list.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "0px 30px",
                              gap: "10px",
                              borderBottom: "1px solid #565656",
                              height: "67px",
                            }}
                          >
                            <PretendardText
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#BABABA",
                                width: "231px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              2023-06-30 11:50:10
                            </PretendardText>
                            <PretendardText
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#BABABA",
                                width: "143px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Standard
                            </PretendardText>
                            <PretendardText
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#BABABA",
                                width: "184px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              50/200
                            </PretendardText>
                            <PretendardText
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#BABABA",
                                width: "120px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              1개월
                            </PretendardText>
                            <PretendardText
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#BABABA",
                                width: "288px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              2023-06-30 ~ 2024-06-30
                            </PretendardText>
                            <div
                              style={{
                                width: "93px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {/* <PretendardText
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "16.8px",
                          color: "#151515",
                          width: "56px",
                          height: "27px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#BFDB38",
                          borderRadius: "100px",
                        }}
                      >
                        구독중
                      </PretendardText> */}
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "12px",
                                  lineHeight: "16.8px",
                                  color: "#151515",
                                  width: "66px",
                                  height: "27px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  background: "#DFDFDF",
                                  borderRadius: "100px",
                                }}
                              >
                                구독종료
                              </PretendardText>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </ScrollContainer>
            </div>

            <Pagination
              style={{ marginTop: "40px", marginBottom: "70px" }}
              route={route.subscribe}
              queryString={`period=${periodFilter}&startDate=${startDate}&endDate=${endDate}`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
            {modalOpen ? (
              <SubscribeCancelModal id={data.id} setModalOpen={setModalOpen} />
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <div
              style={{
                height: "80px",
              }}
            ></div>

            <div
              style={{
                display: "flex",
                minHeight: "1772px",
              }}
            >
              <MypageSide />

              <div
                style={{
                  paddingTop: "30px",
                  paddingLeft: "35px",
                }}
              >
                <PretendardText
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#00E4BB",
                  }}
                >
                  Subscription
                </PretendardText>
                <PretendardText
                  style={{
                    fontWeight: "700",
                    fontSize: "36px",
                    lineHeight: "54px",
                    color: "#FFFFFF",
                    marginTop: "15px",
                  }}
                >
                  구독관리
                </PretendardText>

                <div
                  style={{
                    marginTop: "70px",
                  }}
                >
                  <PretendardText
                    style={{
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "28.8px",
                      color: "#FFFFFF",
                    }}
                  >
                    사용중인 구독
                  </PretendardText>

                  {data?.subscribeStatus === "ONGOING" ? (
                    <div
                      style={{
                        marginTop: "20px",
                        width: "729px",
                        height: "421px",
                        background: "#1E1E1E",
                        border: "2px solid #00E4BB",
                        borderRadius: "10px",
                        padding: "30px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontWeight: "600",
                            fontSize: "24px",
                            lineHeight: "36px",
                            color: "#FFFFFF",
                          }}
                        >
                          {data.planTypeTitle}
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "16.8px",
                            color: "#151515",
                            width: "56px",
                            height: "27px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#BFDB38",
                            borderRadius: "100px",
                          }}
                        >
                          구독중
                        </PretendardText>
                      </div>
                      <PretendardText
                        style={{
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          marginTop: "15px",
                        }}
                      >
                        {data.planName}
                      </PretendardText>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                          marginTop: "10px",
                        }}
                      >
                        {data.resolutions?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <img src="/assets/icons/icon_check.svg" alt="" />
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#FFFFFF",
                                }}
                              >
                                {item}
                              </PretendardText>
                            </div>
                          );
                        })}
                      </div>

                      <div
                        style={{
                          height: "1px",
                          marginTop: "30px",
                          background: "#565656",
                        }}
                      ></div>

                      <div
                        style={{
                          marginTop: "30px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#BABABA",
                            }}
                          >
                            서비스 사용 기간
                          </PretendardText>
                          <PretendardText
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#BABABA",
                            }}
                          >
                            {data.startDate} ~ {data.endDate}
                          </PretendardText>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#BABABA",
                            }}
                          >
                            다음 결제일
                          </PretendardText>
                          <PretendardText
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#BABABA",
                            }}
                          >
                            {data.nextDate}
                          </PretendardText>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#BABABA",
                            }}
                          >
                            결제 예정 금액
                          </PretendardText>
                          <PretendardText
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#BABABA",
                            }}
                          >
                            {data.payAmount.toLocaleString("ko-KR")}/
                            {data.isMonthly ? "월" : "년"}
                          </PretendardText>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#BABABA",
                            }}
                          >
                            다운로드 횟수
                          </PretendardText>
                          <PretendardText
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#BABABA",
                            }}
                          >
                            <span style={{ color: "#F1F1F1" }}>
                              {data.downloadCount}
                            </span>{" "}
                            / 총 {data.downloadLimit}
                          </PretendardText>
                        </div>
                      </div>

                      <div
                        style={{
                          marginTop: "30px",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#939393",
                          }}
                        >
                          구독 해지를 원하시나요?{" "}
                          <span
                            style={{
                              textDecorationLine: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => setModalOpen(true)}
                          >
                            구독해지
                          </span>
                        </PretendardText>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#939393",
                          margin: "120px 0px",
                          textAlign: "center",
                        }}
                      >
                        사용중인 구독이 없습니다.
                      </PretendardText>
                    </div>
                  )}

                  <div
                    style={{
                      marginTop: "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontWeight: "700",
                        fontSize: "18px",
                        lineHeight: "28.8px",
                        color: "#FFFFFF",
                      }}
                    >
                      전체 이용내역({list.length})
                    </PretendardText>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginLeft: "199px",
                      }}
                    >
                      <FilterBtn
                        title={"전체"}
                        active={periodFilter === ""}
                        onClick={() => {
                          filterFunc("");
                        }}
                      />
                      <FilterBtn
                        title={"1주일"}
                        active={periodFilter === "WEEK"}
                        onClick={() => {
                          filterFunc("WEEK");
                        }}
                      />
                      <FilterBtn
                        title={"1개월"}
                        active={periodFilter === "MONTH"}
                        onClick={() => {
                          filterFunc("MONTH");
                        }}
                      />
                      <FilterBtn
                        title={"3개월"}
                        active={periodFilter === "THREE_MONTH"}
                        onClick={() => {
                          filterFunc("THREE_MONTH");
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "15px",
                        marginLeft: "12px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <DateInput
                          style={{
                            width: "200px",
                          }}
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(e.target.value);
                            setPeriodFilter("");
                          }}
                        ></DateInput>
                        <div
                          style={{
                            height: "1px",
                            width: "12px",
                            background: "#939393",
                          }}
                        ></div>
                        <DateInput
                          style={{
                            width: "200px",
                          }}
                          value={endDate}
                          onChange={(e) => {
                            setEndDate(e.target.value);
                            setPeriodFilter("");
                          }}
                        ></DateInput>
                      </div>
                      <WhiteBtn
                        title={"검색"}
                        style={{
                          width: "118px",
                          height: "48px",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                        onClick={() => {
                          searchFunc();
                        }}
                      ></WhiteBtn>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "30px",
                      width: "1170px",
                      height: "704px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0px 30px",
                        gap: "10px",
                        borderTop: "2px solid #565656",
                        borderBottom: "1px solid #565656",
                        height: "64px",
                      }}
                    >
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "231px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        결제일자
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "143px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        요금제명
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "184px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        다운로드 횟수
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "120px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        결제주기
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "288px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        서비스 이용 기간
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "93px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        상태
                      </PretendardText>
                    </div>
                    {list.length === 0 ? (
                      <div>
                        <PretendardText
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#939393",
                            margin: "100px 0px",
                            textAlign: "center",
                          }}
                        >
                          이용내역이 없습니다.
                        </PretendardText>
                      </div>
                    ) : (
                      <>
                        {list.map((item, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0px 30px",
                                gap: "10px",
                                borderBottom: "1px solid #565656",
                                height: "67px",
                              }}
                            >
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "231px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {moment(item.createdAt).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "143px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.planName}
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "184px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.subscribeStatus === "ONGOING"
                                  ? item.downloadCount
                                  : item.totalDownloadCount}
                                /
                                {item.subscribeStatus === "ONGOING"
                                  ? item.downloadLimit
                                  : item.totalDownloadLimit}
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "120px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.isMonthly ? "1개월" : "1년"}
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "288px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.startDate} ~ {item.endDate}
                              </PretendardText>
                              <div
                                style={{
                                  width: "93px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.subscribeStatus === "ONGOING" ? (
                                  <PretendardText
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "12px",
                                      lineHeight: "16.8px",
                                      color: "#151515",
                                      width: "56px",
                                      height: "27px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      background: "#BFDB38",
                                      borderRadius: "100px",
                                    }}
                                  >
                                    구독중
                                  </PretendardText>
                                ) : (
                                  <PretendardText
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "12px",
                                      lineHeight: "16.8px",
                                      color: "#151515",
                                      width: "66px",
                                      height: "27px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      background: "#DFDFDF",
                                      borderRadius: "100px",
                                    }}
                                  >
                                    구독종료
                                  </PretendardText>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}

                    <Pagination
                      style={{ marginTop: "80px" }}
                      route={route.subscribe}
                      queryString={`period=${periodFilter}&startDate=${startDate}&endDate=${endDate}`}
                      currentPage={page}
                      totalPages={pagination.totalPages}
                      rangeSize={pagination.rangeSize}
                      first={pagination.first}
                      last={pagination.last}
                    />
                  </div>
                </div>
              </div>
            </div>
            {modalOpen ? (
              <SubscribeCancelModal id={data.id} setModalOpen={setModalOpen} />
            ) : (
              ""
            )}
          </>
        )}

        <Footer />
      </Container>
    </>
  );
}

export default Subscribe;

const FilterBtnM = styled.button.attrs((props) => {})`
  display: flex;
  width: 25%;
  height: 52px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.active ? "#00E4BB" : "transparent")};
  color: ${(props) => (props.active ? "#151515" : "#f1f1f1")};
  border: none;
  border-right: 1px solid #565656;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  &:first-child {
    border-radius: 5px 0px 0px 5px;
  }

  &:last-child {
    border-right: none;
    border-radius: 0px 5px 5px 0px;
  }
`;
