import React, { useEffect, useMemo } from "react";
import PretendardText from "../../components/atoms/pretendardText";
import { errorHandler } from "../../utils/error-handler";
import { useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { route } from "../../routes/route";
import useDisplay from "../../hooks/useDisplay";
import Blur from "../../components/atoms/blur/blur";
import styled from "styled-components";
import SelectBox from "../../components/atoms/select/selectBox";
import RadioInput from "../../components/atoms/input/radioInput";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import TextInput from "../../components/atoms/input/textInput";
import TextArea from "../../components/atoms/input/textarea";
import { nullCheck } from "../../utils/check";
import { useRecoilState } from "recoil";
import { contactModalState } from "../../recoil/modalState";
import ContactModal from "./../../components/templates/modal/contactModal";
import ContactApi from "./../../api/contactApi";
import { toast } from "react-toastify";
import { regExpression } from "./../../utils/reg-expresstion";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import Footer from "../../components/organisms/footer";

function Contact() {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [checked, setChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(-1);
  const [contactModal, setContactModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const inquirerType = [
    {
      title: "개인",
      value: "1",
    },
    {
      title: "기업",
      value: "2",
    },
  ];
  const inquiryType = [
    {
      title: "구독 문의",
      value: "1",
    },
    {
      title: "영상공급 문의",
      value: "2",
    },
    {
      title: "요금제 문의",
      value: "3",
    },
    {
      title: "환불/취소 문의",
      value: "4",
    },
    {
      title: "기타 문의",
      value: "5",
    },
  ];

  const [contactFormData, setContactFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    inquirerType: null,
    inquirerTypeTitle: "유형을 선택해 주세요.",
    inquiryType: null,
    inquiryTypeTitle: "유형을 선택해 주세요.",
    title: "",
    content: "",
    subscribe: "",
  });

  const [emailErrorM, setEmailErrorM] = useState("");

  const emailCheck = useMemo(() => {
    if (
      !nullCheck(contactFormData.email) &&
      !regExpression.email.test(contactFormData.email)
    ) {
      setEmailErrorM("올바른 형식을 입력해 주세요.");
      return false;
    }
    if (nullCheck(contactFormData.email)) {
      setEmailErrorM("");
      return false;
    }
    setEmailErrorM("");
    return true;
  }, [contactFormData]);

  const onContactChange = (key, value) => {
    setContactFormData({ ...contactFormData, [key]: value });
  };

  const blurInputChange = (key) => {
    setContactFormData({
      ...contactFormData,
      [key]: contactFormData[key].trim(),
    });
  };

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}

        <div
          style={{
            width: "100%",
            padding: `${isMobile ? "95px 0 95px 0" : "200px 19.53% 0 19.53%"}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          {!isMobile ? (
            <Blur
              style={{
                width: "1342px",
                height: "454px",
                borderRadius: "1342px",
                background: "rgba(0, 237, 194, 0.15)",
                filter: "blur(150px)",
                top: "-255px",
                left: "50%",
                transform: "translate(-50%,0)",
              }}
            />
          ) : (
            ""
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginBottom: `${isMobile ? "34px" : "60px"}`,
            }}
          >
            <PretendardText
              style={{
                color: "#00E4BB",
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "14px" : "16px"}`,
                lineHeight: `${isMobile ? "21px" : "24px"}`,
                marginBottom: `${isMobile ? "4px" : "13px"}`,
                textAlign: `${isMobile ? "center" : "left"}`,
              }}
            >
              Contact Us
            </PretendardText>
            <PretendardText
              style={{
                color: "#fff",
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "24px" : "36px"}`,
                lineHeight: `${isMobile ? "36px" : "54px"}`,
                textAlign: `${isMobile ? "center" : "left"}`,
              }}
            >
              문의하기
            </PretendardText>
          </div>
          {isMobile ? (
            <div
              style={{
                width: "100%",
                borderTop: "6px solid #1e1e1e",
                marginBottom: "35px",
              }}
            ></div>
          ) : (
            ""
          )}
          <div
            style={{ width: `100%`, padding: `${isMobile ? "0 5.33%" : "0"}` }}
          >
            <div
              style={{
                display: "flex",
                gap: "30px 30px",
                flexWrap: "wrap",
                marginBottom: "30px",
              }}
            >
              <div style={{ width: "100%" }}>
                <InputTitle>이름</InputTitle>
                <TextInput
                  placeholder={"이름"}
                  onChange={(e) => {
                    onContactChange("name", e.target.value);
                  }}
                  value={contactFormData.name}
                  maxLength={20}
                  onBlur={() => {
                    blurInputChange("name");
                  }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <InputTitle>전화번호</InputTitle>
                <TextInput
                  placeholder={"숫자만 입력 가능합니다."}
                  onChange={(e) => {
                    onContactChange(
                      "phoneNumber",
                      e.target.value.replace(/[^0-9]/g, "")
                    );
                  }}
                  value={contactFormData.phoneNumber}
                  maxLength={11}
                />
              </div>
              <div style={{ width: "100%" }}>
                <InputTitle>E-mail</InputTitle>
                <TextInput
                  placeholder={"E-mail"}
                  onChange={(e) => {
                    onContactChange("email", e.target.value.trim());
                  }}
                  errorText={emailErrorM}
                  isError={!nullCheck(emailErrorM)}
                  value={contactFormData.email}
                  maxLength={50}
                />
              </div>
              <div style={{ width: "100%" }}>
                <InputTitle>문의자 유형</InputTitle>
                <SelectBox
                  style={{
                    width: "100%",
                  }}
                  optionList={inquirerType}
                  isOpen={isOpen === 1}
                  title={contactFormData.inquirerTypeTitle}
                  onClickSelect={() => {
                    isOpen === 1 ? setIsOpen(false) : setIsOpen(1);
                  }}
                  onClickOption={(item) => {
                    setContactFormData({
                      ...contactFormData,
                      inquirerTypeTitle: item.title,
                      inquirerType: item.value,
                    });
                    setIsOpen(-1);
                  }}
                  value={contactFormData.inquirerType}
                />
              </div>
              <div style={{ width: "100%" }}>
                <InputTitle>문의 유형</InputTitle>
                <SelectBox
                  style={{
                    width: "100%",
                  }}
                  optionList={inquiryType}
                  isOpen={isOpen === 2}
                  title={contactFormData.inquiryTypeTitle}
                  onClickSelect={() => {
                    isOpen === 2 ? setIsOpen(false) : setIsOpen(2);
                  }}
                  onClickOption={(item) => {
                    setContactFormData({
                      ...contactFormData,
                      inquiryTypeTitle: item.title,
                      inquiryType: item.value,
                    });
                    setIsOpen(-1);
                  }}
                  value={contactFormData.inquiryType}
                />
              </div>
              <div style={{ width: "100%" }}>
                <InputTitle>문의 제목</InputTitle>
                <TextInput
                  placeholder={"제목을 입력하세요."}
                  onChange={(e) => {
                    onContactChange("title", e.target.value);
                  }}
                  value={contactFormData.title}
                  maxLength={50}
                  onBlur={() => {
                    onContactChange("title", contactFormData.title.trim());
                  }}
                />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <InputTitle>기타</InputTitle>
              <TextArea
                placeholder={"기타 문의사항을 입력해 주세요."}
                style={{ height: `${isMobile ? "175px" : "252px"}` }}
                onChange={(e) => {
                  onContactChange("content", e.target.value);
                }}
                maxLength={5000}
              />
            </div>
            <div
              style={{ width: "100%", marginTop: "30px", marginBottom: "60px" }}
            >
              <PretendardText
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  color: "#fff",
                  marginBottom: "10px",
                }}
              >
                구독 여부 (선택)
              </PretendardText>
              <div
                style={{ display: "flex", alignItems: "center", gap: "30px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setChecked(1);
                    onContactChange("subscribe", true);
                  }}
                >
                  <RadioInput checked={checked === 1}></RadioInput>
                  <PretendardText
                    style={{
                      color: "#fff",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    구독 중입니다.
                  </PretendardText>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setChecked(2);
                    onContactChange("subscribe", false);
                  }}
                >
                  <RadioInput checked={checked === 2}></RadioInput>
                  <PretendardText
                    style={{
                      color: "#fff",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    구독하지 않습니다.
                  </PretendardText>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 200,
              }}
            >
              <PrimaryBtn
                style={{
                  width: `${isMobile ? "146px" : "160px"}`,
                  height: `${isMobile ? "48px" : "54px"}`,
                  fontWeight: `${isMobile ? "600" : "700"}`,
                  fontSize: `${isMobile ? "16px" : "20px"}`,
                  lineHeight: `${isMobile ? "24px" : "30px"}`,
                }}
                disabled={
                  !emailCheck ||
                  nullCheck(contactFormData.name) ||
                  nullCheck(contactFormData.phoneNumber) ||
                  nullCheck(contactFormData.email) ||
                  nullCheck(contactFormData.inquirerType) ||
                  nullCheck(contactFormData.inquirerTypeTitle) ||
                  nullCheck(contactFormData.inquiryType) ||
                  nullCheck(contactFormData.inquiryTypeTitle) ||
                  nullCheck(contactFormData.title) ||
                  nullCheck(contactFormData.content)
                }
                title={"제출하기"}
                onClick={() => {
                  if (
                    nullCheck(contactFormData.name) ||
                    nullCheck(contactFormData.phoneNumber) ||
                    nullCheck(contactFormData.email) ||
                    nullCheck(contactFormData.inquirerType) ||
                    nullCheck(contactFormData.inquirerTypeTitle) ||
                    nullCheck(contactFormData.inquiryType) ||
                    nullCheck(contactFormData.inquiryTypeTitle) ||
                    nullCheck(contactFormData.title) ||
                    nullCheck(contactFormData.content)
                  ) {
                    return;
                  } else {
                    setContactModal(true);
                  }
                }}
              />
            </div>
          </div>
        </div>
        {contactModal ? (
          <ContactModal
            isOpen={setContactModal}
            onClick={async () => {
              try {
                const obj = {
                  email: contactFormData.email,
                  etc: contactFormData.content,
                  inquirerType: contactFormData.inquirerType,
                  inquiryCategory: contactFormData.inquiryType,
                  isMember: contactFormData.subscribe,
                  name: contactFormData.name,
                  phoneNumber: contactFormData.phoneNumber,
                  title: contactFormData.title,
                };
                await ContactApi.ContactNormal(obj);
                toast("문의가 제출되었습니다");
              } catch (error) {
                setContactModal(false);
                errorHandler(error);
              }
            }}
          />
        ) : null}

        <Footer />
      </Container>
    </>
  );
}

export default Contact;

const InputTitle = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
  margin-bottom: 10px;

  &::after {
    content: " *";
    color: #00e4bb;
  }
`;
