import React from "react";
import styled from "styled-components";
import useDisplay from "../../../hooks/useDisplay";
import { keyboard } from "@testing-library/user-event/dist/keyboard";

function SearchInput({
  placeholder,
  style,
  inputStyle,
  iconStyle,
  onChange,
  onClick,
  onKeyDown,
  value,
  onBlur,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          position: "relative",
          ...style,
        }}
      >
        <InputWrapper
          id={"searchInputFocusFlag"}
          style={{
            width: `${isMobile ? "100%" : "700px"}`,
            height: `${isMobile ? "43px%" : "50px"}`,
            borderRadius: "25.5px",
            border: "1px solid #FFF",
            background: "#FFF",
            paddingLeft: "22px",
            paddingRight: "50px",

            color: "#333333",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            ...inputStyle,
          }}
          value={value || ""}
          placeholder={placeholder}
          onChange={(e) => {
            if (e.target.value.length > 20) {
              e.target.value = e.target.value.slice(0, 20);
            }
            onChange(e);
          }}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />

        <img
          src="/assets/icons/icon_search.svg"
          alt=""
          style={{
            position: "absolute",
            right: "25px",
            top: "12px",
            cursor: "pointer",
            ...iconStyle,
          }}
          onClick={onClick}
        />
      </div>
    </>
  );
}

export default SearchInput;

const InputWrapper = styled.input.attrs((props) => {})`
  :focus {
    outline: none !important;
    border-color: #7e53ff;
  }

  ::placeholder {
    color: #939393;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;
