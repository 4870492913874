import React from "react";
import PretendardText from "../pretendardText";

function FilterBtn({ style, title, onClick, active }) {
  return (
    <>
      <PretendardText
        style={{
          fontWeight: active ? "600" : "400",
          fontSize: "16px",
          lineHeight: "24px",
          color: active ? "#151515" : "#BABABA",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "34px",
          background: active ? "#00E4BB" : "#565656",
          borderRadius: "100px",
          padding: "0px 12px",
          cursor: "pointer",
          ...style,
        }}
        onClick={onClick}
      >
        {title}
      </PretendardText>
    </>
  );
}

export default FilterBtn;
