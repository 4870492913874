export const contactMenu3 = {
  title: "영상 스타일",
  value: "1",
  subMenus: [
    {
      title: "공공 행정",
      value: "1",
    },
    {
      title: "푸드 요리",
      value: "2",
    },
    {
      title: "행사",
      value: "3",
    },
    {
      title: "건축 공간",
      value: "4",
    },
    {
      title: "교육",
      value: "5",
    },
  ],
};
