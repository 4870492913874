import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import PretendardText from "../../components/atoms/pretendardText";
import TitleLabel from "../../components/atoms/text/titleLabel";
import PasswordInput from "../../components/atoms/input/passwordInput";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import useDisplay from "../../hooks/useDisplay";
import Footer from "../../components/organisms/footer";
import NavM from "../../components/organisms/navM";
import useModal from "../../hooks/useModal";
import { nullCheck } from "../../utils/check";
import { regExpression } from "../../utils/reg-expresstion";
import { errorHandler } from "../../utils/error-handler";
import UserApi from "../../api/user";
import { toast } from "react-toastify";

function ChangePwModal() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const modal = useModal();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.getElementById("container").style.cssText = `
          height: 951px;          
          overflow: hidden;
          background: #151515;
          `;
    return () => {
      document.getElementById("container").style.cssText = `
      min-height: 100vh;
      overflow: auto;
      background: #151515;
      `;
    };
  }, []);

  const [changePwData, setChangePwData] = useState({
    password: "",
    passwordCheck: "",
  });
  const [pwErrorM, setPwErrorM] = useState("");
  const [pwCheckErrorM, setPwCheckErrorM] = useState("");

  const pwCheck = useMemo(() => {
    if (
      !nullCheck(changePwData.password) &&
      !regExpression.password.test(changePwData.password)
    ) {
      setPwErrorM("영문/숫자 조합 6~16자를 입력해 주세요.");
      return false;
    }
    if (nullCheck(changePwData.password)) {
      setPwErrorM("");
      return false;
    }
    setPwErrorM("");
    return true;
  }, [changePwData]);

  const pwCheckCheck = useMemo(() => {
    if (
      !nullCheck(changePwData.passwordCheck) &&
      !regExpression.password.test(changePwData.passwordCheck)
    ) {
      setPwCheckErrorM("영문/숫자 조합 6~16자를 입력해 주세요.");
      return false;
    }
    if (
      !nullCheck(changePwData.passwordCheck) &&
      changePwData.password !== changePwData.passwordCheck
    ) {
      setPwCheckErrorM("비밀번호가 일치하지 않습니다.");
      return false;
    }
    if (nullCheck(changePwData.passwordCheck)) {
      setPwCheckErrorM("");
      return false;
    }
    setPwCheckErrorM("");
    return true;
  }, [changePwData]);

  const btnFlag = useMemo(() => {
    return pwCheck && pwCheckCheck;
  }, [pwCheck, pwCheckCheck]);

  const changeFunc = async () => {
    try {
      const formData = new FormData();
      formData.append("email", sessionStorage.getItem("email"));
      formData.append("password", changePwData.password);

      await UserApi.changePw(formData);
      toast("비밀번호가 변경되었습니다.");
      sessionStorage.clear();
      modal.close();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      {isMobile ? (
        <div>
          <div
            style={{
              background: "#151515",
              width: "100vw",
              height: "951px",
              zIndex: 10000,
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
              overflow: "auto",
            }}
          >
            <NavM />

            <div
              style={{
                minHeight: "532px",
                width: "100%",
                padding: "0px 20px",
                marginBottom: "70px",
              }}
            >
              <div
                style={{
                  marginTop: "120px",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  비밀번호 변경
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#00E4BB",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    marginTop: "5px",
                  }}
                >
                  새로운 비밀번호를 설정해주세요.
                </PretendardText>
              </div>

              <TitleLabel title={"비밀번호"} isRequired={true}></TitleLabel>
              <PasswordInput
                placeholder={"영문/숫자 조합 6~16자"}
                errorText={pwErrorM}
                isError={!nullCheck(pwErrorM)}
                onChange={(e) => {
                  changePwData.password = e.target.value.trim();
                  setChangePwData({ ...changePwData });
                }}
              ></PasswordInput>

              <TitleLabel
                title={"비밀번호 확인"}
                isRequired={true}
              ></TitleLabel>
              <PasswordInput
                placeholder={"영문/숫자 조합 6~16자"}
                isError={!nullCheck(pwCheckErrorM)}
                onChange={(e) => {
                  changePwData.passwordCheck = e.target.value.trim();
                  setChangePwData({ ...changePwData });
                }}
              ></PasswordInput>

              <PrimaryBtn
                title={"변경완료"}
                disabled={!btnFlag}
                style={{
                  width: "100%",
                  height: "48px",
                  marginTop: "40px",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => {
                  changeFunc();
                }}
              ></PrimaryBtn>
            </div>

            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              background: "rgba(30, 30, 30, 0.50)",
              backdropFilter: "blur(32.5px)",
              width: "100vw",
              height: "100vh",
              zIndex: 10000,
              display: "flex",
              position: "fixed",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
            }}
          ></div>
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 10001,
            }}
          >
            <div
              style={{
                width: "520px",
                height: "100%",
                borderRadius: "15px",
                background: "#151515",
                boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.20)",
                padding: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  비밀번호 변경
                </PretendardText>
                <img
                  src="/assets/icons/icon_close.svg"
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.close();
                  }}
                />
              </div>

              <PretendardText
                style={{
                  color: "#00E4BB",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  marginTop: "10px",
                }}
              >
                새로운 비밀번호를 설정해주세요.
              </PretendardText>

              <div
                style={{
                  height: "1px",
                  marginTop: "25px",
                  background: "#939393",
                }}
              ></div>

              <TitleLabel title={"비밀번호"} isRequired={true}></TitleLabel>
              <PasswordInput
                placeholder={"영문/숫자 조합 6~16자"}
                errorText={pwErrorM}
                isError={!nullCheck(pwErrorM)}
                onChange={(e) => {
                  changePwData.password = e.target.value.trim();
                  setChangePwData({ ...changePwData });
                }}
              ></PasswordInput>

              <TitleLabel
                title={"비밀번호 확인"}
                isRequired={true}
              ></TitleLabel>
              <PasswordInput
                placeholder={"영문/숫자 조합 6~16자"}
                errorText={pwCheckErrorM}
                isError={!nullCheck(pwCheckErrorM)}
                onChange={(e) => {
                  changePwData.passwordCheck = e.target.value.trim();
                  setChangePwData({ ...changePwData });
                }}
              ></PasswordInput>

              <PrimaryBtn
                title={"변경완료"}
                disabled={!btnFlag}
                style={{
                  width: "100%",
                  height: "48px",
                  marginTop: "40px",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => {
                  changeFunc();
                }}
              ></PrimaryBtn>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChangePwModal;
