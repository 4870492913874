import React, { useState, useEffect } from "react";

function CheckboxInput({ size, checked, onChange, disabled }) {
    return (
        <>
            {checked ? (
                <img
                    onClick={onChange}
                    src="/assets/icons/icon_checkbox_checked.svg"
                    style={{
                        height: size ? size : 18,
                        width: size ? size : 18,
                        cursor: "pointer",
                    }}
                    alt=""
                />
            ) : (
                disabled ? <img                    
                    src="/assets/icons/icon_checkbox_disabled.svg"
                    style={{
                        height: size ? size : 18,
                        width: size ? size : 18,
                        cursor: "pointer",
                    }}
                    alt=""
                /> : <img
                    onClick={onChange}
                    src="/assets/icons/icon_checkbox_empty.svg"
                    style={{
                        height: size ? size : 18,
                        width: size ? size : 18,
                        cursor: "pointer",
                    }}
                    alt=""
                />
            )}
        </>
    );
}

export default CheckboxInput;
