import React, { useState } from "react";
import styled from "styled-components";
import PretendardText from "../pretendardText";

function SelectBox2({ style, title, optionList, onClickSelect, onClickOption, isOpen, closeFunc, value, values }) {
  const [hover, setHover] = useState(-1);

  return (
    <>
      <div
        style={{
          position: "relative",
          ...style,
        }}
      >
        <Select onClick={onClickSelect}>
          <PretendardText
            style={{
              color: value ? "#dfdfdf" : "#939393",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
            }}
          >
            {title}
          </PretendardText>
          <img src="/assets/icons/icon_arrow.svg" alt="" style={{ transform: isOpen ? "rotate(180deg)" : "" }} />
        </Select>
        {isOpen ? (
          <Option>
            {optionList.map((item, index) => {
              return (
                <PretendardText
                  key={index}
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(-1)}
                  style={{
                    width: "100%",
                    height: "52px",
                    background: hover === index ? "#333333" : "#1E1E1E",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 20px",
                    cursor: "pointer",

                    color: values?.includes(item.value) ? "#00E4BB" : "#939393",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    position: "relative",
                  }}
                  onClick={() => {
                    onClickOption(item);
                    closeFunc();
                  }}
                >
                  {item.title}
                  {values?.includes(item.value) ? (
                    <img
                      src="/assets/icons/icon_check.svg"
                      alt=""
                      style={{
                        width: "20px",
                        height: "20px",
                        position: "absolute",
                        right: "20px",
                      }}
                    />
                  ) : null}
                </PretendardText>
              );
            })}
          </Option>
        ) : null}
      </div>
    </>
  );
}

export default SelectBox2;

const Select = styled.div.attrs((props) => {})`
  width: 100%;
  height: 52px;
  border-radius: 5px;
  border: 1px solid #939393;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const Option = styled.div.attrs((props) => {})`
  width: 100%;
  min-height: 104px;
  max-height: 270px;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.2));
  margin-top: 4px;
  border-radius: 5px;
  border: 1px solid #939393;
  overflow-y: auto;
  position: absolute;
  z-index: 99;
`;
