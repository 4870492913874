import React from "react";
import PretendardText from "../pretendardText";

function WhiteEmptyBtn({ style, title, onClick, disabled }) {
  return (
    <>
      <PretendardText
        style={{
          width: "160px",
          height: "54px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "30px",
          border: disabled ? "1px solid #565656" : "1px solid #BABABA",
          cursor: "pointer",
          backdropFilter: "blur(1px)",

          color: disabled ? "#565656" : "#FFFFFF",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "30px",
          ...style,
        }}
        onClick={() => {
          if (disabled) {
            return;
          }
          onClick();
        }}
      >
        {title}
      </PretendardText>
    </>
  );
}

export default WhiteEmptyBtn;
