import React from "react";
import { useRecoilState } from "recoil";
import { contactModalState } from "../../../recoil/modalState";
import useDisplay from "../../../hooks/useDisplay";
import PretendardText from "../../atoms/pretendardText";
import RadioInput from "../../atoms/input/radioInput";
import WhiteBtn from "../../atoms/btn/whiteBtn";
import PrimaryBtn from "../../atoms/btn/primaryBtn";

function ContactModal({ isOpen, onClick }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div>
        <div
          style={{
            background: "rgba(30, 30, 30, 0.50)",
            backdropFilter: "blur(32.5px)",
            width: "100vw",
            height: "100vh",
            zIndex: 10000,
            display: "flex",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            left: 0,
          }}
        ></div>
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 10001,
          }}
        >
          <div
            style={{
              width: isMobile ? "335px" : "520px",
              height: "100%",
              borderRadius: "15px",
              background: "#151515",
              boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.20)",
              padding: isMobile ? "30px" : "40px",
            }}
          >
            <PretendardText
              style={{
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "35.2px",
                color: "#FFFFFF",
                textAlign: "center",
              }}
            >
              문의를 제출하시나요?
              <br />
              작성된 내용은 수정이 불가능합니다.
            </PretendardText>
            <div
              style={{
                marginTop: isMobile ? "30px" : "40px",
                display: "flex",
                gap: "15px",
                justifyContent: "center",
              }}
            >
              <WhiteBtn
                title={"취소"}
                style={{
                  width: "78px",
                  height: "34px",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#000000",
                }}
                onClick={() => {
                  isOpen(false);
                }}
              ></WhiteBtn>
              <PrimaryBtn
                title={"확인"}
                style={{
                  width: "78px",
                  height: "34px",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#000000",
                }}
                onClick={() => {
                  onClick();
                  isOpen(false);
                }}
              ></PrimaryBtn>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactModal;
