import React, { useEffect, useState } from "react";
import PretendardText from "../atoms/pretendardText";
import { useLocation, useHistory } from "react-router-dom";
import { route } from "../../routes/route";
import useDisplay from "../../hooks/useDisplay";
import { loginState } from "../../recoil/loginState";
import { useResetRecoilState } from "recoil";
import UserApi from "../../api/user";
import { errorHandler } from "../../utils/error-handler";
import useModal from "../../hooks/useModal";

function MypageSide() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const modal = useModal();
  const location = useLocation();
  const history = useHistory();
  const loginReset = useResetRecoilState(loginState);

  //회원 정보 가져오기
  const [data, setData] = useState({});
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      if (sessionStorage.getItem("id")) {
        let data = (await UserApi.getById()).data.data;
        setData(data);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return isMobile ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "32px",
        justifyContent: "center",
      }}
    >
      <PretendardText
        style={{
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
          color: location.pathname.includes(route.account) ? "#00E4BB" : "#939393",
          height: "64px",
          borderBottom: location.pathname.includes(route.account) ? "1px solid #00E4BB" : "",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          history.push(route.account);
        }}
      >
        계정관리
      </PretendardText>
      <PretendardText
        style={{
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
          color: location.pathname.includes(route.subscribe) ? "#00E4BB" : "#939393",
          height: "64px",
          borderBottom: location.pathname.includes(route.subscribe) ? "1px solid #00E4BB" : "",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          history.push(route.subscribe + `/1`);
        }}
      >
        구독관리
      </PretendardText>
      <PretendardText
        style={{
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
          color: location.pathname.includes(route.payHistory) ? "#00E4BB" : "#939393",
          height: "64px",
          borderBottom: location.pathname.includes(route.payHistory) ? "1px solid #00E4BB" : "",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          history.push(route.payHistory + `/1`);
        }}
      >
        결제내역
      </PretendardText>
      <PretendardText
        style={{
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
          color: location.pathname.includes(route.library) ? "#00E4BB" : "#939393",
          height: "64px",
          borderBottom: location.pathname.includes(route.library) ? "1px solid #00E4BB" : "",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          history.push(route.library + `/1?tab=1`);
        }}
      >
        라이브러리
      </PretendardText>
    </div>
  ) : (
    <>
      <div
        style={{
          width: "340px",
          background: "#1E1E1E",
          paddingLeft: "48px",
          paddingTop: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img
            src={data.fileUrl ?? "/assets/icons/profile_empty.svg"}
            alt=""
            style={{ width: "35px", height: "35px", borderRadius: "50%" }}
          />
          <PretendardText
            style={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#939393",
            }}
          >
            {data.name}님
          </PretendardText>
        </div>
        <PretendardText
          style={{
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "28.8px",
            color: location.pathname.includes(route.account) ? "#00E4BB" : "#939393",
            marginTop: "33px",
            cursor: "pointer",
          }}
          onClick={() => {
            history.push(route.account);
          }}
        >
          계정관리
        </PretendardText>
        <PretendardText
          style={{
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "28.8px",
            color: location.pathname.includes(route.subscribe) ? "#00E4BB" : "#939393",
            marginTop: "26px",
            cursor: "pointer",
          }}
          onClick={() => {
            history.push(route.subscribe + `/1`);
          }}
        >
          구독관리
        </PretendardText>
        <PretendardText
          style={{
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "28.8px",
            color: location.pathname.includes(route.payHistory) ? "#00E4BB" : "#939393",
            marginTop: "26px",
            cursor: "pointer",
          }}
          onClick={() => {
            history.push(route.payHistory + `/1`);
          }}
        >
          결제내역
        </PretendardText>
        <PretendardText
          style={{
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "28.8px",
            color: location.pathname.includes(route.library) ? "#00E4BB" : "#939393",
            marginTop: "26px",
            cursor: "pointer",
          }}
          onClick={() => {
            history.push(route.library + `/1?tab=1`);
          }}
        >
          라이브러리
        </PretendardText>
        <PretendardText
          style={{
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "21px",
            color: "#939393",
            marginTop: "83px",
            cursor: "pointer",
            textDecorationLine: "underline",
          }}
          onClick={() => {
            modal.close();
            sessionStorage.clear();
            loginReset();
            history.push(route.home);
          }}
        >
          로그아웃
        </PretendardText>
        <PretendardText
          style={{
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "21px",
            color: "#939393",
            marginTop: "15px",
            cursor: "pointer",
            textDecorationLine: "underline",
          }}
          onClick={() => modal.open("leave")}
        >
          회원탈퇴
        </PretendardText>
      </div>
    </>
  );
}

export default MypageSide;
