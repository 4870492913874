import React, { useState, useEffect } from "react";
import styled from "styled-components";

const MuktaText = (props) => {
  return <MuktaTextWrapper {...props} />;
};

export default MuktaText;

const MuktaTextWrapper = styled.div.attrs((props) => {})`
  font-family: "Mukta", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;

  display: block;
`;
