export const contactMenu2 = {
  title: "영상 촬영 기법",
  value: "1",
  subMenus: [
    {
      title: "고정 촬영",
      value: "1",
    },
    {
      title: "짐벌 촬영",
      value: "2",
    },
    {
      title: "하이퍼랩스 촬영",
      value: "3",
    },
    {
      title: "일반 드론 촬영",
      value: "4",
    },
    {
      title: "FPV드론 촬영",
      value: "5",
    },
    {
      title: "슬로우 촬영",
      value: "6",
    },
    {
      title: "액션 팔로우",
      value: "7",
    },
    {
      title: "행사 중계",
      value: "8",
    },
    {
      title: "지미집 촬영",
      value: "9",
    },
    {
      title: "타임랩스 촬영",
      value: "10",
    },
    {
      title: "4K 촬영",
      value: "11",
    },
    {
      title: "8K 이상 촬영",
      value: "12",
    },
    {
      title: "매크로 촬영",
      value: "13",
    },
  ],
};
