import React, { useMemo, useState } from "react";
import PretendardText from "../../components/atoms/pretendardText";
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  findEmailModalState,
  findPwModalState,
  joinModalState,
  loginModalState,
} from "../../recoil/modalState";
import TextInput from "../../components/atoms/input/textInput";
import PasswordInput from "../../components/atoms/input/passwordInput";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import TitleLabel from "../../components/atoms/text/titleLabel";
import useDisplay from "../../hooks/useDisplay";
import Footer from "../../components/organisms/footer";
import NavM from "../../components/organisms/navM";
import { nullCheck } from "../../utils/check";
import { errorHandler } from "../../utils/error-handler";
import UserApi from "../../api/user";
import { toast } from "react-toastify";
import { regExpression } from "../../utils/reg-expresstion";
import useModal from "../../hooks/useModal";
import { loginState } from "../../recoil/loginState";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useRef } from "react";
import api from "../../api/api";

function LoginModal() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const modal = useModal();

  useEffect(() => {
    document.getElementById("container").style.cssText = `
      height: 1135px;      
      overflow: hidden;
      background: #151515;
      `;
    return () => {
      document.getElementById("container").style.cssText = `
      min-height: 100vh;
      overflow: auto;
      background: #151515;
      `;
    };
  }, []);

  const [loginData, setLoginData] = useState({
    account: "",
    password: "",
  });
  const [emailErrorM, setEmailErrorM] = useState("");
  const [pwErrorM, setPwErrorM] = useState("");

  const accountCheck = useMemo(() => {
    if (
      !nullCheck(loginData.account) &&
      !regExpression.email.test(loginData.account)
    ) {
      setEmailErrorM("올바른 형식을 입력해 주세요.");
      return false;
    }
    if (nullCheck(loginData.account)) {
      setEmailErrorM("");
      return false;
    }
    setEmailErrorM("");
    return true;
  }, [loginData]);

  const pwCheck = useMemo(() => {
    if (
      !nullCheck(loginData.password) &&
      !regExpression.password.test(loginData.password)
    ) {
      setPwErrorM("영문/숫자 조합 6~16자를 입력해 주세요.");
      return false;
    }
    if (nullCheck(loginData.password)) {
      setPwErrorM("");
      return false;
    }
    setPwErrorM("");
    return true;
  }, [loginData]);

  const loginBtnFlag = useMemo(() => {
    return !(accountCheck && pwCheck);
  }, [accountCheck, pwCheck]);

  const setLoginState = useSetRecoilState(loginState);

  const loginFunc = async () => {
    try {
      const data = {
        account: loginData.account,
        password: loginData.password,
      };

      const response1 = await UserApi.LoginNormal(data);

      sessionStorage.setItem("token", response1.data.data.accessToken);
      sessionStorage.setItem("id", response1.data.data.id);

      const response2 = await UserApi.getById();
      sessionStorage.setItem("name", response2.data.data.name);
      sessionStorage.setItem("fileUrl", response2.data.data.fileUrl);

      setLoginState({
        id: sessionStorage.getItem("id"),
        token: sessionStorage.getItem("token"),
        name: sessionStorage.getItem("name"),
      });

      modal.close();
    } catch (error) {
      toast("계정 정보가 올바르지 않습니다.");
      if (error.response.status === 404) {
        return setEmailErrorM("존재하지 않는 회원입니다.");
      }
      if (error.response.status === 401) {
        return setPwErrorM(`비밀번호가 일치하지 않습니다.`);
      }
      errorHandler(error);
    }
  };

  // 소셜로그인
  const socialLogin = async (socialId, joinType) => {
    try {
      // 가입되어있는지 확인
      const formData = new FormData();
      formData.append("socialToken ", socialId);
      formData.append("joinType", joinType);

      const response = await UserApi.LoginSocial(formData);

      // 가입되어있는 경우
      sessionStorage.setItem("token", response.data.data.accessToken);
      sessionStorage.setItem("id", response.data.data.id);

      const response2 = await UserApi.getById();
      sessionStorage.setItem("name", response2.data.data.name);
      sessionStorage.setItem("fileUrl", response2.data.data.fileUrl);

      setLoginState({
        id: sessionStorage.getItem("id"),
        token: sessionStorage.getItem("token"),
        name: sessionStorage.getItem("name"),
      });

      modal.close();
      return true;
    } catch (error) {
      // 가입되어있지 않은 경우
      if (error.response.status === 404) {
        return false;
      }
    }
  };

  // 네이버로그인
  const initNaver = new window.naver.LoginWithNaverId({
    clientId: "_myv1hZyL0pFp5m_X46n",
    callbackUrl: `${api.getUrl()}/bestock/home?naver_login=true`,
    isPopup: false,
    loginButton: { color: "green", type: 2, height: 40 },
  });

  useEffect(() => {
    initNaver.init();
  }, [isMobile]);

  useEffect(() => {
    if (initNaver.accessToken) {
      initNaver.getLoginStatus((status) => {
        if (status) {
          const socialId = initNaver.user.id;

          if (!nullCheck(sessionStorage.getItem("socialModalFlag"))) {
            socialLogin(socialId, "NAVER").then((joinFlag) => {
              if (joinFlag) {
                modal.close();
              } else {
                sessionStorage.setItem("socialToken", socialId);
                sessionStorage.setItem("joinType", "NAVER");
                sessionStorage.setItem(
                  "email",
                  initNaver.user.email ? initNaver.user.email : ""
                );
                sessionStorage.setItem(
                  "name",
                  initNaver.user.name ? initNaver.user.name : ""
                );
                modal.open("join");
              }
            });
          }
        }
      });
    }
  }, []);

  const naverLogin = () => {
    const naverLoginBtn = document.getElementById("naverIdLogin").firstChild;
    naverLoginBtn.click();
    sessionStorage.setItem("socialModalFlag", true);
  };

  // 카카오 로그인
  const initKakao = () => {
    const jsKey = "eddf7762667113ff736ab218658a4c59";
    const Kakao = window.Kakao;
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init(jsKey);
    }
  };

  useEffect(() => {
    initKakao();
  }, [isMobile]);

  const kakaoLogin = () => {
    window.Kakao.Auth.login({
      success() {
        window.Kakao.API.request({
          url: "/v2/user/me",
          success(res) {
            const socialId = res.id;
            const kakaoAccount = res.kakao_account;

            socialLogin(socialId, "KAKAO").then((joinFlag) => {
              if (joinFlag) {
              } else {
                sessionStorage.setItem("socialToken", socialId);
                sessionStorage.setItem("joinType", "KAKAO");

                if (kakaoAccount.email) {
                  sessionStorage.setItem("email", kakaoAccount.email);
                }
                if (kakaoAccount.name) {
                  sessionStorage.setItem("name", kakaoAccount.name);
                }
                modal.open("join");
              }
            });
          },
          fail(error) {},
        });
      },
      fail(error) {},
    });
  };

  // 구글로그인
  const googleClientId =
    "40587346059-rnu4q64g4mtc0ijsrup86rjmq5nkgbna.apps.googleusercontent.com";

  const onSuccess = (res) => {
    const googleInfo = jwtDecode(res.credential);
    sessionStorage.setItem("socialToken", googleInfo.sub);
    sessionStorage.setItem("joinType", "GOOGLE");
    sessionStorage.setItem("email", googleInfo.email ? googleInfo.email : "");
    sessionStorage.setItem(
      "name",
      googleInfo.family_name + googleInfo.given_name
    );
    modal.open("join");
  };

  const onFailure = (err) => {
    // console.log("err", err);
  };

  const googleLogin = () => {
    const googleBtn = document.querySelector(
      '[aria-labelledby="button-label"]'
    );
    googleBtn?.click();
  };

  return (
    <>
      {isMobile ? (
        <>
          <div
            style={{
              background: "#151515",
              width: "100vw",
              height: "1135px",
              zIndex: 10000,
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
              overflow: "auto",
            }}
          >
            <NavM />

            <div
              style={{
                minHeight: "716px",
                width: "100%",
                padding: "0px 20px",
                marginBottom: "70px",
              }}
            >
              <div
                style={{
                  marginTop: "161px",
                }}
              >
                <PretendardText
                  style={{
                    fontWeight: "700",
                    fontSize: "22px",
                    lineHeight: "35.2px",
                    color: "#FFFFFF",
                  }}
                >
                  로그인
                </PretendardText>

                <TitleLabel title={"이메일"} isRequired={true}></TitleLabel>
                <TextInput
                  placeholder={"이메일을 입력해 주세요."}
                  errorText={emailErrorM}
                  isError={!nullCheck(emailErrorM)}
                  onChange={(e) => {
                    loginData.account = e.target.value.trim();
                    setLoginData({ ...loginData });
                  }}
                  value={loginData.account}
                  maxLength={50}
                ></TextInput>

                <TitleLabel title={"비밀번호"} isRequired={true}></TitleLabel>
                <PasswordInput
                  placeholder={"영문/숫자 조합 6~16자"}
                  errorText={pwErrorM}
                  isError={!nullCheck(pwErrorM)}
                  onChange={(e) => {
                    loginData.password = e.target.value.trim();
                    setLoginData({ ...loginData });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (!loginBtnFlag) {
                        loginFunc();
                      }
                    }
                  }}
                ></PasswordInput>

                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    gap: "13px",
                    justifyContent: "end",
                  }}
                >
                  <PretendardText
                    style={{
                      color: "#939393",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "19.6px",
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      modal.open("findEmail");
                    }}
                  >
                    이메일 찾기
                  </PretendardText>
                  <PretendardText
                    style={{
                      color: "#939393",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "19.6px",
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      modal.open("findPw");
                    }}
                  >
                    비밀번호 찾기
                  </PretendardText>
                </div>

                <PrimaryBtn
                  title={"로그인"}
                  disabled={loginBtnFlag}
                  style={{
                    width: "100%",
                    height: "48px",
                    borderRadius: "60px",
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginTop: "30px",
                  }}
                  onClick={() => {
                    loginFunc();
                  }}
                ></PrimaryBtn>

                <div
                  style={{
                    height: "1px",
                    marginTop: "30px",
                    background: "rgba(147, 147, 147, 0.40)",
                  }}
                ></div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <div id="naverIdLogin" style={{ display: "none" }}></div>
                  <img
                    src="/assets/icons/ic_naver.svg"
                    alt=""
                    style={{ cursor: "pointer", width: "50px", height: "50px" }}
                    onClick={() => {
                      naverLogin();
                    }}
                  />
                  <img
                    src="/assets/icons/ic_kakao.svg"
                    alt=""
                    style={{ cursor: "pointer", width: "50px", height: "50px" }}
                    onClick={() => {
                      kakaoLogin();
                    }}
                  />
                  <img
                    src="/assets/icons/ic_google.svg"
                    alt=""
                    style={{ cursor: "pointer", width: "50px", height: "50px" }}
                  />
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", gap: "5px" }}>
                    <PretendardText
                      style={{
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "19.6px",
                      }}
                    >
                      회원이 아니신가요?
                    </PretendardText>
                    <PretendardText
                      style={{
                        color: "#00E4BB",
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "19.6px",
                        textDecorationLine: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        modal.open("join");
                      }}
                    >
                      회원가입
                    </PretendardText>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </>
      ) : (
        <div>
          <div
            style={{
              background: "rgba(30, 30, 30, 0.50)",
              backdropFilter: "blur(32.5px)",
              width: "100vw",
              height: "100vh",
              zIndex: 10000,
              display: "flex",
              position: "fixed",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
            }}
          ></div>
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 10001,
            }}
          >
            <div
              style={{
                width: "520px",
                height: "100%",
                borderRadius: "15px",
                background: "#151515",
                boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.20)",
                padding: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  로그인
                </PretendardText>
                <img
                  src="/assets/icons/icon_close.svg"
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.close();
                  }}
                />
              </div>
              <TitleLabel title={"이메일"} isRequired={true}></TitleLabel>
              <TextInput
                placeholder={"이메일을 입력해 주세요."}
                errorText={emailErrorM}
                isError={!nullCheck(emailErrorM)}
                onChange={(e) => {
                  loginData.account = e.target.value.trim();
                  setLoginData({ ...loginData });
                }}
                value={loginData.account}
                maxLength={50}
              ></TextInput>
              <TitleLabel title={"비밀번호"} isRequired={true}></TitleLabel>
              <PasswordInput
                placeholder={"영문/숫자 조합 6~16자"}
                errorText={pwErrorM}
                isError={!nullCheck(pwErrorM)}
                onChange={(e) => {
                  loginData.password = e.target.value.trim();
                  setLoginData({ ...loginData });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (!loginBtnFlag) {
                      loginFunc();
                    }
                  }
                }}
              ></PasswordInput>
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "end",
                  gap: "16px",
                }}
              >
                <PretendardText
                  style={{
                    color: "#939393",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "22.4px",
                    textDecorationLine: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.open("findEmail");
                  }}
                >
                  이메일 찾기
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#939393",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "22.4px",
                    textDecorationLine: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.open("findPw");
                  }}
                >
                  비밀번호 찾기
                </PretendardText>
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <div id="naverIdLogin" style={{ display: "none" }}></div>
                <img
                  src="/assets/icons/ic_naver.svg"
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    naverLogin();
                  }}
                />
                <img
                  src="/assets/icons/ic_kakao.svg"
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    kakaoLogin();
                  }}
                />
                <img
                  src="/assets/icons/ic_google.svg"
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    googleLogin();
                  }}
                />
                <div style={{ display: "none" }}>
                  <GoogleOAuthProvider clientId={googleClientId}>
                    <GoogleLogin
                      onSuccess={(res) => {
                        onSuccess(res);
                      }}
                      onFailure={(err) => {
                        onFailure(err);
                      }}
                    />
                  </GoogleOAuthProvider>
                </div>
              </div>
              <div
                style={{
                  height: "1px",
                  marginTop: "30px",
                  background: "rgba(147, 147, 147, 0.40)",
                }}
              ></div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", gap: "5px" }}>
                  <PretendardText
                    style={{
                      color: "#FFFFFF",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "22.4px",
                    }}
                  >
                    회원이 아니신가요?
                  </PretendardText>
                  <PretendardText
                    style={{
                      color: "#00E4BB",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "22.4px",
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      modal.open("join");
                    }}
                  >
                    회원가입
                  </PretendardText>
                </div>
                <PrimaryBtn
                  title={"로그인"}
                  disabled={loginBtnFlag}
                  onClick={() => {
                    loginFunc();
                  }}
                ></PrimaryBtn>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginModal;
