import React, { useState, useEffect } from "react";

function RadioInput({ size, checked, onChange }) {
  return (
    <>
      {checked ? (
        <img
          onClick={onChange}
          src="/assets/icons/icon_radio_checked.svg"
          style={{
            height: size ? size : 18,
            width: size ? size : 18,
            cursor: "pointer",
          }}
          alt=""
        />
      ) : (
        <img
          onClick={onChange}
          src="/assets/icons/icon_radio_empty.svg"
          style={{
            height: size ? size : 18,
            width: size ? size : 18,
            cursor: "pointer",
          }}
          alt=""
        />
      )}
    </>
  );
}

export default RadioInput;
