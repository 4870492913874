import React, { useEffect, useMemo, useRef, useState } from "react";
import PretendardText from "../../components/atoms/pretendardText";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import TitleLabel from "../../components/atoms/text/titleLabel";
import TextInput from "../../components/atoms/input/textInput";
import CheckboxInput from "../../components/atoms/input/checkboxInput";
import PasswordInput from "../../components/atoms/input/passwordInput";
import useDisplay from "../../hooks/useDisplay";
import NavM from "../../components/organisms/navM";
import Footer from "../../components/organisms/footer";
import useModal from "../../hooks/useModal";
import { errorHandler } from "../../utils/error-handler";
import UserApi from "../../api/user";
import { nullCheck } from "../../utils/check";
import { regExpression } from "../../utils/reg-expresstion";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { route } from "../../routes/route";

function JoinModal() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const modal = useModal();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.getElementById("container").style.cssText = `
          height: 1667px;          
          overflow: hidden;
          background: #151515;
          `;
    return () => {
      document.getElementById("container").style.cssText = `
      min-height: 100vh;
      overflow: auto;
      background: #151515;
      `;
    };
  }, []);

  const [joinData, setJoinData] = useState({
    name: "",
    email: "",
    password: "",
    passwordCheck: "",
    phoneNumber: "",
    recommender: "",
    agree1: false,
    agree2: false,
    agreeToNews: false,
    joinType: "NORMAL",
    socialToken: "",
  });

  const [isSocial, setIsSocial] = useState(false);

  useEffect(() => {
    const prevData = JSON.parse(sessionStorage.getItem("joinData"));

    if (sessionStorage.getItem("joinType") === "NAVER") {
      if (nullCheck(prevData)) {
        joinData.email = sessionStorage.getItem("email");
        joinData.name = sessionStorage.getItem("name");
        joinData.password = "adfjdsabi&$%($ba";
        joinData.passwordCheck = "adfjdsabi&$%($ba";
        joinData.joinType = sessionStorage.getItem("joinType");
        joinData.socialToken = sessionStorage.getItem("socialToken");
        setJoinData({ ...joinData });
      } else {
        setJoinData({ ...prevData });
        setPhoneCheck(true);
      }
      setEmailFlag(true);
      setNameFlag(true);
      setIsSocial(true);
    }

    if (sessionStorage.getItem("joinType") === "KAKAO") {
      if (nullCheck(prevData)) {
        joinData.email = sessionStorage.getItem("email")
          ? sessionStorage.getItem("email")
          : "";
        joinData.name = sessionStorage.getItem("name")
          ? sessionStorage.getItem("name")
          : "";
        joinData.password = "adfjdsabi&$%($ba";
        joinData.passwordCheck = "adfjdsabi&$%($ba";
        joinData.joinType = sessionStorage.getItem("joinType");
        joinData.socialToken = sessionStorage.getItem("socialToken");
        setJoinData({ ...joinData });
      } else {
        setJoinData({ ...prevData });
        setPhoneCheck(true);
      }

      setIsSocial(true);
    }

    if (sessionStorage.getItem("joinType") === "GOOGLE") {
      if (nullCheck(prevData)) {
        joinData.email = sessionStorage.getItem("email")
          ? sessionStorage.getItem("email")
          : "";
        joinData.name = sessionStorage.getItem("name")
          ? sessionStorage.getItem("name")
          : "";
        joinData.password = "adfjdsabi&$%($ba";
        joinData.passwordCheck = "adfjdsabi&$%($ba";
        joinData.joinType = sessionStorage.getItem("joinType");
        joinData.socialToken = sessionStorage.getItem("socialToken");
        setJoinData({ ...joinData });
      } else {
        setJoinData({ ...prevData });
        setPhoneCheck(true);
      }
      setEmailFlag(true);
      setNameFlag(true);
      setIsSocial(true);
    }

    if (!sessionStorage.getItem("joinType") && !nullCheck(prevData)) {
      setJoinData({ ...prevData });
      setPhoneCheck(true);
    }
  }, []);

  const [emailErrorM, setEmailErrorM] = useState("");
  const [pwErrorM, setPwErrorM] = useState("");
  const [pwCheckErrorM, setPwCheckErrorM] = useState("");

  const emailCheck = useMemo(() => {
    if (
      !nullCheck(joinData.email) &&
      !regExpression.email.test(joinData.email)
    ) {
      setEmailErrorM("올바른 형식을 입력해 주세요.");
      return false;
    }
    if (nullCheck(joinData.email)) {
      setEmailErrorM("");
      return false;
    }
    setEmailErrorM("");
    return true;
  }, [joinData]);

  const pwCheck = useMemo(() => {
    if (joinData.joinType === "NORMAL") {
      if (
        !nullCheck(joinData.password) &&
        !regExpression.password.test(joinData.password)
      ) {
        setPwErrorM("영문/숫자 조합 6~16자를 입력해 주세요.");
        return false;
      }
      if (nullCheck(joinData.password)) {
        setPwErrorM("");
        return false;
      }
    }
    setPwErrorM("");
    return true;
  }, [joinData]);

  const pwCheckCheck = useMemo(() => {
    if (joinData.joinType === "NORMAL") {
      if (
        !nullCheck(joinData.passwordCheck) &&
        !regExpression.password.test(joinData.passwordCheck)
      ) {
        setPwCheckErrorM("영문/숫자 조합 6~16자를 입력해 주세요.");
        return false;
      }
      if (
        !nullCheck(joinData.passwordCheck) &&
        joinData.password !== joinData.passwordCheck
      ) {
        setPwCheckErrorM("비밀번호가 일치하지 않습니다.");
        return false;
      }
      if (nullCheck(joinData.passwordCheck)) {
        setPwCheckErrorM("");
        return false;
      }
    }
    setPwCheckErrorM("");
    return true;
  }, [joinData]);

  const [phoneCheck, setPhoneCheck] = useState(false);
  const [randomNumber, setRandomNumber] = useState("");

  const phoneCheckFunc = async () => {
    try {
      setCode("");
      const data = {
        phoneNumber: joinData.phoneNumber,
      };
      const response = await UserApi.sendSmsCode(data);
      setRandomNumber(response.data.data.randomNumber);
      setTime(180);
      const timerId = setInterval(tick, 1000);
      setTimer(timerId);
    } catch (error) {
      errorHandler(error);
    }
  };

  const [code, setCode] = useState("");
  const codeCheckFunc = () => {
    if (time <= 0) {
      return toast("인증에 실패하였습니다. 인증을 다시 시도하세요.");
    }
    if (randomNumber !== code) {
      return toast("인증번호가 일치하지 않습니다. 인증을 다시 시도하세요.");
    }
    toast("인증되었습니다.");
    setRandomNumber("");
    setPhoneCheck(true);
    clearInterval(timer);
  };

  const [time, setTime] = useState(180); // 초 단위로 시간을 저장
  const [timer, setTimer] = useState("");

  // 1초마다 시간을 감소시키는 함수
  const tick = () => {
    setTime((time) => time - 1);
  };

  // 시간을 포맷팅하여 00:00 형태로 표시
  const formattedTime = useMemo(() => {
    if (time <= 0) {
      clearInterval(timer);
      toast("인증에 실패하였습니다. 인증을 다시 시도하세요.");
      setRandomNumber("");
    }
    return `${String(Math.floor(time / 60)).padStart(2, "0")}:${String(
      time % 60
    ).padStart(2, "0")}`;
  }, [time]);

  const [recommenderErrorM, setRecommenderErrorM] = useState("");

  useEffect(() => {
    if (nullCheck(joinData.recommender)) {
      setRecommenderErrorM("");
    }
  }, [joinData.recommender]);

  const recommenderCheck = useMemo(() => {
    if (
      !nullCheck(joinData.recommender) &&
      !regExpression.email.test(joinData.recommender)
    ) {
      setRecommenderErrorM("올바른 형식을 입력해 주세요.");
      return false;
    }
    if (nullCheck(joinData.recommender)) {
      setRecommenderErrorM("");
      return false;
    }
    setRecommenderErrorM("");
    return true;
  }, [joinData]);

  const handleBlur = async () => {
    try {
      if (!nullCheck(joinData.recommender) && recommenderCheck) {
        const data = {
          email: joinData.recommender,
        };

        await UserApi.checkRecommender(data);
        setRecommenderErrorM("");
      }
    } catch (error) {
      if (error.response.status === 400) {
        return setRecommenderErrorM("존재하지 않는 추천인코드입니다.");
      }
      errorHandler(error);
    }
  };

  const joinBtnFlag = useMemo(() => {
    return (
      emailCheck &&
      pwCheck &&
      pwCheckCheck &&
      phoneCheck &&
      joinData.agree1 &&
      joinData.agree2 &&
      nullCheck(recommenderErrorM)
    );
  }, [
    emailCheck,
    pwCheck,
    pwCheckCheck,
    phoneCheck,
    joinData,
    recommenderErrorM,
  ]);

  const joinFunc = async () => {
    try {
      const data = {
        email: joinData.email,
      };
      await UserApi.checkEmail(data);

      sessionStorage.setItem("joinData", JSON.stringify(joinData));
      modal.open("field");
    } catch (error) {
      if (error.response.status === 409) {
        toast("이미 등록된 이메일입니다.");
        return;
      }
      errorHandler(error);
    }
  };

  const [emailFlag, setEmailFlag] = useState(false);
  const [nameFlag, setNameFlag] = useState(false);

  return (
    <>
      {isMobile ? (
        <div>
          <div
            style={{
              background: "#151515",
              width: "100vw",
              height: "1667px",
              zIndex: 10000,
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
              overflow: "auto",
            }}
          >
            <NavM />

            <div
              style={{
                minHeight: "1248px",
                width: "100%",
                padding: "0px 20px",
                marginBottom: "70px",
              }}
            >
              <div
                style={{
                  marginTop: "100px",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  회원가입
                </PretendardText>
              </div>
              <TitleLabel title={"이름"} isRequired={true}></TitleLabel>
              <TextInput
                placeholder={"이름을 입력해 주세요."}
                value={joinData.name}
                onChange={(e) => {
                  joinData.name = e.target.value;
                  setJoinData({ ...joinData });
                }}
                disabled={nameFlag}
                maxLength={20}
                onBlur={() => {
                  joinData.name = joinData.name.trim();
                  setJoinData({ ...joinData });
                }}
              ></TextInput>

              <TitleLabel title={"이메일"} isRequired={true}></TitleLabel>
              <TextInput
                value={joinData.email}
                placeholder={"이메일을 입력해 주세요."}
                errorText={emailErrorM}
                isError={!nullCheck(emailErrorM)}
                onChange={(e) => {
                  joinData.email = e.target.value.trim();
                  setJoinData({ ...joinData });
                }}
                disabled={emailFlag}
                maxLength={50}
              ></TextInput>

              {isSocial ? null : (
                <>
                  <TitleLabel title={"비밀번호"} isRequired={true}></TitleLabel>
                  <PasswordInput
                    value={joinData.password}
                    placeholder={"영문/숫자 조합 6~16자"}
                    errorText={pwErrorM}
                    isError={!nullCheck(pwErrorM)}
                    onChange={(e) => {
                      joinData.password = e.target.value.trim();
                      setJoinData({ ...joinData });
                    }}
                  ></PasswordInput>
                </>
              )}

              {isSocial ? null : (
                <>
                  <TitleLabel
                    title={"비밀번호 확인"}
                    isRequired={true}
                  ></TitleLabel>
                  <PasswordInput
                    value={joinData.passwordCheck}
                    placeholder={"영문/숫자 조합 6~16자"}
                    errorText={pwCheckErrorM}
                    isError={!nullCheck(pwCheckErrorM)}
                    onChange={(e) => {
                      joinData.passwordCheck = e.target.value.trim();
                      setJoinData({ ...joinData });
                    }}
                  ></PasswordInput>
                </>
              )}

              <TitleLabel title={"전화번호"} isRequired={true}></TitleLabel>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <TextInput
                  placeholder={"숫자만 입력 가능합니다."}
                  style={{
                    width: "",
                  }}
                  value={joinData.phoneNumber}
                  onChange={(e) => {
                    joinData.phoneNumber = e.target.value.replace(
                      /[^0-9]/g,
                      ""
                    );
                    setJoinData({ ...joinData });
                  }}
                  maxLength={11}
                  disabled={phoneCheck}
                ></TextInput>
                {phoneCheck ? (
                  <PrimaryBtn
                    title={"다시인증"}
                    disabled={false}
                    style={{
                      width: "119px",
                      height: "45px",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                    onClick={() => {
                      setPhoneCheck(false);
                    }}
                  ></PrimaryBtn>
                ) : (
                  <PrimaryBtn
                    title={"인증요청"}
                    disabled={
                      nullCheck(joinData.phoneNumber) ||
                      phoneCheck ||
                      !regExpression.phoneNumber.test(joinData.phoneNumber) ||
                      !nullCheck(randomNumber)
                    }
                    style={{
                      width: "119px",
                      height: "45px",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                    onClick={() => {
                      phoneCheckFunc();
                    }}
                  ></PrimaryBtn>
                )}
              </div>

              {randomNumber ? (
                <>
                  <TitleLabel
                    title={"인증번호 입력"}
                    isRequired={true}
                  ></TitleLabel>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <TextInput
                        placeholder={"인증번호 6자리를 입력하세요."}
                        value={code}
                        onChange={(e) => {
                          setCode(e.target.value.replace(/[^0-9]/g, ""));
                        }}
                        maxLength={6}
                      ></TextInput>
                      <PretendardText
                        style={{
                          position: "absolute",
                          top: "13px",
                          right: "20px",
                          color: "#00E4BB",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        {formattedTime}
                      </PretendardText>
                    </div>
                    <PrimaryBtn
                      title={"인증하기"}
                      disabled={false}
                      style={{
                        width: "119px",
                        height: "45px",
                        fontSize: "14px",
                        lineHeight: "21px",
                      }}
                      onClick={() => {
                        codeCheckFunc();
                      }}
                    ></PrimaryBtn>
                  </div>
                </>
              ) : null}

              <TitleLabel title={"추천인 코드"}></TitleLabel>
              <TextInput
                placeholder={"이메일 형식으로 입력해 주세요."}
                onChange={(e) => {
                  joinData.recommender = e.target.value.trim();
                  setJoinData({ ...joinData });
                }}
                value={joinData.recommender}
                onBlur={handleBlur}
                errorText={recommenderErrorM}
                isError={!nullCheck(recommenderErrorM)}
                maxLength={50}
              ></TextInput>

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  marginTop: "36px",
                }}
              >
                <CheckboxInput
                  checked={
                    joinData.agree1 && joinData.agree2 && joinData.agreeToNews
                  }
                  onChange={() => {
                    if (
                      joinData.agree1 &&
                      joinData.agree2 &&
                      joinData.agreeToNews
                    ) {
                      joinData.agree1 = false;
                      joinData.agree2 = false;
                      joinData.agreeToNews = false;
                    } else {
                      joinData.agree1 = true;
                      joinData.agree2 = true;
                      joinData.agreeToNews = true;
                    }

                    setJoinData({ ...joinData });
                  }}
                />
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                >
                  전체선택
                </PretendardText>
              </div>

              <div
                style={{
                  height: "1px",
                  marginTop: "15px",
                  background: "rgba(147, 147, 147, 0.40)",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  marginTop: "14px",
                }}
              >
                <CheckboxInput
                  checked={joinData.agree1}
                  onChange={() => {
                    joinData.agree1 = !joinData.agree1;
                    setJoinData({ ...joinData });
                  }}
                />
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <PretendardText
                    style={{
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open(route.terms);
                    }}
                  >
                    이용약관,
                  </PretendardText>
                  <PretendardText
                    style={{
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                      marginLeft: "5px",
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open(route.privacy);
                    }}
                  >
                    개인정보 처리방침
                  </PretendardText>
                  <PretendardText
                    style={{
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                    }}
                  >
                    에 동의합니다. (필수)
                  </PretendardText>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  marginTop: "14px",
                }}
              >
                <CheckboxInput
                  checked={joinData.agree2}
                  onChange={() => {
                    joinData.agree2 = !joinData.agree2;
                    setJoinData({ ...joinData });
                  }}
                />
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "21px",
                  }}
                >
                  만 14세 이상 서비스 동의
                </PretendardText>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  marginTop: "14px",
                }}
              >
                <CheckboxInput
                  checked={joinData.agreeToNews}
                  onChange={() => {
                    joinData.agreeToNews = !joinData.agreeToNews;
                    setJoinData({ ...joinData });
                  }}
                />
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "21px",
                  }}
                >
                  뉴스레터 등 마케팅 수신 동의
                </PretendardText>
              </div>

              <div>
                <PrimaryBtn
                  title={"회원가입"}
                  disabled={!joinBtnFlag}
                  style={{
                    width: "100%",
                    height: "48px",
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginTop: "30px",
                  }}
                  onClick={() => {
                    joinFunc();
                  }}
                ></PrimaryBtn>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    marginTop: "30px",
                    justifyContent: "center",
                  }}
                >
                  <PretendardText
                    style={{
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "19.6px",
                    }}
                  >
                    이미 회원이신가요?
                  </PretendardText>
                  <PretendardText
                    style={{
                      color: "#00E4BB",
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "19.6px",
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      sessionStorage.clear();
                      modal.open("login");
                    }}
                  >
                    로그인
                  </PretendardText>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              background: "rgba(30, 30, 30, 0.50)",
              backdropFilter: "blur(32.5px)",
              width: "100vw",
              height: "100vh",
              zIndex: 10000,
              display: "flex",
              position: "fixed",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              top: "65%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 10001,
            }}
          >
            <div
              style={{
                width: "520px",
                height: "100%",
                borderRadius: "15px",
                background: "#151515",
                boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.20)",
              }}
            >
              <div
                style={{
                  padding: "40px",
                  paddingTop: "40px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <PretendardText
                    style={{
                      color: "#FFFFFF",
                      fontSize: "22px",
                      fontWeight: "700",
                      lineHeight: "35.2px",
                    }}
                  >
                    회원가입
                  </PretendardText>
                  <img
                    src="/assets/icons/icon_close.svg"
                    alt=""
                    style={{
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      sessionStorage.clear();
                      modal.close();
                    }}
                  />
                </div>
                <TitleLabel title={"이름"} isRequired={true}></TitleLabel>
                <TextInput
                  value={joinData.name}
                  placeholder={"이름을 입력해 주세요."}
                  onChange={(e) => {
                    joinData.name = e.target.value;
                    setJoinData({ ...joinData });
                  }}
                  disabled={nameFlag}
                  maxLength={20}
                  onBlur={() => {
                    joinData.name = joinData.name.trim();
                    setJoinData({ ...joinData });
                  }}
                ></TextInput>

                <TitleLabel title={"이메일"} isRequired={true}></TitleLabel>
                <TextInput
                  value={joinData.email}
                  placeholder={"이메일을 입력해 주세요."}
                  errorText={emailErrorM}
                  isError={!nullCheck(emailErrorM)}
                  onChange={(e) => {
                    joinData.email = e.target.value.trim();
                    setJoinData({ ...joinData });
                  }}
                  disabled={emailFlag}
                  maxLength={50}
                ></TextInput>

                {isSocial ? null : (
                  <>
                    <TitleLabel
                      title={"비밀번호"}
                      isRequired={true}
                    ></TitleLabel>
                    <PasswordInput
                      value={joinData.password}
                      placeholder={"영문/숫자 조합 6~16자"}
                      errorText={pwErrorM}
                      isError={!nullCheck(pwErrorM)}
                      onChange={(e) => {
                        joinData.password = e.target.value.trim();
                        setJoinData({ ...joinData });
                      }}
                    ></PasswordInput>
                  </>
                )}

                {isSocial ? null : (
                  <>
                    <TitleLabel
                      title={"비밀번호 확인"}
                      isRequired={true}
                    ></TitleLabel>
                    <PasswordInput
                      value={joinData.passwordCheck}
                      placeholder={"영문/숫자 조합 6~16자"}
                      errorText={pwCheckErrorM}
                      isError={!nullCheck(pwCheckErrorM)}
                      onChange={(e) => {
                        joinData.passwordCheck = e.target.value.trim();
                        setJoinData({ ...joinData });
                      }}
                    ></PasswordInput>
                  </>
                )}

                <TitleLabel title={"전화번호"} isRequired={true}></TitleLabel>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <TextInput
                    placeholder={"숫자만 입력 가능합니다."}
                    style={{
                      width: "285px",
                    }}
                    value={joinData.phoneNumber}
                    onChange={(e) => {
                      joinData.phoneNumber = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      setJoinData({ ...joinData });
                    }}
                    disabled={phoneCheck}
                    maxLength={11}
                  ></TextInput>
                  {phoneCheck ? (
                    <PrimaryBtn
                      title={"다시인증"}
                      disabled={false}
                      style={{
                        width: "146px",
                        height: "48px",
                        fontSize: "20px",
                        lineHeight: "30px",
                      }}
                      onClick={() => {
                        setPhoneCheck(false);
                      }}
                    />
                  ) : (
                    <PrimaryBtn
                      title={"인증요청"}
                      disabled={
                        nullCheck(joinData.phoneNumber) ||
                        phoneCheck ||
                        !regExpression.phoneNumber.test(joinData.phoneNumber) ||
                        !nullCheck(randomNumber)
                      }
                      style={{
                        width: "146px",
                        height: "48px",
                        fontSize: "20px",
                        lineHeight: "30px",
                      }}
                      onClick={() => {
                        phoneCheckFunc();
                      }}
                    />
                  )}
                </div>

                {randomNumber ? (
                  <>
                    <TitleLabel
                      title={"인증번호 입력"}
                      isRequired={true}
                    ></TitleLabel>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "285px",
                        }}
                      >
                        <TextInput
                          placeholder={"인증번호 6자리를 입력하세요."}
                          value={code}
                          onChange={(e) => {
                            setCode(e.target.value.replace(/[^0-9]/g, ""));
                          }}
                          maxLength={6}
                        ></TextInput>
                        <PretendardText
                          style={{
                            position: "absolute",
                            top: "13px",
                            right: "20px",
                            color: "#00E4BB",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                        >
                          {formattedTime}
                        </PretendardText>
                      </div>
                      <PrimaryBtn
                        title={"인증하기"}
                        disabled={false}
                        style={{
                          width: "146px",
                          height: "48px",
                          fontSize: "20px",
                          lineHeight: "30px",
                        }}
                        onClick={() => {
                          codeCheckFunc();
                        }}
                      />
                    </div>
                  </>
                ) : null}

                <TitleLabel title={"추천인 코드"}></TitleLabel>
                <TextInput
                  value={joinData.recommender}
                  placeholder={"이메일 형식으로 입력해 주세요."}
                  onChange={(e) => {
                    joinData.recommender = e.target.value.trim();
                    setJoinData({ ...joinData });
                  }}
                  onBlur={handleBlur}
                  errorText={recommenderErrorM}
                  isError={!nullCheck(recommenderErrorM)}
                  maxLength={50}
                ></TextInput>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    marginTop: "36px",
                  }}
                >
                  <CheckboxInput
                    checked={
                      joinData.agree1 && joinData.agree2 && joinData.agreeToNews
                    }
                    onChange={() => {
                      if (
                        joinData.agree1 &&
                        joinData.agree2 &&
                        joinData.agreeToNews
                      ) {
                        joinData.agree1 = false;
                        joinData.agree2 = false;
                        joinData.agreeToNews = false;
                      } else {
                        joinData.agree1 = true;
                        joinData.agree2 = true;
                        joinData.agreeToNews = true;
                      }

                      setJoinData({ ...joinData });
                    }}
                  />
                  <PretendardText
                    style={{
                      color: "#FFFFFF",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "24px",
                    }}
                  >
                    전체선택
                  </PretendardText>
                </div>

                <div
                  style={{
                    height: "1px",
                    marginTop: "15px",
                    background: "rgba(147, 147, 147, 0.40)",
                  }}
                ></div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    marginTop: "14px",
                  }}
                >
                  <CheckboxInput
                    checked={joinData.agree1}
                    onChange={() => {
                      joinData.agree1 = !joinData.agree1;
                      setJoinData({ ...joinData });
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <PretendardText
                      style={{
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "21px",
                        textDecorationLine: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(route.terms);
                      }}
                    >
                      이용약관,
                    </PretendardText>
                    <PretendardText
                      style={{
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "21px",
                        marginLeft: "5px",
                        textDecorationLine: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(route.privacy);
                      }}
                    >
                      개인정보 처리방침
                    </PretendardText>
                    <PretendardText
                      style={{
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "21px",
                      }}
                    >
                      에 동의합니다. (필수)
                    </PretendardText>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    marginTop: "14px",
                  }}
                >
                  <CheckboxInput
                    checked={joinData.agree2}
                    onChange={() => {
                      joinData.agree2 = !joinData.agree2;
                      setJoinData({ ...joinData });
                    }}
                  />
                  <PretendardText
                    style={{
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                    }}
                  >
                    만 14세 이상 서비스 동의
                  </PretendardText>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    marginTop: "14px",
                  }}
                >
                  <CheckboxInput
                    checked={joinData.agreeToNews}
                    onChange={() => {
                      joinData.agreeToNews = !joinData.agreeToNews;
                      setJoinData({ ...joinData });
                    }}
                  />
                  <PretendardText
                    style={{
                      color: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                    }}
                  >
                    뉴스레터 등 마케팅 수신 동의
                  </PretendardText>
                </div>

                <div
                  style={{
                    marginTop: "56px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", gap: "5px" }}>
                    <PretendardText
                      style={{
                        color: "#FFFFFF",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "22.4px",
                      }}
                    >
                      이미 회원이신가요?
                    </PretendardText>
                    <PretendardText
                      style={{
                        color: "#00E4BB",
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "22.4px",
                        textDecorationLine: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        sessionStorage.clear();
                        modal.open("login");
                      }}
                    >
                      로그인
                    </PretendardText>
                  </div>
                  <PrimaryBtn
                    title={"회원가입"}
                    disabled={!joinBtnFlag}
                    style={{
                      width: "184px",
                    }}
                    onClick={() => {
                      joinFunc();
                    }}
                  ></PrimaryBtn>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default JoinModal;
