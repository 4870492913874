import React from "react";
import { useMemo } from "react";
import styled from "styled-components";
import PretendardText from "../atoms/pretendardText";
import { useHistory } from "react-router-dom";

function Pagination({
  route,
  queryString,
  totalPages,
  currentPage,
  rangeSize,
  first,
  last,
  style,
}) {
  const history = useHistory();

  const pageList = useMemo(() => {
    const pageGroup = Math.ceil(currentPage / rangeSize);

    let firstNumber = pageGroup * rangeSize - (rangeSize - 1);
    if (firstNumber <= 0) {
      firstNumber = 1;
    }

    let lastNumber = pageGroup * rangeSize;
    if (lastNumber > totalPages) {
      lastNumber = totalPages;
    }

    const pageList = [];
    for (let i = firstNumber; i <= lastNumber; i++) {
      pageList.push(i);
    }

    return pageList;
  }, [totalPages, currentPage, rangeSize]);

  return (
    <>
      {pageList.length > 0 ? (
        <>
          <PagenationWrapper style={style}>
            <div
              style={{
                cursor: "pointer",
                marginRight: "5px",
              }}
              onClick={() => {
                if (first) {
                  return;
                }

                history.replace(
                  route +
                    `/${Number(currentPage) - 1}?${
                      queryString ? queryString : ""
                    }`
                );
              }}
            >
              <img src="/assets/icons/icon_arrow-left.svg" alt="arrow-left" />
            </div>
            <PagenationNumWrapper>
              {pageList.map((item, index) => {
                return (
                  <PagenationNum
                    key={index}
                    style={{
                      color:
                        Number(currentPage) === item ? "#00E4BB" : "#939393",
                      background: Number(currentPage) === item ? "#1E1E1E" : "",
                      borderRadius: Number(currentPage) === item ? "50%" : "",
                    }}
                    onClick={() => {
                      history.replace(
                        route + `/${item}?${queryString ? queryString : ""}`
                      );
                    }}
                  >
                    {item}
                  </PagenationNum>
                );
              })}
            </PagenationNumWrapper>
            <div
              style={{
                cursor: "pointer",
                marginLeft: "5px",
              }}
              onClick={() => {
                if (last) {
                  return;
                }
                history.replace(
                  route +
                    `/${Number(currentPage) + 1}?${
                      queryString ? queryString : ""
                    }`
                );
              }}
            >
              <img src="/assets/icons/icon_arrow-right.svg" alt="arrow-right" />
            </div>
          </PagenationWrapper>
        </>
      ) : null}
    </>
  );
}

export default Pagination;

const PagenationWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PagenationNum = styled(PretendardText).attrs((props) => {})`
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #939393;
  cursor: pointer;
`;

const PagenationNumWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
`;
