import React from "react";
import PretendardText from "../../components/atoms/pretendardText";
import { errorHandler } from "../../utils/error-handler";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { route } from "../../routes/route";
import useDisplay from "../../hooks/useDisplay";
import Blur from "../../components/atoms/blur/blur";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import NoticeApi from "./../../api/noticeApi";
import moment from "moment";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import Footer from "../../components/organisms/footer";

function NoticeDetail() {
  const history = useHistory();
  const location = useLocation();
  const { isMobile, isTablet, isDesktop } = useDisplay();

  //페이지네이션
  const { id } = useParams();

  useEffect(() => {
    getList();
  }, [location]);

  const [data, setdata] = useState({});

  const getList = async () => {
    try {
      let data = (await NoticeApi.GetNoticeById(id)).data.data;
      setdata(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}

        <div
          style={{
            width: "100%",
            padding: `${
              isMobile ? "95px 0 70px 0" : "280px 19.53% 200px 19.53%"
            }`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          {!isMobile ? (
            <Blur
              style={{
                width: "1342px",
                height: "454px",
                borderRadius: "1342px",
                background: "rgba(0, 237, 194, 0.15)",
                filter: "blur(150px)",
                top: "-255px",
                left: "50%",
                transform: "translate(-50%,0)",
              }}
            />
          ) : (
            ""
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: `${isMobile ? "34px" : "59px"}`,
            }}
          >
            <PretendardText
              style={{
                color: "#00E4BB",
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "14px" : "16px"}`,
                lineHeight: `${isMobile ? "21px" : "24px"}`,
                marginBottom: `${isMobile ? "0" : "13px"}`,
                textAlign: "center",
              }}
            >
              Notice
            </PretendardText>
            <PretendardText
              style={{
                color: "#fff",
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "24px" : "36px"}`,
                lineHeight: `${isMobile ? "36px" : "54px"}`,
                textAlign: "center",
              }}
            >
              공지사항
            </PretendardText>
          </div>
          <div
            style={{
              width: "100%",
              minHeight: `${isMobile ? "502px" : "704px"}`,
            }}
          >
            {/* 제목 */}
            <div
              style={{
                display: "flex",
                flexDirection: `${isMobile ? "column" : "row"}`,
                alignItems: "center",
                padding: `${isMobile ? "13px 20px" : "0px 30px"}`,
                gap: `${isMobile ? "7px" : "10px"}`,
                borderTop: `${
                  isMobile ? "2px solid #939393" : "2px solid #F1F1F1"
                }`,
                borderBottom: `${
                  isMobile ? "1px solid #56565650" : "1px solid #93939350"
                }`,
                height: `${isMobile ? "74px" : "64px"}`,
              }}
            >
              <PretendardText
                style={{
                  fontWeight: `${isMobile ? "400" : "600"}`,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#FFFFFF",
                  width: `${isMobile ? "90vw" : "932px"}`,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  wordBreak: "break-all",
                }}
              >
                {data.title}
              </PretendardText>
              <PretendardText
                style={{
                  fontWeight: `${isMobile ? "400" : "600"}`,
                  fontSize: `${isMobile ? "12px" : "16px"}`,
                  lineHeight: `${isMobile ? "16.8px" : "24px"}`,
                  color: "#FFFFFF",
                  width: `${isMobile ? "100%" : "168px"}`,
                  display: "flex",
                  justifyContent: `${isMobile ? "left" : "right"}`,
                }}
              >
                {moment(data.createdAt).format("YYYY-MM-DD")}
              </PretendardText>
            </div>
            {/* 본문 */}
            <div
              style={{
                width: "100%",
                padding: `${isMobile ? "30px 20px" : "65px 30px"}`,
              }}
            >
              <PretendardText
                style={{
                  fontWeight: "400",
                  fontSize: `${isMobile ? "15px" : "16px"}`,
                  lineHeight: "24px",
                  color: "#FFFFFF",
                }}
              >
                {data.content}
              </PretendardText>
            </div>
            {/* 페이지 이동 */}
            <div
              style={{
                marginBottom: `${isMobile ? "35px" : "58px"}`,
              }}
            >
              {data.previous ? (
                <div
                  style={{
                    height: `${isMobile ? "46px" : "63px"}`,
                    padding: `${isMobile ? "12px 20px" : "0 30px"}`,
                    display: "flex",
                    alignItems: "center",
                    gap: `${isMobile ? "35px" : "70px"}`,
                    borderTop: `${
                      isMobile ? "1px solid #56565650" : "1px solid #93939350"
                    }`,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    history.push(route.notice_detail + `/${data.previous?.id}`)
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img src="/assets/icons/icon_notice_arrow.svg" alt="" />
                    <PretendardText
                      style={{
                        color: "#dfdfdf",
                        fontWeight: `${isMobile ? "400" : "600"}`,
                        fontSize: `${isMobile ? "14px" : "16px"}`,
                        lineHeight: `${isMobile ? "21px" : "24px"}`,
                        width: `${isMobile ? "54px" : "65px"}`,
                      }}
                    >
                      Previous
                    </PretendardText>
                  </div>
                  <PretendardText
                    style={{
                      fontWeight: `${isMobile ? "400" : "600"}`,
                      fontSize: `${isMobile ? "14px" : "16px"}`,
                      lineHeight: `${isMobile ? "21px" : "24px"}`,
                      color: "#dfdfdf",
                      width: `${isMobile ? "219px" : "774px"}`,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      wordBreak: "break-all",
                    }}
                  >
                    {data.previous?.title}
                  </PretendardText>
                  {!isMobile ? (
                    <PretendardText
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#dfdfdf",
                        minWidth: "97px",
                      }}
                    >
                      {moment(data.previous?.createdAt).format("YYYY-MM-DD")}
                    </PretendardText>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {data.next ? (
                <div
                  style={{
                    height: `${isMobile ? "46px" : "63px"}`,
                    padding: `${isMobile ? "12px 20px" : "0 30px"}`,
                    display: "flex",
                    alignItems: "center",
                    gap: `${isMobile ? "35px" : "70px"}`,
                    borderTop: `${
                      isMobile ? "1px solid #56565650" : "1px solid #93939350"
                    }`,
                    borderBottom: `${
                      isMobile ? "2px solid #939393" : "2px solid #F1F1F1"
                    }`,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push(route.notice_detail + `/${data.next?.id}`);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      style={{ transform: "rotate(180deg)" }}
                      src="/assets/icons/icon_notice_arrow.svg"
                      alt=""
                    />
                    <PretendardText
                      style={{
                        color: "#dfdfdf",
                        fontWeight: `${isMobile ? "400" : "600"}`,
                        fontSize: `${isMobile ? "14px" : "16px"}`,
                        lineHeight: `${isMobile ? "21px" : "24px"}`,
                        width: `${isMobile ? "54px" : "65px"}`,
                      }}
                    >
                      Next
                    </PretendardText>
                  </div>
                  <PretendardText
                    style={{
                      fontWeight: `${isMobile ? "400" : "600"}`,
                      fontSize: `${isMobile ? "14px" : "16px"}`,
                      lineHeight: `${isMobile ? "21px" : "24px"}`,
                      color: "#dfdfdf",
                      width: `${isMobile ? "219px" : "774px"}`,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      wordBreak: "break-all",
                    }}
                  >
                    {data.next?.title}
                  </PretendardText>
                  {!isMobile ? (
                    <PretendardText
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#dfdfdf",
                        minWidth: "97px",
                      }}
                    >
                      {moment(data.next?.createdAt).format("YYYY-MM-DD")}
                    </PretendardText>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: `${isMobile ? "right" : "center"}`,
                paddingRight: `${isMobile ? "20px" : ""}`,
              }}
            >
              <PrimaryBtn
                style={{
                  width: "146px",
                  height: "48px",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                title={"목록으로"}
                disabled={false}
                onClick={() => history.push(route.notice + "/1")}
              />
            </div>
          </div>
        </div>

        <Footer />
      </Container>
    </>
  );
}

export default NoticeDetail;
