import React from "react";
import MypageSide from "../../components/organisms/mypageSide";
import PretendardText from "../../components/atoms/pretendardText";
import { useState } from "react";
import MuktaText from "../../components/atoms/muktaText";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { route } from "../../routes/route";
import Pagination from "../../components/organisms/pagination";
import { useEffect } from "react";
import { errorHandler } from "../../utils/error-handler";
import WhiteBtn from "../../components/atoms/btn/whiteBtn";
import DateInput from "../../components/atoms/input/dateInput";
import useDisplay from "../../hooks/useDisplay";
import VideoCardM from "../../components/organisms/videoCardM";
import VideoCard from "../../components/organisms/videoCard";
import UserApi from "./../../api/user";
import useModal from "../../hooks/useModal";
import Footer from "../../components/organisms/footer";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import moment from "moment";
import { toast } from "react-toastify";

function Library() {
  const modal = useModal();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [list, setList] = useState([]);
  const [downloadList, setDownloadList] = useState([]);
  const [numberOfElements, setNumberOfElements] = useState(null);
  const [numberOfDownload, setNumberOfDownload] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchBarOpen, setSearchBarOpen] = useState(false);

  //페이지네이션
  const { page } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const [pagination, setPagination] = useState({
    size: 15, // 한 페이지에 표시될 게시글 수
    rangeSize: 10, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  useEffect(() => {
    if (!sessionStorage.getItem("id")) {
      history.push(route.home);
      modal.open("login");
      return;
    }
    setTab(Number(queryParams.get("tab")));
    setStartDate(
      searchParams.get("startDate") ? searchParams.get("startDate") : ""
    );
    setEndDate(searchParams.get("endDate") ? searchParams.get("endDate") : "");
    getList();
    getDownloadList();
  }, [location]);

  const getList = async () => {
    try {
      const data = {
        page: page - 1,
        size: pagination.size,
      };

      let list = (await UserApi.getSaveVideo(data)).data.data;
      setNumberOfElements(list.totalElements);

      setList(
        list.content.map((item) => {
          return {
            ...item,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const getDownloadList = async () => {
    try {
      const data = {
        page: page - 1,
        size: pagination.size,
        startDate: searchParams.get("startDate"),
        endDate: searchParams.get("endDate"),
      };

      let list = (await UserApi.getdownVideo(data)).data.data;
      setNumberOfDownload(list.totalElements);

      setDownloadList(
        list.content.map((item) => {
          return {
            ...item,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const [tab, setTab] = useState(1);

  const searchFunc = () => {
    if (moment(startDate).isAfter(moment(endDate))) {
      return toast("조회 기간을 다시 확인하십시오.");
    }
    history.replace(
      `${route.library}/1?tab=${tab}&startDate=${startDate}&endDate=${endDate}`
    );
    setSearchBarOpen(false);
  };

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}

        {isMobile ? (
          <>
            <div
              style={{
                padding: "0px 20px",
                paddingTop: "70px",
              }}
            >
              <PretendardText
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#00E4BB",
                  textAlign: "center",
                }}
              >
                My page
              </PretendardText>
              <PretendardText
                style={{
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "36px",
                  color: "#FFFFFF",
                  textAlign: "center",
                  marginTop: "4px",
                }}
              >
                마이페이지
              </PretendardText>
            </div>
            <MypageSide></MypageSide>

            <div
              style={{
                height: "1px",
                background: "#333333",
              }}
            ></div>
            <div
              style={{
                padding: "0px 20px",
                marginBottom: "70px",
              }}
            >
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <div>
                  <PretendardText
                    style={{
                      fontWeight: tab === 1 ? "600" : "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: tab === 1 ? "#151515" : "#939393",
                      borderRadius: tab === 1 ? "100px" : "",
                      background: tab === 1 ? "#00E4BB" : "",
                      display: tab === 1 ? "flex" : "",
                      alignItems: tab === 1 ? "center" : "",
                      justifyContent: tab === 1 ? "center" : "",
                      width: tab === 1 ? "103px" : "",
                      height: tab === 1 ? "34px" : "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.replace(route.library + `/1?tab=1`);
                    }}
                  >
                    저장한 영상
                  </PretendardText>
                </div>
                <div>
                  <PretendardText
                    style={{
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: tab === 2 ? "#151515" : "#939393",
                      borderRadius: tab === 2 ? "100px" : "",
                      background: tab === 2 ? "#00E4BB" : "",
                      display: tab === 2 ? "flex" : "",
                      alignItems: tab === 2 ? "center" : "",
                      justifyContent: tab === 2 ? "center" : "",
                      width: tab === 2 ? "103px" : "",
                      height: tab === 2 ? "34px" : "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.replace(route.library + `/1?tab=2`);
                    }}
                  >
                    다운로드 기록
                  </PretendardText>
                </div>
              </div>

              {tab === 1 ? (
                <>
                  <PretendardText
                    style={{
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "28.8px",
                      color: "#FFFFFF",
                      marginTop: "40px",
                    }}
                  >
                    저장한 영상 수({numberOfElements})
                  </PretendardText>
                  <div
                    style={{
                      marginTop: "33px",
                      gap: "30px",
                      flexWrap: "wrap",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    {numberOfElements === 0 ? (
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#939393",
                            margin: "100px 0px",
                            textAlign: "center",
                          }}
                        >
                          이용내역이 없습니다.
                        </PretendardText>
                      </div>
                    ) : (
                      <>
                        {list.map((item, index) => {
                          return <VideoCardM key={index} item={item} />;
                        })}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      marginTop: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      position: "relative",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontWeight: "700",
                        fontSize: "18px",
                        lineHeight: "28.8px",
                        color: "#FFFFFF",
                      }}
                    >
                      전체 다운로드 내역({numberOfDownload})
                    </PretendardText>
                    <img
                      src="/assets/icons/icon_table_search_m.svg"
                      alt=""
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => setSearchBarOpen(!searchBarOpen)}
                    />
                    {searchBarOpen ? (
                      <div
                        style={{
                          position: "absolute",
                          top: 46,
                          right: 0,
                          width: "100%",
                          height: "140px",
                          background: "#333333",
                          borderRadius: "5px",
                          zIndex: "111",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <DateInput
                            style={{
                              width: "143px",
                              paddingLeft: "10px",
                              borderColor: "#565656",
                            }}
                            iconStyle={{
                              top: "11px",
                              right: "8px",
                            }}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          ></DateInput>
                          <div
                            style={{
                              height: "1px",
                              width: "10px",
                              background: "#939393",
                            }}
                          ></div>
                          <DateInput
                            style={{
                              width: "143px",
                              paddingLeft: "10px",
                              borderColor: "#565656",
                            }}
                            iconStyle={{
                              top: "11px",
                              right: "8px",
                            }}
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          ></DateInput>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "20px",
                          }}
                        >
                          <WhiteBtn
                            title={"검색"}
                            style={{
                              color: "#1e1e1e",
                              width: "117px",
                              height: "48px",
                              fontSize: "16px",
                              fontWeight: "700",
                              lineHeight: "24px",
                            }}
                            onClick={() => {
                              searchFunc();
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    style={{
                      marginTop: "33px",
                      gap: "30px",
                      flexWrap: "wrap",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    {numberOfDownload === 0 ? (
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#939393",
                            margin: "100px 0px",
                            textAlign: "center",
                          }}
                        >
                          이용내역이 없습니다.
                        </PretendardText>
                      </div>
                    ) : (
                      <>
                        {downloadList.map((item, index) => {
                          return (
                            <div key={index} style={{ width: "100%" }}>
                              <VideoCardM item={item} />
                              <div style={{ marginTop: "15px" }}>
                                <div style={{ display: "flex", gap: "10px" }}>
                                  <PretendardText
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                      color: "#939393",
                                    }}
                                  >
                                    다운일시
                                  </PretendardText>
                                  <PretendardText
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                      color: "#939393",
                                    }}
                                  >
                                    {moment(item.createdAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </PretendardText>
                                </div>
                                <div style={{ display: "flex", gap: "10px" }}>
                                  <PretendardText
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                      color: "#939393",
                                    }}
                                  >
                                    다운IP
                                  </PretendardText>
                                  <PretendardText
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                      color: "#939393",
                                    }}
                                  >
                                    {item.downloadIp}
                                  </PretendardText>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </>
              )}

              <Pagination
                style={{ marginTop: "40px" }}
                route={route.library}
                queryString={`tab=${tab}&startDate=${startDate}&endDate=${endDate}`}
                currentPage={page}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
                first={pagination.first}
                last={pagination.last}
              />
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                height: "80px",
              }}
            ></div>

            <div
              style={{
                display: "flex",
                minHeight: tab === 1 ? "1894px" : "2218px",
              }}
            >
              <MypageSide />

              <div
                style={{
                  paddingTop: "30px",
                  paddingLeft: "35px",
                }}
              >
                <PretendardText
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#00E4BB",
                  }}
                >
                  Library
                </PretendardText>
                <PretendardText
                  style={{
                    fontWeight: "700",
                    fontSize: "36px",
                    lineHeight: "54px",
                    color: "#FFFFFF",
                    marginTop: "15px",
                  }}
                >
                  라이브러리
                </PretendardText>

                <div
                  style={{
                    marginTop: "60px",
                    display: "flex",
                    gap: "45px",
                  }}
                >
                  <div>
                    <PretendardText
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "30px",
                        color: tab === 1 ? "#FFFFFF" : "#939393",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.replace(route.library + `/1?tab=1`);
                      }}
                    >
                      저장한 영상
                    </PretendardText>
                    {tab === 1 ? (
                      <div
                        style={{
                          height: "2px",
                          marginTop: "10px",
                          background: "#00E4BB",
                        }}
                      ></div>
                    ) : null}
                  </div>
                  <div>
                    <PretendardText
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "30px",
                        color: tab === 2 ? "#FFFFFF" : "#939393",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.replace(route.library + `/1?tab=2`);
                      }}
                    >
                      다운로드 기록
                    </PretendardText>
                    {tab === 2 ? (
                      <div
                        style={{
                          height: "2px",
                          marginTop: "10px",
                          background: "#00E4BB",
                        }}
                      ></div>
                    ) : null}
                  </div>
                </div>

                {tab === 1 ? (
                  <>
                    <PretendardText
                      style={{
                        fontWeight: "700",
                        fontSize: "18px",
                        lineHeight: "28.8px",
                        color: "#FFFFFF",
                        marginTop: "82px",
                      }}
                    >
                      저장한 영상 수({numberOfElements})
                    </PretendardText>
                    <div
                      style={{
                        marginTop: "50px",
                        gap: "30px",
                        flexWrap: "wrap",
                        display: "flex",
                        width: "1170px",
                      }}
                    >
                      {numberOfElements === 0 ? (
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#939393",
                              margin: "120px 0px",
                              textAlign: "center",
                            }}
                          >
                            이용내역이 없습니다.
                          </PretendardText>
                        </div>
                      ) : (
                        <>
                          {list.map((item, index) => {
                            return (
                              <VideoCard
                                key={index}
                                style={{
                                  height: "220px",
                                  width: "370px",
                                }}
                                item={item}
                              />
                            );
                          })}
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        marginTop: "82px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <PretendardText
                        style={{
                          fontWeight: "700",
                          fontSize: "18px",
                          lineHeight: "28.8px",
                          color: "#FFFFFF",
                        }}
                      >
                        전체 다운로드 내역({numberOfDownload})
                      </PretendardText>
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <DateInput
                            style={{
                              width: "200px",
                            }}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          ></DateInput>
                          <div
                            style={{
                              height: "1px",
                              width: "12px",
                              background: "#939393",
                            }}
                          ></div>
                          <DateInput
                            style={{
                              width: "200px",
                            }}
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          ></DateInput>
                        </div>
                        <WhiteBtn
                          title={"검색"}
                          style={{
                            width: "118px",
                            height: "48px",
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                          onClick={() => {
                            searchFunc();
                          }}
                        ></WhiteBtn>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "50px",
                        gap: "30px",
                        flexWrap: "wrap",
                        display: "flex",
                        width: "1170px",
                      }}
                    >
                      {numberOfDownload === 0 ? (
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#939393",
                              margin: "120px 0px",
                              textAlign: "center",
                            }}
                          >
                            이용내역이 없습니다.
                          </PretendardText>
                        </div>
                      ) : (
                        <>
                          {downloadList.map((item, index) => {
                            return (
                              <div key={index}>
                                <VideoCard
                                  style={{
                                    height: "220px",
                                    width: "370px",
                                  }}
                                  item={item}
                                />
                                <div style={{ marginTop: "10px" }}>
                                  <div style={{ display: "flex", gap: "10px" }}>
                                    <PretendardText
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        color: "#939393",
                                      }}
                                    >
                                      다운일시
                                    </PretendardText>
                                    <PretendardText
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        color: "#939393",
                                      }}
                                    >
                                      {moment(item.createdAt).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </PretendardText>
                                  </div>
                                  <div style={{ display: "flex", gap: "10px" }}>
                                    <PretendardText
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        color: "#939393",
                                      }}
                                    >
                                      다운IP
                                    </PretendardText>
                                    <PretendardText
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        color: "#939393",
                                      }}
                                    >
                                      {item.downloadIp}
                                    </PretendardText>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </>
                )}

                <Pagination
                  style={{ marginTop: "40px" }}
                  route={route.library}
                  queryString={`tab=${tab}&startDate=${startDate}&endDate=${endDate}`}
                  currentPage={page}
                  totalPages={pagination.totalPages}
                  rangeSize={pagination.rangeSize}
                  first={pagination.first}
                  last={pagination.last}
                />
              </div>
            </div>
          </>
        )}

        <Footer />
      </Container>
    </>
  );
}

export default Library;
