import React, { useState } from "react";
import styled from "styled-components";

import PretendardText from "../../atoms/pretendardText";
import useDisplay from "../../../hooks/useDisplay";

function Card({ title, basic, standard, pro, style }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <div
        style={{
          borderTop: "2px solid #565656",
          borderBottom: "1px solid #565656",
          height: isSmall ? 37 : 64,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: isSmall ? "0px 8px" : "0px 30px",
          ...style,
        }}
      >
        <PretendardText
          style={{
            fontWeight: isSmall ? "700" : "700",
            fontSize: isSmall ? "12px" : "16px",
            lineHeight: isSmall ? "18px" : "24px",
            color: "#FFF",
            width: isSmall ? "64%" : 635,
            paddingRight: isSmall ? 0 : 7,
          }}
        >
          {title}
        </PretendardText>
        <PretendardText
          style={{
            fontWeight: isSmall ? "700" : "700",
            fontSize: isSmall ? "12px" : "16px",
            lineHeight: isSmall ? "18px" : "24px",
            color: "#FFF",
            width: isSmall ? "12%" : 150,
            textAlign: "center",
            paddingRight: isSmall ? 0 : 7,
          }}
        >
          {basic}
        </PretendardText>
        <PretendardText
          style={{
            fontWeight: isSmall ? "700" : "700",
            fontSize: isSmall ? "12px" : "16px",
            lineHeight: isSmall ? "18px" : "24px",
            color: "#FFF",
            width: isSmall ? "16%" : 180,
            textAlign: "center",
            paddingRight: isSmall ? 0 : 7,
          }}
        >
          {standard}
        </PretendardText>
        <PretendardText
          style={{
            fontWeight: isSmall ? "700" : "700",
            fontSize: isSmall ? "12px" : "16px",
            lineHeight: isSmall ? "18px" : "24px",
            color: "#FFF",
            width: isSmall ? "10%" : 125,
            textAlign: "center",
          }}
        >
          {pro}
        </PretendardText>
      </div>
    </>
  );
}

export default Card;
