import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import PretendardText from "../../components/atoms/pretendardText";
import TitleLabel from "../../components/atoms/text/titleLabel";
import TextInput from "../../components/atoms/input/textInput";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import useDisplay from "../../hooks/useDisplay";
import Footer from "../../components/organisms/footer";
import NavM from "../../components/organisms/navM";
import useModal from "../../hooks/useModal";
import { errorHandler } from "../../utils/error-handler";
import UserApi from "../../api/user";
import { toast } from "react-toastify";
import { nullCheck } from "../../utils/check";
import { regExpression } from "../../utils/reg-expresstion";

function FindEmailModal() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const modal = useModal();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.getElementById("container").style.cssText = `
          height: 1037px;          
          overflow: hidden;
          background: #151515;
          `;
    return () => {
      document.getElementById("container").style.cssText = `
      min-height: 100vh;
      overflow: auto;
      background: #151515;
      `;
    };
  }, []);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [phoneCheck, setPhoneCheck] = useState(false);
  const [randomNumber, setRandomNumber] = useState("");

  const phoneCheckFunc = async () => {
    try {
      setCode("");
      const data = {
        name: name,
        phoneNumber: phoneNumber,
      };
      const response = await UserApi.searchEmailCheckSms(data);
      setRandomNumber(response.data.data.randomNumber);
      setTime(180);
      const timerId = setInterval(tick, 1000);
      setTimer(timerId);
    } catch (error) {
      errorHandler(error);
    }
  };

  const [code, setCode] = useState("");
  const codeCheckFunc = () => {
    if (time <= 0) {
      return toast("인증에 실패하였습니다. 인증을 다시 시도하세요.");
    }
    if (randomNumber !== code) {
      return toast("인증번호가 일치하지 않습니다. 인증을 다시 시도하세요.");
    }
    toast("인증되었습니다.");
    setRandomNumber("");
    setPhoneCheck(true);
    clearInterval(timer);
  };

  const [time, setTime] = useState(180); // 초 단위로 시간을 저장
  const [timer, setTimer] = useState("");

  // 1초마다 시간을 감소시키는 함수
  const tick = () => {
    setTime((time) => time - 1);
  };

  // 시간을 포맷팅하여 00:00 형태로 표시
  const formattedTime = useMemo(() => {
    if (time <= 0) {
      clearInterval(timer);
      toast("인증에 실패하였습니다. 인증을 다시 시도하세요.");
      setRandomNumber("");
    }
    return `${String(Math.floor(time / 60)).padStart(2, "0")}:${String(
      time % 60
    ).padStart(2, "0")}`;
  }, [time]);

  const btnFlag = useMemo(() => {
    return !phoneCheck || nullCheck(name);
  }, [phoneCheck, name]);

  const nextFunc = () => {
    sessionStorage.setItem("name", name);
    sessionStorage.setItem("phoneNumber", phoneNumber);
    modal.open("findEmailComplete");
  };

  return (
    <>
      {isMobile ? (
        <div>
          <div
            style={{
              background: "#151515",
              width: "100vw",
              height: "1037px",
              zIndex: 10000,
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
              overflow: "auto",
            }}
          >
            <NavM />

            <div
              style={{
                minHeight: "618px",
                width: "100%",
                padding: "0px 20px",
                marginBottom: "70px",
              }}
            >
              <div
                style={{
                  marginTop: "120px",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  이메일 찾기
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#00E4BB",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    marginTop: "5px",
                  }}
                >
                  가입 정보를 입력해주세요.
                </PretendardText>
              </div>

              <TitleLabel title={"이름"} isRequired={true}></TitleLabel>
              <TextInput
                placeholder={"가입하신 이름을 입력하세요."}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                maxLength={20}
                onBlur={() => {
                  setName(name.trim());
                }}
              ></TextInput>

              <TitleLabel title={"전화번호"} isRequired={true}></TitleLabel>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <TextInput
                  placeholder={"숫자만 입력 가능합니다."}
                  style={{
                    width: "285px",
                  }}
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value.replace(/[^0-9]/g, ""));
                  }}
                  disabled={phoneCheck}
                  maxLength={11}
                ></TextInput>

                {phoneCheck ? (
                  <PrimaryBtn
                    title={"다시인증"}
                    disabled={false}
                    style={{
                      width: "146px",
                      height: "48px",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                    onClick={() => {
                      setPhoneCheck(false);
                    }}
                  ></PrimaryBtn>
                ) : (
                  <PrimaryBtn
                    title={"인증요청"}
                    disabled={
                      nullCheck(phoneNumber) ||
                      phoneCheck ||
                      !regExpression.phoneNumber.test(phoneNumber) ||
                      !nullCheck(randomNumber)
                    }
                    style={{
                      width: "146px",
                      height: "48px",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                    onClick={() => {
                      phoneCheckFunc();
                    }}
                  ></PrimaryBtn>
                )}
              </div>

              {randomNumber ? (
                <>
                  <TitleLabel
                    title={"인증번호 입력"}
                    isRequired={true}
                  ></TitleLabel>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "285px",
                      }}
                    >
                      <TextInput
                        placeholder={"인증번호 6자리를 입력하세요."}
                        value={code}
                        onChange={(e) => {
                          setCode(e.target.value.replace(/[^0-9]/g, ""));
                        }}
                        maxLength={6}
                      ></TextInput>
                      <PretendardText
                        style={{
                          position: "absolute",
                          top: "13px",
                          right: "20px",
                          color: "#00E4BB",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        {formattedTime}
                      </PretendardText>
                    </div>
                    <PrimaryBtn
                      title={"인증하기"}
                      disabled={false}
                      style={{
                        width: "146px",
                        height: "48px",
                        fontSize: "14px",
                        lineHeight: "21px",
                      }}
                      onClick={() => {
                        codeCheckFunc();
                      }}
                    ></PrimaryBtn>
                  </div>
                </>
              ) : null}

              <PrimaryBtn
                title={"다음"}
                disabled={btnFlag}
                style={{
                  width: "100%",
                  height: "48px",
                  marginTop: "40px",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => {
                  nextFunc();
                }}
              ></PrimaryBtn>
            </div>

            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              background: "rgba(30, 30, 30, 0.50)",
              backdropFilter: "blur(32.5px)",
              width: "100vw",
              height: "100vh",
              zIndex: 10000,
              display: "flex",
              position: "fixed",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
            }}
          ></div>
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 10001,
            }}
          >
            <div
              style={{
                width: "520px",
                height: "100%",
                borderRadius: "15px",
                background: "#151515",
                boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.20)",
                padding: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  이메일 찾기
                </PretendardText>
                <img
                  src="/assets/icons/icon_close.svg"
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.close();
                    sessionStorage.clear();
                  }}
                />
              </div>

              <PretendardText
                style={{
                  color: "#00E4BB",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  marginTop: "10px",
                }}
              >
                가입 정보를 입력해주세요.
              </PretendardText>

              <div
                style={{
                  height: "1px",
                  marginTop: "25px",
                  background: "#939393",
                }}
              ></div>

              <TitleLabel title={"이름"} isRequired={true}></TitleLabel>
              <TextInput
                placeholder={"가입하신 이름을 입력하세요."}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                maxLength={20}
                onBlur={() => {
                  setName(name.trim());
                }}
              ></TextInput>

              <TitleLabel title={"전화번호"} isRequired={true}></TitleLabel>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <TextInput
                  placeholder={"숫자만 입력 가능합니다."}
                  style={{
                    width: "285px",
                  }}
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value.replace(/[^0-9]/g, ""));
                  }}
                  disabled={phoneCheck}
                  maxLength={11}
                ></TextInput>
                {phoneCheck ? (
                  <PrimaryBtn
                    title={"다시인증"}
                    disabled={false}
                    style={{
                      width: "146px",
                      height: "48px",
                    }}
                    onClick={() => {
                      setPhoneCheck(false);
                    }}
                  ></PrimaryBtn>
                ) : (
                  <PrimaryBtn
                    title={"인증요청"}
                    disabled={
                      nullCheck(phoneNumber) ||
                      phoneCheck ||
                      !regExpression.phoneNumber.test(phoneNumber) ||
                      !nullCheck(randomNumber)
                    }
                    style={{
                      width: "146px",
                      height: "48px",
                    }}
                    onClick={() => {
                      phoneCheckFunc();
                    }}
                  ></PrimaryBtn>
                )}
              </div>

              {randomNumber ? (
                <>
                  <TitleLabel
                    title={"인증번호 입력"}
                    isRequired={true}
                  ></TitleLabel>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "285px",
                      }}
                    >
                      <TextInput
                        placeholder={"인증번호 6자리를 입력하세요."}
                        value={code}
                        onChange={(e) => {
                          setCode(e.target.value.replace(/[^0-9]/g, ""));
                        }}
                        maxLength={6}
                      ></TextInput>
                      <PretendardText
                        style={{
                          position: "absolute",
                          top: "13px",
                          right: "20px",
                          color: "#00E4BB",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        {formattedTime}
                      </PretendardText>
                    </div>
                    <PrimaryBtn
                      title={"인증하기"}
                      disabled={false}
                      style={{
                        width: "146px",
                        height: "48px",
                      }}
                      onClick={() => {
                        codeCheckFunc();
                      }}
                    ></PrimaryBtn>
                  </div>
                </>
              ) : null}

              <PrimaryBtn
                title={"다음"}
                disabled={btnFlag}
                style={{
                  width: "100%",
                  height: "48px",
                  marginTop: "40px",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => {
                  nextFunc();
                }}
              ></PrimaryBtn>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FindEmailModal;
