import api from "./api.js";

export default class PaymentApi {
  static async getPlan(params) {
    return await api.get(`plan`, params);
  }

  static async getCustomerKey() {
    return await api.get(`user/customer_key`);
  }

  static async subscribe(data) {
    return await api.post(`user/subscribe`, data);
  }
}
