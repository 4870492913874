import React, { useEffect, useState } from "react";
import PretendardText from "../atoms/pretendardText";
import { useRecoilValue } from "recoil";
import {
  menu01StateAtom,
  menu02StateAtom,
  menu03StateAtom,
  menu04StateAtom,
  menu05StateAtom,
  menu06StateAtom,
} from "../../recoil/menuState";
import { useHistory, useLocation } from "react-router-dom";
import { route } from "../../routes/route";

function VideoFilter({
  filterIdList,
  setFilterIdList,
  selectedMenuTemp,
  setSerchUrl,
}) {
  const history = useHistory();

  const menu01 = useRecoilValue(menu01StateAtom);
  const menu02 = useRecoilValue(menu02StateAtom);
  const menu03 = useRecoilValue(menu03StateAtom);
  const menu04 = useRecoilValue(menu04StateAtom);
  const menu05 = useRecoilValue(menu05StateAtom);
  const menu06 = useRecoilValue(menu06StateAtom);

  useEffect(() => {
    setMenus([menu01, menu02, menu03, menu04, menu05, menu06]);
  }, [menu01, menu02, menu03, menu04, menu05, menu06]);

  const [menus, setMenus] = useState([]);

  const [menuHover, setMenuHover] = useState(-1);
  const [selectedMenu, setSelectedMenu] = useState({});
  const [subMenuHover, setSubMenuHover] = useState(-1);
  const [selectedSubMenu, setSelectedSubMenu] = useState({});

  useEffect(() => {
    if (selectedMenuTemp) {
      setSelectedMenu(selectedMenuTemp);
    }
  }, [selectedMenuTemp]);

  return (
    <>
      <div style={{}}>
        <PretendardText
          style={{
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#939393",
            marginTop: "30px",
            marginLeft: "48px",
            marginBottom: "17px",
          }}
        >
          필터
        </PretendardText>
        <div>
          {menus.map((menu, index) => {
            return (
              <div key={menu.value}>
                <div
                  onMouseEnter={() => setMenuHover(index)}
                  onMouseLeave={() => setMenuHover(-1)}
                  style={{
                    width: "340px",
                    height: "55px",
                    padding: "0px 48px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",

                    background: menuHover === index ? "#333333" : "",
                  }}
                  onClick={() => {
                    if (selectedMenu.value === menu.value) {
                      setSelectedMenu({
                        title: "",
                        value: "",
                      });
                    } else {
                      setSelectedMenu({
                        title: menu.title,
                        value: menu.value,
                      });
                    }
                    setSelectedSubMenu({
                      title: "",
                      value: "",
                    });
                  }}
                >
                  <PretendardText
                    style={{
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "28.8px",
                      color:
                        selectedMenu.value === menu.value
                          ? "#00E4BB"
                          : "#FFFFFF",
                    }}
                  >
                    {menu.title}
                  </PretendardText>
                  <img
                    src="/assets/icons/icon_arrow.svg"
                    alt=""
                    style={{
                      width: "25px",
                      height: "25px",
                      transform:
                        selectedMenu.value === menu.value
                          ? "rotate(180deg)"
                          : "",
                    }}
                  />
                </div>

                {selectedMenu.value === menu.value
                  ? menu.subMenus.map((subMenu, index2) => {
                      return (
                        <div
                          key={subMenu.value}
                          onMouseEnter={() => setSubMenuHover(index2)}
                          onMouseLeave={() => setSubMenuHover(-1)}
                          style={{
                            width: "340px",
                            height: "55px",
                            padding: "0px 48px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            cursor: "pointer",

                            background:
                              subMenuHover === index2 ? "#333333" : "#1E1E1E",
                          }}
                          onClick={() => {
                            if (filterIdList.includes(subMenu.value)) {
                              filterIdList = filterIdList.filter(
                                (item) => item !== subMenu.value
                              );
                            } else {
                              filterIdList.push(subMenu.value);
                            }
                            setSerchUrl(2, filterIdList.join());
                          }}
                        >
                          <PretendardText
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: filterIdList.includes(subMenu.value)
                                ? "#00E4BB"
                                : "#FFFFFF",
                            }}
                          >
                            {subMenu.title}
                          </PretendardText>
                        </div>
                      );
                    })
                  : null}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default VideoFilter;
