import api from "./api.js";

export default class NoticeApi {
  static async GetNotice(data) {
    return await api.get(`notices`, data);
  }
  static async GetNoticeById(id) {
    return await api.get(`notice/${id}`);
  }
}
