import React, { useEffect, useState } from "react";
import MuktaText from "../atoms/muktaText";
import { useHistory } from "react-router-dom";
import { route } from "../../routes/route";
import PretendardText from "../atoms/pretendardText";
import PrimaryBtn from "../atoms/btn/primaryBtn";
import useModal from "../../hooks/useModal";
import { loginState } from "../../recoil/loginState";
import { useRecoilValue, useResetRecoilState } from "recoil";
import UserApi from "../../api/user";
import { errorHandler } from "../../utils/error-handler";

function NavM() {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const modal = useModal();
  const loginInfo = useRecoilValue(loginState);
  const loginReset = useResetRecoilState(loginState);

  //회원 정보 가져오기
  const [data, setData] = useState({});
  useEffect(() => {
    getData();
  }, [sessionStorage.name]);

  const getData = async () => {
    try {
      if (sessionStorage.getItem("id")) {
        let data = (await UserApi.getById()).data.data;
        setData(data);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      {isOpen ? (
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 8,
              width: "100%",
              height: "100%",
              backdropFilter: "blur(3.5px)",
            }}
          ></div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "55px",
              paddingLeft: "20px",
              paddingRight: "15px",
              justifyContent: "space-between",
              backdropFilter: "blur(3.5px)",
              zIndex: 10,
            }}
          >
            <MuktaText
              style={{
                color: "#FFFFFF",
                fontWeight: "700",
                fontSize: "25px",
                lineHeight: "32.5px",
              }}
              onClick={() => {
                modal.close();
                setIsOpen(false);
                history.push({
                  pathname: route.home,
                  search: ``,
                  state: {
                    activePage: 0,
                  },
                });
              }}
            >
              BeStock
            </MuktaText>
            <img
              src="/assets/icons/icon_close.svg"
              alt=""
              style={{
                width: "23px",
                height: "23px",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 9,
              width: "100%",
            }}
          >
            <div
              style={{
                background: "#000000",
                display: "flex",
                alignItems: "center",
                height: "200px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  paddingTop: "55px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {loginInfo.id ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <img
                          src={
                            data.fileUrl ?? "/assets/icons/profile_empty.svg"
                          }
                          alt=""
                          style={{
                            width: "55px",
                            height: "55px",
                            borderRadius: "50%",
                          }}
                        />
                        <div>
                          <PretendardText
                            style={{
                              fontWeight: "700",
                              fontSize: "18px",
                              lineHeight: "28.8px",
                              color: "#FFFFFF",
                            }}
                          >
                            {data.name}
                          </PretendardText>
                          <PretendardText
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#939393",
                            }}
                          >
                            마이페이지
                          </PretendardText>
                        </div>
                      </div>
                      <img
                        src="/assets/icons/icon_arrow.svg"
                        alt=""
                        style={{
                          transform: "rotate(270deg)",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          history.push(route.account);
                          setIsOpen(false);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <PretendardText
                      style={{
                        fontWeight: "400",
                        fontSize: "18px",
                        lineHeight: "28.8",
                        color: "#939393",
                      }}
                    >
                      로그인이 필요합니다.
                    </PretendardText>
                    <PrimaryBtn
                      title={"로그인"}
                      style={{
                        width: "125px",
                        height: "44px",
                        borderRadius: "60px",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                      onClick={() => {
                        sessionStorage.clear();
                        modal.open("login");
                        setIsOpen(false);
                      }}
                    ></PrimaryBtn>
                  </>
                )}
              </div>
            </div>

            <div
              style={{
                borderTop: "3px solid #333333",
                padding: "30px 20px",
                background: "#0E0E0E",
                height: "711px",
              }}
            >
              <div>
                <PretendardText
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "#939393",
                  }}
                >
                  메뉴
                </PretendardText>

                <PretendardText
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "28.5px",
                    color: "#FFFFFF",
                    height: "65px",
                    borderBottom: "1px solid #333333",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.close();
                    history.push({
                      pathname: route.home,
                      state: { activePage: 1 },
                    });
                    setIsOpen(false);
                  }}
                >
                  영상
                </PretendardText>
                <PretendardText
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "28.5px",
                    color: "#FFFFFF",
                    height: "65px",
                    borderBottom: "1px solid #333333",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.close();
                    history.push(route.bestock);
                    setIsOpen(false);
                  }}
                >
                  BeStock
                </PretendardText>
                <PretendardText
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "28.5px",
                    color: "#FFFFFF",
                    height: "65px",
                    borderBottom: "1px solid #333333",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.close();
                    history.push(route.payment);
                    setIsOpen(false);
                  }}
                >
                  요금제
                </PretendardText>
                <PretendardText
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "28.5px",
                    color: "#FFFFFF",
                    height: "65px",
                    borderBottom: "1px solid #333333",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.close();
                    history.push(route.matching + "/1");
                    setIsOpen(false);
                  }}
                >
                  1:1 매칭
                </PretendardText>
              </div>

              <div style={{ marginTop: "40px" }}>
                <PretendardText
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "#939393",
                  }}
                >
                  커뮤니티
                </PretendardText>

                <PretendardText
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "28.5px",
                    color: "#FFFFFF",
                    height: "65px",
                    borderBottom: "1px solid #333333",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.close();
                    history.push(route.notice + `/1`);
                    setIsOpen(false);
                  }}
                >
                  공지사항
                </PretendardText>
                <PretendardText
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "28.5px",
                    color: "#FFFFFF",
                    height: "65px",
                    borderBottom: "1px solid #333333",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.close();
                    history.push(route.faq + `/1`);
                    setIsOpen(false);
                  }}
                >
                  FAQ
                </PretendardText>
                <PretendardText
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "28.5px",
                    color: "#FFFFFF",
                    height: "65px",
                    borderBottom: "1px solid #333333",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.close();
                    history.push(route.contact);
                    setIsOpen(false);
                  }}
                >
                  문의하기
                </PretendardText>
              </div>

              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  gap: "22px",
                  justifyContent: "end",
                  marginRight: "10px",
                }}
              >
                {loginInfo.id ? (
                  <>
                    <PretendardText
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#BABABA",
                        textDecorationLine: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        modal.open("leave");
                        setIsOpen(false);
                      }}
                    >
                      회원탈퇴
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#BABABA",
                        textDecorationLine: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        modal.close();
                        sessionStorage.clear();
                        loginReset();
                        setIsOpen(false);
                        history.push(route.home);
                      }}
                    >
                      로그아웃
                    </PretendardText>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "55px",
            paddingLeft: "21px",
            paddingRight: "15px",
            justifyContent: "space-between",
            background: "rgba(255, 255, 255, 0.01)",
            backdropFilter: "blur(2.5px)",
            zIndex: 999,
          }}
        >
          <MuktaText
            style={{
              color: "#FFFFFF",
              fontWeight: "700",
              fontSize: "25px",
              lineHeight: "32.5px",
            }}
            onClick={() => {
              modal.close();
              history.push({
                pathname: route.home,
                search: ``,
                state: {
                  activePage: 0,
                  selectedMenu: {},
                },
              });
            }}
          >
            BeStock
          </MuktaText>
          <img
            src="/assets/icons/icon_menu.svg"
            alt=""
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setIsOpen(true);
            }}
          />
        </div>
      )}
    </>
  );
}

export default NavM;
