import React, { useRef, useState, useEffect } from "react";
import PretendardText from "../../atoms/pretendardText";
import useDisplay from "../../../hooks/useDisplay";

let currentScrollY = 0;
function TermModal({ setIsModal, content, title }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  useEffect(() => {
    currentScrollY = window.scrollY;

    document.body.style.cssText = `
      position: fixed;
      overflow-y: hidden;
      width: 100%;`;

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo({ top: currentScrollY, behavior: "auto" });
    };
  }, []);

  return (
    <>
      <>
        <div>
          <div
            style={{
              background: "rgba(30, 30, 30, 0.50)",
              backdropFilter: "blur(32.5px)",
              width: "100vw",
              height: "100vh",
              zIndex: 10000,
              display: "flex",
              position: "fixed",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 10001,
            }}
          >
            <div
              style={{
                width: isSmall ? "90vw" : "800px",
                height: isSmall ? "80vh" : "84vh",
                borderRadius: "15px",
                background: "#151515",
                boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.20)",
                padding: isSmall ? "30px 16px" : "40px",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontWeight: "700",
                    fontSize: "22px",
                    lineHeight: "35.2px",
                    color: "#fff",
                  }}
                >
                  {title}
                </PretendardText>
                <img
                  src="/assets/icons/icon_close.svg"
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsModal(false);
                  }}
                />
              </div>
              <PretendardText
                style={{
                  marginTop: 30,
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#fff",
                  whiteSpace: "pre-wrap",
                }}
              >
                {content}
              </PretendardText>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default TermModal;
