import api from "./api.js";

export default class TermsApi {
  static async getTerms(params) {
    return await api.get(`terms`, params);
  }
  static async getTermsByTitle(params) {
    return await api.get(`terms/title`, params);
  }
}
