import React, { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { route } from "../../routes/route";

import useDisplay from "../../hooks/useDisplay";
import PretendardText from "../../components/atoms/pretendardText";
import Card from "../../components/organisms/license/card";
import Card02 from "../../components/organisms/license/card02";
import LicenseApi from "../../api/license";
import { ko } from "../../utils/ko";
import TermsApi from "../../api/terms";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import Footer from "../../components/organisms/footer";

function Licenses() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const [licenseData, setLicenseData] = useState();
  const [licenseTermData, setLicenseTermData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getLicenseInfo();
    getTermInfo();
  }, []);

  const getLicenseInfo = async () => {
    try {
      const dataTemp = (await LicenseApi.getLicense()).data.data;

      setLicenseData(dataTemp);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const getTermInfo = async () => {
    try {
      const dataTemp = (await TermsApi.getTerms({ termsType: "LICENSE" })).data.data;

      setLicenseTermData(dataTemp);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "#151515",
            width: "100%",
            position: "relative",
            padding: isSmall ? "95px 0px 70px 0px" : "280px 0px 200px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: isSmall ? "100%" : 1170,
            }}
          >
            <PretendardText
              style={{
                fontWeight: isSmall ? "600" : "700",
                fontSize: isSmall ? "14px" : "16px",
                lineHeight: isSmall ? "21px" : "24px",
                color: "#00E4BB",
                marginBottom: isSmall ? 4 : 15,
                textAlign: isSmall ? "center" : "left",
              }}
            >
              License and Copyright
            </PretendardText>
            <PretendardText
              style={{
                fontWeight: isSmall ? "600" : "700",
                fontSize: isSmall ? "24px" : "36px",
                lineHeight: isSmall ? "36px" : "54px",
                color: "#FFFFFF",
                textAlign: isSmall ? "center" : "left",
                paddingBottom: isSmall ? 34 : 60,
                borderBottom: isSmall ? "6px solid #1E1E1E" : "none",
              }}
            >
              콘텐츠 라이선스 및 저작권 정책
            </PretendardText>

            <div
              style={{
                marginLeft: isSmall ? 20 : 0,
                marginRight: isSmall ? 20 : 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: isSmall ? 30 : 60,
                  marginTop: isSmall ? 12 : 0,
                }}
              >
                <PretendardText
                  onClick={() => {
                    history.push({
                      pathname: route.licenses,
                    });
                  }}
                  style={{
                    fontWeight: isSmall ? "600" : "600",
                    fontSize: isSmall ? "16px" : "20px",
                    lineHeight: isSmall ? "24px" : "30px",
                    color: "#FFFFFF",
                    textAlign: "left",
                    paddingBottom: 10,
                    marginRight: 45,
                    cursor: "pointer",
                    borderBottom: "2px solid #00E4BB",
                  }}
                >
                  콘텐츠 라이선스
                </PretendardText>
                <PretendardText
                  onClick={() => {
                    history.push({
                      pathname: route.copyright,
                    });
                  }}
                  style={{
                    fontWeight: isSmall ? "600" : "600",
                    fontSize: isSmall ? "16px" : "20px",
                    lineHeight: isSmall ? "24px" : "30px",
                    color: "#939393",
                    textAlign: "left",
                    marginBottom: 10,
                    cursor: "pointer",
                  }}
                >
                  저작권 정책
                </PretendardText>
              </div>

              <Card title={"규정"} basic={"Basic"} standard={"Standard"} pro={"Pro"} />

              {licenseData && licenseData.length > 0
                ? licenseData.map((item) => {
                    return (
                      <Card02
                        title={item.title}
                        basic={item.basic ? "O" : "-"}
                        standard={item.standard ? "O" : "-"}
                        pro={item.pro ? "O" : "-"}
                      />
                    );
                  })
                : null}

              <div
                style={{
                  width: isSmall ? "100%" : 1170,
                  height: isSmall ? 10 : 30,
                }}
              />

              {licenseTermData && licenseTermData.length > 0
                ? licenseTermData.map((item) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: isSmall ? "100%" : 1170,
                          background: "#1E1E1E",
                          borderRadius: 10,
                          padding: isSmall ? 16 : 30,
                          marginTop: isSmall ? 20 : 30,
                        }}
                      >
                        <PretendardText
                          style={{
                            fontWeight: isSmall ? "600" : "700",
                            fontSize: isSmall ? "16px" : "18px",
                            lineHeight: isSmall ? "24px" : "28.8px",
                            color: "#DFDFDF",
                            marginBottom: isSmall ? 12 : 15,
                          }}
                        >
                          {item.title}
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontWeight: "400",
                            fontSize: isSmall ? "14px" : "16px",
                            lineHeight: isSmall ? "21px" : "24px",
                            color: "#DFDFDF",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {item.content}
                        </PretendardText>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>

        <Footer />
      </Container>
    </>
  );
}

export default Licenses;
