import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useDisplay from "../../hooks/useDisplay";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";

function VideoStreaming() {
  const setScreenSize = () => {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    setScreenSize();
  }, [window.innerHeight]);

  const [resource, setResource] = useState("");
  useEffect(() => {
    const str = window.location.pathname;

    setResource(
      // `${window.location.origin}${str.replace("/bestock", "/api")}${
      //   window.location.search
      // }`
      sessionStorage.getItem("src")
    );
  }, []);

  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      {isMobile ? <NavM /> : null}
      <div
        style={{
          background: "#151515",
          height: isMobile ? "calc(var(--vh, 1vh) * 100)" : "100vh",
          padding: isMobile ? "" : "0px 51px",
          display: isMobile ? "flex" : "",
          flexDirection: isMobile ? "column" : "",
          justifyContent: isMobile ? "center" : "",
        }}
      >
        <div
          style={{
            width: "100%",
            height: isMobile ? "" : "100%",
            position: "relative",
          }}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          {resource ? (
            <video
              disablePictureInPicture
              preload="true"
              controls={true}
              playsInline
              controlsList="nodownload"
              style={{
                width: "100%",
                objectFit: "cover",
                height: "100%",
              }}
            >
              <source src={`${resource}`} />
              {/* <source src={`https://bestock.co.kr/api/video/streaming?resolution=HD&videoId=37`} type="video/mp4" /> */}
            </video>
          ) : null}
          <img
            src="/assets/icons/detail_watermark.svg"
            alt=""
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isMobile ? "142px" : "604px",
              height: isMobile ? "52px" : "221px",
            }}
          />
        </div>
      </div>
    </>
  );
}

export default VideoStreaming;
