import React from "react";
import { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { errorHandler } from "../../utils/error-handler";
import PretendardText from "../../components/atoms/pretendardText";
import TitleLabel from "../../components/atoms/text/titleLabel";
import TextInput from "../../components/atoms/input/textInput";
import PasswordInput from "../../components/atoms/input/passwordInput";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import MypageSide from "../../components/organisms/mypageSide";
import useDisplay from "../../hooks/useDisplay";
import UserApi from "./../../api/user";
import { nullCheck } from "../../utils/check";
import { regExpression } from "../../utils/reg-expresstion";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ko } from "../../utils/ko";
import useModal from "../../hooks/useModal";
import { route } from "../../routes/route";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import Footer from "../../components/organisms/footer";
import { useMemo } from "react";

function Account() {
  const modal = useModal();
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [originData, setOriginData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [data, setData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [password, setPassword] = useState({ password: "", passwordCheck: "" });

  useEffect(() => {
    if (!sessionStorage.getItem("id")) {
      history.push(route.home);
      modal.open("login");
      return;
    }
    getList();
  }, []);

  const getList = async () => {
    try {
      if (sessionStorage.getItem("id")) {
        let data = (await UserApi.getById()).data.data;
        setData({ ...data });
        setOriginData({ ...data });
        if (data.joinType === "NAVER" || data.joinType === "GOOGLE") {
          setNameFlag(true);
        }
        if (data.joinType !== "NORMAL") {
          setSocialFlag(true);
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const [nameFlag, setNameFlag] = useState(false);
  const [socialFlag, setSocialFlag] = useState(false);

  //클립보드 복사
  const handleCopyClipBoard = async (email) => {
    try {
      await navigator.clipboard.writeText(email);
      toast("추천인 코드가 복사되었습니다.");
    } catch (e) {
      toast("추천인 코드 복사에 실패하였습니다");
    }
  };

  //이름 변경되었는지 판단
  const [isNotNameChanged, setIsNotNameChanged] = useState(true);
  useEffect(() => {
    if (nullCheck(data.name) || data.name === originData.name) {
      setIsNotNameChanged(true);
    }
    if (!nullCheck(data.name) && data.name !== originData.name) {
      setIsNotNameChanged(false);
    }
  }, [data, originData]);

  //비밀번호 변경되었는지 판단
  const [isNotPwChanged, setIsNotPwChanged] = useState(true);
  useEffect(() => {
    if (
      regExpression.password.test(password.password) &&
      regExpression.password.test(password.passwordCheck) &&
      !nullCheck(password.password) &&
      !nullCheck(password.passwordCheck) &&
      password.password === password.passwordCheck
    ) {
      setIsNotPwChanged(false);
    } else {
      setIsNotNameChanged(true);
    }
  }, [password]);

  //사진 바꾸기
  const [isNotFileChanged, setIsNotFileChanged] = useState(true);

  //이미지 추가, 삭제
  const inputFile = (e) => {
    setIsNotFileChanged(false);
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (fileData) => {
      setData({
        ...data,
        file: file,
        fileUrl: fileData.target.result,
      });
    };
    e.target.value = "";
  };

  const [phoneChanged, setPhoneChanged] = useState(false);
  const [phoneCheck, setPhoneCheck] = useState(true);
  const [randomNumber, setRandomNumber] = useState("");

  const phoneCheckFunc = async () => {
    try {
      setCode("");
      const saveData = {
        phoneNumber: data.phoneNumber,
      };
      const response = await UserApi.sendSmsCode(saveData);
      setRandomNumber(response.data.data.randomNumber);
      setTime(180);
      const timerId = setInterval(tick, 1000);
      setTimer(timerId);
    } catch (error) {
      errorHandler(error);
    }
  };

  const [code, setCode] = useState("");
  const codeCheckFunc = () => {
    if (time <= 0) {
      return toast("인증에 실패하였습니다. 인증을 다시 시도하세요.");
    }
    if (randomNumber !== code) {
      return toast("인증번호가 일치하지 않습니다. 인증을 다시 시도하세요.");
    }
    toast("인증되었습니다.");
    setRandomNumber("");
    setPhoneCheck(true);
    clearInterval(timer);
  };

  const [time, setTime] = useState(180); // 초 단위로 시간을 저장
  const [timer, setTimer] = useState("");

  // 1초마다 시간을 감소시키는 함수
  const tick = () => {
    setTime((time) => time - 1);
  };

  // 시간을 포맷팅하여 00:00 형태로 표시
  const formattedTime = useMemo(() => {
    if (time <= 0) {
      clearInterval(timer);
      toast("인증에 실패하였습니다. 인증을 다시 시도하세요.");
      setRandomNumber("");
    }
    return `${String(Math.floor(time / 60)).padStart(2, "0")}:${String(
      time % 60
    ).padStart(2, "0")}`;
  }, [time]);

  const updateBtnFlag = useMemo(() => {
    return (
      (isNotNameChanged &&
        isNotPwChanged &&
        isNotFileChanged &&
        !phoneChanged) ||
      (phoneChanged && !phoneCheck)
    );
  }, [
    isNotNameChanged,
    isNotPwChanged,
    isNotFileChanged,
    phoneChanged,
    phoneCheck,
  ]);

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}
        {isMobile ? (
          <>
            <div
              style={{
                padding: "0px 20px",
                paddingTop: "70px",
              }}
            >
              <PretendardText
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#00E4BB",
                  textAlign: "center",
                }}
              >
                My page
              </PretendardText>
              <PretendardText
                style={{
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "36px",
                  color: "#FFFFFF",
                  textAlign: "center",
                  marginTop: "4px",
                }}
              >
                마이페이지
              </PretendardText>
            </div>
            <MypageSide></MypageSide>

            <div
              style={{
                height: "1px",
                background: "#333333",
              }}
            ></div>
            <div
              style={{
                padding: "0px 20px",
                marginBottom: "70px",
              }}
            >
              <TitleLabel title={"이름"}></TitleLabel>
              <TextInput
                placeholder={"이름을 입력해 주세요."}
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                disabled={nameFlag}
                maxLength={20}
                onBlur={() => {
                  setData({ ...data, name: data.name.trim() });
                }}
              ></TextInput>

              <TitleLabel title={"이메일"}></TitleLabel>
              <TextInput
                placeholder={"이메일을 입력해 주세요."}
                disabled={true}
                value={data.email}
              ></TextInput>
              <PretendardText
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "16.8px",
                  color: "#00E4BB",
                  width: "96px",
                  height: "40px",
                  borderRadius: "5px",
                  border: "1px solid #00E4BB",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
                onClick={() => handleCopyClipBoard(data.email)}
              >
                추천인코드 복사
              </PretendardText>
              <PretendardText
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "16.8px",
                  color: "#BABABA",
                  width: "338px",
                  marginTop: "10px",
                }}
              >
                *연간 구독자일 경우 신규 회원가입 시 추천인 코드를 입력하면
                <br />
                2개월 구독기간이 연장됩니다.
              </PretendardText>

              {socialFlag ? null : (
                <>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      marginTop: "30px",
                      marginBottom: "10px",
                    }}
                  >
                    <TitleLabel
                      title={"비밀번호"}
                      style={{ marginTop: "0px", marginBottom: "0px" }}
                    ></TitleLabel>
                    <PretendardText
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#939393",
                      }}
                    >
                      비밀번호 변경 시에만 입력해 주세요.
                    </PretendardText>
                  </div>
                  <PasswordInput
                    placeholder={"영문/숫자 조합 6~16자"}
                    onChange={(e) => {
                      setPassword({
                        ...password,
                        password: e.target.value.trim(),
                      });
                    }}
                    isError={
                      password.password &&
                      !regExpression.password.test(password.password)
                    }
                    errorText={"영문/숫자 조합 6~16자를 입력해 주세요."}
                  ></PasswordInput>
                </>
              )}

              {socialFlag ? null : (
                <>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      marginTop: "30px",
                      marginBottom: "10px",
                    }}
                  >
                    <TitleLabel
                      title={"비밀번호 확인"}
                      style={{ marginTop: "0px", marginBottom: "0px" }}
                    ></TitleLabel>
                    <PretendardText
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#939393",
                      }}
                    >
                      비밀번호 변경 시에만 입력해 주세요.
                    </PretendardText>
                  </div>
                  <PasswordInput
                    placeholder={"영문/숫자 조합 6~16자"}
                    onChange={(e) => {
                      setPassword({
                        ...password,
                        passwordCheck: e.target.value.trim(),
                      });
                    }}
                    isError={
                      password.passwordCheck &&
                      password.password !== password.passwordCheck
                    }
                    errorText={"비밀번호가 일치하지 않습니다"}
                  ></PasswordInput>
                </>
              )}

              <TitleLabel title={"전화번호"}></TitleLabel>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <TextInput
                  placeholder={"숫자만 입력 가능합니다."}
                  style={{
                    width: "100%",
                  }}
                  value={data.phoneNumber}
                  onChange={(e) => {
                    data.phoneNumber = e.target.value.replace(/[^0-9]/g, "");
                    setData({ ...data });
                  }}
                  disabled={phoneCheck}
                  maxLength={11}
                ></TextInput>
                {phoneCheck ? (
                  <PrimaryBtn
                    title={"다시인증"}
                    disabled={false}
                    style={{
                      width: "119px",
                      height: "48px",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                    onClick={() => {
                      setPhoneCheck(false);
                      setPhoneChanged(true);
                    }}
                  ></PrimaryBtn>
                ) : (
                  <PrimaryBtn
                    title={"인증요청"}
                    disabled={
                      nullCheck(data.phoneNumber) ||
                      phoneCheck ||
                      !regExpression.phoneNumber.test(data.phoneNumber) ||
                      !nullCheck(randomNumber)
                    }
                    style={{
                      width: "119px",
                      height: "48px",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                    onClick={() => {
                      phoneCheckFunc();
                    }}
                  ></PrimaryBtn>
                )}
              </div>

              {randomNumber ? (
                <>
                  <TitleLabel
                    title={"인증번호 입력"}
                    isRequired={true}
                  ></TitleLabel>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <TextInput
                        placeholder={"인증번호 6자리를 입력하세요."}
                        value={code}
                        onChange={(e) => {
                          setCode(e.target.value.replace(/[^0-9]/g, ""));
                        }}
                        maxLength={6}
                      ></TextInput>
                      <PretendardText
                        style={{
                          position: "absolute",
                          top: "13px",
                          right: "20px",
                          color: "#00E4BB",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        {formattedTime}
                      </PretendardText>
                    </div>
                    <PrimaryBtn
                      title={"인증하기"}
                      disabled={false}
                      style={{
                        width: "119px",
                        height: "48px",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "21px",
                      }}
                      onClick={() => {
                        codeCheckFunc();
                      }}
                    ></PrimaryBtn>
                  </div>
                </>
              ) : null}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <PrimaryBtn
                  title={"수정하기"}
                  disabled={updateBtnFlag}
                  style={{
                    width: "146px",
                    height: "48px",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={async () => {
                    try {
                      const formData = new FormData();
                      formData.append("name", data.name);
                      formData.append("phoneNumber", data.phoneNumber);
                      formData.append("deleteFile", false);
                      if (data.fileUrl) {
                        formData.append("file", data.file);
                      }
                      if (!isNotPwChanged) {
                        formData.append("password", password.password);
                      }
                      await UserApi.updateAccount(formData);
                      toast(ko.update);
                    } catch (error) {
                      errorHandler(error);
                    }
                  }}
                ></PrimaryBtn>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                height: "80px",
              }}
            ></div>

            <div
              style={{
                display: "flex",
                minHeight: "1135px",
              }}
            >
              <MypageSide />

              <div
                style={{
                  paddingTop: "30px",
                  paddingLeft: "35px",
                  width: "475px",
                }}
              >
                <PretendardText
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#00E4BB",
                  }}
                >
                  Account
                </PretendardText>
                <PretendardText
                  style={{
                    fontWeight: "700",
                    fontSize: "36px",
                    lineHeight: "54px",
                    color: "#FFFFFF",
                    marginTop: "15px",
                  }}
                >
                  계정관리
                </PretendardText>
                <FileInput
                  type="file"
                  id="file"
                  accept="image/jpg, image/png, image/jpeg"
                  onChange={(e) => inputFile(e)}
                />
                <FileLabel htmlFor="file">
                  {data.fileUrl ? (
                    <img
                      style={{
                        width: "72px",
                        height: "72px",
                        borderRadius: "50%",
                      }}
                      src={data.fileUrl}
                      alt=""
                    />
                  ) : (
                    <img src="/assets/icons/profile_input.svg" alt="" />
                  )}

                  <img
                    src="/assets/icons/icon_profile_edit.svg"
                    alt=""
                    style={{
                      position: "absolute",
                      right: "-8px",
                      bottom: "1.6px",
                    }}
                  />
                </FileLabel>

                <TitleLabel title={"이름"}></TitleLabel>
                <TextInput
                  placeholder={"이름을 입력해 주세요."}
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  disabled={nameFlag}
                  maxLength={20}
                  onBlur={() => {
                    setData({ ...data, name: data.name.trim() });
                  }}
                ></TextInput>

                <TitleLabel title={"이메일"}></TitleLabel>
                <div style={{ display: "flex", gap: "11px" }}>
                  <TextInput
                    placeholder={"이메일을 입력해 주세요."}
                    value={data.email}
                    disabled={true}
                    style={{ width: "310px" }}
                  ></TextInput>
                  <PretendardText
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#00E4BB",
                      width: "119px",
                      height: "52px",
                      borderRadius: "5px",
                      border: "1px solid #00E4BB",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => handleCopyClipBoard(data.email)}
                  >
                    추천인코드 복사
                  </PretendardText>
                </div>
                <PretendardText
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "#BABABA",
                    width: "338px",
                    marginTop: "10px",
                  }}
                >
                  *연간 구독자일 경우 신규 회원가입 시 추천인 코드를 입력하면
                  2개월 구독기간이 연장됩니다.
                </PretendardText>

                {socialFlag ? null : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        marginTop: "30px",
                        marginBottom: "10px",
                      }}
                    >
                      <TitleLabel
                        title={"비밀번호"}
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                      ></TitleLabel>
                      <PretendardText
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#939393",
                        }}
                      >
                        비밀번호 변경 시에만 입력해 주세요.
                      </PretendardText>
                    </div>
                    <PasswordInput
                      placeholder={"영문/숫자 조합 6~16자"}
                      onChange={(e) => {
                        setPassword({
                          ...password,
                          password: e.target.value.trim(),
                        });
                      }}
                      isError={
                        password.password &&
                        !regExpression.password.test(password.password)
                      }
                      errorText={"영문/숫자 조합 6~16자를 입력해 주세요."}
                    ></PasswordInput>
                  </>
                )}

                {socialFlag ? null : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        marginTop: "30px",
                        marginBottom: "10px",
                      }}
                    >
                      <TitleLabel
                        title={"비밀번호 확인"}
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                      ></TitleLabel>
                      <PretendardText
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#939393",
                        }}
                      >
                        비밀번호 변경 시에만 입력해 주세요.
                      </PretendardText>
                    </div>
                    <PasswordInput
                      placeholder={"영문/숫자 조합 6~16자"}
                      onChange={(e) => {
                        setPassword({
                          ...password,
                          passwordCheck: e.target.value.trim(),
                        });
                      }}
                      isError={
                        password.passwordCheck &&
                        password.password !== password.passwordCheck
                      }
                      errorText={"비밀번호가 일치하지 않습니다"}
                    ></PasswordInput>
                  </>
                )}

                <TitleLabel title={"전화번호"}></TitleLabel>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <TextInput
                    placeholder={"숫자만 입력 가능합니다."}
                    style={{
                      width: "285px",
                    }}
                    value={data.phoneNumber}
                    onChange={(e) => {
                      data.phoneNumber = e.target.value.replace(/[^0-9]/g, "");
                      setData({ ...data });
                    }}
                    disabled={phoneCheck}
                    maxLength={11}
                  ></TextInput>
                  {phoneCheck ? (
                    <PrimaryBtn
                      title={"다시인증"}
                      disabled={false}
                      style={{
                        fontWeight: "700",
                        width: "146px",
                        height: "48px",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                      onClick={() => {
                        setPhoneCheck(false);
                        setPhoneChanged(true);
                      }}
                    />
                  ) : (
                    <PrimaryBtn
                      title={"인증요청"}
                      disabled={
                        nullCheck(data.phoneNumber) ||
                        phoneCheck ||
                        !regExpression.phoneNumber.test(data.phoneNumber) ||
                        !nullCheck(randomNumber)
                      }
                      style={{
                        fontWeight: "700",
                        width: "146px",
                        height: "48px",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                      onClick={() => {
                        phoneCheckFunc();
                      }}
                    />
                  )}
                </div>

                {randomNumber ? (
                  <>
                    <TitleLabel
                      title={"인증번호 입력"}
                      isRequired={true}
                    ></TitleLabel>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "285px",
                        }}
                      >
                        <TextInput
                          placeholder={"인증번호 6자리를 입력하세요."}
                          value={code}
                          onChange={(e) => {
                            setCode(e.target.value.replace(/[^0-9]/g, ""));
                          }}
                          maxLength={6}
                        ></TextInput>
                        <PretendardText
                          style={{
                            position: "absolute",
                            top: "13px",
                            right: "20px",
                            color: "#00E4BB",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                        >
                          {formattedTime}
                        </PretendardText>
                      </div>
                      <PrimaryBtn
                        title={"인증하기"}
                        disabled={false}
                        style={{
                          fontWeight: "700",
                          width: "146px",
                          height: "48px",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                        onClick={() => {
                          codeCheckFunc();
                        }}
                      />
                    </div>
                  </>
                ) : null}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "70px",
                  }}
                >
                  <PrimaryBtn
                    title={"수정하기"}
                    disabled={updateBtnFlag}
                    style={{
                      width: "146px",
                      height: "48px",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                    onClick={async () => {
                      try {
                        const formData = new FormData();
                        formData.append("name", data.name);
                        formData.append("phoneNumber", data.phoneNumber);
                        formData.append("deleteFile", false);
                        if (data.fileUrl) {
                          formData.append("file", data.file);
                        }
                        if (!isNotPwChanged) {
                          formData.append("password", password.password);
                        }
                        await UserApi.updateAccount(formData);
                        toast(ko.update);
                      } catch (error) {
                        errorHandler(error);
                      }
                    }}
                  ></PrimaryBtn>
                </div>
              </div>
            </div>
          </>
        )}

        <Footer />
      </Container>
    </>
  );
}

export default Account;

const FileInput = styled.input.attrs((props) => {})`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const FileLabel = styled.label.attrs((props) => {})`
  display: block;
  position: relative;
  width: 72px;
  height: 72px;
  margin-top: 70px;
  cursor: pointer;
`;
