import React from "react";
import styled from "styled-components";

function DateInput({ placeholder, style, iconStyle, onChange, value }) {
  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <InputWrapper
          required
          type={"date"}
          data-placeholder={"날짜 선택"}
          style={{
            border: "1px solid #939393",
            ...style,
          }}
          onChange={onChange}
          value={value}
        />
        <img
          src="/assets/icons/icon_celendar.svg"
          alt=""
          style={{
            position: "absolute",
            right: "20px",
            top: "11px",
            ...iconStyle,
          }}
        />
      </div>
    </>
  );
}

export default DateInput;

const InputWrapper = styled.input.attrs((props) => {})`
  width: 100%;
  height: 52px;
  border-radius: 5px;
  background: inherit;
  padding-left: 20px;
  color: #dfdfdf;
  font-weight: 400;
  font-size: 16px;
  lign-height: 24px;

  :focus {
    outline: none !important;
    border-color: #ffffff;
    caret-color: #ffffff !important;
  }

  ::placeholder {
    color: #939393;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  :disabled {
    color: #939393 !important;
    background: #333333 !important;
  }
`;
