import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { route } from "../../routes/route";

function VideoTemp() {
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    history.replace(route.videoDetail + `/${id}`);
  }, []);

  return <div>VideoTemp</div>;
}

export default VideoTemp;
