import React from "react";
import MypageSide from "../../components/organisms/mypageSide";
import PretendardText from "../../components/atoms/pretendardText";
import { route } from "../../routes/route";
import { errorHandler } from "../../utils/error-handler";
import { useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import Pagination from "../../components/organisms/pagination";
import useDisplay from "../../hooks/useDisplay";
import ScrollContainer from "react-indiana-drag-scroll";
import UserApi from "./../../api/user";
import useModal from "../../hooks/useModal";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import Footer from "../../components/organisms/footer";

function PayHistory() {
  const modal = useModal();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const history = useHistory();
  const location = useLocation();

  const [list, setList] = useState([]);
  const [numberOfElements, setNumberOfElements] = useState(null);

  //페이지네이션
  const { page } = useParams();
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 10, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  useEffect(() => {
    if (!sessionStorage.getItem("id")) {
      history.push(route.home);
      modal.open("login");
      return;
    }
    getList();
  }, [location]);

  const getList = async () => {
    try {
      const data = {
        page: page - 1,
        size: pagination.size,
      };

      let list = (await UserApi.getPayments(data)).data.data;
      setNumberOfElements(list.totalElements);
      setList(
        list.content.map((item) => {
          return {
            ...item,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}

        {isMobile ? (
          <>
            <div
              style={{
                padding: "0px 20px",
                paddingTop: "70px",
              }}
            >
              <PretendardText
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#00E4BB",
                  textAlign: "center",
                }}
              >
                My page
              </PretendardText>
              <PretendardText
                style={{
                  fontWeight: "600",
                  fontSize: "24px",
                  lineHeight: "36px",
                  color: "#FFFFFF",
                  textAlign: "center",
                  marginTop: "4px",
                }}
              >
                마이페이지
              </PretendardText>
            </div>
            <MypageSide></MypageSide>

            <div
              style={{
                height: "1px",
                background: "#333333",
              }}
            ></div>
            <div
              style={{
                padding: "0px 20px",
                marginTop: "40px",
                marginBottom: "70px",
              }}
            >
              <PretendardText
                style={{
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "28.8px",
                  color: "#FFFFFF",
                }}
              >
                전체 이용내역({numberOfElements})
              </PretendardText>

              <div
                style={{
                  width: "100vw",
                  marginTop: "30px",
                  overflowX: "auto",
                  overflowY: "hidden",
                }}
              >
                <ScrollContainer
                  className="scroll-container"
                  hideScrollbars={false}
                >
                  <div
                    style={{
                      width: "1170px",
                      height: "704px",
                      marginBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0px 30px",
                        gap: "10px",
                        borderTop: "2px solid #565656",
                        borderBottom: "1px solid #565656",
                        height: "64px",
                      }}
                    >
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "160px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        결제일
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "160px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        요금제명
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "285px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        서비스 이용 기간
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "150px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        결제수단
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "180px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        결제금액
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "125px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        영수증
                      </PretendardText>
                    </div>
                    {list.length === 0 ? (
                      <div>
                        <PretendardText
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#939393",
                            margin: "100px 0px",
                            textAlign: "center",
                          }}
                        >
                          이용내역이 없습니다.
                        </PretendardText>
                      </div>
                    ) : (
                      <>
                        {list.map((item, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0px 30px",
                                gap: "10px",
                                borderBottom: "1px solid #565656",
                                height: "64px",
                              }}
                            >
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "160px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.payDate}
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "160px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.planName}
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "285px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.payDate} ~ {item.endDate}
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "150px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.payMethod}
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "180px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.payAmount.toLocaleString("ko-KR")}원
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#00E4BB",
                                  width: "125px",
                                  display: "flex",
                                  justifyContent: "center",
                                  textDecorationLine: "underline",
                                }}
                              >
                                카드전표출력
                              </PretendardText>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </ScrollContainer>
              </div>

              <Pagination
                style={{ marginTop: "40px", marginBottom: "70px" }}
                route={route.payHistory}
                currentPage={page}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
                first={pagination.first}
                last={pagination.last}
              />
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                height: "80px",
              }}
            ></div>

            <div
              style={{
                display: "flex",
                minHeight: "1250px",
              }}
            >
              <MypageSide />

              <div
                style={{
                  paddingTop: "30px",
                  paddingLeft: "35px",
                }}
              >
                <PretendardText
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#00E4BB",
                  }}
                >
                  Payments
                </PretendardText>
                <PretendardText
                  style={{
                    fontWeight: "700",
                    fontSize: "36px",
                    lineHeight: "54px",
                    color: "#FFFFFF",
                    marginTop: "15px",
                  }}
                >
                  결제내역
                </PretendardText>

                <div
                  style={{
                    marginTop: "70px",
                  }}
                >
                  <PretendardText
                    style={{
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "28.8px",
                      color: "#FFFFFF",
                    }}
                  >
                    전체 결제내역({numberOfElements})
                  </PretendardText>

                  <div
                    style={{
                      marginTop: "41px",
                      width: "1170px",
                      height: "704px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0px 30px",
                        gap: "10px",
                        borderTop: "2px solid #565656",
                        borderBottom: "1px solid #565656",
                        height: "64px",
                      }}
                    >
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "160px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        결제일
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "160px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        요금제명
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "285px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        서비스 이용 기간
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "150px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        결제수단
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "180px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        결제금액
                      </PretendardText>
                      <PretendardText
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          width: "125px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        영수증
                      </PretendardText>
                    </div>
                    {list.length === 0 ? (
                      <div>
                        <PretendardText
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#939393",
                            margin: "120px 0px",
                            textAlign: "center",
                          }}
                        >
                          이용내역이 없습니다.
                        </PretendardText>
                      </div>
                    ) : (
                      <>
                        {list.map((item, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0px 30px",
                                gap: "10px",
                                borderBottom: "1px solid #565656",
                                height: "64px",
                              }}
                            >
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "160px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.payDate}
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "160px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.planName}
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "285px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.payDate} ~ {item.endDate}
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "150px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.payMethod}
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#BABABA",
                                  width: "180px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {item.payAmount.toLocaleString("ko-KR")}원
                              </PretendardText>
                              <PretendardText
                                style={{
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#00E4BB",
                                  width: "125px",
                                  display: "flex",
                                  justifyContent: "center",
                                  textDecorationLine: "underline",
                                }}
                              >
                                카드전표출력
                              </PretendardText>
                            </div>
                          );
                        })}
                      </>
                    )}

                    <Pagination
                      style={{ marginTop: "43px" }}
                      route={route.payHistory}
                      currentPage={page}
                      totalPages={pagination.totalPages}
                      rangeSize={pagination.rangeSize}
                      first={pagination.first}
                      last={pagination.last}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <Footer />
      </Container>
    </>
  );
}

export default PayHistory;
