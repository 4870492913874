import React, { useEffect, useMemo, useRef, useState } from "react";
import PretendardText from "../atoms/pretendardText";
import MuktaText from "../atoms/muktaText";
import { useHistory } from "react-router-dom";
import { route } from "../../routes/route";
import ReactPlayer from "react-player";
import { errorHandler } from "../../utils/error-handler";
import VideoApi from "../../api/video";
import api from "../../api/api";
import { nullCheck } from "../../utils/check";

function VideoCard({ style, item }) {
  const history = useHistory();
  const [hover, setHover] = useState(false);

  useEffect(() => {
    setData({ ...item });
  }, [item]);

  const [data, setData] = useState({});

  // const [videoState, setVideoState] = useState({
  //   playing: true, // 재생중인지
  //   muted: false, // 음소거인지
  //   controls: false, // 기본으로 제공되는 컨트롤러 사용할건지
  //   volume: 0.5, // 볼륨크기
  //   playbackRate: 1.0, // 배속
  //   played: 0, // 재생의 정도 (value)
  //   seeking: false, // 재생바를 움직이고 있는지
  //   duration: 0, // 전체 시간
  // });

  const storeFunc = async (storeFlag) => {
    try {
      if (storeFlag) {
        await VideoApi.storeVideo(data.id);
      } else {
        await VideoApi.unStoreVideo(data.id);
      }
      setData({ ...data, userStored: storeFlag });
    } catch (error) {
      errorHandler(error);
    }
  };

  // const resolution = useMemo(() => {
  //   if(!nullCheck(item)) {
  //     if (item.hdVideoItem) {
  //       return "HD"
  //     }
  //     else if (item.fhdVideoItem) {
  //       return "FHD"
  //     }
  //     else if (item.twoKiloVideoItem) {
  //       return "TWO_KILO"
  //     }
  //     else if (item.fourKiloVideoItem) {
  //       return "FOUR_KILO"
  //     }
  //     else if (item.logVideoItem) {
  //       return "LOG"
  //     }
  //   }
  //   return ""
  // }, [item])

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: "490px",
        // height: "275px",
        aspectRatio: "16 / 9",
        background: "#D9D9D9",
        position: "relative",
        overflow: "hidden",
        ...style,
      }}
    >
      {/* <video
        playsInline
        style={{
          width: "100%",
          objectFit: "cover",
          height: "100%",
        }}
        onClick={() => {
          history.push(route.videoDetail + `/1`);
        }}
      >
        <source
          src="https://v.ftcdn.net/06/34/04/60/700_F_634046077_twhrC0SuXpLOF226wxmU5kbFcSTUaEnE_ST.mp4"
          type="video/mp4"
        />
      </video> */}
      {/* <ReactPlayer
        className="videoPlayer"
        width={"100%"}
        height={"100%"}
        controls={false}
        onDuration={(duration) => {
          const minutes = Math.floor(duration / 60);
          const seconds = Math.floor(duration % 60);
          setVideoState({
            ...videoState,
            duration: `${minutes.toString().padStart(2, "0")}:${seconds
              .toString()
              .padStart(2, "0")}`,
          });
        }}
        url={`${api.getUrl()}/api/video/streaming?resolution=${resolution}&videoId=${data.id
          }`}
        onClick={() => {
          history.push(route.videoDetail + `/${data.id}`);
        }}
      /> */}

      <img
        src={data.thumbFileUrl}
        alt=""
        style={{
          width: "100%",
          height: "100%",
        }}
        onClick={() => {
          history.push(route.videoDetail + `/${data.id}`);
        }}
      />

      <img
        src="/assets/icons/watermark.svg"
        alt=""
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        onClick={() => {
          history.push(route.videoDetail + `/${data.id}`);
        }}
      />

      {hover ? (
        <>
          {sessionStorage.getItem("id") ? (
            <img
              src={
                data.userStored
                  ? "/assets/icons/icon_like_active.svg"
                  : "/assets/icons/icon_like.svg"
              }
              alt=""
              style={{
                width: "25px",
                height: "25px",
                position: "absolute",
                top: "20px",
                right: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                storeFunc(!data.userStored);
              }}
            />
          ) : null}

          <div
            style={{
              backgroundColor: "rgba(153, 153, 153, 0.8)",
              position: "absolute",
              left: "0",
              bottom: "0",
              right: "0",
              display: "flex",
              justifyContent: "space-between",
              padding: "0 20px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "12px",
              }}
            >
              {data.moodList?.map((item, index) => {
                return (
                  <PretendardText
                    key={index}
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#FFFFFF",
                    }}
                  >
                    {item.title}
                  </PretendardText>
                );
              })}
            </div>

            <div
              style={{
                display: "flex",
                gap: "5px",
              }}
            >
              {/* <PretendardText
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  color: "#FFFFFF",
                }}
              >
                {resolution}
              </PretendardText> */}
              <PretendardText
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#FFFFFF",
                }}
              >
                {data.duration}
              </PretendardText>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default VideoCard;
