import React, { useState } from "react";
import PretendardText from "../../atoms/pretendardText";

function NavText({ text, onClick, isActive }) {
  const [hover, setHover] = useState(false);

  return (
    <>
      <PretendardText
        onMouseEnter={() => setHover(true)} // 마우스엔터(호버)시 키값이 저장된다
        onMouseLeave={() => setHover(false)} // 마우스리브 시에는 키값이 지워진다
        style={{
          color: hover || isActive ? "#00E4BB" : "#939393",
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "28.8px",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        {text}
      </PretendardText>
    </>
  );
}

export default NavText;
