import React, { useRef } from "react";
import useDisplay from "../hooks/useDisplay";
import PretendardText from "../components/atoms/pretendardText";
import MuktaText from "../components/atoms/muktaText";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Footer from "../components/organisms/footer";
import Nav from "../components/organisms/nav";
import SearchInput from "../components/atoms/input/searchInput";
import WhiteEmptyBtn from "../components/atoms/btn/whiteEmptyBtn";
import VideoFilter from "../components/organisms/videoFilter";
import { useHistory, useLocation } from "react-router-dom";
import { route } from "../routes/route";
import VideoFilterM from "../components/organisms/videoFilterM";
import VideoCardM from "../components/organisms/videoCardM";
import VideoCard from "../components/organisms/videoCard";
import VideoApi from "../api/video";
import { errorHandler } from "../utils/error-handler";
import ReactPageScroller from "react-page-scroller";
import NavM from "../components/organisms/navM";
import useModal from "../hooks/useModal";
import { nullCheck } from "../utils/check";
import { useInView } from "react-intersection-observer";

function Home() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const history = useHistory();
  const location = useLocation();
  const modal = useModal();

  const [list, setList] = useState([]);
  const [orderByOpen, setOrderByOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [ref, inView] = useInView();
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [filterIdList, setFilterIdList] = useState([]);
  const [orderBy, setOrderBy] = useState({
    title: "최근등록순",
    value: "CREATED",
  });

  const getList = async () => {
    try {
      const params = {
        keyword: queryParams.get("keyword"),
        filterIdList: queryParams.get("filter"),
        orderType: queryParams.get("orderType"),
        page: queryParams.get("page"),
        size: 9,
      };

      const response = await VideoApi.getVideos(params);
      const data = response.data.data;
      setList(
        data.content?.map((item) => {
          return {
            ...item,
          };
        })
      );
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getListScroll = async (page) => {
    try {
      setLoading(true);
      const params = {
        keyword: queryParams.get("keyword"),
        filterIdList: queryParams.get("filter"),
        orderType: queryParams.get("orderType"),
        page: page,
        size: 9,
      };

      const response = await VideoApi.getVideos(params);
      const data = response.data.data;
      setList(list.concat(data.content));
      setIsLast(data.last);
      setLoading(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (inView && !isLast && !loading) {
      setCurrentPage(currentPage + 1);
      getListScroll(currentPage + 1);
    }
  }, [inView, loading, isLast]);

  const [activePage, setActivePage] = useState(0);
  const pageOnChange = (page) => {
    setActivePage(page);
  };

  const layout_scroll = useRef();
  const [isReviewShow, setIsReviewShow] = useState(false);

  const [blockScrollUpFlag, setBlockScrollUpFlag] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState({});
  const queryParams = new URLSearchParams(location.search);

  // mounted
  useEffect(() => {
    if (!nullCheck(location.state?.activePage)) {
      setActivePage(location.state.activePage);
    }
    if (!nullCheck(location.state?.selectedMenu)) {
      setSelectedMenu(location.state.selectedMenu);
    }
    if (queryParams.get("orderType")) {
      setOrderBy({
        title:
          queryParams.get("orderType") === "CREATED"
            ? "최근등록순"
            : queryParams.get("orderType") === "DOWNLOAD"
            ? "다운로드순"
            : "업로드순",
        value: queryParams.get("orderType"),
      });
    } else {
      setOrderBy({
        title: "최근등록순",
        value: "CREATED",
      });
    }
    setFilterIdList(
      queryParams.get("filter")
        ? queryParams
            .get("filter")
            .split(",")
            .map((item) => Number(item))
        : []
    );

    setKeyword(queryParams.get("keyword") ? queryParams.get("keyword") : "");
    setCurrentPage(
      queryParams.get("page") ? Number(queryParams.get("page")) : 0
    );
    getList();
    // 맨위로 올리기
    layout_scroll?.current?.scrollTo(0, 0);
  }, [location]);

  // 네이버 로그인일때
  useEffect(() => {
    if (
      queryParams.get("naver_login") &&
      !nullCheck(sessionStorage.getItem("socialModalFlag"))
    ) {
      modal.open("login");
    }
  }, [location]);

  const setSerchUrl = (type, value) => {
    switch (type) {
      // 키워드
      case 0:
        history.replace({
          pathname: route.home,
          search: `?keyword=${value}&orderType=${
            orderBy.value
          }&filter=${filterIdList.join()}&page=0`,
        });
        break;
      // 정렬
      case 1:
        history.replace({
          pathname: route.home,
          search: `?keyword=${keyword}&orderType=${value}&filter=${filterIdList.join()}&page=0`,
        });
        break;
      // 필터
      case 2:
        history.replace({
          pathname: route.home,
          search: `?keyword=${keyword}&orderType=${orderBy.value}&filter=${value}&page=0`,
        });
        break;
      default:
        break;
    }
  };

  const setScreenSize = () => {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    setScreenSize();
  }, [window.innerHeight]);

  setScreenSize();

  // 맨위로 버튼 보이기
  const [topBtnView, setTopBtnView] = useState(false);

  useEffect(() => {
    function handleKeyDown(e) {
      // home
      if (e.keyCode === 36) {
        layout_scroll.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }

      // end
      if (e.keyCode === 35) {
        layout_scroll.current.scrollTo({
          top: layout_scroll.current.scrollHeight,
          behavior: "smooth",
        });
      }

      // page up
      if (e.keyCode === 33) {
        layout_scroll.current.scrollTo({
          top: layout_scroll.current.scrollTop - 1000,
          behavior: "smooth",
        });
      }

      // page down, space
      if (e.keyCode === 34 || e.keyCode === 32) {
        layout_scroll.current.scrollTo({
          top: layout_scroll.current.scrollTop + 1000,
          behavior: "smooth",
        });
      }

      // insert
      if (e.keyCode === 45) {
        const searchInputFocusFlag = document.getElementById(
          "searchInputFocusFlag"
        );
        searchInputFocusFlag.focus();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <div
        id="container"
        style={{
          height: isMobile ? "calc(var(--vh, 1vh) * 100)" : "100vh",
          overflow: "auto",
          background: "#151515",
        }}
      >
        {isMobile ? (
          <NavM />
        ) : (
          <Nav
            activePage={activePage}
            setActivePage={setActivePage}
            keyword={keyword}
            onChangeSearchBar={(e) => {
              setKeyword(e.target.value);
            }}
            onClickSearchBar={() => {
              setSerchUrl(0, keyword);
            }}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            onBlur={() => {
              setKeyword(keyword?.trim());
            }}
            setSerchUrl={setSerchUrl}
          />
        )}

        <ReactPageScroller
          pageOnChange={pageOnChange}
          blockScrollUp={blockScrollUpFlag}
          customPageNumber={activePage}
          containerHeight={isMobile ? "calc(var(--vh, 1vh) * 100)" : "100vh"}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
              }}
              src={
                isMobile
                  ? "/assets/images/home_bg_m.png"
                  : "/assets/images/home_bg.png"
              }
              alt=""
            />

            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <MuktaText
                style={{
                  color: "#FFFFFF",
                  fontWeight: "700",
                  fontSize: isMobile ? "36px" : "75px",
                  lineHeight: isMobile ? "48.6px" : "97.5px",
                  textAlign: "center",
                  textShadow: isMobile
                    ? "0px 4px 20px rgba(10, 13, 20, 0.15)"
                    : "",
                }}
              >
                BeStock <br />
                Best K-Clip Studio
              </MuktaText>
              <PretendardText
                style={{
                  color: isMobile ? "#E6E6E6" : "#FFFFFF",
                  fontWeight: "400",
                  fontSize: isMobile ? "16px" : "18px",
                  lineHeight: isMobile ? "24px" : "25.2px",
                  marginTop: "30px",
                  textAlign: "center",
                }}
              >
                K-콘텐츠 크리에이터들을 위한{isMobile ? <br /> : ""} 고품질 클립
                영상 플랫폼
              </PretendardText>
              <SearchInput
                placeholder={"원하는 영상을 검색해보세요."}
                style={{
                  marginTop: isMobile ? "63px" : "50px",
                }}
                inputStyle={{
                  width: isMobile ? "335px" : "700px",
                }}
                iconStyle={{
                  right: isMobile ? "18px" : "25px",
                }}
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onClick={() => {
                  setActivePage(1);
                  setSerchUrl(0, keyword);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setActivePage(1);
                    setSerchUrl(0, keyword);
                  }
                }}
                onBlur={() => {
                  setKeyword(keyword?.trim());
                }}
              />
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  gap: isMobile ? "11px" : "20px",
                  justifyContent: "center",
                }}
              >
                <WhiteEmptyBtn
                  style={{
                    width: isMobile ? "136px" : "146px",
                    height: isMobile ? "40px" : "48px",
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  title={"추천받기"}
                  onClick={() => {
                    history.push(route.recommend);
                  }}
                ></WhiteEmptyBtn>

                <WhiteEmptyBtn
                  style={{
                    width: isMobile ? "136px" : "146px",
                    height: isMobile ? "40px" : "48px",
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={() => {
                    setActivePage(1);
                  }}
                  title={"둘러보기"}
                ></WhiteEmptyBtn>
              </div>
            </div>
          </div>

          <div
            ref={layout_scroll}
            onScroll={(e) => {
              if (layout_scroll.current.scrollTop === 0) {
                setBlockScrollUpFlag(false);
                setTopBtnView(false);
              } else {
                setBlockScrollUpFlag(true);
                setTopBtnView(true);
              }
            }}
            style={{
              height: "85%",
              overflow: "auto",
              marginTop: "171px",
            }}
          >
            {isMobile ? (
              <div>
                <div
                  style={{
                    padding: "0px 20px",
                    paddingTop: "70px",
                  }}
                >
                  <SearchInput
                    placeholder={"검색어를 입력해 주세요."}
                    style={{
                      width: "100%",
                      maxWidth: "1365px",
                    }}
                    inputStyle={{
                      width: "100%",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                    iconStyle={{
                      right: "22px",
                    }}
                    value={keyword}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                    }}
                    onClick={() => {
                      setActivePage(1);
                      setSerchUrl(0, keyword);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setActivePage(1);
                        setSerchUrl(0, keyword);
                      }
                    }}
                    onBlur={() => {
                      setKeyword(keyword.trim());
                    }}
                  />
                </div>

                <VideoFilterM
                  filterIdList={filterIdList}
                  selectedMenuTemp={selectedMenu}
                  setSerchUrl={setSerchUrl}
                ></VideoFilterM>

                <div
                  style={{
                    height: "100%",
                    padding: "0 20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "26px",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontWeight: "400",
                        fontSize: "13px",
                        lineHeight: "18.75px",
                        color: "#DFDFDF",
                      }}
                    >
                      전체 {list.length}건
                    </PretendardText>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOrderByOpen(!orderByOpen);
                        }}
                      >
                        <PretendardText
                          style={{
                            color: "#DFDFDF",
                            fontWeight: "400",
                            fontSize: "13px",
                            lineHeight: "18.75px",
                          }}
                        >
                          {orderBy.title}
                        </PretendardText>
                        <img
                          src="/assets/icons/icon_arrow.svg"
                          alt=""
                          style={{
                            width: "18px",
                            height: "18px",
                            transform: orderByOpen ? "rotate(180deg)" : "",
                          }}
                        />
                      </div>
                      {orderByOpen ? (
                        <div
                          style={{
                            position: "absolute",
                            top: "24px",
                            right: 0,
                            width: "126px",
                            height: "114px",
                            background: "#333333",
                            borderRadius: "5px",
                            paddingTop: "15px",
                            paddingLeft: "15px",
                            zIndex: 99,
                          }}
                        >
                          <PretendardText
                            style={{
                              color: "#FFFFFF",
                              fontWeight: "400",
                              fontSize: "13px",
                              lineHeight: "18.2px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSerchUrl(1, "CREATED");
                              setOrderByOpen(false);
                            }}
                          >
                            최근등록순
                          </PretendardText>
                          <PretendardText
                            style={{
                              color: "#FFFFFF",
                              fontWeight: "400",
                              fontSize: "13px",
                              lineHeight: "18.2px",
                              marginTop: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSerchUrl(1, "DOWNLOAD");
                              setOrderByOpen(false);
                            }}
                          >
                            다운로드순
                          </PretendardText>
                          <PretendardText
                            style={{
                              color: "#FFFFFF",
                              fontWeight: "400",
                              fontSize: "13px",
                              lineHeight: "18.2px",
                              marginTop: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSerchUrl(1, "UPLOAD");
                              setOrderByOpen(false);
                            }}
                          >
                            업로드순
                          </PretendardText>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    minHeight: "500px",
                    padding: "0 20px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      marginTop: "30px",
                      gap: "30px",
                      display: "flex",
                      flexDirection: "column",
                      paddingBottom: "20px",
                    }}
                  >
                    {list.map((item, index) => {
                      return <VideoCardM key={index} item={item} />;
                    })}
                    <div ref={ref}>{/* loading */}</div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  background: "#151515",
                  position: "relative",
                  paddingBottom: "20px",
                  gap: "36px",
                  display: "flex",
                }}
              >
                <VideoFilter
                  filterIdList={filterIdList}
                  selectedMenuTemp={selectedMenu}
                  setSerchUrl={setSerchUrl}
                ></VideoFilter>

                {list.length > 0 ? (
                  <div
                    style={{
                      minHeight: "1000px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "13px",
                        flexWrap: "wrap",
                      }}
                    >
                      {list.map((item, index) => {
                        return <VideoCard key={index} item={item} />;
                      })}
                      <div ref={ref}>{/* loading */}</div>
                    </div>
                  </div>
                ) : (
                  <PretendardText
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "24px",
                      color: "#939393",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "204px",
                      minHeight: "600px",
                    }}
                  >
                    등록된 영상이 없습니다.
                  </PretendardText>
                )}
              </div>
            )}
            <Footer />
          </div>
        </ReactPageScroller>
      </div>

      {activePage === 1 && topBtnView ? (
        <div
          style={{
            position: "absolute",
            right: "63px",
            bottom: "70px",
            cursor: "pointer",
          }}
          onClick={() => {
            layout_scroll.current.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          <img src="/assets/icons/ic_top_func.svg" alt="" />
        </div>
      ) : null}
    </>
  );
}

export default Home;
