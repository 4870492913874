export const menu2 = {
  title: "지역",
  value: "2",
  subMenus: [
    {
      title: "서울",
      value: "1",
    },
    {
      title: "경기",
      value: "2",
    },
    {
      title: "인천",
      value: "3",
    },
    {
      title: "강원",
      value: "4",
    },
    {
      title: "경북",
      value: "5",
    },
    {
      title: "경남",
      value: "6",
    },
    {
      title: "광주",
      value: "7",
    },
    {
      title: "대전",
      value: "8",
    },
    {
      title: "대구",
      value: "9",
    },
    {
      title: "울산",
      value: "10",
    },
    {
      title: "세종",
      value: "11",
    },
    {
      title: "충남",
      value: "12",
    },
    {
      title: "충북",
      value: "13",
    },
    {
      title: "전남",
      value: "14",
    },
    {
      title: "전북",
      value: "15",
    },
    {
      title: "부산",
      value: "16",
    },
    {
      title: "제주",
      value: "17",
    },
  ],
};
