import React from "react";
import PretendardText from "../pretendardText";

function TitleLabel({ style, title, isRequired }) {
  return (
    <>
      <PretendardText
        style={{
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
          marginBottom: "10px",
          marginTop: "30px",
          ...style,
        }}
      >
        {title}
        {isRequired ? (
          <span
            style={{
              color: "#00E4BB",
            }}
          >
            *
          </span>
        ) : null}
      </PretendardText>
    </>
  );
}

export default TitleLabel;
