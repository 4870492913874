import api from "./api.js";

export default class ContactApi {
  static async ContactMatching(data) {
    return await api.post(`inquiry/matching`, data);
  }
  static async ContactNormal(data) {
    return await api.post(`inquiry/normal`, data);
  }
}
