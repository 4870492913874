import React, { useState } from "react";
import { useEffect } from "react";
import PretendardText from "../../components/atoms/pretendardText";
import TitleLabel from "../../components/atoms/text/titleLabel";
import TextInput from "../../components/atoms/input/textInput";
import WhiteBtn from "../../components/atoms/btn/whiteBtn";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import useDisplay from "../../hooks/useDisplay";
import Footer from "../../components/organisms/footer";
import NavM from "../../components/organisms/navM";
import useModal from "../../hooks/useModal";
import { toast } from "react-toastify";

function CodeModal() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const modal = useModal();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.getElementById("container").style.cssText = `
          height: 834px;          
          overflow: hidden;
          background: #151515;
          `;
    return () => {
      document.getElementById("container").style.cssText = `
      min-height: 100vh;
      overflow: auto;
      background: #151515;
      `;
    };
  }, []);

  const [random, setRandom] = useState("");
  useEffect(() => {
    setRandom(sessionStorage.getItem("random"));
  }, []);

  const [code, setCode] = useState("");

  const nextFunc = () => {
    modal.open("changePw");
  };

  return (
    <>
      {isMobile ? (
        <div>
          <div
            style={{
              background: "#151515",
              width: "100vw",
              height: "834px",
              zIndex: 10000,
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
              overflow: "auto",
            }}
          >
            <NavM />

            <div
              style={{
                minHeight: "415px",
                width: "100%",
                padding: "0px 20px",
                marginBottom: "70px",
              }}
            >
              <div
                style={{
                  marginTop: "120px",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  인증코드 입력
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#00E4BB",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    marginTop: "5px",
                  }}
                >
                  이메일로 발송된 인증코드를 입력해주세요.
                </PretendardText>
              </div>

              <TitleLabel title={"인증코드"} isRequired={true}></TitleLabel>
              <TextInput
                placeholder={"인증코드 6자리를 입력하세요."}
                onChange={(e) => {
                  setCode(e.target.value.replace(/[^0-9]/g, ""));
                }}
                value={code}
                maxLength={6}
                isError={code.length === 6 && random !== code}
                errorText={"인증번호가 일치하지 않습니다."}
              ></TextInput>

              <div
                style={{
                  marginTop: "40px",
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                }}
              >
                <WhiteBtn
                  title={"이전"}
                  style={{
                    width: "118px",
                    height: "48px",
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={() => {
                    modal.open("findPw");
                    sessionStorage.clear();
                  }}
                ></WhiteBtn>
                <PrimaryBtn
                  title={"다음"}
                  disabled={random !== code}
                  style={{
                    width: "118px",
                    height: "48px",
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={() => {
                    nextFunc();
                  }}
                ></PrimaryBtn>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              background: "rgba(30, 30, 30, 0.50)",
              backdropFilter: "blur(32.5px)",
              width: "100vw",
              height: "100vh",
              zIndex: 10000,
              display: "flex",
              position: "fixed",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
            }}
          ></div>
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 10001,
            }}
          >
            <div
              style={{
                width: "520px",
                height: "100%",
                borderRadius: "15px",
                background: "#151515",
                boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.20)",
                padding: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  인증코드 입력
                </PretendardText>
                <img
                  src="/assets/icons/icon_close.svg"
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    modal.close();
                    sessionStorage.clear();
                  }}
                />
              </div>

              <PretendardText
                style={{
                  color: "#00E4BB",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  marginTop: "10px",
                }}
              >
                이메일로 발송된 인증코드를 입력해주세요.
              </PretendardText>

              <div
                style={{
                  height: "1px",
                  marginTop: "25px",
                  background: "#939393",
                }}
              ></div>

              <TitleLabel title={"인증코드"} isRequired={true}></TitleLabel>
              <TextInput
                placeholder={"인증코드 6자리를 입력하세요."}
                onChange={(e) => {
                  setCode(e.target.value.replace(/[^0-9]/g, ""));
                }}
                value={code}
                maxLength={6}
                isError={code.length === 6 && random !== code}
                errorText={"인증번호가 일치하지 않습니다."}
              ></TextInput>

              <div
                style={{
                  marginTop: "40px",
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                }}
              >
                <WhiteBtn
                  title={"이전"}
                  style={{
                    width: "118px",
                    height: "48px",
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={() => {
                    modal.open("findPw");
                    sessionStorage.clear();
                  }}
                ></WhiteBtn>
                <PrimaryBtn
                  title={"다음"}
                  disabled={random !== code}
                  style={{
                    width: "118px",
                    height: "48px",
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={() => {
                    nextFunc();
                  }}
                ></PrimaryBtn>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CodeModal;
