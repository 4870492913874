import React from "react";
import useDisplay from "../../hooks/useDisplay";
import MuktaText from "../atoms/muktaText";
import PretendardText from "../atoms/pretendardText";
import { route } from "../../routes/route";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import useModal from "../../hooks/useModal";

function Footer() {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const modal = useModal();

  return (
    <>
      <div
        style={{
          background: "#282828",
          height: isMobile ? "364px" : "293px",
          paddingLeft: isMobile ? "20px" : "60px",
          paddingTop: isMobile ? "50px" : "61px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: isMobile ? "" : "flex",
            alignItems: "center",
          }}
        >
          <MuktaText
            style={{
              color: "#A9A9A9",
              fontWeight: "700",
              fontSize: isMobile ? "38px" : "40px",
              lineHeight: isMobile ? "49.4px" : "52px",
              marginRight: "50px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push(route.home);
              modal.close();
            }}
          >
            BeStock
          </MuktaText>
          {isMobile ? (
            <div>
              <div style={{ display: "flex" }}>
                <PretendardText
                  style={{
                    color: "#F1F1F1",
                    fontWeight: "700",
                    fontSize: "12px",
                    lineHeight: "22.8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push(route.notice + "/1");
                    modal.close();
                  }}
                >
                  공지사항
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#616161",
                    fontWeight: "700",
                    fontSize: "12px",
                    lineHeight: "22.8px",
                  }}
                >
                  ㅣ
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#F1F1F1",
                    fontWeight: "700",
                    fontSize: "12px",
                    lineHeight: "22.8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push(route.faq + "/1");
                    modal.close();
                  }}
                >
                  FAQ
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#616161",
                    fontWeight: "700",
                    fontSize: "12px",
                    lineHeight: "22.8px",
                  }}
                >
                  ㅣ
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#F1F1F1",
                    fontWeight: "700",
                    fontSize: "12px",
                    lineHeight: "22.8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push(route.contact);
                    modal.close();
                  }}
                >
                  문의하기
                </PretendardText>
              </div>
              <div style={{ display: "flex" }}>
                <PretendardText
                  onClick={() => {
                    history.push({
                      pathname: route.privacy,
                    });
                    modal.close();
                  }}
                  style={{
                    color: "#F1F1F1",
                    fontWeight: "700",
                    fontSize: "12px",
                    lineHeight: "22.8px",
                    cursor: "pointer",
                  }}
                >
                  개인정보처리방침
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#616161",
                    fontWeight: "700",
                    fontSize: "12px",
                    lineHeight: "22.8px",
                  }}
                >
                  ㅣ
                </PretendardText>
                <PretendardText
                  onClick={() => {
                    history.push({
                      pathname: route.terms,
                    });
                    modal.close();
                  }}
                  style={{
                    color: "#F1F1F1",
                    fontWeight: "700",
                    fontSize: "12px",
                    lineHeight: "22.8px",
                    cursor: "pointer",
                  }}
                >
                  이용약관
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#616161",
                    fontWeight: "700",
                    fontSize: "12px",
                    lineHeight: "22.8px",
                  }}
                >
                  ㅣ
                </PretendardText>
                <PretendardText
                  onClick={() => {
                    history.push({
                      pathname: route.licenses,
                    });
                    modal.close();
                  }}
                  style={{
                    color: "#F1F1F1",
                    fontWeight: "700",
                    fontSize: "12px",
                    lineHeight: "22.8px",
                    cursor: "pointer",
                  }}
                >
                  라이선스 및 저작권
                </PretendardText>
              </div>
            </div>
          ) : (
            <>
              <PretendardText
                onClick={() => {
                  history.push({
                    pathname: route.privacy,
                  });
                }}
                style={{
                  color: "#F1F1F1",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                  cursor: "pointer",
                }}
              >
                개인정보처리방침
              </PretendardText>
              <PretendardText
                style={{
                  color: "#616161",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                ㅣ
              </PretendardText>
              <PretendardText
                onClick={() => {
                  history.push({
                    pathname: route.terms,
                  });
                }}
                style={{
                  color: "#F1F1F1",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                  cursor: "pointer",
                }}
              >
                이용약관
              </PretendardText>
              <PretendardText
                style={{
                  color: "#616161",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                ㅣ
              </PretendardText>
              <PretendardText
                onClick={() => {
                  history.push({
                    pathname: route.licenses,
                  });
                }}
                style={{
                  color: "#F1F1F1",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                  cursor: "pointer",
                }}
              >
                라이선스 및 저작권
              </PretendardText>
              <PretendardText
                style={{
                  color: "#616161",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                ㅣ
              </PretendardText>
              <PretendardText
                style={{
                  color: "#F1F1F1",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                  cursor: "pointer",
                }}
                onClick={() => history.push(route.notice + "/1")}
              >
                공지사항
              </PretendardText>
              <PretendardText
                style={{
                  color: "#616161",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                ㅣ
              </PretendardText>
              <PretendardText
                style={{
                  color: "#F1F1F1",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                  cursor: "pointer",
                }}
                onClick={() => history.push(route.faq + "/1")}
              >
                FAQ
              </PretendardText>
              <PretendardText
                style={{
                  color: "#616161",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                ㅣ
              </PretendardText>
              <PretendardText
                style={{
                  color: "#F1F1F1",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                  cursor: "pointer",
                }}
                onClick={() => history.push(route.contact)}
              >
                문의하기
              </PretendardText>
            </>
          )}
        </div>

        <div
          style={{ marginTop: "32px", display: "flex", alignItems: "center" }}
        >
          <PretendardText
            style={{
              color: "#505050",
              fontWeight: "400",
              fontSize: isMobile ? "12px" : "14px",
              lineHeight: isMobile ? "16.8px" : "21px",
            }}
          >
            주식회사 더플릭 ㅣ 대표 : 김태현 {isMobile ? <br /> : "ㅣ"}{" "}
            사업자등록번호 : 608-87-01987 <br /> 서울지사 : 서울특별시 마포구
            월드컵북로 5길 49-7 {isMobile ? <br /> : "ㅣ"} 본사 : 강원도 강릉시
            송정길 20 비동 507호 <br />
            TEL : 070-4415-9960 ㅣ E-MAIL : theflick.office@gmail.com{" "}
            {isMobile ? <br /> : "ㅣ"} 통신판매업 신고번호 : 제
            2023-강원강릉-0273 호 <br />
            <span
              style={{
                color: isMobile ? "#616161" : "",
                marginTop: isMobile ? "12px" : "",
                display: isMobile ? "block" : "",
              }}
            >
              Copyright ⓒ 2023 The Flick (주식회사 더플릭) All rights reserved.
            </span>
          </PretendardText>
        </div>
      </div>
    </>
  );
}

export default Footer;
