import React from "react";
import PretendardText from "../pretendardText";

function WhiteBtn({ style, title, onClick, disabled }) {
  return (
    <>
      <PretendardText
        style={{
          width: "160px",
          height: "54px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "30px",
          cursor: "pointer",
          background: disabled ? "#333333" : "#FFFFFF",

          color: disabled ? "#939393" : "#1E1E1E",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "30px",
          ...style,
        }}
        onClick={() => {
          if (disabled) {
            return;
          }
          onClick();
        }}
      >
        {title}
      </PretendardText>
    </>
  );
}

export default WhiteBtn;
