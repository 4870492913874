import React from "react";
import useDisplay from "../../../hooks/useDisplay";
import PretendardText from "../../atoms/pretendardText";
import RadioInput from "../../atoms/input/radioInput";
import WhiteBtn from "../../atoms/btn/whiteBtn";
import PrimaryBtn from "../../atoms/btn/primaryBtn";
import { useState } from "react";
import TextArea from "../../atoms/input/textarea";
import UserApi from "../../../api/user";
import { errorHandler } from "../../../utils/error-handler";
import { route } from "../../../routes/route";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";

function SubscribeCancelModal({ id, setModalOpen }) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [checked, setChecked] = useState(-1);
  const [resignedReasonText, setResignedReasonText] = useState("");

  const leave = async () => {
    try {
      let obj = {
        resignedReason: checked,
        resignedReasonText: resignedReasonText,
      };
      await UserApi.subscribeCancel(id, obj);
      toast("해지 되었습니다");
      setModalOpen(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <div>
        <div
          style={{
            background: "rgba(30, 30, 30, 0.50)",
            backdropFilter: "blur(32.5px)",
            width: "100vw",
            height: "100vh",
            zIndex: 10000,
            display: "flex",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            left: 0,
          }}
        ></div>
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 10001,
          }}
        >
          <div
            style={{
              width: isMobile ? "335px" : "520px",
              height: "100%",
              borderRadius: "15px",
              background: "#151515",
              boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.20)",
              padding: isMobile ? "30px" : "40px",
            }}
          >
            <PretendardText
              style={{
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "35.2px",
                color: "#FFFFFF",
              }}
            >
              정말로 구독을 해지하시나요?
            </PretendardText>
            <PretendardText
              style={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#BABABA",
                marginTop: "10px",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              구독을 해지하시면 더이상 혜택을 이용할 수{" "}
              {isMobile ? <br /> : null}없습니다. {!isMobile ? <br /> : null}
              취소 사유를 입력해 주세요.
            </PretendardText>

            <div
              style={{
                marginTop: isMobile ? "30px" : "40px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setChecked("TECHNICAL")}
              >
                <RadioInput checked={checked === "TECHNICAL"} />
                <PretendardText
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#FFFFFF",
                  }}
                >
                  기술적인 문제가 있습니다.
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setChecked("CONTENT")}
              >
                <RadioInput checked={checked === "CONTENT"} />
                <PretendardText
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#FFFFFF",
                  }}
                >
                  원하는 콘텐츠가 없습니다.
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setChecked("SERVICE")}
              >
                <RadioInput checked={checked === "SERVICE"} />
                <PretendardText
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#FFFFFF",
                  }}
                >
                  다른 서비스를 이용할 예정입니다.
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setChecked("ETC")}
              >
                <RadioInput checked={checked === "ETC"} />
                <PretendardText
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#FFFFFF",
                  }}
                >
                  기타
                </PretendardText>
              </div>
              {checked === "ETC" ? (
                <TextArea
                  style={{ width: "100%", height: "104px" }}
                  placeholder={"사유를 입력해 주세요."}
                  onChange={(e) => {
                    setResignedReasonText(e.target.value);
                  }}
                  maxLength={200}
                />
              ) : (
                ""
              )}
            </div>

            <div
              style={{
                marginTop: isMobile ? "30px" : "40px",
                display: "flex",
                gap: "15px",
                justifyContent: "center",
              }}
            >
              <WhiteBtn
                title={"해지"}
                disabled={checked === -1}
                style={{
                  width: "78px",
                  height: "34px",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#000000",
                }}
                onClick={() => {
                  leave();
                }}
              ></WhiteBtn>
              <PrimaryBtn
                title={"닫기"}
                style={{
                  width: "78px",
                  height: "34px",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#000000",
                }}
                onClick={() => {
                  setModalOpen(false);
                }}
              ></PrimaryBtn>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubscribeCancelModal;
