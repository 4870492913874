import React from "react";
import styled from "styled-components";
import PretendardText from "../pretendardText";

function TextInput({
  placeholder,
  disabled,
  isError,
  errorText,
  style,
  onChange,
  value,
  onBlur,
  type,
  maxLength,
}) {
  return (
    <>
      <InputWrapper
        value={value}
        type={type ?? "text"}
        placeholder={placeholder}
        disabled={disabled}
        isError={isError}
        style={{ ...style }}
        onChange={(e) => {
          if (e.target.value.length > maxLength) {
            e.target.value = e.target.value.slice(0, maxLength);
          }
          onChange(e);
        }}
        onBlur={onBlur}
      />
      {isError ? (
        <PretendardText
          style={{
            color: "#D74040",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16.8px",
            marginTop: "3px",
          }}
        >
          {errorText}
        </PretendardText>
      ) : null}
    </>
  );
}

export default TextInput;

const InputWrapper = styled.input.attrs((props) => {})`
  width: 100%;
  height: 52px;
  border-radius: 5px;
  border: ${(props) =>
    props.isError ? "1px solid #D74040 !important" : "1px solid #939393"};
  background: inherit;
  padding-left: 20px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  :focus {
    outline: none !important;
    border-color: #ffffff;
    caret-color: #ffffff !important;
  }

  ::placeholder {
    color: #939393;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  :disabled {
    color: #939393 !important;
    background: #333333 !important;
  }
`;
