import React, { useState } from "react";
import PretendardText from "../pretendardText";
import useDisplay from "../../../hooks/useDisplay";
import RadioInput from "../input/radioInput";
import DownloadBtnBox from "../../molecules/nav/downloadBtnBox";
import WhiteBtn from "./whiteBtn";
import PrimaryBtn from "./primaryBtn";
import { errorHandler } from "../../../utils/error-handler";
import VideoApi from "../../../api/video";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fileDownload } from "../../../utils/file-management";
import api from "../../../api/api";
import { route } from "../../../routes/route";

function DownloadBtn({
  style,
  disabled,
  active,
  onClick,
  hdVideoItem,
  fhdVideoItem,
  twoKiloVideoItem,
  fourKiloVideoItem,
  logVideoItem,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { id } = useParams();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const downloadFunc = async () => {
    try {
      if (selectedIndex) {
        const data = {
          resolution: "",
        };
        const downloadData = {
          originFileName: "",
          serverFileName: "",
        };
        switch (selectedIndex) {
          case 1:
            data.resolution = hdVideoItem.resolution;
            downloadData.originFileName = hdVideoItem.originFileName;
            downloadData.serverFileName = hdVideoItem.serverFileName;
            break;
          case 2:
            data.resolution = fhdVideoItem.resolution;
            downloadData.originFileName = fhdVideoItem.originFileName;
            downloadData.serverFileName = fhdVideoItem.serverFileName;
            break;
          case 3:
            data.resolution = twoKiloVideoItem.resolution;
            downloadData.originFileName = twoKiloVideoItem.originFileName;
            downloadData.serverFileName = twoKiloVideoItem.serverFileName;
            break;
          case 4:
            data.resolution = fourKiloVideoItem.resolution;
            downloadData.originFileName = fourKiloVideoItem.originFileName;
            downloadData.serverFileName = fourKiloVideoItem.serverFileName;
            break;
          case 5:
            data.resolution = logVideoItem.resolution;
            downloadData.originFileName = logVideoItem.originFileName;
            downloadData.serverFileName = logVideoItem.serverFileName;
            break;
          default:
            break;
        }

        await VideoApi.postDownload(id, data);

        fileDownload(downloadData.serverFileName, downloadData.originFileName);
      }
    } catch (error) {
      if (error.response.status === 400) {
        return toast(error.response.data.message);
      }
      errorHandler(error);
    }
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          width: isMobile ? "100%" : "",
        }}
      >
        <PretendardText
          style={{
            width: "156px",
            height: "49px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "30px",
            cursor: "pointer",
            background: disabled ? "#333333" : "#00E4BB",

            color: disabled ? "#939393" : "#1E1E1E",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "30px",
            ...style,
          }}
          onClick={onClick}
        >
          다운로드
          <img
            src="/assets/icons/icon_arrow_black.svg"
            alt=""
            style={{
              width: "25px",
              height: "25px",
              transform: active ? "rotate(180deg)" : "",
              marginLeft: "5px",
            }}
          />
        </PretendardText>

        {active ? (
          <div
            style={{
              position: "absolute",
              top: "65px",
              right: "0px",
              width: isMobile ? "335px" : "366px",
              background: "#333333",
              boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.20)",
              borderRadius: "15px",
              zIndex: 99,
              padding: "20px",
              gap: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {hdVideoItem ? (
              <DownloadBtnBox
                title={hdVideoItem.resolutionTitle}
                type={hdVideoItem.extension}
                volume={hdVideoItem.size}
                checked={selectedIndex === 1}
                onClick={() => {
                  setSelectedIndex(1);
                }}
              />
            ) : null}
            {fhdVideoItem ? (
              <DownloadBtnBox
                title={fhdVideoItem.resolutionTitle}
                type={fhdVideoItem.extension}
                volume={fhdVideoItem.size}
                checked={selectedIndex === 2}
                onClick={() => {
                  setSelectedIndex(2);
                }}
              />
            ) : null}
            {twoKiloVideoItem ? (
              <DownloadBtnBox
                title={twoKiloVideoItem.resolutionTitle}
                type={twoKiloVideoItem.extension}
                volume={twoKiloVideoItem.size}
                checked={selectedIndex === 3}
                onClick={() => {
                  setSelectedIndex(3);
                }}
              />
            ) : null}
            {fourKiloVideoItem ? (
              <DownloadBtnBox
                title={fourKiloVideoItem.resolutionTitle}
                type={fourKiloVideoItem.extension}
                volume={fourKiloVideoItem.size}
                checked={selectedIndex === 4}
                onClick={() => {
                  setSelectedIndex(4);
                }}
              />
            ) : null}
            {logVideoItem ? (
              <DownloadBtnBox
                title={"LOG"}
                type={logVideoItem.extension}
                volume={logVideoItem.size}
                checked={selectedIndex === 5}
                onClick={() => {
                  setSelectedIndex(5);
                }}
              />
            ) : null}

            <div
              style={{
                display: "flex",
                gap: "15px",
                justifyContent: "center",
              }}
            >
              <WhiteBtn
                title={"보기"}
                style={{
                  width: "106px",
                  height: "34px",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => {
                  if (selectedIndex === 0) {
                    toast("영상을 선택해주세요.");
                    return;
                  }
                  // let resolution = "";
                  // switch (selectedIndex) {
                  //   case 1:
                  //     resolution = "HD";
                  //     break;
                  //   case 2:
                  //     resolution = "FHD";
                  //     break;
                  //   case 3:
                  //     resolution = "TWO_KILO";
                  //     break;
                  //   case 4:
                  //     resolution = "FOUR_KILO";
                  //     break;
                  //   case 5:
                  //     resolution = "LOG";
                  //     break;
                  //   default:
                  //     break;
                  // }
                  let src = "";
                  switch (selectedIndex) {
                    case 1:
                      src = hdVideoItem.fileUrl;
                      break;
                    case 2:
                      src = fhdVideoItem.fileUrl;
                      break;
                    case 3:
                      src = twoKiloVideoItem.fileUrl;
                      break;
                    case 4:
                      src = fourKiloVideoItem.fileUrl;
                      break;
                    case 5:
                      src = logVideoItem.fileUrl;
                      break;
                    default:
                      break;
                  }
                  sessionStorage.setItem("src", src);
                  // window.open(`http://localhost:3000${route.videoStreaming}`);
                  window.open(`${api.getUrl()}${route.videoStreaming}`);
                  // window.open(
                  //   `${api.getUrl()}${
                  //     route.videoStreaming
                  //   }?resolution=${resolution}&videoId=${id}`
                  // );
                }}
              />
              <PrimaryBtn
                title={"다운로드"}
                style={{
                  width: "106px",
                  height: "34px",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => {
                  downloadFunc();
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default DownloadBtn;
