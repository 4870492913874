import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useDisplay from "../../hooks/useDisplay";
import PretendardText from "../../components/atoms/pretendardText";
import MuktaText from "../../components/atoms/muktaText";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import Blur from "../../components/atoms/blur/blur";
import PrimaryEmptyBtn from "../../components/atoms/btn/primaryEmptyBtn";
import styled, { keyframes } from "styled-components";
import BestockSlider from "./bestockSlider";
import { route } from "./../../routes/route";
import useModal from "../../hooks/useModal";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import Footer from "../../components/organisms/footer";

function Bestock() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const modal = useModal();

  const [isInViewport, setIsInViewport] = useState(false);
  const fadeInUpRef = useRef([]);

  useEffect(() => {
    if (!fadeInUpRef.current) return; // 요소가 아직 준비되지 않은 경우 중단

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 요소가 뷰포트에 나타났을 경우
          setIsInViewport(true);
        } else {
          // 요소가 뷰포트를 벗어난 경우
          setIsInViewport(false);
        }
      });
    };
    const options = { root: null, rootMargin: "0px", threshold: 0 };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(fadeInUpRef.current[0]); // 요소 관찰 시작
    observer.observe(fadeInUpRef.current[1]); // 요소 관찰 시작

    return () => {
      observer.disconnect(); // 컴포넌트 언마운트 시 관찰 중단
    };
  }, [isInViewport]);

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}

        <div
          style={{
            position: "relative",
            backgroundColor: "#151515",
            height: `${isMobile ? "773px" : "100vh"}`,
            overflow: "hidden",
          }}
        >
          {/* Blur */}
          <Blur
            style={{
              width: `${isMobile ? "270px" : "412px"}`,
              height: `${isMobile ? "270px" : "412px"}`,
              borderRadius: `${isMobile ? "270px" : "412px"}`,
              background: "rgba(0, 255, 209, 0.50)",
              top: `${isMobile ? "-123px" : "-109px"}`,
              left: `${isMobile ? "-131px" : "-143px"}`,
            }}
          />
          {!isMobile ? (
            <>
              <Blur
                style={{
                  width: "390px",
                  height: "481px",
                  borderRadius: "481px",
                  background: "rgba(0, 125, 103, 0.50)",
                  bottom: "-56px",
                  right: "-133px",
                }}
              />
              <Blur
                style={{
                  width: "185px",
                  height: "332px",
                  borderRadius: "10px",
                  background: "rgba(0, 228, 187, 0.10)",
                  top: "129px",
                  right: "33.96%",
                  filter: "blur(50px)",
                }}
              />
            </>
          ) : (
            ""
          )}

          <div
            style={{
              height: `${isMobile ? "773px" : ""}`,
              position: `${isMobile ? "static" : "absolute"}`,
              top: "50%",
              left: "19.5%",
              transform: `${isMobile ? "unset" : "translate(0%, -50%)"}`,
              display: "flex",
              flexDirection: "column",
              alignItems: `${isMobile ? "center" : "left"}`,
            }}
          >
            <MuktaText
              style={{
                color: "#FFFFFF",
                fontWeight: "700",
                fontSize: `${isMobile ? "36px" : "75px"}`,
                lineHeight: `${isMobile ? "54px" : "97.5px"}`,
                textshadow: "0px 4px 20px rgba(10, 13, 20, 0.15)",
                textAlign: `${isMobile ? "center" : "left"}`,
                marginTop: `${isMobile ? "118px" : ""}`,
              }}
            >
              BeStock <br />
              K-Clip Studio
            </MuktaText>
            <PretendardText
              style={{
                color: "#E6E6E6",
                fontWeight: "400",
                fontSize: `${isMobile ? "16px" : "20px"}`,
                lineHeight: `${isMobile ? "24px" : "32px"}`,
                marginTop: `${isMobile ? "18px" : "49px"}`,
                marginBottom: `${isMobile ? "433px" : "0"}`,
                textAlign: `${isMobile ? "center" : "left"}`,
              }}
            >
              이제 해외무대가 아닌 한국 무대에서
              <br />
              원하는 K-영상을 빠르게 검색하고 추천 받으세요.
            </PretendardText>
            <PrimaryBtn
              style={{
                marginTop: `${isMobile ? "0" : "50px"}`,
                width: `${isMobile ? "191px" : "216px"}`,
                height: `${isMobile ? "48px" : "54px"}`,
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "16px" : "20px"}`,
                lineHeight: `${isMobile ? "24px" : "30px"}`,
              }}
              title={"지금 시작하세요"}
              disabled={false}
              onClick={() => {
                if (sessionStorage.getItem("token")) {
                  history.push(route.payment);
                } else {
                  modal.open("login");
                }
              }}
            ></PrimaryBtn>
          </div>
          <ImgContainer
            style={{
              position: "absolute",
              top: `${isMobile ? "327px" : "50%"}`,
              right: `${isMobile ? "0" : "19.5%"}`,
              width: `${isMobile ? "100%" : "570px"}`,
              height: `${isMobile ? "363px" : "807px"}`,
              transform: `${isMobile ? "" : "translate(0%, -50%)"}`,
              zIndex: 2,
            }}
          >
            <Box>
              <img
                src="/assets/images/bestock/bestock-img-4.png"
                alt=""
                style={{
                  position: "absolute",
                  top: `${isMobile ? "63.16px" : "0"}`,
                  left: `${isMobile ? "20px" : 0}`,
                  zIndex: 1,
                  width: `${isMobile ? "136.8px" : "270px"}`,
                  height: `${isMobile ? "208.747px" : "412px"}`,
                  boxShadow: "0px 4px 20px 0px rgba(10, 13, 20, 0.15)",
                }}
              />
            </Box>
            <Box>
              <img
                src="/assets/images/bestock/bestock-img-5.png"
                alt=""
                style={{
                  position: "absolute",
                  top: `${isMobile ? "0" : "235px"}`,
                  right: `${isMobile ? "20px" : 0}`,
                  zIndex: 2,
                  width: `${isMobile ? "187px" : "370px"}`,
                  height: `${isMobile ? "185px" : "302px"}`,
                  boxShadow: "0px 4px 20px 0px rgba(10, 13, 20, 0.25)",
                }}
              />
            </Box>
            <Box>
              <img
                src="/assets/images/bestock/bestock-img-6.png"
                alt=""
                style={{
                  position: "absolute",
                  top: `${isMobile ? "176.28px" : "473px"}`,
                  left: `${isMobile ? "107px" : 0}`,
                  zIndex: 1,
                  width: `${isMobile ? "210px" : "370px"}`,
                  height: `${isMobile ? "186.715px" : "330px"}`,
                  boxShadow: "0px 4px 20px 0px rgba(10, 13, 20, 0.20)",
                }}
              />
            </Box>
          </ImgContainer>

          {/* absolute-img */}
          {!isMobile ? (
            <>
              <img
                src="/assets/images/bestock/bestock-img-1.png"
                alt=""
                style={{ position: "absolute", top: "161px", right: "14%" }}
              />
              <img
                src="/assets/images/bestock/bestock-img-2.png"
                alt=""
                style={{
                  position: "absolute",
                  bottom: "188px",
                  right: "18.75%",
                }}
              />
              <img
                src="/assets/images/bestock/bestock-img-3.png"
                alt=""
                style={{ position: "absolute", bottom: "0px", left: "33.80%" }}
              />
            </>
          ) : (
            ""
          )}
        </div>

        {/* section-1 */}
        <div
          style={{
            height: `${isMobile ? "1011px" : "1395px"}`,
            background: "#151515",
            position: "relative",
            paddingTop: `${isMobile ? "130px" : "210px"}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PretendardText
            style={{
              color: "#00E4BB",
              fontWeight: `${isMobile ? "600" : "700"}`,
              fontSize: `${isMobile ? "14px" : "16px"}`,
              lineHeight: `${isMobile ? "21px" : "24px"}`,
              marginBottom: "20px",
            }}
          >
            AI 데이터 라벨링
          </PretendardText>
          <PretendardText
            style={{
              color: "#fff",
              fontWeight: `${isMobile ? "600" : "700"}`,
              fontSize: `${isMobile ? "24px" : "36px"}`,
              lineHeight: `${isMobile ? "36px" : "54px"}`,
              marginBottom: "15px",
              textAlign: "center",
            }}
          >
            10초 안에 원하는
            <br />
            K-Clip 영상을 찾아보세요
          </PretendardText>
          <PretendardText
            style={{
              color: "#fff",
              fontWeight: "400",
              fontSize: `${isMobile ? "15px" : "16px"}`,
              lineHeight: "24px",
              marginBottom: `${isMobile ? "66px" : "72px"}`,
              textAlign: "center",
            }}
          >
            BeStock에서는 영상 구성 요소의 AI 라벨링을 통해
            <br />
            빠르게 영상을 분류하고 사용자에게 추천합니다.
          </PretendardText>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              background:
                "linear-gradient(180deg, rgba(21, 21, 21, 0.00) 69.78%, #151515 89.02%)",
            }}
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <img
                src="/assets/images/bestock/mockup.png"
                alt=""
                style={{
                  width: `${isMobile ? "321px" : "466px"}`,
                  height: `${isMobile ? "519px" : "756px"}`,
                  position: "relative",
                  zIndex: 2,
                }}
              />
              <img
                src={`/assets/images/bestock/hashtag1${
                  isMobile ? "-mobile" : ""
                }.png`}
                alt=""
                style={{
                  position: "absolute",
                  width: `${isMobile ? "120px" : ""}`,
                  top: `${isMobile ? "70px" : "153px"}`,
                  left: `${isMobile ? "19px" : "-39px"}`,
                  zIndex: `${isMobile ? "3" : "1"}`,
                }}
              />
              <img
                src={`/assets/images/bestock/hashtag2${
                  isMobile ? "-mobile" : ""
                }.png`}
                alt=""
                style={{
                  position: "absolute",
                  width: `${isMobile ? "80px" : ""}`,
                  bottom: `${isMobile ? "197px" : "251px"}`,
                  right: `${isMobile ? "12px" : "-26px"}`,
                  zIndex: `${isMobile ? "3" : "1"}`,
                }}
              />
              <FadeInContainer
                className={isInViewport ? "frame-in" : ""}
                ref={(el) => (fadeInUpRef.current[0] = el)}
                src="/assets/images/bestock/searchbar1.png"
                alt=""
                style={{
                  position: "absolute",
                  width: `${isMobile ? "154.7px" : ""}`,
                  top: `${isMobile ? "138px" : "163px"}`,
                  right: `${isMobile ? "1px" : "-117px"}`,
                  zIndex: 3,
                }}
              />
              <FadeInContainer
                className={isInViewport ? "frame-in" : ""}
                ref={(el) => (fadeInUpRef.current[1] = el)}
                src="/assets/images/bestock/searchbar2.png"
                alt=""
                style={{
                  position: "absolute",
                  width: `${isMobile ? "154.7px" : ""}`,
                  top: `${isMobile ? "226px" : "365px"}`,
                  left: `${isMobile ? "-8px" : "-112px"}`,
                  zIndex: 3,
                }}
              />
            </div>
          </div>

          {/* Blur */}
          {!isMobile ? (
            <>
              <Blur
                style={{
                  width: "258px",
                  height: "258px",
                  borderRadius: "258px",
                  background: "rgba(0, 255, 209, 0.20)",
                  top: "434px",
                  right: "36%",
                  filter: "blur(285px)",
                }}
              />
              <Blur
                style={{
                  width: "178px",
                  height: "178px",
                  borderRadius: "178px",
                  background: "rgba(0, 255, 209, 0.20)",
                  top: "680px",
                  right: "28.5%",
                  filter: "blur(285px)",
                }}
              />
            </>
          ) : (
            ""
          )}
        </div>

        {/* section-2 */}
        <div
          style={{
            width: "100%",
            height: `${isMobile ? "705px" : "570px"}`,
            background: "#151515",
            position: "relative",
          }}
        >
          {!isMobile ? (
            <img
              style={{ height: "570px", position: "absolute", top: 0, left: 0 }}
              src="/assets/images/bestock/premium1.png"
              alt=""
            />
          ) : (
            ""
          )}
          <div
            style={{
              position: "relative",
              // width: "422px",
              height: `${isMobile ? "unset" : "100%"}`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: `${isMobile ? "center" : "unset"}`,
              marginLeft: `${isMobile ? "0" : "19.53%"}`,
            }}
          >
            <PretendardText
              style={{
                color: "#00E4BB",
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "14px" : "16px"}`,
                lineHeight: `${isMobile ? "21px" : "24px"}`,
                marginBottom: "20px",
                textAlign: `${isMobile ? "center" : "left"}`,
              }}
            >
              고화질의 다양한 테마
            </PretendardText>
            <PretendardText
              style={{
                color: "#fff",
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "24px" : "36px"}`,
                lineHeight: `${isMobile ? "36px" : "54px"}`,
                marginBottom: "15px",
                textAlign: `${isMobile ? "center" : "left"}`,
              }}
            >
              압도적인 퀄리티의
              <br />
              프리미엄 영상물을 확인하세요
            </PretendardText>
            <PretendardText
              style={{
                color: "#fff",
                fontSize: `${isMobile ? "15px" : "16px"}`,
                fontWeight: "400",
                lineHeight: "24px",
                marginBottom: `${isMobile ? "48px" : "72px"}`,
                textAlign: `${isMobile ? "center" : "left"}`,
              }}
            >
              퀄리티 높은 영상만을 제공하여 더욱 생생한
              <br />
              K-Contets를 제작할 수 있습니다.
            </PretendardText>
            <PrimaryEmptyBtn
              title={"영상 둘러보기"}
              style={{
                width: `${isMobile ? "161px" : "183px"}`,
                height: `${isMobile ? "48px" : "54px"}`,
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "16px" : "20px"}`,
                lineHeight: `${isMobile ? "24px" : "30px"}`,
              }}
              onClick={() => {
                history.push({
                  pathname: route.home,
                  state: { activePage: 1 },
                });
              }}
            ></PrimaryEmptyBtn>
          </div>
          {isMobile ? (
            <img
              style={{ width: "100%", height: "351px", marginTop: "-41px" }}
              src="/assets/images/bestock/premium-mobile.png"
              alt=""
            />
          ) : (
            <img
              style={{
                height: "570px",
                position: "absolute",
                top: 0,
                right: 0,
              }}
              src="/assets/images/bestock/premium2.png"
              alt=""
            />
          )}
        </div>

        {/* section-3 */}
        <div
          style={{
            height: `${isMobile ? "1174px" : "824px"}`,
            background: "#151515",
            position: "relative",
            paddingTop: `${isMobile ? "0px" : "200px"}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PretendardText
            style={{
              color: "#00E4BB",
              fontWeight: `${isMobile ? "600" : "700"}`,
              fontSize: `${isMobile ? "14px" : "16px"}`,
              lineHeight: `${isMobile ? "21px" : "24px"}`,
              marginBottom: "20px",
            }}
          >
            Why BeStock
          </PretendardText>
          <PretendardText
            style={{
              color: "#fff",
              fontWeight: `${isMobile ? "600" : "700"}`,
              fontSize: `${isMobile ? "24px" : "36px"}`,
              lineHeight: `${isMobile ? "36px" : "54px"}`,
              marginBottom: "15px",
              textAlign: "center",
            }}
          >
            차별화 된 영상 검색 {isMobile && <br />}시스템을 경험해 보세요
          </PretendardText>
          <PretendardText
            style={{
              color: "#fff",
              fontWeight: "400",
              lineHeight: "24px",
              fontSize: `${isMobile ? "15px" : "16px"}`,
              marginBottom: `${isMobile ? "50px" : "72px"}`,
              textAlign: "center",
            }}
          >
            그동안 없었던 특별한 K-Video 브라우징을 {isMobile && <br />}이제
            BeStock에서 체험할 수 있습니다.
          </PretendardText>
          <div
            style={{
              width: `${isMobile ? "100%" : "unset"}`,
              padding: `${isMobile ? "0 5.33%" : "unset"}`,
              display: "flex",
              flexDirection: `${isMobile ? "column" : "row"}`,
              gap: `${isMobile ? "15px" : "154px"}`,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                width: `${isMobile ? "100%" : "unset"}`,
                height: `${isMobile ? "193px" : "unset"}`,
                padding: `${isMobile ? "25px 0" : "unset"}`,
                border: `${isMobile ? "1px solid #00E4BB" : "unset"}`,
                alignItems: `${isMobile ? "center" : "unset"}`,
              }}
            >
              <img
                src="/assets/icons/bestock/ic_korea.svg"
                alt=""
                style={{ width: "50px", height: "50px", marginBottom: "13px" }}
              />
              <PretendardText
                style={{
                  color: "#e9e9e9",
                  fontWeight: "600",
                  lineHeight: "30px",
                  fontSize: `${isMobile ? "16px" : "20px"}`,
                  marginBottom: `${isMobile ? "12px" : "22px"}`,
                }}
              >
                해외 영상이 아닌 {!isMobile && <br />}
                <span style={{ color: "#00E4BB" }}>한국 영상</span>
              </PretendardText>
              <PretendardText
                style={{
                  color: "#fff",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: `${isMobile ? "14px" : "16px"}`,
                  textAlign: `${isMobile ? "center" : "left"}`,
                }}
              >
                해외영상 뿐인 플랫폼 대신
                <br />
                한국이 무대인 BeStock에서 {!isMobile && <br />}
                쉽게 찾아보세요.
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                width: `${isMobile ? "100%" : "unset"}`,
                height: `${isMobile ? "193px" : "unset"}`,
                padding: `${isMobile ? "25px 0" : "unset"}`,
                border: `${isMobile ? "1px solid #00E4BB" : "unset"}`,
                alignItems: `${isMobile ? "center" : "unset"}`,
              }}
            >
              <img
                src="/assets/icons/bestock/ic_recommend.svg"
                alt=""
                style={{ width: "50px", height: "50px", marginBottom: "13px" }}
              />
              <PretendardText
                style={{
                  color: "#e9e9e9",
                  fontWeight: "600",
                  lineHeight: "30px",
                  fontSize: `${isMobile ? "16px" : "20px"}`,
                  marginBottom: `${isMobile ? "12px" : "22px"}`,
                }}
              >
                10초 안에 {!isMobile && <br />}
                <span style={{ color: "#00E4BB" }}>빠르고 정확한 추천</span>
              </PretendardText>
              <PretendardText
                style={{
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: `${isMobile ? "14px" : "16px"}`,
                  textAlign: `${isMobile ? "center" : "left"}`,
                  lineHeight: "24px",
                }}
              >
                BeStock의 AI 데이터 {!isMobile && <br />}
                라벨링으로 {isMobile && <br />} 원하는 영상을{" "}
                {!isMobile && <br />}
                빠르게 추천받아 보세요.
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                width: `${isMobile ? "100%" : "unset"}`,
                height: `${isMobile ? "193px" : "unset"}`,
                padding: `${isMobile ? "25px 0" : "unset"}`,
                border: `${isMobile ? "1px solid #00E4BB" : "unset"}`,
                alignItems: `${isMobile ? "center" : "unset"}`,
              }}
            >
              <img
                src="/assets/icons/bestock/ic_possible.svg"
                alt=""
                style={{ width: "50px", height: "50px", marginBottom: "13px" }}
              />
              <PretendardText
                style={{
                  color: "#e9e9e9",
                  fontWeight: "600",
                  lineHeight: "30px",
                  fontSize: `${isMobile ? "16px" : "20px"}`,
                  marginBottom: `${isMobile ? "12px" : "22px"}`,
                }}
              >
                고품질의 영상
                {!isMobile && <br />}
                <span style={{ color: "#00E4BB" }}>상업적 사용 가능</span>
              </PretendardText>
              <PretendardText
                style={{
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: `${isMobile ? "14px" : "16px"}`,
                  textAlign: `${isMobile ? "center" : "left"}`,
                  lineHeight: "24px",
                }}
              >
                BeStock의 모든 영상은 {!isMobile && <br />}
                상업적 사용이 가능합니다.
                <br />
                구독 후 걱정 없이 사용해보세요.
              </PretendardText>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                width: `${isMobile ? "100%" : "unset"}`,
                height: `${isMobile ? "193px" : "unset"}`,
                padding: `${isMobile ? "25px 0 " : "unset"}`,
                border: `${isMobile ? "1px solid #00E4BB" : "unset"}`,
                alignItems: `${isMobile ? "center" : "unset"}`,
              }}
            >
              <img
                src="/assets/icons/bestock/ic_sametime.svg"
                alt=""
                style={{ width: "50px", height: "50px", marginBottom: "13px" }}
              />
              <PretendardText
                style={{
                  color: "#e9e9e9",
                  fontWeight: "600",
                  lineHeight: "30px",
                  fontSize: `${isMobile ? "16px" : "20px"}`,
                  marginBottom: `${isMobile ? "12px" : "22px"}`,
                }}
              >
                영상 구매 및 판매를 {!isMobile && <br />}
                <span style={{ color: "#00E4BB" }}>동시에</span>
              </PretendardText>
              <PretendardText
                style={{
                  color: "#fff",
                  fontWeight: "400",
                  fontSize: `${isMobile ? "14px" : "16px"}`,
                  textAlign: `${isMobile ? "center" : "left"}`,
                  lineHeight: "24px",
                }}
              >
                영상 구매 뿐만 아니라 {!isMobile && <br />}
                Film maker가 되어
                <br />
                영상을 판매할 수 있습니다.
              </PretendardText>
            </div>
          </div>
        </div>

        {/* section-4 */}
        <div
          style={{
            height: `${isMobile ? "829px" : "864px"}`,
            background: "#151515",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PretendardText
            style={{
              color: "#00E4BB",
              fontWeight: `${isMobile ? "600" : "700"}`,
              fontSize: `${isMobile ? "14px" : "16px"}`,
              lineHeight: `${isMobile ? "21px" : "24px"}`,
              marginBottom: "20px",
            }}
          >
            Videos
          </PretendardText>
          <PretendardText
            style={{
              color: "#fff",
              fontWeight: `${isMobile ? "600" : "700"}`,
              fontSize: `${isMobile ? "24px" : "36px"}`,
              lineHeight: `${isMobile ? "36px" : "54px"}`,
              marginBottom: "15px",
              textAlign: "center",
            }}
          >
            Today’s Best K-Clip
          </PretendardText>
          <PretendardText
            style={{
              color: "#fff",
              fontWeight: "400",
              lineHeight: "24px",
              fontSize: `${isMobile ? "15px" : "16px"}`,
              marginBottom: "72px",
              textAlign: "center",
            }}
          >
            바로 지금, BeStock을 통해 원하는 {isMobile && <br />}한국 영상을
            빠르게 찾아보세요.
          </PretendardText>
          {/* 슬라이더 */}
          <BestockSlider />
        </div>

        {/* section-5 */}
        <div
          style={{
            height: `${isMobile ? "375px" : "400px"}`,
            background: "#151515",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(180deg, #ffe4bb0d -13%, #000f0c0d 62%)",
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PretendardText
              style={{
                color: "#fff",
                fontWeight: "700",
                fontSize: `${isMobile ? "24px" : "36px"}`,
                lineHeight: `${isMobile ? "36px" : "54px"}`,
                marginBottom: "30px",
                textAlign: "center",
              }}
            >
              BeStock으로 {isMobile && <br />}지금 시작해 보세요.
            </PretendardText>
            {sessionStorage.getItem("token") ? (
              <PrimaryBtn
                style={{
                  width: `${isMobile ? "177px" : "199px"}`,
                  height: `${isMobile ? "48px" : "54px"}`,
                  fontWeight: "600",
                  fontSize: `${isMobile ? "16px" : "20px"}`,
                  lineHeight: `${isMobile ? "24px" : "30px"}`,
                }}
                title={"영상 둘러보기"}
                disabled={false}
                onClick={() => {
                  history.push({
                    pathname: route.home,
                    state: { activePage: 1 },
                  });
                }}
              ></PrimaryBtn>
            ) : (
              <PrimaryBtn
                style={{
                  width: `${isMobile ? "146px" : "160px"}`,
                  height: `${isMobile ? "48px" : "54px"}`,
                  fontWeight: "600",
                  fontSize: `${isMobile ? "16px" : "20px"}`,
                  lineHeight: `${isMobile ? "24px" : "30px"}`,
                }}
                title={"회원가입"}
                disabled={false}
                onClick={() => {
                  modal.open("join");
                }}
              ></PrimaryBtn>
            )}
          </div>
        </div>

        <Footer />
      </Container>
    </>
  );
}

export default Bestock;

const ImgContainer = styled.div.attrs((props) => {})`
  & > div:first-child:hover {
    transform: translate(-15px, -10px);
  }
  & > div:nth-child(2):hover {
    transform: translate(15px, -10px);
  }
  & > div:last-child:hover {
    transform: translate(0, 10px);
  }
`;

const Box = styled.div.attrs((props) => {})`
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  transition-timing-function: linear;
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100%{
    opacity: 1;
    transform: translateY(0%);
  }
`;

const FadeInContainer = styled.img.attrs((props) => {})`
  opacity: 0;
  &.frame-in {
    animation: ${fadeInAnimation} 1s forwards;
  }
`;
