export const menu7 = {
  title: "work",
  value: "7",
  subMenus: [
    {
      title: "마케터",
      value: "1",
    },
    {
      title: "디자이너",
      value: "2",
    },
    {
      title: "기획자",
      value: "3",
    },
    {
      title: "PD",
      value: "4",
    },
    {
      title: "그 외",
      value: "5",
    },
  ],
};
