import React, { useRef, useState, useEffect } from "react";
import PretendardText from "../pretendardText";

function PrimaryBtn02({ style, title, onClick, disabled, active }) {
  const [hover, setHover] = useState(false);

  return (
    <>
      <PretendardText
        onClick={onClick}
        onMouseEnter={() => setHover(true)} // 마우스엔터(호버)시 키값이 저장된다
        onMouseLeave={() => setHover(false)} // 마우스리브 시에는 키값이 지워진다
        style={{
          width: "146px",
          height: "44px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "100px",
          cursor: "pointer",
          background: hover || active ? "#00E4BB" : "#1E1E1E",

          color: hover || active ? "#1E1E1E" : "#fff",
          fontWeight: "700",
          fontSize: "16px",
          lineHeight: "24px",
          ...style,
        }}
      >
        {title}
      </PretendardText>
    </>
  );
}

export default PrimaryBtn02;
