import { atom } from "recoil";

export const menu01StateAtom = atom({
  key: "menu01StateAtom",
  default: {
    title: "무드",
    value: "1",
    subMenus: [],
  },
});

export const menu02StateAtom = atom({
  key: "menu02StateAtom",
  default: {
    title: "지역",
    value: "2",
    subMenus: [],
  },
});

export const menu03StateAtom = atom({
  key: "menu03StateAtom",
  default: {
    title: "주제",
    value: "3",
    subMenus: [],
  },
});

export const menu04StateAtom = atom({
  key: "menu04StateAtom",
  default: {
    title: "해상도",
    value: "4",
    subMenus: [],
  },
});

export const menu05StateAtom = atom({
  key: "menu05StateAtom",
  default: {
    title: "촬영기법",
    value: "5",
    subMenus: [],
  },
});

export const menu06StateAtom = atom({
  key: "menu06StateAtom",
  default: {
    title: "속도",
    value: "6",
    subMenus: [],
  },
});
