import { atom } from "recoil";

export const modalState = atom({
  key: "modalState",
  default: "",
});

export const contactModalState = atom({
  key: "contactModal",
  default: false,
});
