export const contactMenu4 = {
  title: "희망 납품 기간",
  value: "1",
  subMenus: [
    {
      title: "긴급(24시간 내)",
      value: "1",
    },
    {
      title: "2~3일 내",
      value: "2",
    },
    {
      title: "일주일 내",
      value: "3",
    },
    {
      title: "한달 내",
      value: "4",
    },
    {
      title: "3개월 내(기획 촬영)",
      value: "5",
    },
  ],
};
