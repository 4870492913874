// 정규식 모음
export const regExpression = {
  // 비밀번호 - 영문 숫자 조합 6자리 ~ 16자리
  password:
    // /^(?!((?:[A-Z]+)|(?:[a-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{6,16}$/,
    /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,16}$/,

  // 이메일
  email:
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,

  // 휴대전화번호
  phoneNumber: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
};
