import React from "react";

function Blur({ style }) {
  return (
    <>
      <div
        style={{
          width: "202px",
          height: "202px",
          borderRadius: "30px",
          background: "rgba(0, 255, 209, 0.50)",
          position: "absolute",
          filter: "blur(285px)",
          ...style,
          //width, height, filter, background, borderRadius, top, left
        }}
      ></div>
    </>
  );
}

export default Blur;
