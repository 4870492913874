import React, { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { route } from "../../routes/route";

import useDisplay from "../../hooks/useDisplay";
import PretendardText from "../../components/atoms/pretendardText";
import WhiteBtn from "../../components/atoms/btn/whiteBtn";

import PriceBox from "../../components/templates/payment/priceBox";
import DetailBox from "../../components/templates/payment/detailBox";
import PaymentApi from "../../api/payment";
import { ko } from "../../utils/ko";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import Footer from "../../components/organisms/footer";

function Payment() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const [basicInfo, setBasicInfo] = useState({
    content: "",
    createdAt: "",
    creditCard: true,
    downloadLimit: "",
    id: 43,
    monthlyFee: 0,
    monthlyPay: false,
    monthlySubscribe: true,
    paypal: true,
    planType: "",
    planTypeTitle: "",
    resolutions: "",
    title: "",
    updatedAt: "",
    yearlyFee: 0,
    yearlyPay: true,
    yearlySubscribe: true,
  });
  const [standardInfo, setStandardInfo] = useState({
    content: "",
    createdAt: "",
    creditCard: true,
    downloadLimit: "",
    id: 43,
    monthlyFee: 0,
    monthlyPay: false,
    monthlySubscribe: true,
    paypal: true,
    planType: "",
    planTypeTitle: "",
    resolutions: "",
    title: "",
    updatedAt: "",
    yearlyFee: 0,
    yearlyPay: true,
    yearlySubscribe: true,
  });
  const [proInfo, setProInfo] = useState({
    content: "",
    createdAt: "",
    creditCard: true,
    downloadLimit: "",
    id: 43,
    monthlyFee: 0,
    monthlyPay: false,
    monthlySubscribe: true,
    paypal: true,
    planType: "",
    planTypeTitle: "",
    resolutions: "",
    title: "",
    updatedAt: "",
    yearlyFee: 0,
    yearlyPay: true,
    yearlySubscribe: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getPaymentInfo();
  }, []);

  const getPaymentInfo = async () => {
    try {
      const basicData = (await PaymentApi.getPlan({ planType: "BASIC" })).data
        .data;
      const proData = (await PaymentApi.getPlan({ planType: "PRO" })).data.data;
      const standardData = (await PaymentApi.getPlan({ planType: "STANDARD" }))
        .data.data;

      setBasicInfo(basicData);
      setProInfo(proData);
      setStandardInfo(standardData);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const countFunc = (value) => {
    switch (value) {
      case "FIFTY":
        return "50회";

      case "TWO_HUNDRED":
        return "200회";

      case "UNLIMITED":
        return "무제한";

      default:
        return "";
    }
  };

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}

        {/* header */}
        <div
          style={{
            position: "relative",
            width: "100%",
            height: isSmall ? 300 : 413,
            background: "#151515",
            backgroundImage: isSmall
              ? "url(/assets/images/payment/m_top.png)"
              : "url(/assets/images/payment/top_bg.png)",
            backgroundSize: "100% 100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PretendardText
            style={{
              fontWeight: isSmall ? "600" : "700",
              fontSize: isSmall ? "14px" : "16px",
              lineHeight: isSmall ? "21px" : "24px",
              color: "#00E4BB",
              marginBottom: isSmall ? 4 : 15,
              marginTop: isSmall ? 0 : 80,
            }}
          >
            BeStock Pricing
          </PretendardText>
          <PretendardText
            style={{
              fontWeight: isSmall ? "600" : "700",
              fontSize: isSmall ? "24px" : "36px",
              lineHeight: isSmall ? "36px" : "54px",
              color: "#fff",
            }}
          >
            요금제
          </PretendardText>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "#151515",
            width: "100%",
            position: "relative",
            padding: isSmall ? "70px 20px" : "200px 0px",
          }}
        >
          {isSmall ? null : (
            <img
              src="/assets/images/payment/left.png"
              style={{
                position: "absolute",
                left: 0,
                bottom: 400,
              }}
            />
          )}

          {isSmall ? null : (
            <img
              src="/assets/images/payment/right.png"
              style={{
                position: "absolute",
                right: 0,
                bottom: 200,
              }}
            />
          )}

          <PretendardText
            style={{
              fontWeight: isSmall ? "600" : "700",
              fontSize: isSmall ? "24px" : "36px",
              lineHeight: isSmall ? "36px" : "54px",
              color: "#fff",
              marginBottom: 15,
              textAlign: "center",
            }}
          >
            합리적인 가격으로 {isSmall ? <br /> : null}K-Video를 만나보세요.
          </PretendardText>
          <PretendardText
            style={{
              fontWeight: "400",
              fontSize: isSmall ? "15px" : "16px",
              lineHeight: "24px",
              color: "#fff",
              marginBottom: isSmall ? 30 : 71,
              textAlign: "center",
            }}
          >
            월/ 연 구독료를 통해 {isSmall ? <br /> : null}원하시는 퀄리티의
            영상을 받아보세요.
          </PretendardText>

          <div
            style={{
              display: "flex",
              flexDirection: isSmall ? "column" : "row",
              width: isSmall ? "100%" : 1170,
            }}
          >
            <PriceBox
              title={basicInfo.title}
              subTitle={basicInfo.content}
              mValue={basicInfo.monthlyFee}
              yValue={basicInfo.yearlyFee}
              onClick={() => {
                history.push({
                  pathname: route.charge + `/basic`,
                });
              }}
              guide={"BeStock - Basic includes"}
              useList={
                basicInfo.resolutions ? basicInfo.resolutions.split(",") : []
              }
            />

            <PriceBox
              style={{
                background: isSmall
                  ? "rgba(0, 228, 187,  0.8)"
                  : "linear-gradient(rgba(0, 228, 187,  0.8) ,rgba(0, 228, 187,  0))",
                marginBottom: isSmall ? 30 : 40,
                position: "relative",
              }}
              boxStyle={{
                background:
                  "linear-gradient(rgba(20, 50,40), rgba(18, 68, 58) , rgba(20,31, 29)",
              }}
              containerStyle={{
                borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
              }}
              title={standardInfo.title}
              subTitle={standardInfo.content}
              mValue={standardInfo.monthlyFee}
              yValue={standardInfo.yearlyFee}
              onClick={() => {
                history.push({
                  pathname: route.charge + `/standard`,
                });
              }}
              guide={"BeStock - Standard includes"}
              useList={
                standardInfo.resolutions
                  ? standardInfo.resolutions.split(",")
                  : []
              }
              isBest={true}
            />

            <PriceBox
              style={{
                marginRight: 0,
              }}
              title={proInfo.title}
              subTitle={proInfo.content}
              mValue={proInfo.monthlyFee}
              yValue={proInfo.yearlyFee}
              onClick={() => {
                history.push({
                  pathname: route.charge + `/pro`,
                });
              }}
              guide={"BeStock - Pro includes"}
              useList={
                proInfo.resolutions ? proInfo.resolutions.split(",") : []
              }
            />
          </div>

          <div
            style={{
              marginTop: 120,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <PretendardText
              style={{
                fontWeight: isSmall ? "700" : "600",
                fontSize: isSmall ? "22px" : "24px",
                lineHeight: isSmall ? "35.2px" : "36px",
                color: "#fff",
                marginBottom: isSmall ? 30 : 40,
              }}
            >
              자세한 내용을 확인하세요.
            </PretendardText>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: isSmall ? "column" : "row",
              width: isSmall ? "100%" : 1170,
            }}
          >
            <DetailBox
              title={"Basic"}
              subTitle={`월 ${countFunc(
                basicInfo.downloadLimit
              )} 다운로드 가능`}
              content01={`저렴한 가격으로 K-스톡 영상 컨텐츠를\n 이용할 수 있는 기본 구독 모델입니다.`}
              content02={`다양한 주제와 스타일의 영상들을 저렴하게 이용하여\n 프로젝트나 컨텐츠 제작에 활용할 수 있습니다.`}
            />

            <DetailBox
              style={{
                borderLeft: isSmall ? "1px solid #565656" : 0,
                marginTop: isSmall ? 20 : 0,
              }}
              title={"Standard"}
              subTitle={`월 ${countFunc(
                standardInfo.downloadLimit
              )} 다운로드 가능`}
              content01={`안정적인 퀄리티의 영상과 4K 해상도\n영상까지 이용 가능한 구독 모델입니다.`}
              content02={`높은 퀄리티의 영상 컨텐츠들을 안정적으로 제공하며,\n4K 해상도로 더욱 선명하고 생생한 경험을 제공합니다.`}
            />

            <DetailBox
              style={{
                borderLeft: isSmall ? "1px solid #565656" : 0,
                marginTop: isSmall ? 20 : 0,
              }}
              title={"Pro"}
              subTitle={`월 ${countFunc(proInfo.downloadLimit)} 다운로드 가능`}
              content01={`높은 퀄리티의 전문적인 영상 제작을 위한\n고급 구독 모델입니다.`}
              content02={`프로덕션 레벨의 영상 컨텐츠들과 다양한 특수효과,\n색보정 커스텀이 가능한 LOG 원본 파일 등을 제공하여\n크리에이터나 전문 영상 제작자를 위한\n맞춤 컨텐츠를 제공합니다.`}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: isSmall ? "column" : "row",
              justifyContent: "center",
              alignItems: "center",

              background: "#1E1E1E",
              width: isSmall ? "100%" : 1170,
              height: isSmall ? 96 : 85,
              marginTop: isSmall ? 70 : 40,
              borderRadius: 10,
            }}
          >
            <PretendardText
              style={{
                fontWeight: "400",
                fontSize: isSmall ? "16px" : "18px",
                lineHeight: isSmall ? "24px" : "28.8px",
                color: "#BABABA",
                marginRight: isSmall ? 0 : 15,
                marginBottom: isSmall ? 8 : 0,
              }}
            >
              요금제 관련 문의사항이 있으신가요?
            </PretendardText>

            <PretendardText
              onClick={() => {
                history.push({
                  pathname: route.contact,
                });
              }}
              style={{
                fontWeight: "700",
                fontSize: isSmall ? "16px" : "18px",
                lineHeight: isSmall ? "24px" : "28.8px",
                color: "#00E4BB",
                cursor: "pointer",
                textDecorationLine: "underline",
              }}
            >
              문의하기
            </PretendardText>
          </div>
        </div>

        <div
          style={{
            position: "relative",
            width: "100%",
            height: 400,
            background: "#151515",
            backgroundImage: isSmall
              ? "url(/assets/images/payment/m_bottom.png)"
              : "url(/assets/images/payment/bottom_bg.png)",
            backgroundSize: "100% 100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PretendardText
            style={{
              fontWeight: "700",
              fontSize: isSmall ? "24px" : "36px",
              lineHeight: isSmall ? "36px" : "54px",
              color: "#fff",
              marginBottom: isSmall ? 23 : 29,
              textAlign: "center",
            }}
          >
            BeStock의 다양한 {isSmall ? <br /> : null}고화질 영상이
            궁금하신가요?
          </PretendardText>
          <WhiteBtn
            onClick={() => {
              history.push({
                pathname: route.home,
                state: { activePage: 1, selectedMenu: {} },
              });
            }}
            style={{
              width: isSmall ? 146 : "160px",
              height: isSmall ? 48 : "54px",
              fontSize: isSmall ? "16px" : "20px",
              lineHeight: isSmall ? "24px" : "30px",
            }}
            title={"둘러보기"}
          />
        </div>

        <Footer />
      </Container>
    </>
  );
}

export default Payment;
