import React from "react";
import PretendardText from "../../atoms/pretendardText";
import RadioInput from "../../atoms/input/radioInput";

function DownloadBtnBox({ title, type, volume, checked, onClick }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <RadioInput checked={checked} onChange={onClick} />
      <PretendardText
        style={{
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#FFFFFF",
          marginLeft: "10px",
          width: "154px",
        }}
      >
        {title}
      </PretendardText>
      <PretendardText
        style={{
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#FFFFFF",
          width: "94px",
        }}
      >
        {type}
      </PretendardText>
      <PretendardText
        style={{
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#FFFFFF",
        }}
      >
        {volume}
      </PretendardText>
    </div>
  );
}

export default DownloadBtnBox;
