const prefix = "/bestock";

export const route = {
  home: prefix + "/home",
  bestock: prefix + "/introduce",
  matching: prefix + "/matching",

  videoDetail: prefix + "/videoDetail",

  recommend: prefix + "/recommend",

  account: prefix + "/user/account",
  library: prefix + "/user/library",
  payHistory: prefix + "/user/payHistory",
  subscribe: prefix + "/user/subscribe",

  payment: prefix + "/payment",
  charge: prefix + "/charge",

  privacy: prefix + "/privacy",
  terms: prefix + "/terms",
  licenses: prefix + "/licenses",
  copyright: prefix + "/copyright",
  notice: prefix + "/notice",
  notice_detail: prefix + "/noticeDetail",

  faq: prefix + "/faq",

  contact: prefix + "/contact",

  videoStreaming: prefix + "/video/streaming",
  videoTemp: prefix + "/video/temp",
};
