import { toast } from "react-toastify";
import { route } from "../routes/route";

function errorHandler(error) {
  switch (error.response?.status) {
    case 403:
      toast("권한이 없습니다. 로그인해주세요.");
      sessionStorage.clear();
      // window.location.href = route.home;
      break;
    case 404:
      toast(
        `${
          error.response.data.message
            ? error.response.data.message
            : "정보를 찾을 수 없습니다."
        }`
      );
      break;
    case 500:
      toast(
        `${
          error.response.data.message
            ? error.response.data.message
            : "서버에 문제가 있습니다. 잠시후 다시 시도해주세요."
        }`
      );
      break;
    default:
      break;
  }
}

export { errorHandler };
