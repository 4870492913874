import React, { forwardRef } from "react";
import useDisplay from "../../../hooks/useDisplay";

const Container = forwardRef(function Container(props, ref) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <div
        id="container"
        style={{
          minHeight: "100vh",
          overflow: "auto",
          background: "#151515",
          ...props.style,
        }}
      >
        {props.children}
      </div>
    </>
  );
});

export default Container;
