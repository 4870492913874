import React, { useRef, useState, useEffect } from "react";
import {
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

import useDisplay from "../../hooks/useDisplay";
import PretendardText from "../../components/atoms/pretendardText";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import TitleLabel from "../../components/atoms/text/titleLabel";
import RadioInput from "../../components/atoms/input/radioInput";
import TextInput from "../../components/atoms/input/textInput";
import CheckboxInput from "../../components/atoms/input/checkboxInput";
import SelectBox from "../../components/atoms/select/selectBox";
import TermModal from "../../components/templates/modal/termModal";

import { menu7 } from "../../utils/menu/menu7";
import { menu8 } from "../../utils/menu/menu8";
import PaymentApi from "../../api/payment";
import { ko } from "../../utils/ko";
import TermsApi from "../../api/terms";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import Footer from "../../components/organisms/footer";
import { route } from "../../routes/route";
import { nullCheck } from "../../utils/check";
import { message } from "antd";
import { errorHandler } from "../../utils/error-handler";
import Loading from "../../components/templates/loading";
import api from "../../api/api";

function Charge() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const { id } = useParams();
  const history = useHistory();
  const [infoData, setInfoData] = useState({
    content: "",
    createdAt: "",
    creditCard: true,
    downloadLimit: "",
    id: 43,
    monthlyFee: 0,
    monthlyPay: false,
    monthlySubscribe: true,
    paypal: true,
    planType: "",
    planTypeTitle: "",
    resolutions: "",
    title: "",
    updatedAt: "",
    yearlyFee: 0,
    yearlyPay: true,
    yearlySubscribe: true,
    useList: [],
  });
  const [paymentType, setPaymentType] = useState("월간 결제");
  const [paymentMethod, setPaymentMethod] = useState("신용카드 또는 직불카드");
  const [company, setCompany] = useState("");
  const [individual, setIndividual] = useState(false);
  const [saveData, setSaveData] = useState({
    optionTitle: "유형을 선택해 주세요.",
    option: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const optionList = menu7.subMenus;

  const [nationSaveData, setNationSaveData] = useState({
    optionTitle: "국가를 선택해 주세요.",
    option: "",
  });
  const [isOpen02, setIsOpen02] = useState(false);
  const optionList02 = menu8.subMenus;

  const [terms01, setTerms01] = useState(false);
  const [terms02, setTerms02] = useState(false);
  const [terms03, setTerms03] = useState(false);
  const [terms04, setTerms04] = useState(false);
  const [terms05, setTerms05] = useState(false);

  const [isTermModal, setIsTermModal] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [termTitle, setTermTitle] = useState("");
  const [termContent, setTermContent] = useState("");

  useEffect(() => {
    if (
      paymentType &&
      paymentMethod &&
      company &&
      saveData.option &&
      (paymentMethod === "페이팔" ? nationSaveData.option : true) &&
      terms01 &&
      terms02 &&
      terms03 &&
      terms04 &&
      terms05
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [
    paymentType,
    paymentMethod,
    company,
    saveData,
    nationSaveData,
    terms01,
    terms02,
    terms03,
    terms04,
    terms05,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);

    switch (id) {
      case "basic":
        getPaymentInfo("BASIC", "BeStock 베이직 요금제");
        break;

      case "standard":
        getPaymentInfo("STANDARD", "BeStock 스탠다드 요금제");

        break;

      case "pro":
        getPaymentInfo("PRO", "BeStock 프로 요금제");

        break;
    }
  }, []);

  const getPaymentInfo = async (type, subTitle) => {
    try {
      const dataInfoTemp = (await PaymentApi.getPlan({ planType: type })).data
        .data;

      setInfoData({
        ...dataInfoTemp,
        useList: dataInfoTemp.resolutions
          ? dataInfoTemp.resolutions.split(",")
          : [],
        subTitle: subTitle,
      });
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  const countFunc = (value) => {
    switch (value) {
      case "FIFTY":
        return "50회";

      case "TWO_HUNDRED":
        return "200회";

      case "UNLIMITED":
        return "무제한";

      default:
        return "";
    }
  };

  const termModalOpen = (value) => {
    getTermInfo(value);
  };

  const getTermInfo = async (title) => {
    try {
      const dataTemp = (await TermsApi.getTermsByTitle({ keyword: title })).data
        .data;

      setTermTitle(dataTemp.title);
      setTermContent(dataTemp.content);

      setIsTermModal(true);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  function toStringByFormatting() {
    var now = new Date(); // 현재 날짜 및 시간
    var oneLater;
    if (paymentType === "월간 결제") {
      var oneLater = new Date(now.setMonth(now.getMonth() + 1)); // 한달 후
    } else {
      oneLater = new Date(now.setFullYear(now.getFullYear() + 1)); // 일년 후
    }

    const year = oneLater.getFullYear();
    const month = leftPad(oneLater.getMonth() + 1);
    const day = leftPad(oneLater.getDate());

    return year + "-" + month + "-" + day;
  }

  function leftPad(value) {
    if (value >= 10) {
      return value;
    }

    return `0${value}`;
  }

  // 결제 api
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    const message = searchParams.get("message");
    const customerKey = searchParams.get("customerKey");
    const authKey = searchParams.get("authKey");
    const payInfo = JSON.parse(sessionStorage.getItem("payInfo"));
    if (!nullCheck(payInfo)) {
      setPaymentType(payInfo.isMonthly ? "월간 결제" : "연간 결제");
      setPaymentMethod(
        payInfo.payMethod === "CREDIT_CARD"
          ? "신용카드 또는 직불카드"
          : "페이팔"
      );
      setCompany(payInfo.businessName);
      setIndividual(payInfo.isPersonal);
      setSaveData({
        optionTitle: payInfo.businessType,
        option: optionList.find((item) => item.title === payInfo.businessType)
          .value,
      });
      setTerms01(true);
      setTerms02(true);
      setTerms03(true);
      setTerms04(true);
      setTerms05(true);
    }

    // 토스 인증 실패
    if (!nullCheck(message)) {
      toast(message);
      return;
    }

    // 토스 인증 성공
    if (!(nullCheck(customerKey) && nullCheck(authKey))) {
      // 결제처리
      const data = {
        ...payInfo,
        authKey: authKey,
      };
      subscribe(data);
    }
  }, [location]);

  const payFunc = async () => {
    if (!sessionStorage.getItem("token")) {
      toast("로그인이 필요한 서비스입니다.");
      return;
    }
    if (nullCheck(company)) {
      toast("기업명 또는 이름을 입력해주세요.");
      return;
    }
    if (nullCheck(saveData.option)) {
      toast("담당 업무 유형을 선택해주세요.");
      return;
    }
    if (!(terms01 && terms02 && terms03 && terms04 && terms05)) {
      toast("필수 약관에 동의해주세요.");
      return;
    }
    const customerKey = await getCustomerKey();

    if (nullCheck(customerKey)) {
      return;
    }

    const payInfo = {
      authKey: "",
      businessName: company,
      businessType: saveData.optionTitle,
      isMonthly: paymentType === "월간 결제",
      isPersonal: individual,
      payMethod:
        paymentMethod === "신용카드 또는 직불카드" ? "CREDIT_CARD" : "PAYPAL",
      paymentToken: customerKey, // customerKey
      planType: id.toUpperCase(),
    };

    sessionStorage.setItem("payInfo", JSON.stringify(payInfo));
    window.useTossPayments(customerKey, `${api.getUrl()}/bestock/charge/${id}`);
  };

  const getCustomerKey = async () => {
    try {
      const response = await PaymentApi.getCustomerKey();
      return response.data.data.customerKey;
    } catch (error) {
      toast("이미 구독중인 상태입니다.");
      return null;
    }
  };

  const subscribe = async (data) => {
    try {
      setisLoading(true);
      await PaymentApi.subscribe(data);
      toast("결제완료되었습니다.");
      history.replace(route.subscribe + `/1`);
      sessionStorage.removeItem("payInfo");
    } catch (error) {
      setisLoading(false);
      errorHandler(error);
    }
  };

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}

        {/* header */}
        {isSmall ? (
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 300,
              background: "#151515",
              backgroundImage: "url(/assets/images/payment/top_bg.png)",
              backgroundSize: "100% 100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PretendardText
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "21px",
                color: "#00E4BB",
                marginBottom: 4,
                marginTop: 0,
              }}
            >
              BeStock Pricing
            </PretendardText>
            <PretendardText
              style={{
                fontWeight: "600",
                fontSize: "24px",
                lineHeight: "36px",
                color: "#fff",
              }}
            >
              요금제
            </PretendardText>
          </div>
        ) : null}

        <div
          style={{
            position: "relative",
            width: "100%",
            height: isSmall ? "auto" : 450,
            background: "#151515",
            backgroundImage: isSmall
              ? "none"
              : "url(/assets/images/payment/top_bg02.png)",
            backgroundSize: "100% 100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingTop: isSmall ? 70 : 0,
          }}
        >
          <div
            style={{
              width: isSmall ? 175 : 244,
              height: isSmall ? 43 : 62,
              background: "#333333",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginBottom: isSmall ? 30 : 50,
              borderRadius: 10,
            }}
          >
            <PretendardText
              style={{
                fontWeight: "700",
                fontSize: isSmall ? "22px" : "36px",
                lineHeight: isSmall ? "35.2px" : "54px",
                color: "#fff",
              }}
            >
              {infoData.title}
            </PretendardText>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              marginBottom: 8,
            }}
          >
            <PretendardText
              style={{
                fontWeight: "700",
                fontSize: isSmall ? "24px" : "36px",
                lineHeight: isSmall ? "33.6px" : "50.4px",
                color: "#fff",
              }}
            >
              ₩ {Number(infoData.monthlyFee).toLocaleString("ko-KR")}
            </PretendardText>
            <PretendardText
              style={{
                fontWeight: "700",
                fontSize: isSmall ? "12px" : "18px",
                lineHeight: isSmall ? "16.8px" : "25.2px",
                color: "#fff",
                marginBottom: isSmall ? 4 : 8,
              }}
            >
              /월
            </PretendardText>
          </div>
        </div>

        <div
          style={{
            paddingTop: 12,
            paddingBottom: isSmall ? 70 : 200,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "#151515",
            width: "100%",
            position: "relative",
            paddingLeft: isSmall ? 20 : 0,
            paddingRight: isSmall ? 20 : 0,
          }}
        >
          <PretendardText
            style={{
              fontWeight: "400",
              fontSize: isSmall ? "16px" : "18px",
              lineHeight: isSmall ? "24px" : "28.8px",
              color: "#fff",
              marginBottom: 5,
            }}
          >
            {infoData.subTitle}
          </PretendardText>
          <PretendardText
            style={{
              fontWeight: "400",
              fontSize: isSmall ? "16px" : "18px",
              lineHeight: isSmall ? "24px" : "28.8px",
              color: "#fff",
            }}
          >
            (연 {Number(infoData.yearlyFee).toLocaleString("ko-KR")}원)
          </PretendardText>

          <div
            style={{
              marginTop: isSmall ? 40 : 80,
              display: "flex",
              flexDirection: "column",
              background: "#1E1E1E",
              width: isSmall ? "100%" : 1170,
              borderRadius: 10,
              padding: isSmall ? 15 : 30,
              paddingBottom: isSmall ? 15 : 23,
            }}
          >
            <PretendardText
              style={{
                fontWeight: isSmall ? "600" : "700",
                fontSize: isSmall ? "14px" : "18px",
                lineHeight: isSmall ? "21px" : "28.8px",
                color: "#F1F1F1",
                marginBottom: isSmall ? 10 : 15,
              }}
            >
              다음 조건의 영상이 월 {countFunc(infoData.downloadLimit)} 다운로드
              가능합니다
            </PretendardText>
            {infoData.useList.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 7,
                  }}
                >
                  <img
                    src="/assets/icons/icon_check.png"
                    style={{
                      height: 15,
                      width: 15,
                      marginRight: 4,
                    }}
                  />
                  <PretendardText
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#fff",
                    }}
                  >
                    {item}
                  </PretendardText>
                </div>
              );
            })}
          </div>

          <div
            style={{
              width: isSmall ? "100%" : 1170,
              display: "flex",
              flexDirection: "column",
              marginTop: isSmall ? 0 : 20,
            }}
          >
            <TitleLabel title={"지불 유형"} isRequired={true} />
            {infoData.monthlySubscribe ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <RadioInput
                  checked={paymentType === "월간 결제"}
                  onChange={() => {
                    setPaymentType("월간 결제");
                  }}
                />
                <PretendardText
                  onClick={() => {
                    setPaymentType("월간 결제");
                  }}
                  style={{
                    cursor: "pointer",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#fff",
                    marginLeft: 10,
                  }}
                >
                  월간 결제
                </PretendardText>
              </div>
            ) : null}

            {infoData.yearlySubscribe ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <RadioInput
                  checked={paymentType === "연간 결제"}
                  onChange={() => {
                    setPaymentType("연간 결제");
                  }}
                />
                <PretendardText
                  onClick={() => {
                    setPaymentType("연간 결제");
                  }}
                  style={{
                    cursor: "pointer",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#fff",
                    marginLeft: 10,
                  }}
                >
                  연간 결제
                </PretendardText>
              </div>
            ) : null}

            <TitleLabel title={"지불 방법"} isRequired={true} />
            {infoData.creditCard ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <RadioInput
                  checked={paymentMethod === "신용카드 또는 직불카드"}
                  onChange={() => {
                    setPaymentMethod("신용카드 또는 직불카드");
                  }}
                />
                <PretendardText
                  onClick={() => {
                    setPaymentMethod("신용카드 또는 직불카드");
                  }}
                  style={{
                    cursor: "pointer",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#fff",
                    marginLeft: 10,
                  }}
                >
                  신용카드 또는 직불카드
                </PretendardText>
              </div>
            ) : null}
            {infoData.paypal ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <RadioInput
                  checked={paymentMethod === "페이팔"}
                  onChange={() => {
                    toast("서비스 준비 중입니다.");
                    // setPaymentMethod("페이팔");
                  }}
                />
                <PretendardText
                  onClick={() => {
                    toast("서비스 준비 중입니다.");
                    // setPaymentMethod("페이팔");
                  }}
                  style={{
                    cursor: "pointer",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#fff",
                    marginLeft: 10,
                  }}
                >
                  페이팔
                </PretendardText>
              </div>
            ) : null}

            <TitleLabel title={"기업명"} isRequired={true} />
            <TextInput
              onChange={(e) => {
                setCompany(e.target.value);
              }}
              value={company}
              placeholder={"기업명 또는 이름"}
              maxLength={20}
              onBlur={() => {
                setCompany(company.trim());
              }}
            />

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <CheckboxInput
                checked={individual}
                onChange={() => {
                  setIndividual(!individual);
                }}
              />
              <PretendardText
                onClick={() => {
                  setIndividual(!individual);
                }}
                style={{
                  cursor: "pointer",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#fff",
                  marginLeft: 10,
                }}
              >
                개인 회원입니다.
              </PretendardText>
            </div>

            <TitleLabel title={"담당 업무 유형"} isRequired={true} />
            <SelectBox
              style={{}}
              title={saveData.optionTitle}
              optionList={optionList}
              onClickSelect={() => {
                setIsOpen(!isOpen);
              }}
              onClickOption={(item) => {
                saveData.optionTitle = item.title;
                saveData.option = item.value;
                setSaveData({ ...saveData });
                setIsOpen(false);
              }}
              isOpen={isOpen}
              value={saveData.option}
            />

            {paymentMethod === "페이팔" ? (
              <div>
                <TitleLabel title={"국가"} isRequired={true} />
                <SelectBox
                  style={{}}
                  title={nationSaveData.optionTitle}
                  optionList={optionList02}
                  onClickSelect={() => {
                    setIsOpen02(!isOpen02);
                  }}
                  onClickOption={(item) => {
                    nationSaveData.optionTitle = item.title;
                    nationSaveData.option = item.value;
                    setNationSaveData({ ...nationSaveData });
                    setIsOpen(false);
                  }}
                  isOpen={isOpen02}
                  value={nationSaveData.option}
                />
              </div>
            ) : null}
          </div>

          <div
            style={{
              marginTop: 30,
              display: "flex",
              flexDirection: "column",
              background: "#1E1E1E",
              width: isSmall ? "100%" : 1170,
              borderRadius: 10,
              padding: isSmall ? "30px 20px" : 30,
              paddingBottom: isSmall ? 30 : 24,
            }}
          >
            <PretendardText
              style={{
                fontWeight: "700",
                fontSize: isSmall ? "16px" : "18px",
                lineHeight: isSmall ? "24px" : "28.8px",
                color: "#F1F1F1",
                marginBottom: 15,
              }}
            >
              약관 동의 (필수)
            </PretendardText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CheckboxInput
                  checked={terms01}
                  onChange={() => {
                    setTerms01(!terms01);
                  }}
                />
                <PretendardText
                  onClick={() => {
                    setTerms01(!terms01);
                  }}
                  style={{
                    cursor: "pointer",
                    fontWeight: "400",
                    fontSize: isSmall ? "15px" : "16px",
                    lineHeight: "24px",
                    color: "#BABABA",
                    marginLeft: isSmall ? 8 : 10,
                  }}
                >
                  정기과금 이용약관
                </PretendardText>
              </div>
              <PretendardText
                onClick={() => {
                  termModalOpen("정기과금 이용약관");
                }}
                style={{
                  cursor: "pointer",
                  fontWeight: "400",
                  fontSize: isSmall ? "15px" : "16px",
                  lineHeight: isSmall ? "24px" : "25.6px",
                  color: "#BABABA",
                  textDecorationLine: "underline",
                }}
              >
                전체보기
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: isSmall ? 12 : 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CheckboxInput
                  checked={terms02}
                  onChange={() => {
                    setTerms02(!terms02);
                  }}
                />
                <PretendardText
                  onClick={() => {
                    setTerms02(!terms02);
                  }}
                  style={{
                    cursor: "pointer",
                    fontWeight: "400",
                    fontSize: isSmall ? "15px" : "16px",
                    lineHeight: "24px",
                    color: "#BABABA",
                    marginLeft: isSmall ? 8 : 10,
                  }}
                >
                  전자금융 이용약관
                </PretendardText>
              </div>
              <PretendardText
                onClick={() => {
                  termModalOpen("전자금융 이용약관");
                }}
                style={{
                  cursor: "pointer",
                  fontWeight: "400",
                  fontSize: isSmall ? "15px" : "16px",
                  lineHeight: isSmall ? "24px" : "25.6px",
                  color: "#BABABA",
                  textDecorationLine: "underline",
                }}
              >
                전체보기
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: isSmall ? 12 : 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CheckboxInput
                  checked={terms03}
                  onChange={() => {
                    setTerms03(!terms03);
                  }}
                />
                <PretendardText
                  onClick={() => {
                    setTerms03(!terms03);
                  }}
                  style={{
                    cursor: "pointer",
                    fontWeight: "400",
                    fontSize: isSmall ? "15px" : "16px",
                    lineHeight: "24px",
                    color: "#BABABA",
                    marginLeft: isSmall ? 8 : 10,
                  }}
                >
                  고유식별정보수집 및 이용약관
                </PretendardText>
              </div>
              <PretendardText
                onClick={() => {
                  termModalOpen("고유식별정보수집 및 이용약관");
                }}
                style={{
                  cursor: "pointer",
                  fontWeight: "400",
                  fontSize: isSmall ? "15px" : "16px",
                  lineHeight: isSmall ? "24px" : "25.6px",
                  color: "#BABABA",
                  textDecorationLine: "underline",
                }}
              >
                전체보기
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: isSmall ? 12 : 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CheckboxInput
                  checked={terms04}
                  onChange={() => {
                    setTerms04(!terms04);
                  }}
                />
                <PretendardText
                  onClick={() => {
                    setTerms04(!terms04);
                  }}
                  style={{
                    cursor: "pointer",
                    fontWeight: "400",
                    fontSize: isSmall ? "15px" : "16px",
                    lineHeight: "24px",
                    color: "#BABABA",
                    marginLeft: isSmall ? 8 : 10,
                  }}
                >
                  개인정보 수집 및 이용 동의
                </PretendardText>
              </div>
              <PretendardText
                onClick={() => {
                  termModalOpen("개인정보 수집 및 이용 동의");
                }}
                style={{
                  cursor: "pointer",
                  fontWeight: "400",
                  fontSize: isSmall ? "15px" : "16px",
                  lineHeight: isSmall ? "24px" : "25.6px",
                  color: "#BABABA",
                  textDecorationLine: "underline",
                }}
              >
                전체보기
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: isSmall ? 12 : 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CheckboxInput
                  checked={terms05}
                  onChange={() => {
                    setTerms05(!terms05);
                  }}
                />
                <PretendardText
                  onClick={() => {
                    setTerms05(!terms05);
                  }}
                  style={{
                    cursor: "pointer",
                    fontWeight: "400",
                    fontSize: isSmall ? "15px" : "16px",
                    lineHeight: "24px",
                    color: "#BABABA",
                    marginLeft: isSmall ? 8 : 10,
                  }}
                >
                  개인정보 제3자 제공 동의
                </PretendardText>
              </div>
              <PretendardText
                onClick={() => {
                  termModalOpen("개인정보 제3자 제공 동의");
                }}
                style={{
                  cursor: "pointer",
                  fontWeight: "400",
                  fontSize: isSmall ? "15px" : "16px",
                  lineHeight: isSmall ? "24px" : "25.6px",
                  color: "#BABABA",
                  textDecorationLine: "underline",
                }}
              >
                전체보기
              </PretendardText>
            </div>
          </div>

          <div
            style={{
              marginTop: 30,
              display: "flex",
              flexDirection: "column",
              background: "#1E1E1E",
              width: isSmall ? "100%" : 1170,
              borderRadius: 10,
              padding: isSmall ? "30px 20px" : 30,
              paddingBottom: isSmall ? 30 : 27,
            }}
          >
            <PretendardText
              style={{
                fontWeight: "700",
                fontSize: isSmall ? "16px" : "18px",
                lineHeight: isSmall ? "24px" : "28.8px",
                color: "#F1F1F1",
                marginBottom: 15,
              }}
            >
              정기 결제 안내
            </PretendardText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: isSmall ? 12 : 9,
              }}
            >
              <PretendardText
                style={{
                  fontWeight: "400",
                  fontSize: isSmall ? "15px" : "16px",
                  lineHeight: "24px",
                  color: "#BABABA",
                }}
              >
                다음 결제일
              </PretendardText>
              <PretendardText
                style={{
                  fontWeight: "400",
                  fontSize: isSmall ? "15px" : "16px",
                  lineHeight: "24px",
                  color: "#BABABA",
                }}
              >
                {toStringByFormatting()}
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: isSmall ? 12 : 9,
              }}
            >
              <PretendardText
                style={{
                  fontWeight: "400",
                  fontSize: isSmall ? "15px" : "16px",
                  lineHeight: "24px",
                  color: "#BABABA",
                }}
              >
                결제 주기
              </PretendardText>
              <PretendardText
                style={{
                  fontWeight: "400",
                  fontSize: isSmall ? "15px" : "16px",
                  lineHeight: "24px",
                  color: "#BABABA",
                }}
              >
                {paymentType === "월간 결제" ? "1개월" : "1년"}
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontWeight: "400",
                  fontSize: isSmall ? "15px" : "16px",
                  lineHeight: "24px",
                  color: "#BABABA",
                }}
              >
                결제 금액
              </PretendardText>
              <PretendardText
                style={{
                  fontWeight: "400",
                  fontSize: isSmall ? "15px" : "16px",
                  lineHeight: "24px",
                  color: "#BABABA",
                }}
              >
                {paymentType === "월간 결제"
                  ? Number(infoData.monthlyFee).toLocaleString("ko-KR")
                  : Number(infoData.yearlyFee).toLocaleString("ko-KR")}
                원
              </PretendardText>
            </div>
          </div>

          <div
            style={{
              borderTop: "1px solid #DFDFDF",
              width: isSmall ? "100%" : 1170,
              display: "flex",
              flexDirection: isSmall ? "column" : "row",
              justifyContent: "space-between",
              paddingTop: isSmall ? 20 : 15,
              marginTop: isSmall ? 30 : 50,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                src="/assets/icons/security.png"
                style={{
                  height: 25,
                  width: 25,
                  marginRight: 5,
                }}
              />
              <PretendardText
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#fff",
                }}
              >
                거래는 암호화되어 안전히 보호됩니다.
              </PretendardText>
            </div>
            <PretendardText
              style={{
                fontWeight: "600",
                fontSize: "24px",
                lineHeight: "36px",
                color: "#fff",
                marginTop: isSmall ? 20 : 0,
                textAlign: "center",
              }}
            >
              Total :{" "}
              {paymentType === "월간 결제"
                ? Number(infoData.monthlyFee).toLocaleString("ko-KR")
                : Number(infoData.yearlyFee).toLocaleString("ko-KR")}
              원 / {paymentType === "월간 결제" ? "월" : "연"}
            </PretendardText>
          </div>

          <PrimaryBtn
            // disabled={btnDisabled}
            style={{
              marginTop: isSmall ? 40 : 80,
              width: isSmall ? 146 : "160px",
              height: isSmall ? 48 : "54px",
              fontSize: isSmall ? "16px" : "20px",
              lineHeight: isSmall ? "24px" : "30px",
            }}
            onClick={() => {
              payFunc();
            }}
            title={"결제하기"}
          />
        </div>

        {isTermModal ? (
          <TermModal
            setIsModal={setIsTermModal}
            title={termTitle}
            content={termContent}
          />
        ) : null}

        <Footer />
        {isLoading ? <Loading /> : ""}
      </Container>
    </>
  );
}

export default Charge;
