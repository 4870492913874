import React from "react";
import PretendardText from "../../components/atoms/pretendardText";
import { errorHandler } from "../../utils/error-handler";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { route } from "../../routes/route";
import Pagination from "../../components/organisms/pagination";
import useDisplay from "../../hooks/useDisplay";
import SearchInput from "../../components/atoms/input/searchInput";
import Blur from "../../components/atoms/blur/blur";
import styled from "styled-components";
import MuktaText from "../../components/atoms/muktaText";
import FaqApi from "./../../api/faqApi";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import Footer from "../../components/organisms/footer";

function Faq() {
  const history = useHistory();
  const location = useLocation();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [isOpen, setIsOpen] = useState(-1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //페이지네이션
  const { page } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 10, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState([]);
  const [faqCategoryId, setFaqCategoryId] = useState("");

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setFaqCategoryId(
      searchParams.get("category") ? Number(searchParams.get("category")) : ""
    );
    getList();
  }, [location]);

  const getList = async () => {
    try {
      const data = {
        page: page - 1,
        size: pagination.size,
        keyword: searchParams.get("keyword"),
        faqCategoryId: searchParams.get("category"),
      };

      let list = (await FaqApi.GetFaqWithoutMatching(data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const [faqCategoryList, setfaqCategoryList] = useState([]);
  const getFaqCategoryList = async () => {
    try {
      let list = (await FaqApi.getFaqCategories()).data.data;

      const temp = {
        id: "",
        title: "전체",
      };

      setfaqCategoryList([temp, ...list.filter((item) => item.id !== 1)]);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getFaqCategoryList();
  }, []);

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}

        <div
          style={{
            width: "100%",
            padding: `${
              isMobile ? "95px 0 95px 0" : "280px 19.53% 200px 19.53%"
            }`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          {!isMobile ? (
            <Blur
              style={{
                width: "1342px",
                height: "454px",
                borderRadius: "1342px",
                background: "rgba(0, 237, 194, 0.15)",
                filter: "blur(150px)",
                top: "-255px",
                left: "50%",
                transform: "translate(-50%,0)",
              }}
            />
          ) : (
            ""
          )}
          <div
            style={{
              width: "100%",
              padding: `${isMobile ? "0" : "unset"}`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: `${isMobile ? "30px" : "60px"}`,
            }}
          >
            <PretendardText
              style={{
                color: "#00E4BB",
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "14px" : "16px"}`,
                lineHeight: `${isMobile ? "21px" : "24px"}`,
                marginBottom: `${isMobile ? "0" : "13px"}`,
                textAlign: "center",
              }}
            >
              FAQ
            </PretendardText>
            <PretendardText
              style={{
                color: "#fff",
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "24px" : "36px"}`,
                lineHeight: `${isMobile ? "36px" : "54px"}`,
                marginBottom: "40px",
                textAlign: "center",
              }}
            >
              자주 묻는 질문
            </PretendardText>
            <>
              <div
                style={{
                  width: `${isMobile ? "100%" : "unset"}`,
                  padding: `${isMobile ? "0 5.33%" : "unset"}`,
                }}
              >
                <SearchInput
                  style={{
                    width: `${isMobile ? "100%" : "unset"}`,
                    marginBottom: "30px",
                  }}
                  value={keyword}
                  placeholder={"검색어를 입력해 주세요."}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  onClick={() => {
                    history.replace(
                      `${route.faq}/1?keyword=${keyword}&category=${faqCategoryId}`
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      history.replace(
                        `${route.faq}/1?keyword=${keyword}&category=${faqCategoryId}`
                      );
                    }
                  }}
                  onBlur={() => {
                    setKeyword(keyword.trim());
                  }}
                />
              </div>
              <FaqFilterWrapper
                style={{
                  width: `${isMobile ? "100vw" : "100%"}`,
                  whiteSpace: `${isMobile ? "nowrap" : "unset"}`,
                  paddingLeft: `${isMobile ? "5.33%" : "unset"}`,
                  justifyContent: `${isMobile ? "unset" : "center"}`,
                }}
              >
                {faqCategoryList.map((item, index) => {
                  return (
                    <FaqFilter
                      key={index}
                      style={{
                        backgroundColor: `${
                          faqCategoryId === item.id ? "#00E4BB" : "#363636"
                        }`,
                      }}
                      onClick={() =>
                        faqCategoryId === item.id
                          ? history.replace(
                              `${route.faq}/1?keyword=${keyword}&category=`
                            )
                          : history.replace(
                              `${route.faq}/1?keyword=${keyword}&category=${item.id}`
                            )
                      }
                    >
                      <PretendardText
                        style={{
                          color: `${
                            faqCategoryId === item.id ? "#1E1E1E" : "#fff"
                          }`,
                          fontWeight: "400",
                          fontSize: `${isMobile ? "14px" : "16px"}`,
                          lineHeight: `${isMobile ? "21px" : "24px"}`,
                        }}
                      >
                        {item.title}
                      </PretendardText>
                    </FaqFilter>
                  );
                })}
                {/* <FaqFilter
                  style={{ backgroundColor: `${faqCategoryId === 1 ? "#00E4BB" : "#363636"}` }}
                  onClick={() => (faqCategoryId === 1 ? setFaqCategoryId(null) : setFaqCategoryId(1))}
                >
                  <PretendardText
                    style={{
                      color: `${faqCategoryId === 1 ? "#1E1E1E" : "#fff"}`,
                      fontWeight: "400",
                      fontSize: `${isMobile ? "14px" : "16px"}`,
                      lineHeight: `${isMobile ? "21px" : "24px"}`,
                    }}
                  >
                    라이선스 및 저작권
                  </PretendardText>
                </FaqFilter>
                <FaqFilter
                  style={{ backgroundColor: `${faqCategoryId === 2 ? "#00E4BB" : "#363636"}` }}
                  onClick={() => (faqCategoryId === 2 ? setFaqCategoryId(null) : setFaqCategoryId(2))}
                >
                  <PretendardText
                    style={{
                      color: `${faqCategoryId === 2 ? "#1E1E1E" : "#fff"}`,
                      fontWeight: "400",
                      fontSize: `${isMobile ? "14px" : "16px"}`,
                      lineHeight: `${isMobile ? "21px" : "24px"}`,
                    }}
                  >
                    요금제
                  </PretendardText>
                </FaqFilter>
                <FaqFilter
                  style={{ backgroundColor: `${faqCategoryId === 3 ? "#00E4BB" : "#363636"}` }}
                  onClick={() => (faqCategoryId === 3 ? setFaqCategoryId(null) : setFaqCategoryId(3))}
                >
                  <PretendardText
                    style={{
                      color: `${faqCategoryId === 3 ? "#1E1E1E" : "#fff"}`,
                      fontWeight: "400",
                      fontSize: `${isMobile ? "14px" : "16px"}`,
                      lineHeight: `${isMobile ? "21px" : "24px"}`,
                    }}
                  >
                    구독
                  </PretendardText>
                </FaqFilter>
                <FaqFilter
                  style={{ backgroundColor: `${faqCategoryId === 4 ? "#00E4BB" : "#363636"}` }}
                  onClick={() => (faqCategoryId === 4 ? setFaqCategoryId(null) : setFaqCategoryId(4))}
                >
                  <PretendardText
                    style={{
                      color: `${faqCategoryId === 4 ? "#1E1E1E" : "#fff"}`,
                      fontWeight: "400",
                      fontSize: `${isMobile ? "14px" : "16px"}`,
                      lineHeight: `${isMobile ? "21px" : "24px"}`,
                    }}
                  >
                    영상
                  </PretendardText>
                </FaqFilter> */}
              </FaqFilterWrapper>
            </>
            {list.length > 0 ? null : (
              <PretendardText
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  color: "#939393",
                  marginTop: "120px",
                }}
              >
                등록된 내용이 없습니다.
              </PretendardText>
            )}
          </div>

          {list.length > 0 ? (
            <div
              style={{
                width: "100%",
                borderTop: "2px solid #565656",
              }}
            >
              {list.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      style={{
                        width: "100%",
                        height: "70px",
                        borderBottom: "1px solid #565656",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "0 20px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        isOpen === index ? setIsOpen(-1) : setIsOpen(index)
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <MuktaText
                          style={{
                            color: "#fff",
                            fontWeight: "700",
                            fontSize: "18px",
                            lineHeight: "28.8px",
                          }}
                        >
                          Q
                        </MuktaText>
                        {!isMobile ? (
                          <PretendardText
                            style={{
                              width: "118px",
                              color: "#00E4BB",
                              fontWeight: "700",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            {item.faqCategoryDto?.title}
                          </PretendardText>
                        ) : (
                          ""
                        )}
                        <PretendardText
                          style={{
                            width: `${isMobile ? "250px" : "895px"}`,
                            color: "#fff",
                            fontWeight: `${isMobile ? "600" : "700"}`,
                            fontSize: `${isMobile ? "16px" : "18px"}`,
                            lineHeight: `${isMobile ? "24px" : "28.8px"}`,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            wordBreak: "break-all",
                          }}
                        >
                          {item.title}
                        </PretendardText>
                      </div>
                      <div>
                        <img
                          src="/assets/icons/icon_arrow.svg"
                          style={{
                            transform: `${
                              isOpen === index ? "rotate(-180deg)" : ""
                            }`,
                          }}
                        />
                      </div>
                    </div>
                    {isOpen === index ? (
                      <div
                        style={{
                          minHeight: `${isMobile ? "58px" : "80px"}`,
                          borderBottom: "1px solid #565656",
                          background: "rgba(50, 50, 50, 0.70)",
                          padding: `${isMobile ? "16px 20px" : "25px 22px"}`,
                          display: "flex",
                          alignItems: "flex-start",
                          gap: `${isMobile ? "21px" : "31px"}`,
                        }}
                      >
                        <MuktaText
                          style={{
                            color: "#00E4BB",
                            fontWeight: "700",
                            fontSize: `${isMobile ? "16px" : "18px"}`,
                            lineHeight: `${isMobile ? "25.6px" : "28.8px"}`,
                          }}
                        >
                          A
                        </MuktaText>
                        <PretendardText
                          style={{
                            width: `${isMobile ? "80%" : "1083px"}`,
                            color: "#FFF",
                            fontWeight: "400",
                            fontSize: `${isMobile ? "14px" : "16px"}`,
                            lineHeight: "24px",
                          }}
                        >
                          {item.content}
                        </PretendardText>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}

              <Pagination
                style={{ marginTop: "43px" }}
                route={route.faq}
                queryString={`keyword=${keyword}&category=${faqCategoryId}`}
                currentPage={page}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
                first={pagination.first}
                last={pagination.last}
              />
            </div>
          ) : null}
        </div>

        <Footer />
      </Container>
    </>
  );
}

export default Faq;

const FaqFilterWrapper = styled.div.attrs((props) => {})`
  display: flex;
  justify-content: center;
  gap: 12px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const FaqFilter = styled.div.attrs((props) => {})`
  display: flex;
  height: 33px;
  align-items: center;
  justify-content: center;
  padding: 6px 25px;
  gap: 10px;
  border-radius: 50px;
  background: #363636;
  cursor: pointer;
`;
