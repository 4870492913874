import api from "./api.js";

export default class FaqApi {
  //faq
  static async GetFaq(data) {
    return await api.get(`faqs`, data);
  }
  static async GetFaqWithoutMatching(data) {
    return await api.get(`faqs/without_matching`, data);
  }
  static async getFaqCategories() {
    return await api.get(`faqCategories`);
  }
}
