import React from "react";
import styled from "styled-components";

function TextArea({
  type,
  placeholder,
  value,
  onChange,
  disabled,
  style,
  maxLength,
}) {
  return (
    <>
      <TextAreaWrapper
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          if (e.target.value.length > maxLength) {
            e.target.value = e.target.value.slice(0, maxLength);
          }
          onChange(e);
        }}
        style={{
          ...style,
        }}
      />
    </>
  );
}

export default TextArea;

const TextAreaWrapper = styled.textarea.attrs((props) => {})`
  width: 100%;
  height: 252px;
  padding: 13px 20px;
  border: 1px solid #939393;
  border-radius: 5px;
  background: transparent;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #fff;
  letter-spacing: -0.048px;
  resize: none;

  &::-webkit-scrollbar {
    width: 6px;
    cursor: pointer !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #939393; /* 스크롤바의 색상 */
    border-radius: 20px;
    background-clip: padding-box;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-track {
    background: #565656; /*스크롤바 뒷 배경 색상*/
  }

  &::placeholder {
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #adb4ba;
    letter-spacing: -0.048px;
  }

  &:focus {
    outline: none;
  }
`;
