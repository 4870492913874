import React from "react";
import PretendardText from "../../components/atoms/pretendardText";
import { errorHandler } from "../../utils/error-handler";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { route } from "../../routes/route";
import Pagination from "../../components/organisms/pagination";
import useDisplay from "../../hooks/useDisplay";
import SearchInput from "../../components/atoms/input/searchInput";
import Blur from "../../components/atoms/blur/blur";
import NoticeApi from "./../../api/noticeApi";
import moment from "moment";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import Footer from "../../components/organisms/footer";

function Notice() {
  const history = useHistory();
  const location = useLocation();
  const { isMobile, isTablet, isDesktop } = useDisplay();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //페이지네이션
  const { page } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 10, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getList();
  }, [location]);

  const getList = async () => {
    try {
      const data = {
        page: page - 1,
        size: pagination.size,
        keyword: searchParams.get("keyword"),
      };

      let list = (await NoticeApi.GetNotice(data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };
  const [list, setList] = useState([]);

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}

        <div
          style={{
            width: "100%",
            padding: `${isMobile ? "95px 0" : "280px 19.53% 200px 19.53%"}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          {!isMobile ? (
            <Blur
              style={{
                width: "1342px",
                height: "454px",
                borderRadius: "1342px",
                background: "rgba(0, 237, 194, 0.15)",
                filter: "blur(150px)",
                top: "-255px",
                left: "50%",
                transform: "translate(-50%,0)",
              }}
            />
          ) : (
            ""
          )}
          <div
            style={{
              width: "100%",
              padding: `${isMobile ? "0 5.33%" : "unset"}`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: `${isMobile ? "30px" : "105px"}`,
            }}
          >
            <PretendardText
              style={{
                color: "#00E4BB",
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "14px" : "16px"}`,
                lineHeight: `${isMobile ? "21px" : "24px"}`,
                marginBottom: `${isMobile ? "0" : "13px"}`,
                textAlign: "center",
              }}
            >
              Notice
            </PretendardText>
            <PretendardText
              style={{
                color: "#fff",
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "24px" : "36px"}`,
                lineHeight: `${isMobile ? "36px" : "54px"}`,
                marginBottom: "40px",
                textAlign: "center",
              }}
            >
              공지사항
            </PretendardText>
            <SearchInput
              value={keyword}
              style={{ width: `${isMobile ? "100%" : "unset"}` }}
              placeholder={"검색어를 입력해 주세요."}
              onChange={(e) => setKeyword(e.target.value)}
              onClick={() => {
                history.replace(`${route.notice}/1?keyword=${keyword}`);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  history.replace(`${route.notice}/1?keyword=${keyword}`);
                }
              }}
              onBlur={() => {
                setKeyword(keyword.trim());
              }}
            />
          </div>
          <div
            style={{
              marginTop: "30px",
              width: "100%",
              // maxHeight: `${isMobile ? "595px" : "704px"}`,
            }}
          >
            {!isMobile ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 30px",
                  gap: "10px",
                  borderTop: "2px solid #565656",
                  borderBottom: "1px solid #565656",
                  height: "64px",
                }}
              >
                <PretendardText
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#FFFFFF",
                    width: "74px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  번호
                </PretendardText>
                <PretendardText
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#FFFFFF",
                    width: "848px",
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  제목
                </PretendardText>
                <PretendardText
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#FFFFFF",
                    width: "168px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  날짜
                </PretendardText>
              </div>
            ) : (
              ""
            )}
            {isMobile ? (
              <div style={{ borderTop: "6px solid #1E1E1E" }}></div>
            ) : (
              ""
            )}
            {list.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: `${isMobile ? "column" : "row"}`,
                    // alignItems: `${isMobile ? "left" : "center"}`,
                    padding: `${isMobile ? "15px 20px" : "15px 30px"}`,
                    gap: "10px",
                    borderBottom: "1px solid #565656",
                    height: `${isMobile ? "85px" : "67px"}`,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    history.push(route.notice_detail + `/${item.id}`)
                  }
                >
                  {!isMobile ? (
                    <PretendardText
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#BABABA",
                        width: "74px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {pagination.totalCount - (index + (page - 1) * 10)}
                    </PretendardText>
                  ) : (
                    ""
                  )}
                  <PretendardText
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: `${isMobile ? "#f1f1f1" : "#BABABA"}`,
                      width: `${isMobile ? "90vw" : "848px"}`,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      wordBreak: "break-all",
                    }}
                  >
                    {item.title}
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontWeight: "400",
                      fontSize: `${isMobile ? "14px" : "16px"}`,
                      lineHeight: `${isMobile ? "21px" : "24px"}`,
                      color: `${isMobile ? "#565656" : "#BABABA"}`,
                      width: `${isMobile ? "100%" : "168px"}`,
                      textAlign: `${isMobile ? "left" : "center"}`,
                    }}
                  >
                    {moment(item.createdAt).format("YYYY-MM-DD")}
                  </PretendardText>
                </div>
              );
            })}

            <Pagination
              style={{ marginTop: "80px" }}
              route={route.notice}
              queryString={`keyword=${keyword}`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </div>
        </div>

        <Footer />
      </Container>
    </>
  );
}

export default Notice;
