import React, { useMemo, useState } from "react";
import PretendardText from "../../components/atoms/pretendardText";
import TitleLabel from "../../components/atoms/text/titleLabel";
import TextInput from "../../components/atoms/input/textInput";
import SelectBox from "../../components/atoms/select/selectBox";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import { useEffect } from "react";
import useDisplay from "../../hooks/useDisplay";
import Footer from "../../components/organisms/footer";
import NavM from "../../components/organisms/navM";
import useModal from "../../hooks/useModal";
import { useRecoilValue } from "recoil";
import {
  menu01StateAtom,
  menu02StateAtom,
  menu03StateAtom,
  menu04StateAtom,
  menu05StateAtom,
  menu06StateAtom,
} from "../../recoil/menuState";
import { toast } from "react-toastify";
import SelectBox2 from "../../components/atoms/select/selectBox2";
import { nullCheck } from "../../utils/check";
import SelectFieldBox from "../../components/templates/fieldModal/selectFieldBox";
import { errorHandler } from "../../utils/error-handler";
import UserApi from "../../api/user";

function FieldModal() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const modal = useModal();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.getElementById("container").style.cssText = `
          height: 1456px;          
          overflow: hidden;
          background: #151515;
          `;
    return () => {
      document.getElementById("container").style.cssText = `
      min-height: 100vh;
      overflow: auto;
      background: #151515;
      `;
    };
  }, []);

  const menu01 = useRecoilValue(menu01StateAtom);
  const menu02 = useRecoilValue(menu02StateAtom);
  const menu03 = useRecoilValue(menu03StateAtom);
  const menu04 = useRecoilValue(menu04StateAtom);
  const menu05 = useRecoilValue(menu05StateAtom);
  const menu06 = useRecoilValue(menu06StateAtom);

  const [openIndex, setOpenIndex] = useState(0);

  useEffect(() => {
    setFieldList1(
      sessionStorage.getItem("fieldList1")
        ? JSON.parse(sessionStorage.getItem("fieldList1"))
        : []
    );
    setFieldList2(
      sessionStorage.getItem("fieldList2")
        ? JSON.parse(sessionStorage.getItem("fieldList2"))
        : []
    );
    setFieldList3(
      sessionStorage.getItem("fieldList3")
        ? JSON.parse(sessionStorage.getItem("fieldList3"))
        : []
    );
    setFieldList4(
      sessionStorage.getItem("fieldList4")
        ? JSON.parse(sessionStorage.getItem("fieldList4"))
        : []
    );
    setFieldList5(
      sessionStorage.getItem("fieldList5")
        ? JSON.parse(sessionStorage.getItem("fieldList5"))
        : []
    );
    setFieldList6(
      sessionStorage.getItem("fieldList6")
        ? JSON.parse(sessionStorage.getItem("fieldList6"))
        : []
    );
  }, []);

  const [fieldList1, setFieldList1] = useState([]);
  const [fieldList2, setFieldList2] = useState([]);
  const [fieldList3, setFieldList3] = useState([]);
  const [fieldList4, setFieldList4] = useState([]);
  const [fieldList5, setFieldList5] = useState([]);
  const [fieldList6, setFieldList6] = useState([]);

  const btnFlag = useMemo(() => {
    return (
      // fieldList1.length <= 0 ||
      fieldList2.length <= 0 ||
      fieldList3.length <= 0 ||
      // fieldList4.length <= 0 ||
      fieldList5.length <= 0
      // fieldList6.length <= 0
    );
  }, [fieldList2, fieldList3, fieldList5]);

  const settingFunc = async () => {
    try {
      const id = await joinFunc();
      if (id) {
        await fieldSettingFunc(id);
        modal.close();
        toast("회원가입이 완료되었습니다.");
        sessionStorage.clear();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    const joinData = JSON.parse(sessionStorage.getItem("joinData"));
    if (!nullCheck(joinData)) {
      setJoinData({ ...joinData });
    } else {
      modal.open("join");
    }
  }, []);

  const [joinData, setJoinData] = useState({
    name: "",
    email: "",
    password: "",
    passwordCheck: "",
    phoneNumber: "",
    recommender: "",
    agree1: false,
    agree2: false,
    agreeToNews: false,
    joinType: "NORMAL",
    socialToken: "",
  });

  const joinFunc = async () => {
    try {
      const data = {
        name: joinData.name,
        email: joinData.email,
        password: joinData.password,
        phoneNumber: joinData.phoneNumber,
        recommender: joinData.recommender,
        agreeToNews: joinData.agreeToNews,
        joinType: joinData.joinType,
        socialToken: joinData.socialToken,
      };
      const response = await UserApi.Join(data);
      return response.data.data;
    } catch (error) {
      if (error.response.status === 409) {
        sessionStorage.setItem("fieldList1", JSON.stringify(fieldList1));
        sessionStorage.setItem("fieldList2", JSON.stringify(fieldList2));
        sessionStorage.setItem("fieldList3", JSON.stringify(fieldList3));
        sessionStorage.setItem("fieldList4", JSON.stringify(fieldList4));
        sessionStorage.setItem("fieldList5", JSON.stringify(fieldList5));
        sessionStorage.setItem("fieldList6", JSON.stringify(fieldList6));
        modal.open("join");
        toast("이미 등록된 이메일입니다.");
        return null;
      }
      errorHandler(error);
    }
  };

  const fieldSettingFunc = async (id) => {
    try {
      const values1 = fieldList1.map((item) => item.value);
      const values2 = fieldList2.map((item) => item.value);
      const values3 = fieldList3.map((item) => item.value);
      const values4 = fieldList4.map((item) => item.value);
      const values5 = fieldList5.map((item) => item.value);
      const values6 = fieldList6.map((item) => item.value);

      const data = {
        filterIdList: [
          ...values1,
          ...values2,
          ...values3,
          ...values4,
          ...values5,
          ...values6,
        ],
      };
      await UserApi.setFilter(id, data);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      {isMobile ? (
        <div>
          <div
            style={{
              background: "#151515",
              width: "100vw",
              minHeight: "1456px",
              zIndex: 10000,
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
              overflow: "auto",
            }}
          >
            <NavM />

            <div
              style={{
                minHeight: "1037px",
                width: "100%",
                padding: "0px 20px",
                marginBottom: "70px",
              }}
            >
              <div
                style={{
                  marginTop: "100px",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  관심 분야 선택
                </PretendardText>

                <PretendardText
                  style={{
                    color: "#BABABA",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    marginTop: "10px",
                  }}
                >
                  선택한 관심분야 위주의 <br /> 영상을 추천받을 수 있습니다.
                </PretendardText>
              </div>

              <SelectFieldBox
                menu={menu01}
                fieldList={fieldList1}
                setFieldList={setFieldList1}
                isOpen={openIndex === 1}
                closeFunc={() => {
                  setOpenIndex(0);
                }}
                onClick={() => {
                  if (openIndex === 1) {
                    setOpenIndex(0);
                  } else {
                    setOpenIndex(1);
                  }
                }}
                limitCount={4}
              />

              <SelectFieldBox
                menu={menu02}
                fieldList={fieldList2}
                setFieldList={setFieldList2}
                isOpen={openIndex === 2}
                closeFunc={() => {
                  setOpenIndex(0);
                }}
                onClick={() => {
                  if (openIndex === 2) {
                    setOpenIndex(0);
                  } else {
                    setOpenIndex(2);
                  }
                }}
                limitCount={4}
              />
              <SelectFieldBox
                menu={menu03}
                fieldList={fieldList3}
                setFieldList={setFieldList3}
                isOpen={openIndex === 3}
                closeFunc={() => {
                  setOpenIndex(0);
                }}
                onClick={() => {
                  if (openIndex === 3) {
                    setOpenIndex(0);
                  } else {
                    setOpenIndex(3);
                  }
                }}
                limitCount={4}
              />
              <SelectFieldBox
                menu={menu04}
                fieldList={fieldList4}
                setFieldList={setFieldList4}
                isOpen={openIndex === 4}
                closeFunc={() => {
                  setOpenIndex(0);
                }}
                onClick={() => {
                  if (openIndex === 4) {
                    setOpenIndex(0);
                  } else {
                    setOpenIndex(4);
                  }
                }}
                limitCount={2}
              />
              <SelectFieldBox
                menu={menu05}
                fieldList={fieldList5}
                setFieldList={setFieldList5}
                isOpen={openIndex === 5}
                closeFunc={() => {
                  setOpenIndex(0);
                }}
                onClick={() => {
                  if (openIndex === 5) {
                    setOpenIndex(0);
                  } else {
                    setOpenIndex(5);
                  }
                }}
                limitCount={2}
              />
              <SelectFieldBox
                menu={menu06}
                fieldList={fieldList6}
                setFieldList={setFieldList6}
                isOpen={openIndex === 6}
                closeFunc={() => {
                  setOpenIndex(0);
                }}
                onClick={() => {
                  if (openIndex === 6) {
                    setOpenIndex(0);
                  } else {
                    setOpenIndex(6);
                  }
                }}
                limitCount={2}
              />

              <PrimaryBtn
                title={"설정 완료"}
                disabled={btnFlag}
                style={{
                  width: "100%",
                  marginTop: "44px",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
                onClick={() => {
                  settingFunc();
                }}
              />
            </div>

            <Footer />
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              background: "rgba(30, 30, 30, 0.50)",
              backdropFilter: "blur(32.5px)",
              width: "100vw",
              height: "100vh",
              zIndex: 10000,
              display: "flex",
              position: "fixed",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              top: "65%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 10001,
            }}
          >
            <div
              style={{
                width: "520px",
                height: "100%",
                borderRadius: "15px",
                background: "#151515",
                boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.20)",
                padding: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  관심 분야 선택
                </PretendardText>
                {/* <img
                  src="/assets/icons/icon_close.svg"
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    toast("필수사항을 모두 입력해주세요");
                  }}
                /> */}
              </div>
              <PretendardText
                style={{
                  color: "#BABABA",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  marginTop: "20px",
                }}
              >
                선택한 관심분야 위주의 영상을 추천받을 수 있습니다.
              </PretendardText>

              {/* <SelectFieldBox
                menu={menu01}
                fieldList={fieldList1}
                setFieldList={setFieldList1}
                isOpen={openIndex === 1}
                closeFunc={() => {
                  setOpenIndex(0);
                }}
                onClick={() => {
                  if (openIndex === 1) {
                    setOpenIndex(0);
                  } else {
                    setOpenIndex(1);
                  }
                }}
                limitCount={4}
              /> */}

              <SelectFieldBox
                menu={menu02}
                fieldList={fieldList2}
                setFieldList={setFieldList2}
                isOpen={openIndex === 2}
                closeFunc={() => {
                  setOpenIndex(0);
                }}
                onClick={() => {
                  if (openIndex === 2) {
                    setOpenIndex(0);
                  } else {
                    setOpenIndex(2);
                  }
                }}
                limitCount={4}
              />
              <SelectFieldBox
                menu={menu03}
                fieldList={fieldList3}
                setFieldList={setFieldList3}
                isOpen={openIndex === 3}
                closeFunc={() => {
                  setOpenIndex(0);
                }}
                onClick={() => {
                  if (openIndex === 3) {
                    setOpenIndex(0);
                  } else {
                    setOpenIndex(3);
                  }
                }}
                limitCount={4}
              />
              {/* <SelectFieldBox
                menu={menu04}
                fieldList={fieldList4}
                setFieldList={setFieldList4}
                isOpen={openIndex === 4}
                closeFunc={() => {
                  setOpenIndex(0);
                }}
                onClick={() => {
                  if (openIndex === 4) {
                    setOpenIndex(0);
                  } else {
                    setOpenIndex(4);
                  }
                }}
                limitCount={2}
              /> */}
              <SelectFieldBox
                menu={menu05}
                fieldList={fieldList5}
                setFieldList={setFieldList5}
                isOpen={openIndex === 5}
                closeFunc={() => {
                  setOpenIndex(0);
                }}
                onClick={() => {
                  if (openIndex === 5) {
                    setOpenIndex(0);
                  } else {
                    setOpenIndex(5);
                  }
                }}
                limitCount={2}
              />
              {/* <SelectFieldBox
                menu={menu06}
                fieldList={fieldList6}
                setFieldList={setFieldList6}
                isOpen={openIndex === 6}
                closeFunc={() => {
                  setOpenIndex(0);
                }}
                onClick={() => {
                  if (openIndex === 6) {
                    setOpenIndex(0);
                  } else {
                    setOpenIndex(6);
                  }
                }}
                limitCount={2}
              /> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <PrimaryBtn
                  title={"설정 완료"}
                  disabled={btnFlag}
                  style={{
                    width: "182px",
                    marginTop: "40px",
                  }}
                  onClick={() => {
                    settingFunc();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FieldModal;
