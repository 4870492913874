import React, { useEffect, useState } from "react";
import PretendardText from "../atoms/pretendardText";
import { menu1 } from "../../utils/menu/menu1";
import { menu2 } from "../../utils/menu/menu2";
import { menu3 } from "../../utils/menu/menu3";
import { menu4 } from "../../utils/menu/menu4";
import { menu5 } from "../../utils/menu/menu5";
import { menu6 } from "../../utils/menu/menu6";
import styled from "styled-components";
import ScrollContainer from "react-indiana-drag-scroll";
import { useRecoilValue } from "recoil";
import {
  menu01StateAtom,
  menu02StateAtom,
  menu03StateAtom,
  menu04StateAtom,
  menu05StateAtom,
  menu06StateAtom,
} from "../../recoil/menuState";

function VideoFilterM({
  filterIdList,
  setFilterIdList,
  selectedMenuTemp,
  setSerchUrl,
}) {
  const menu01 = useRecoilValue(menu01StateAtom);
  const menu02 = useRecoilValue(menu02StateAtom);
  const menu03 = useRecoilValue(menu03StateAtom);
  const menu04 = useRecoilValue(menu04StateAtom);
  const menu05 = useRecoilValue(menu05StateAtom);
  const menu06 = useRecoilValue(menu06StateAtom);

  useEffect(() => {
    setMenus([menu01, menu02, menu03, menu04, menu05, menu06]);
  }, [menu01, menu02, menu03, menu04, menu05, menu06]);

  const [menus, setMenus] = useState([]);

  const [selectedMenu, setSelectedMenu] = useState({});
  const [selectedSubMenu, setSelectedSubMenu] = useState({});

  useEffect(() => {
    if (selectedMenuTemp) {
      setSelectedMenu(selectedMenuTemp);
    }
  }, [selectedMenuTemp]);

  return (
    <>
      <FilterContatiner
        style={{
          width: "100vw",
          overflow: "auto",
        }}
      >
        <ScrollContainer className="scroll-container">
          <FilterContent
            style={{
              height: "64px",
              gap: "38px",
              width: "max-content",
            }}
          >
            {menus.map((menu, index) => {
              return (
                <PretendardText
                  key={index}
                  style={{
                    color:
                      selectedMenu.value === menu.value ? "#00E4BB" : "#939393",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    cursor: "pointer",
                    minWidth: "28px",
                    height: "64px",
                    display: "flex",
                    alignItems: "center",
                    borderBottom:
                      selectedMenu.value === menu.value
                        ? "2px solid #00E4BB"
                        : "",
                  }}
                  onClick={() => {
                    if (selectedMenu.value === menu.value) {
                      setSelectedMenu({});
                    } else {
                      setSelectedMenu(menu);
                    }
                    setSelectedSubMenu({});
                  }}
                >
                  {menu.title}
                </PretendardText>
              );
            })}
          </FilterContent>
        </ScrollContainer>
      </FilterContatiner>

      <div
        style={{
          height: "1px",
          background: "#333333",
        }}
      ></div>

      <FilterContatiner
        style={{
          width: "100vw",
          overflow: "auto",
        }}
      >
        <ScrollContainer className="scroll-container">
          <FilterContent
            style={{
              height: "67px",
              gap: "10px",
              width: "max-content",
            }}
          >
            {selectedMenu.subMenus?.map((subMenu, index) => {
              return (
                <PretendardText
                  key={index}
                  style={{
                    height: "31px",
                    borderRadius: "100px",
                    padding: "0px 15px",
                    fontSize: "14px",
                    fontWeight: filterIdList?.includes(subMenu.value)
                      ? "600"
                      : "400",
                    lineHeight: "21px",
                    background: filterIdList?.includes(subMenu.value)
                      ? "#00E4BB"
                      : "",
                    display: "flex",
                    alignItems: "center",
                    color: filterIdList?.includes(subMenu.value)
                      ? "#151515"
                      : "#939393",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (filterIdList?.includes(subMenu.value)) {
                      filterIdList = filterIdList.filter(
                        (item) => item !== subMenu.value
                      );
                    } else {
                      filterIdList.push(subMenu.value);
                    }
                    setSerchUrl(2, filterIdList.join());
                  }}
                >
                  {subMenu.title}
                </PretendardText>
              );
            })}
          </FilterContent>
        </ScrollContainer>
      </FilterContatiner>

      <div
        style={{
          height: "4px",
          background: "#333333",
        }}
      ></div>
    </>
  );
}

export default VideoFilterM;

const FilterContatiner = styled.div.attrs((props) => {})`
  /* 스크롤바 설정*/
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FilterContent = styled.div.attrs((props) => {})`
  display: flex;
  gap: 38px;
  align-items: center;
  width: 400px;
  padding: 0 20px;
`;
