import React, { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { route } from "../../routes/route";

import useDisplay from "../../hooks/useDisplay";
import PretendardText from "../../components/atoms/pretendardText";
import TermsApi from "../../api/terms";
import { ko } from "../../utils/ko";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import Footer from "../../components/organisms/footer";

function Privacy() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;
  const history = useHistory();
  const [dataInfo, setDataInfo] = useState({
    title: "",
    content: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getTermInfo();
  }, []);

  const getTermInfo = async () => {
    try {
      const dataTemp = (await TermsApi.getTermsByTitle({ keyword: "개인정보처리방침" })).data.data;

      setDataInfo(dataTemp);
    } catch (error) {
      toast(ko.errorText_500);
    }
  };

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "#151515",
            width: "100%",
            position: "relative",
            padding: isSmall ? "95px 20px 70px 20px" : "280px 0px 200px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: isSmall ? "100%" : 1170,
            }}
          >
            <PretendardText
              style={{
                fontWeight: isSmall ? "600" : "700",
                fontSize: isSmall ? "14px" : "16px",
                lineHeight: isSmall ? "21px" : "24px",
                color: "#00E4BB",
                marginBottom: isSmall ? 5 : 15,
                textAlign: isSmall ? "center" : "left",
              }}
            >
              Privacy Notice
            </PretendardText>
            <PretendardText
              style={{
                fontWeight: isSmall ? "600" : "700",
                fontSize: isSmall ? "24px" : "36px",
                lineHeight: isSmall ? "36px" : "54px",
                color: "#FFFFFF",
                textAlign: isSmall ? "center" : "left",
                marginBottom: isSmall ? 40 : 60,
              }}
            >
              {dataInfo.title}
            </PretendardText>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: isSmall ? "column" : "row",
              width: isSmall ? "100%" : 1170,
              background: "#1E1E1E",
              borderRadius: 10,
              padding: isSmall ? 16 : 30,
            }}
          >
            <PretendardText
              style={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#DFDFDF",
                whiteSpace: "pre-wrap",
              }}
            >
              {dataInfo.content}
            </PretendardText>
          </div>
        </div>

        <Footer />
      </Container>
    </>
  );
}

export default Privacy;
