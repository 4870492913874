import api, { _axios } from "./api.js";

export default class VideoApi {
  static async getVideos(params) {
    return await api.get(`video`, params);
  }
  static async getVideosWithoutById(id, params) {
    return await api.get(`video/without/${id}`, params);
  }
  static async getVideoById(id, params) {
    return await api.get(`video/${id}`, params);
  }

  static async getDownloads(params) {
    return await api.get(`user/video/downloaded`, params);
  }
  static async postDownload(id, data) {
    return await api.post(`user/video/download/${id}`, data);
  }

  static async storeVideo(id, data) {
    return await api.post(`user/video/store/${id}`, data);
  }
  static async unStoreVideo(id, data) {
    return await api.post(`user/video/unstore/${id}`, data);
  }
  static async getStoredVideos(params) {
    return await api.get(`user/video/stored`, params);
  }

  static async getVideoFilters(params) {
    return await api.get(`filters/simple`, params);
  }
}
