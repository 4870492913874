import React, { useEffect } from "react";
import PretendardText from "../../components/atoms/pretendardText";
import DownloadBtn from "../../components/atoms/btn/downloadBtn";
import { useState } from "react";
import MuktaText from "../../components/atoms/muktaText";
import WhiteBtn from "../../components/atoms/btn/whiteBtn";
import { useHistory, useParams } from "react-router-dom";
import { route } from "../../routes/route";
import useDisplay from "../../hooks/useDisplay";
import VideoCardM from "../../components/organisms/videoCardM";
import { errorHandler } from "../../utils/error-handler";
import VideoApi from "../../api/video";
import ReactPlayer from "react-player";
import moment from "moment";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import api from "../../api/api";
import Footer from "../../components/organisms/footer";
import { useMemo } from "react";
import ReactHlsPlayer from "react-hls-player";

function VideoDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { isMobile, isTablet, isDesktop } = useDisplay();

  const history = useHistory();
  const { id } = useParams();

  const [btnActive, setBtnActive] = useState(false);

  const [list, setList] = useState([]);

  const [data, setData] = useState({});
  // const [videoFileUrl, setVideoFileUrl] = useState(
  //   id ? `${api.getUrl()}/api/video/streaming?resolution=HD&videoId=${id}` : ""
  // );

  const videoFileUrl = useMemo(() => {
    // let resolution = "";
    // if (data.hdVideoItem) {
    //   resolution = "HD";
    // } else if (data.fhdVideoItem) {
    //   resolution = "FHD";
    // } else if (data.twoKiloVideoItem) {
    //   resolution = "TWO_KILO";
    // } else if (data.fourKiloVideoItem) {
    //   resolution = "FOUR_KILO";
    // } else if (data.logVideoItem) {
    //   resolution = "LOG";
    // }

    // return id
    //   ? `${api.getUrl()}/api/video/streaming?resolution=${resolution}&videoId=${id}`
    //   : "";

    let src = "";
    if (data.hdVideoItem) {
      src = data.hdVideoItem.fileUrl;
    } else if (data.fhdVideoItem) {
      src = data.fhdVideoItem.fileUrl;
    } else if (data.twoKiloVideoItem) {
      src = data.twoKiloVideoItem.fileUrl;
    } else if (data.fourKiloVideoItem) {
      src = data.fourKiloVideoItem.fileUrl;
    } else if (data.logVideoItem) {
      src = data.logVideoItem.fileUrl;
    }

    return id ? src : "";
  }, [id, data]);

  const getData = async () => {
    try {
      const response = await VideoApi.getVideoById(id);
      setData({ ...response.data.data });

      const moodList = response.data.data.moodList.map((item) => item.id);
      const locationList = response.data.data.locationList.map(
        (item) => item.id
      );
      const subjectList = response.data.data.subjectList.map((item) => item.id);
      const resolutionList = response.data.data.resolutionList.map(
        (item) => item.id
      );
      const technicList = response.data.data.technicList.map((item) => item.id);
      const speedList = response.data.data.speedList.map((item) => item.id);

      const filterIdList = [
        ...moodList,
        ...locationList,
        ...subjectList,
        ...resolutionList,
        ...technicList,
        ...speedList,
      ];
      setSelectedSubMenus([...filterIdList]);

      const params = {
        keyword: "",
        filterIdList: filterIdList.join(),
        orderType: "CREATED",
      };

      const response2 = await VideoApi.getVideosWithoutById(id, params);
      setList(
        response2.data.data.content.map((item) => {
          return {
            ...item,
          };
        })
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const storeFunc = async (storeFlag) => {
    try {
      if (storeFlag) {
        await VideoApi.storeVideo(data.id);
      } else {
        await VideoApi.unStoreVideo(data.id);
      }
      setData({ ...data, userStored: storeFlag });
    } catch (error) {
      errorHandler(error);
    }
  };

  const [selectedSubMenus, setSelectedSubMenus] = useState([]);
  const recommendFunc = () => {
    history.push({
      pathname: route.home,
      state: {
        activePage: 1,
        filterIdList: selectedSubMenus,
        isRecommend: true,
      },
    });
  };

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}

        {isMobile ? (
          <div
            style={{
              paddingTop: "55px",
              marginBottom: "70px",
              width: isMobile ? "100vw" : "",
            }}
          >
            <div style={{ position: "relative" }}>
              {videoFileUrl ? (
                <video
                  disablePictureInPicture
                  key={videoFileUrl}
                  preload="true"
                  controls={true}
                  playsInline
                  controlsList="nodownload"
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    height: "100%",
                  }}
                >
                  <source src={videoFileUrl} />
                </video>
              ) : null}

              <img
                src="/assets/icons/watermark.svg"
                alt=""
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </div>

            <div
              style={{
                padding: "0 20px",
                marginTop: "25px",
              }}
            >
              <PretendardText
                style={{
                  color: "#DFDFDF",
                  fontWeight: "700",
                  fontSize: "22px",
                  lineHeight: "35.2px",
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  display: "-webkit-box",
                }}
              >
                {data.title}
              </PretendardText>

              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "21px",
                    color: "#939393",
                  }}
                >
                  게시일: {moment(data.createdAt).format("YYYY년 M월 D일")}
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "21px",
                    color: "#939393",
                  }}
                >
                  촬영일자 ㅣ {moment(data.shootingDate).format("YYYY-MM-DD")}
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "21px",
                    color: "#939393",
                  }}
                >
                  촬영장소 ㅣ {data.shootingPlace}
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "21px",
                    color: "#939393",
                  }}
                >
                  제작자명 ㅣ {data.creator}
                </PretendardText>
              </div>

              <PretendardText
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  color: "#00E4BB",
                  textDecorationLine: "underline",
                  marginTop: "30px",
                  cursor: "pointer",
                  width: "130px",
                }}
                onClick={() => {
                  history.push(route.licenses);
                }}
              >
                콘텐츠 라이선스
              </PretendardText>

              <div
                style={{
                  marginTop: "26px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <PretendardText
                    style={{
                      color: "#F1F1F1",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                    }}
                  >
                    무드
                  </PretendardText>
                  {data.moodList?.map((item, index) => {
                    return (
                      <PretendardText
                        key={index}
                        style={{
                          background: "#1E1E1E",
                          height: "36px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#FFFFFF",
                          padding: "0px 20px",
                        }}
                      >
                        {item.title}
                      </PretendardText>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <PretendardText
                    style={{
                      color: "#F1F1F1",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                    }}
                  >
                    지역
                  </PretendardText>
                  {data.locationList?.map((item, index) => {
                    return (
                      <PretendardText
                        key={index}
                        style={{
                          background: "#1E1E1E",
                          height: "36px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#FFFFFF",
                          padding: "0px 20px",
                        }}
                      >
                        {item.title}
                      </PretendardText>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <PretendardText
                    style={{
                      color: "#F1F1F1",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                    }}
                  >
                    주제
                  </PretendardText>
                  {data.subjectList?.map((item, index) => {
                    return (
                      <PretendardText
                        key={index}
                        style={{
                          background: "#1E1E1E",
                          height: "36px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#FFFFFF",
                          padding: "0px 20px",
                        }}
                      >
                        {item.title}
                      </PretendardText>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <PretendardText
                    style={{
                      color: "#F1F1F1",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                    }}
                  >
                    해상도
                  </PretendardText>
                  {data.resolutionList?.map((item, index) => {
                    return (
                      <PretendardText
                        key={index}
                        style={{
                          background: "#1E1E1E",
                          height: "36px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#FFFFFF",
                          padding: "0px 20px",
                        }}
                      >
                        {item.title}
                      </PretendardText>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <PretendardText
                    style={{
                      color: "#F1F1F1",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                    }}
                  >
                    촬영기법
                  </PretendardText>
                  {data.technicList?.map((item, index) => {
                    return (
                      <PretendardText
                        key={index}
                        style={{
                          background: "#1E1E1E",
                          height: "36px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#FFFFFF",
                          padding: "0px 20px",
                        }}
                      >
                        {item.title}
                      </PretendardText>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <PretendardText
                    style={{
                      color: "#F1F1F1",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "24px",
                    }}
                  >
                    속도
                  </PretendardText>
                  {data.speedList?.map((item, index) => {
                    return (
                      <PretendardText
                        key={index}
                        style={{
                          background: "#1E1E1E",
                          height: "36px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#FFFFFF",
                          padding: "0px 20px",
                        }}
                      >
                        {item.title}
                      </PretendardText>
                    );
                  })}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "17px",
                  width: "100%",
                  marginTop: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "49px",
                    height: "49px",
                    borderRadius: "5px",
                    border: data.userStored
                      ? "1px solid #00E4BB"
                      : "1px solid #565656",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={
                      data.userStored
                        ? "/assets/icons/icon_like_active.svg"
                        : "/assets/icons/icon_like.svg"
                    }
                    alt=""
                    style={{
                      width: "37.78px",
                      height: "37.78px",
                    }}
                    onClick={() => {
                      storeFunc(!data.userStored);
                    }}
                  />
                </div>
                <DownloadBtn
                  style={{
                    width: "100%",
                  }}
                  active={btnActive}
                  onClick={() => {
                    setBtnActive(!btnActive);
                  }}
                  hdVideoItem={data.hdVideoItem}
                  fhdVideoItem={data.fhdVideoItem}
                  twoKiloVideoItem={data.twoKiloVideoItem}
                  fourKiloVideoItem={data.fourKiloVideoItem}
                  logVideoItem={data.logVideoItem}
                />
              </div>

              <div
                style={{
                  marginTop: "45px",
                  height: "1px",
                  width: "100%",
                  background: "#565656",
                }}
              ></div>

              <div
                style={{
                  marginTop: "35px",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "28.8px",
                  }}
                >
                  같은 테마의 최신 영상
                </PretendardText>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "30px",
                  }}
                >
                  {list.map((item, index) => {
                    return (
                      <VideoCardM key={index} item={item} useTemp={true} />
                    );
                  })}
                </div>
              </div>

              <div
                style={{
                  marginTop: "40px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <WhiteBtn
                  title={"모두보기"}
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={() => {
                    recommendFunc();
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              padding: "205px 375px 200px 375px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
              }}
            >
              <PretendardText
                style={{
                  color: "#DFDFDF",
                  fontWeight: "700",
                  fontSize: "22px",
                  lineHeight: "35.2px",
                  width: "770px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  display: "-webkit-box",
                  marginBottom: 2,
                }}
              >
                {data.title}
              </PretendardText>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "51px",
                    height: "51px",
                    borderRadius: "5px",
                    border: data.userStored
                      ? "1px solid #00E4BB"
                      : "1px solid #565656",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={
                      data.userStored
                        ? "/assets/icons/icon_like_active.svg"
                        : "/assets/icons/icon_like.svg"
                    }
                    alt=""
                    style={{
                      width: "37.78px",
                      height: "37.78px",
                    }}
                    onClick={() => {
                      storeFunc(!data.userStored);
                    }}
                  />
                </div>
                <DownloadBtn
                  active={btnActive}
                  onClick={() => {
                    setBtnActive(!btnActive);
                  }}
                  hdVideoItem={data.hdVideoItem}
                  fhdVideoItem={data.fhdVideoItem}
                  twoKiloVideoItem={data.twoKiloVideoItem}
                  fourKiloVideoItem={data.fourKiloVideoItem}
                  logVideoItem={data.logVideoItem}
                />
              </div>
            </div>

            <div
              style={{
                marginTop: "22px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    color: "#939393",
                  }}
                >
                  촬영일자 ㅣ {moment(data.shootingDate).format("YYYY-MM-DD")}
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    color: "#939393",
                  }}
                >
                  게시일: {moment(data.createdAt).format("YYYY년 M월 D일")}
                </PretendardText>
              </div>
              <PretendardText
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#939393",
                  marginTop: "8px",
                }}
              >
                촬영장소 ㅣ {data.shootingPlace}
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#939393",
                  marginTop: "8px",
                }}
              >
                제작자명 ㅣ {data.creator}
              </PretendardText>

              <div
                style={{
                  marginTop: "30px",
                  width: "1170px",
                  height: "658px",
                  position: "relative",
                  overflow: "hidden",
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
              >
                {videoFileUrl ? (
                  <video
                    disablePictureInPicture
                    key={videoFileUrl}
                    preload="true"
                    controls={true}
                    playsInline
                    controlsList="nodownload"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      height: "100%",
                    }}
                  >
                    <source src={videoFileUrl} />
                  </video>
                ) : // <ReactHlsPlayer
                //   src="https://theflick.cdn.ntruss.com/files/2aaed82f-6e04-4a97-9300-dc946581ba9b.MP4"
                //   hlsConfig={{
                //     maxLoadingDelay: 4,
                //     minAutoBitrate: 0,
                //     lowLatencyMode: true,
                //   }}
                // />
                null}

                <img
                  src="/assets/icons/detail_watermark.svg"
                  alt=""
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />

                {/* <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <img
                      src="/assets/icons/player/play.svg"
                      alt=""
                      style={{
                        cursor: "pointer",
                      }}
                    />
                    <div
                      style={{
                        width: "100%",
                        height: "35px",
                        background: "#1E1E1E",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        padding: "0 10px",
                      }}
                    >
                      <div
                        style={{
                          height: "5px",
                          width: "100%",
                          background: "#565656",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                      ></div>
                      <PretendardText
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "21px",
                          color: "#FFFFFF",
                          marginRight: "10px",
                        }}
                      >
                        00:00
                      </PretendardText>
                      <img
                        src="/assets/icons/player/sound.svg"
                        alt=""
                        style={{
                          marginRight: "7px",
                          cursor: "pointer",
                        }}
                      />
                      <img
                        src="/assets/icons/player/setting.svg"
                        alt=""
                        style={{
                          marginRight: "7px",
                          cursor: "pointer",
                        }}
                      />
                      <img
                        src="/assets/icons/player/full.svg"
                        alt=""
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                </div> */}
              </div>

              <div
                style={{
                  marginTop: "30px",
                }}
              >
                <PretendardText
                  style={{
                    color: "#00E4BB",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    cursor: "pointer",
                    width: "130px",
                  }}
                  onClick={() => {
                    history.push(route.licenses);
                  }}
                >
                  콘텐츠 라이선스
                </PretendardText>
                <div
                  style={{
                    marginTop: "26px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <PretendardText
                      style={{
                        color: "#F1F1F1",
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "24px",
                      }}
                    >
                      무드
                    </PretendardText>
                    {data.moodList?.map((item, index) => {
                      return (
                        <PretendardText
                          key={index}
                          style={{
                            background: "#1E1E1E",
                            height: "36px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#FFFFFF",
                            padding: "0px 20px",
                          }}
                        >
                          {item.title}
                        </PretendardText>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <PretendardText
                      style={{
                        color: "#F1F1F1",
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "24px",
                      }}
                    >
                      지역
                    </PretendardText>
                    {data.locationList?.map((item, index) => {
                      return (
                        <PretendardText
                          key={index}
                          style={{
                            background: "#1E1E1E",
                            height: "36px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#FFFFFF",
                            padding: "0px 20px",
                          }}
                        >
                          {item.title}
                        </PretendardText>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <PretendardText
                      style={{
                        color: "#F1F1F1",
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "24px",
                      }}
                    >
                      주제
                    </PretendardText>
                    {data.subjectList?.map((item, index) => {
                      return (
                        <PretendardText
                          key={index}
                          style={{
                            background: "#1E1E1E",
                            height: "36px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#FFFFFF",
                            padding: "0px 20px",
                          }}
                        >
                          {item.title}
                        </PretendardText>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <PretendardText
                      style={{
                        color: "#F1F1F1",
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "24px",
                      }}
                    >
                      해상도
                    </PretendardText>
                    {data.resolutionList?.map((item, index) => {
                      return (
                        <PretendardText
                          key={index}
                          style={{
                            background: "#1E1E1E",
                            height: "36px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#FFFFFF",
                            padding: "0px 20px",
                          }}
                        >
                          {item.title}
                        </PretendardText>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <PretendardText
                      style={{
                        color: "#F1F1F1",
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "24px",
                      }}
                    >
                      촬영기법
                    </PretendardText>
                    {data.technicList?.map((item, index) => {
                      return (
                        <PretendardText
                          key={index}
                          style={{
                            background: "#1E1E1E",
                            height: "36px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#FFFFFF",
                            padding: "0px 20px",
                          }}
                        >
                          {item.title}
                        </PretendardText>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <PretendardText
                      style={{
                        color: "#F1F1F1",
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "24px",
                      }}
                    >
                      속도
                    </PretendardText>
                    {data.speedList?.map((item, index) => {
                      return (
                        <PretendardText
                          key={index}
                          style={{
                            background: "#1E1E1E",
                            height: "36px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#FFFFFF",
                            padding: "0px 20px",
                          }}
                        >
                          {item.title}
                        </PretendardText>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div
                style={{
                  height: "1px",
                  marginTop: "30px",
                  background: "#565656",
                }}
              ></div>

              <div
                style={{
                  marginTop: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <PretendardText
                    style={{
                      color: "#FFFFFF",
                      fontSize: "24px",
                      fontWeight: "600",
                      lineHeight: "36px",
                    }}
                  >
                    같은 테마의 최신 영상
                  </PretendardText>
                  <WhiteBtn
                    title={"모두보기"}
                    onClick={() => {
                      recommendFunc();
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  marginTop: "30px",
                  gap: "30px",
                  flexWrap: "wrap",
                  display: "flex",
                }}
              >
                {list.map((item, index) => {
                  return (
                    <VideoCardM
                      key={index}
                      style={{
                        width: "370px",
                        height: "220px",
                      }}
                      item={item}
                      useTemp={true}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <Footer />
      </Container>
    </>
  );
}

export default VideoDetail;
