import React, { useEffect, useMemo, useRef, useState } from "react";
import useDisplay from "../../hooks/useDisplay";
import PretendardText from "../atoms/pretendardText";
import MuktaText from "../atoms/muktaText";
import NavText from "../molecules/nav/navText";
import { useHistory, useLocation } from "react-router-dom";
import { route } from "../../routes/route";
import useModal from "../../hooks/useModal";
import { nullCheck } from "../../utils/check";
import { loginState } from "../../recoil/loginState";
import { useRecoilValue, useResetRecoilState } from "recoil";
import UserApi from "../../api/user";
import { errorHandler } from "../../utils/error-handler";
import SearchInput from "../atoms/input/searchInput";

function Nav({
  activePage,
  setActivePage,
  keyword,
  onChangeSearchBar,
  onClickSearchBar,
  orderBy,
  setOrderBy,
  onBlur,
  setSerchUrl,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const history = useHistory();
  const location = useLocation();

  const [isFixed, setIsFixed] = useState(false);

  function onScroll() {
    if (window.scrollY > 60 || document.documentElement.scrollTop > 60) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
    setMenuOpen(false);
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);
  const modal = useModal();
  const loginInfo = useRecoilValue(loginState);
  const loginReset = useResetRecoilState(loginState);

  //회원 정보 가져오기
  const [data, setData] = useState({});
  useEffect(() => {
    getData();
  }, [sessionStorage.name]);

  const getData = async () => {
    try {
      if (sessionStorage.getItem("id")) {
        let data = (await UserApi.getById()).data.data;
        setData(data);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  const [orderByOpen, setOrderByOpen] = useState(false);

  return (
    <>
      <div
        style={{
          position: isFixed || activePage !== 0 ? "fixed" : "absolute",
          top: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "80px",
          paddingLeft: "84px",
          paddingRight: "85px",
          justifyContent: "space-between",
          background:
            isFixed || activePage !== 0
              ? "#151515"
              : "rgba(255, 255, 255, 0.01)",
          backdropFilter: "blur(5px)",
          zIndex: 999,
        }}
      >
        <MuktaText
          style={{
            color: "#FFFFFF",
            fontWeight: "700",
            fontSize: "27px",
            lineHeight: "35.1px",
            cursor: "pointer",
          }}
          onClick={() => {
            if (location.pathname.includes(route.home)) {
              history.replace({
                pathname: route.home,
                search: ``,
                state: {
                  activePage: 0,
                  selectedMenu: {},
                },
              });
            } else {
              history.push({
                pathname: route.home,
                search: ``,
                state: {
                  activePage: 0,
                  selectedMenu: {},
                },
              });
            }
          }}
        >
          BeStock
        </MuktaText>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "47px",
            }}
          >
            <NavText
              text={"영상"}
              onClick={() => {
                if (location.pathname.includes(route.home)) {
                  history.replace({
                    pathname: route.home,
                    search: ``,
                    state: {
                      activePage: 1,
                      selectedMenu: {},
                    },
                  });
                } else {
                  history.push({
                    pathname: route.home,
                    search: ``,
                    state: {
                      activePage: 1,
                      selectedMenu: {},
                    },
                  });
                }
              }}
              isActive={
                activePage === 1 ||
                location.pathname.includes(route.videoDetail)
              }
            ></NavText>

            <NavText
              text={"BeStock"}
              onClick={() => history.push(route.bestock)}
              isActive={location.pathname.includes(route.bestock)}
            ></NavText>

            <NavText
              text={"요금제"}
              onClick={() => history.push(route.payment)}
              isActive={location.pathname.includes(route.payment)}
            ></NavText>

            <NavText
              text={"1:1매칭"}
              onClick={() => history.push(route.matching + "/1")}
              isActive={location.pathname.includes(route.matching)}
            ></NavText>
          </div>

          {loginInfo.id ? (
            <>
              <PretendardText
                style={{
                  color: "#FFFFFF",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  cursor: "pointer",
                  marginLeft: "101px",
                  borderRadius: "100px",
                  background:
                    isFixed || activePage !== 0
                      ? ""
                      : "rgba(106, 106, 106, 0.50)",
                  padding: "3px 14px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setMenuOpen(!menuOpen);
                }}
              >
                {data.name} 님
              </PretendardText>
              <img
                src={data.fileUrl ?? "/assets/icons/profile_empty.svg"}
                alt=""
                style={{
                  marginLeft: "9px",
                  width: "35px",
                  height: "35px",
                  borderRadius: "50%",
                }}
              />
            </>
          ) : (
            <>
              <PretendardText
                style={{
                  color: "#939393",
                  fontWeight: "400",
                  fontSize: "18px",
                  lineHeight: "28.8px",
                  cursor: "pointer",
                  marginLeft: "55px",
                }}
                onClick={() => {
                  modal.open("login");
                }}
              >
                로그인
              </PretendardText>
              <PretendardText
                style={{
                  color: "#1E1E1E",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "16px",
                  cursor: "pointer",
                  background: "#FFFFFF",
                  width: "116px",
                  height: "40px",
                  borderRadius: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "15px",
                }}
                onClick={() => {
                  modal.open("join");
                }}
              >
                회원가입
              </PretendardText>{" "}
            </>
          )}
        </div>

        {menuOpen ? (
          <div
            style={{
              position: "absolute",
              top: "86px",
              right: "84px",
              boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.20)",
              borderRadius: "10px",
              width: "149px",
              height: "105px",
              background: "#333333",
              paddingTop: "20px",
              paddingLeft: "30px",
            }}
          >
            <PretendardText
              style={{
                color: "#FFFFFF",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "24px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push(route.account);
              }}
            >
              마이페이지
            </PretendardText>
            <PretendardText
              style={{
                color: "#BABABA",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "21px",
                marginTop: "20px",
                textDecorationLine: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                modal.close();
                sessionStorage.clear();
                loginReset();
                setMenuOpen(false);
                history.push(route.home);
              }}
            >
              로그아웃
            </PretendardText>
          </div>
        ) : null}
      </div>

      {activePage && activePage !== 0 ? (
        <div
          style={{
            position: "fixed",
            top: 80,
            left: 0,
            width: "100%",
            height: "90px",
            background: "#151515",
            backdropFilter: "blur(5px)",
            zIndex: 998,
            display: "flex",
            justifyContent: "end",
            paddingLeft: "376px",
            paddingRight: "48px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              gap: "26px",
              width: "100%",
            }}
          >
            <SearchInput
              placeholder={"원하는 영상을 검색해보세요."}
              style={{
                width: "100%",
                maxWidth: "1850px",
              }}
              inputStyle={{
                width: "100%",
              }}
              iconStyle={{
                right: "15.19px",
              }}
              value={keyword}
              onChange={(e) => {
                onChangeSearchBar(e);
              }}
              onClick={() => {
                onClickSearchBar();
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onClickSearchBar();
                }
              }}
              onBlur={() => {
                onBlur();
              }}
            />

            <div
              style={{
                display: "flex",
                gap: "10px",
                cursor: "pointer",
                minWidth: "105px",
              }}
              onClick={() => {
                setOrderByOpen(!orderByOpen);
              }}
            >
              <PretendardText
                style={{
                  color: "#DFDFDF",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "22.4px",
                }}
              >
                {orderBy.title}
              </PretendardText>
              <img
                src="/assets/icons/icon_arrow.svg"
                alt=""
                style={{
                  width: "25px",
                  height: "25px",
                  transform: orderByOpen ? "rotate(180deg)" : "",
                }}
              />
            </div>

            {orderByOpen ? (
              <div
                style={{
                  position: "absolute",
                  top: "70px",
                  right: 0,
                  width: "126px",
                  height: "132px",
                  background: "#333333",
                  borderRadius: "5px",
                  paddingTop: "15px",
                  paddingLeft: "15px",
                  zIndex: 99,
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSerchUrl(1, "CREATED");
                    setOrderByOpen(false);
                  }}
                >
                  최근등록순
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginTop: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSerchUrl(1, "DOWNLOAD");
                    setOrderByOpen(false);
                  }}
                >
                  다운로드순
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    marginTop: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSerchUrl(1, "UPLOAD");
                    setOrderByOpen(false);
                  }}
                >
                  업로드순
                </PretendardText>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Nav;
