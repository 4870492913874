import React from "react";
import TitleLabel from "../../atoms/text/titleLabel";
import SelectBox2 from "../../atoms/select/selectBox2";
import { nullCheck } from "../../../utils/check";
import { toast } from "react-toastify";
import PretendardText from "../../atoms/pretendardText";

function SelectFieldBox({ menu, fieldList, setFieldList, isOpen, closeFunc, onClick, limitCount }) {
  return (
    <>
      <TitleLabel title={menu.title} isRequired={true}></TitleLabel>
      <SelectBox2
        optionList={menu.subMenus}
        isOpen={isOpen}
        closeFunc={closeFunc}
        onClickSelect={() => {
          onClick();
        }}
        title={
          // nullCheck(fieldList[fieldList.length - 1])
          //   ? `${menu.title}를 최대 ${limitCount}개까지 선택해 주세요.`
          //   : fieldList[fieldList.length - 1].title
          `${menu.title}를 최대 ${limitCount}개까지 선택해 주세요.`
        }
        onClickOption={(item) => {
          if (fieldList.length >= limitCount) {
            return toast(`최대 ${limitCount}개까지 선택가능합니다.`);
          }
          const temp = fieldList.map((item) => item.value);
          if (!temp.includes(item.value)) {
            fieldList.push(item);
            setFieldList([...fieldList]);
          }
        }}
        value={nullCheck(fieldList[fieldList.length - 1]) ? "" : fieldList[fieldList.length - 1].value}
        values={fieldList.map((item) => item.value)}
      ></SelectBox2>

      {fieldList.length > 0 ? (
        <div
          style={{
            marginTop: "12.89px",
            display: "flex",
            gap: "12px",
          }}
        >
          {fieldList.map((item, index) => {
            return (
              <PretendardText
                key={index}
                style={{
                  color: "#00E4BB",
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "18.75px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "32px",
                  border: "1px solid #00E4BB",
                  borderRadius: "34px",
                  paddingLeft: "9px",
                  paddingRight: "6px",
                }}
              >
                {item.title}
                <img
                  src="/assets/icons/icon_x.svg"
                  alt=""
                  style={{ width: "16px", height: "16px", marginLeft: "5px", cursor: "pointer" }}
                  onClick={() => {
                    fieldList.splice(index, 1);
                    setFieldList([...fieldList]);
                  }}
                />
              </PretendardText>
            );
          })}
        </div>
      ) : null}
    </>
  );
}

export default SelectFieldBox;
