import React, { useRef, useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { errorHandler } from "../../utils/error-handler";
import useDisplay from "../../hooks/useDisplay";
import PretendardText from "../../components/atoms/pretendardText";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import Blur from "../../components/atoms/blur/blur";
import styled, { keyframes } from "styled-components";
import TextInput from "../../components/atoms/input/textInput";
import SelectBox from "../../components/atoms/select/selectBox";
import { contactMenu1 } from "../../utils/menu/contactMenu1";
import { contactMenu2 } from "../../utils/menu/contactMenu2";
import { contactMenu3 } from "../../utils/menu/contactMenu3";
import { contactMenu4 } from "../../utils/menu/contactMenu4";
import { menu2 } from "./../../utils/menu/menu2";
import TextArea from "../../components/atoms/input/textarea";
import RadioInput from "../../components/atoms/input/radioInput";
import MuktaText from "../../components/atoms/muktaText";
import { route } from "../../routes/route";
import Pagination from "../../components/organisms/pagination";
import FaqApi from "./../../api/faqApi";
import { useHistory } from "react-router-dom";
import { nullCheck } from "../../utils/check";
import ContactModal from "./../../components/templates/modal/contactModal";
import ContactApi from "./../../api/contactApi";
import { toast } from "react-toastify";
import { regExpression } from "./../../utils/reg-expresstion";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import Footer from "../../components/organisms/footer";

function Matching() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const goContact = useRef();
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(1);
  const optionList = [
    contactMenu1.subMenus,
    contactMenu2.subMenus,
    contactMenu3.subMenus,
    contactMenu4.subMenus,
  ];
  const [faqlist, setFaqList] = useState([]);
  const [contactModal, setContactModal] = useState(false);

  const [contactFormData, setContactFormData] = useState({
    companyName: "",
    name: "",
    phoneNumber: "",
    email: "",
    rank: "",
    maxBudjet: "",
    content: "",
    isMember: "",
    shootingType: "",
    shootingTypeTitle: "촬영 유형을 선택해 주세요.",
    videoStyle: "",
    videoStyleTitle: "영상스타일을 선택해 주세요.",
    shootingTechnic: "",
    shootingTechnicTitle: "영상 촬영 기법을 선택해 주세요.",
    location: "",
    locationTitle: "지역을 선택해 주세요.",
    period: "",
    periodTitle: "희망 납품 기간을 선택해 주세요.",
  });

  const [emailErrorM, setEmailErrorM] = useState("");

  const emailCheck = useMemo(() => {
    if (
      !nullCheck(contactFormData.email) &&
      !regExpression.email.test(contactFormData.email)
    ) {
      setEmailErrorM("올바른 형식을 입력해 주세요.");
      return false;
    }
    if (nullCheck(contactFormData.email)) {
      setEmailErrorM("");
      return false;
    }
    setEmailErrorM("");
    return true;
  }, [contactFormData]);

  //페이지네이션
  const { page } = useParams();
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 10, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    try {
      const data = {
        page: page - 1,
        size: pagination.size,
        faqCategoryId: 1,
      };

      let list = (await FaqApi.GetFaq(data)).data.data;
      setFaqList(list.content);
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const onContactChange = (key, value) => {
    setContactFormData({ ...contactFormData, [key]: value });
  };

  //스크롤 이동
  const onMoveBox = () => {
    goContact.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    // history.push(route.contact);
  };

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}
        {/* header */}
        <div
          style={{
            position: "relative",
            backgroundColor: "#151515",
            height: `${isMobile ? "878px" : "100vh"}`,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              height: `${isMobile ? "100%" : "202px"}`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: `${isMobile ? "127px" : ""}`,
              position: `${isMobile ? "relative" : "absolute"}`,
              bottom: `${isMobile ? "" : "57%"}`,
            }}
          >
            <PretendardText
              style={{
                color: "#fff",
                fontWeight: "700",
                fontSize: `${isMobile ? "24px" : "36px"}`,
                lineHeight: `${isMobile ? "36px" : "54px"}`,
                textshadow: "0px 4px 20px rgba(10, 13, 20, 0.15)",
                marginBottom: "40px",
                textAlign: "center",
                position: "relative",
                zIndex: 1,
              }}
            >
              원하는 유형의 영상 전문 {isMobile && <br />}촬영 감독에게 의뢰하고
              <br />
              지금 바로 만나보세요.
            </PretendardText>
            <PrimaryBtn
              style={{
                width: `${isMobile ? "177px" : "160px"}`,
                height: `${isMobile ? "48px" : "54px"}`,
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "16px" : "20px"}`,
                lineHeight: `${isMobile ? "24px" : "30px"}`,
                position: "relative",
                zIndex: 1,
              }}
              title={"매칭 문의하기"}
              disabled={false}
              onClick={onMoveBox}
            ></PrimaryBtn>
          </div>
          <img
            src="/assets/images/matching/computer.png"
            alt=""
            style={{
              width: `${isMobile ? "100%" : "1246px"}`,
              height: `${isMobile ? "272px" : "595px"}`,
              objectFit: "cover",
              position: "absolute",
              bottom: `${isMobile ? "130px" : "0"}`,
              left: `${isMobile ? "unset" : "50%"}`,
              transform: `${isMobile ? "unset" : "translate(-50%, 0%)"}`,
            }}
          />
          <SwingImg
            src="/assets/images/matching/slate.png"
            alt=""
            style={{
              width: `${isMobile ? "65.172px" : ""}`,
              height: `${isMobile ? "118.854px" : ""}`,
              position: "absolute",
              bottom: `${isMobile ? "389px" : "308px"}`,
              left: `${isMobile ? "4%" : "14.64%"}`,
            }}
          />
          <SwingImg
            src="/assets/images/matching/video1.png"
            alt=""
            style={{
              width: `${isMobile ? "83.544px" : ""}`,
              height: `${isMobile ? "92.998px" : ""}`,
              position: "absolute",
              bottom: `${isMobile ? "365px" : "485px"}`,
              left: `${isMobile ? "28.8%" : "25.89%"}`,
              animationDuration: "5s",
              animationTimingFunction: "ease-in",
            }}
          />
          <SwingImg
            src="/assets/images/matching/firm.png"
            alt=""
            style={{
              width: `${isMobile ? "75.879px" : ""}`,
              height: `${isMobile ? "90.575px" : ""}`,

              position: "absolute",
              bottom: `${isMobile ? "356.42px" : "362px"}`,
              right: `${isMobile ? "11.77%" : "23.75%"}`,

              animationDuration: "9s",
              animationTimingFunction: "linear",
            }}
          />
          <SwingImg
            src="/assets/images/matching/video2.png"
            alt=""
            style={{
              width: `${isMobile ? "60px" : ""}`,
              height: `${isMobile ? "60px" : ""}`,
              position: "absolute",
              top: `${isMobile ? "365px" : "387px"}`,
              right: `${isMobile ? "28.8%" : "17.93%"}`,
              animationDuration: "6s",
            }}
          />
          {/* Blur */}
          {!isMobile ? (
            <>
              <Blur
                style={{
                  width: "300px",
                  height: "370px",
                  borderRadius: "370px",
                  background: "rgba(151, 50, 142, 0.75)",
                  bottom: "75px",
                  left: "60px",
                  filter: "blur(285px)",
                  zIndex: 2,
                }}
              />
              <Blur
                style={{
                  width: "300px",
                  height: "370px",
                  borderRadius: "370px",
                  background: "rgba(0, 125, 103, 0.50)",
                  bottom: "-70px",
                  right: "123px",
                  filter: "blur(285px)",
                }}
              />
            </>
          ) : (
            ""
          )}
        </div>
        {/* section-1 */}
        <div
          style={{
            width: "100%",
            height: `${isMobile ? "3361px" : "2380px"}`,
            paddingTop: `${isMobile ? "0" : "200px"}`,
            backgroundColor: "#151515",
            display: "flex",
            flexDirection: "column",
            gap: `${isMobile ? "150px" : "200px"}`,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: `${isMobile ? "column" : "row"}`,
              gap: `${isMobile ? "60px" : "204px"}`,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <PretendardText
                style={{
                  color: "#00E4BB",
                  fontWeight: `${isMobile ? "600" : "700"}`,
                  fontSize: `${isMobile ? "14px" : "16px"}`,
                  lineHeight: `${isMobile ? "21px" : "24px"}`,
                  textAlign: `${isMobile ? "center" : ""}`,
                  marginBottom: "10px",
                }}
              >
                확실한 전문인력 매칭
              </PretendardText>
              <PretendardText
                style={{
                  color: "#fff",
                  fontWeight: `${isMobile ? "600" : "700"}`,
                  fontSize: `${isMobile ? "24px" : "36px"}`,
                  lineHeight: `${isMobile ? "36px" : "54px"}`,
                  marginBottom: `${isMobile ? "25px" : "30px"}`,
                  textAlign: `${isMobile ? "center" : ""}`,
                }}
              >
                제작 조건 및 퀄리티에 부합하는
                <br />
                검증된 촬영감독을 선별하여 매칭
              </PretendardText>
              <PretendardText
                style={{
                  color: "#f1f1f1",
                  fontWeight: "400",
                  fontSize: `${isMobile ? "14px" : "20px"}`,
                  lineHeight: `${isMobile ? "21px" : "24px"}`,
                  marginBottom: `${isMobile ? "" : "32px"}`,
                  textAlign: `${isMobile ? "center" : ""}`,
                }}
              >
                BeStock은 검증된 촬영감독만을 선별하여 제작 방향성,{" "}
                {isMobile && <br />}예산 등{!isMobile && <br />}
                다양한 조건에 부합하는 최적의 매칭을 제공합니다.
              </PretendardText>
            </div>
            <div style={{ position: "relative", overflow: "hidden" }}>
              {isMobile ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    background:
                      "linear-gradient(180deg, rgba(21, 21, 21, 0.00) 34.25%, #151515 85.55%)",
                  }}
                ></div>
              ) : (
                ""
              )}
              <img
                style={{
                  width: `${isMobile ? "100%" : "448px"}`,
                  height: `${isMobile ? "215px" : "269px"}`,
                  objectFit: "cover",
                  position: "relative",
                  left: `${isMobile ? "-10px" : "0"}`,
                }}
                src={`/assets/images/matching/main-img-1${
                  isMobile ? "-mobile" : ""
                }.png`}
                alt=""
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: `${isMobile ? "column-reverse" : "row"}`,
              gap: `${isMobile ? "86px" : "204px"}`,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: `${isMobile ? "100%" : ""}`,
                padding: `${isMobile ? "0 5.33%" : ""}`,
              }}
            >
              <img
                style={{
                  width: `${isMobile ? "100%" : "522px"}`,
                  height: `${isMobile ? "100%" : "175px"}`,
                  objectFit: "cover",
                }}
                src="/assets/images/matching/main-img-2.png"
                alt=""
              />
            </div>
            <div>
              <PretendardText
                style={{
                  color: "#00E4BB",
                  fontWeight: `${isMobile ? "600" : "700"}`,
                  fontSize: `${isMobile ? "14px" : "16px"}`,
                  lineHeight: `${isMobile ? "21px" : "24px"}`,
                  marginBottom: "10px",
                  textAlign: `${isMobile ? "center" : ""}`,
                }}
              >
                체계적인 관리 시스템
              </PretendardText>
              <PretendardText
                style={{
                  color: "#fff",
                  fontWeight: `${isMobile ? "600" : "700"}`,
                  fontSize: `${isMobile ? "24px" : "36px"}`,
                  lineHeight: `${isMobile ? "36px" : "54px"}`,
                  marginBottom: `${isMobile ? "25px" : "30px"}`,
                  textAlign: `${isMobile ? "center" : ""}`,
                }}
              >
                매칭부터 납품까지
                <br />
                담당 매니저의 1:1 관리
              </PretendardText>
              <PretendardText
                style={{
                  color: "#f1f1f1",
                  fontWeight: "400",
                  fontSize: `${isMobile ? "14px" : "20px"}`,
                  lineHeight: `${isMobile ? "21px" : "24px"}`,
                  marginBottom: `${isMobile ? "" : "32px"}`,
                  textAlign: `${isMobile ? "center" : ""}`,
                }}
              >
                BeStock의 담당 매니저가 매칭부터 계약 진행,
                <br />
                영상 납품까지 모든 프로세스를 꼼꼼하게 관리하여
                <br />
                안전한 거래가 진행됩니다.
              </PretendardText>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: `${isMobile ? "column" : "row"}`,
              gap: `${isMobile ? "85px" : "204px"}`,
              justifyContent: "center",
            }}
          >
            <div>
              <PretendardText
                style={{
                  color: "#00E4BB",
                  fontWeight: `${isMobile ? "600" : "700"}`,
                  fontSize: `${isMobile ? "14px" : "16px"}`,
                  lineHeight: `${isMobile ? "21px" : "24px"}`,
                  textAlign: `${isMobile ? "center" : ""}`,
                  marginBottom: "10px",
                }}
              >
                1:1 맞춤 상담 및 분석
              </PretendardText>
              <PretendardText
                style={{
                  color: "#fff",
                  fontWeight: `${isMobile ? "600" : "700"}`,
                  fontSize: `${isMobile ? "24px" : "36px"}`,
                  lineHeight: `${isMobile ? "36px" : "54px"}`,
                  marginBottom: `${isMobile ? "25px" : "30px"}`,
                  textAlign: `${isMobile ? "center" : ""}`,
                }}
              >
                다양한 촬영 유형을 제시하여
                <br />
                정확한 니즈 파악
              </PretendardText>
              <PretendardText
                style={{
                  color: "#f1f1f1",
                  fontWeight: "400",
                  fontSize: `${isMobile ? "14px" : "20px"}`,
                  lineHeight: `${isMobile ? "21px" : "24px"}`,
                  marginBottom: `${isMobile ? "" : "32px"}`,
                  textAlign: `${isMobile ? "center" : ""}`,
                }}
              >
                같은 영상도 목적에 따라 촬영 기법과 방식이 달라집니다.
                <br />
                꼼꼼한 사전상담을 통해 정확한 니즈를 파악 후
                <br />
                전문 촬영 PD 매칭이 이루어집니다.
              </PretendardText>
            </div>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(180deg, rgba(21, 21, 21, 0.03) 0%, #151515 100%)",
                }}
              ></div>
              <img
                style={{
                  width: `${isMobile ? "100%" : "570px"}`,
                  height: `${isMobile ? "305px" : "350px"}`,
                  objectFit: "cover",
                }}
                src="/assets/images/matching/main-img-3.png"
                alt=""
              />
            </div>
          </div>
          <div style={{ height: `${isMobile ? "1498px" : "652px"}` }}>
            <div>
              <PretendardText
                style={{
                  color: "#00E4BB",
                  fontWeight: `${isMobile ? "600" : "700"}`,
                  fontSize: `${isMobile ? "14px" : "16px"}`,
                  lineHeight: `${isMobile ? "21px" : "24px"}`,
                  marginBottom: "10px",
                  textAlign: "center",
                }}
              >
                Process
              </PretendardText>
              <PretendardText
                style={{
                  color: "#fff",
                  fontWeight: `${isMobile ? "600" : "700"}`,
                  fontSize: `${isMobile ? "24px" : "36px"}`,
                  lineHeight: `${isMobile ? "36px" : "54px"}`,
                  marginBottom: `${isMobile ? "25px" : "30px"}`,
                  textAlign: "center",
                }}
              >
                간단한 5단계 프로세스
              </PretendardText>
              <PretendardText
                style={{
                  color: "#f1f1f1",
                  fontSize: `${isMobile ? "14px" : "20px"}`,
                  lineHeight: `${isMobile ? "21px" : "24px"}`,
                  marginBottom: `${isMobile ? "50px" : "60px"}`,
                  textAlign: "center",
                }}
              >
                BeStock의 담당 매니저가 문의를 접수받으면
                <br />
                상담 및 매칭-견적-계약-납품 순으로 빠르게 진행합니다.
              </PretendardText>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: `${isMobile ? "column" : "row"}`,
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <ProcessDiv>
                  <img src="/assets/icons/matching/ic_mail.svg" alt="" />
                  <PretendardText
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "30px",
                      textAlign: "center",
                    }}
                  >
                    문의접수
                  </PretendardText>
                  <img
                    src="/assets/icons/matching/ic_arrow_black.svg"
                    style={{
                      position: "absolute",
                      top: `${isMobile ? "192px" : "88px"}`,
                      right: `${isMobile ? "88px" : "-17px"}`,
                      transform: `${isMobile ? "rotate(90deg)" : "unset"}`,
                    }}
                  />
                </ProcessDiv>
                <ProcessDiv>
                  <img src="/assets/icons/matching/ic_cs.svg" alt="" />
                  <PretendardText
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "30px",
                      textAlign: "center",
                    }}
                  >
                    상담 및 매칭
                  </PretendardText>
                  <img
                    src="/assets/icons/matching/ic_arrow_black.svg"
                    style={{
                      position: "absolute",
                      top: `${isMobile ? "192px" : "88px"}`,
                      right: `${isMobile ? "88px" : "-17px"}`,
                      transform: `${isMobile ? "rotate(90deg)" : "unset"}`,
                    }}
                  />
                </ProcessDiv>
                <ProcessDiv>
                  <img src="/assets/icons/matching/ic_estimate.svg" alt="" />
                  <PretendardText
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "30px",
                      textAlign: "center",
                    }}
                  >
                    견적 전달
                  </PretendardText>
                  <img
                    src="/assets/icons/matching/ic_arrow_black.svg"
                    style={{
                      position: "absolute",
                      top: `${isMobile ? "192px" : "88px"}`,
                      right: `${isMobile ? "88px" : "-17px"}`,
                      transform: `${isMobile ? "rotate(90deg)" : "unset"}`,
                    }}
                  />
                </ProcessDiv>
                <ProcessDiv>
                  <img src="/assets/icons/matching/ic_meeting.svg" alt="" />
                  <PretendardText
                    style={{
                      color: "#fff",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "30px",
                      textAlign: "center",
                    }}
                  >
                    미팅 및 계약
                  </PretendardText>
                  <img
                    src="/assets/icons/matching/ic_arrow_black.svg"
                    style={{
                      position: "absolute",
                      top: `${isMobile ? "192px" : "88px"}`,
                      right: `${isMobile ? "88px" : "-17px"}`,
                      transform: `${isMobile ? "rotate(90deg)" : "unset"}`,
                    }}
                  />
                </ProcessDiv>
                <ProcessDiv style={{ border: "2px solid #00E4BB" }}>
                  <img src="/assets/icons/matching/ic_produce.svg" alt="" />
                  <PretendardText
                    style={{
                      color: "#00E4BB",
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "30px",
                      textAlign: "center",
                    }}
                  >
                    제작 및 납품
                  </PretendardText>
                </ProcessDiv>
              </div>
            </div>
          </div>
        </div>
        {/* section-2 */}
        <div
          ref={goContact}
          style={{
            width: "100%",
            height: `${isMobile ? "2154px" : "1827px"}`,
            padding: `${isMobile ? "90px 5.33%" : "120px 19.53% 200px 19.53%"}`,
            backgroundColor: "#151515",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <PretendardText
            style={{
              color: "#fff",
              fontWeight: "700",
              fontSize: `${isMobile ? "26px" : "36px"}`,
              lineHeight: "54px",
              textShadow: "0px 4px 20px rgba(10, 13, 20, 0.15)",
              marginBottom: `${isMobile ? "60px" : "68px"}`,
              textAlign: "center",
            }}
          >
            단 5분으로 견적 요청을 {isMobile && <br />}완료하세요.
          </PretendardText>
          <div style={{ zIndex: 20, width: `${isMobile ? "100%" : "1170px"}` }}>
            <PretendardText
              style={{
                color: "#fff",
                fontWeight: "600",
                fontSize: `${isMobile ? "16px" : "20px"}`,
                lineHeight: `${isMobile ? "24px" : "30px"}`,
              }}
            >
              기업 입력 사항
            </PretendardText>
            <div
              style={{
                width: "100%",
                borderTop: "1px solid #FFF",
                margin: `${isMobile ? "20px 0px" : "30px 0px"}`,
              }}
            ></div>
            <div
              style={{
                display: "flex",
                gap: "30px 30px",
                flexWrap: "wrap",
                marginBottom: "60px",
              }}
            >
              <div style={{ width: `${isMobile ? "100%" : "570px"}` }}>
                <InputTitle>기업명</InputTitle>
                <TextInput
                  placeholder={"기업명을 입력해 주세요."}
                  onChange={(e) => {
                    onContactChange("companyName", e.target.value);
                  }}
                  value={contactFormData.companyName}
                  maxLength={20}
                  onBlur={() => {
                    onContactChange(
                      "companyName",
                      contactFormData.companyName.trim()
                    );
                  }}
                />
              </div>
              <div style={{ width: `${isMobile ? "100%" : "570px"}` }}>
                <InputTitle>담당자명</InputTitle>
                <TextInput
                  placeholder={"담당자명을 입력해 주세요."}
                  onChange={(e) => {
                    onContactChange("name", e.target.value.trim());
                  }}
                  value={contactFormData.name}
                  maxLength={20}
                />
              </div>
              <div style={{ width: `${isMobile ? "100%" : "570px"}` }}>
                <InputTitle>연락처</InputTitle>
                <TextInput
                  placeholder={"연락처를 입력해 주세요."}
                  onChange={(e) => {
                    onContactChange(
                      "phoneNumber",
                      e.target.value.replace(/[^0-9]/g, "")
                    );
                  }}
                  value={contactFormData.phoneNumber}
                  maxLength={11}
                />
              </div>
              <div style={{ width: `${isMobile ? "100%" : "570px"}` }}>
                <InputTitle>이메일</InputTitle>
                <TextInput
                  placeholder={"이메일을 입력해 주세요."}
                  onChange={(e) => {
                    onContactChange("email", e.target.value.trim());
                  }}
                  errorText={emailErrorM}
                  isError={!nullCheck(emailErrorM)}
                  value={contactFormData.email}
                  maxLength={50}
                />
              </div>
              <div style={{ width: `${isMobile ? "100%" : "570px"}` }}>
                <InputTitle>직급</InputTitle>
                <TextInput
                  placeholder={"직급을 입력해 주세요."}
                  onChange={(e) => {
                    onContactChange("rank", e.target.value.trim());
                  }}
                  value={contactFormData.rank}
                  maxLength={20}
                />
              </div>
              <div style={{ width: `${isMobile ? "100%" : "570px"}` }}>
                <InputTitleNonEssential>최대 예산</InputTitleNonEssential>
                <TextInput
                  placeholder={"최대 예산 금액을 입력해 주세요."}
                  onChange={(e) => {
                    onContactChange(
                      "maxBudjet",
                      Number(
                        e.target.value.replace(/[^0-9]/g, "")
                      ).toLocaleString()
                    );
                  }}
                  value={contactFormData.maxBudjet}
                  maxLength={100}
                />
              </div>
            </div>
          </div>
          <div style={{ zIndex: 20, width: `${isMobile ? "100%" : "1170px"}` }}>
            <PretendardText
              style={{
                color: "#fff",
                fontWeight: "600",
                fontSize: `${isMobile ? "16px" : "20px"}`,
                lineHeight: `${isMobile ? "24px" : "30px"}`,
              }}
            >
              영상 입력 사항
            </PretendardText>
            <div
              style={{
                borderTop: "1px solid #FFF",
                margin: `${isMobile ? "20px 0px" : "30px 0px"}`,
              }}
            ></div>
            <div
              style={{
                display: "flex",
                gap: "30px 30px",
                flexWrap: "wrap",
                marginBottom: "30px",
              }}
            >
              <div style={{ width: `${isMobile ? "100%" : "570px"}` }}>
                <InputTitle>촬영 유형</InputTitle>
                <SelectBox
                  style={{
                    width: "100%",
                  }}
                  optionList={optionList[0]}
                  isOpen={isOpen === 1}
                  title={contactFormData.shootingTypeTitle}
                  onClickSelect={() => {
                    isOpen === 1 ? setIsOpen(false) : setIsOpen(1);
                  }}
                  onClickOption={(item) => {
                    setContactFormData({
                      ...contactFormData,
                      shootingType: item.value,
                      shootingTypeTitle: item.title,
                    });
                    setIsOpen(false);
                  }}
                  value={contactFormData.shootingType}
                />
              </div>
              <div style={{ width: `${isMobile ? "100%" : "570px"}` }}>
                <InputTitle>영상 스타일</InputTitle>
                <SelectBox
                  style={{
                    width: "100%",
                  }}
                  optionList={optionList[2]}
                  isOpen={isOpen === 2}
                  title={contactFormData.videoStyleTitle}
                  onClickSelect={() => {
                    isOpen === 2 ? setIsOpen(false) : setIsOpen(2);
                  }}
                  onClickOption={(item) => {
                    setContactFormData({
                      ...contactFormData,
                      videoStyle: item.value,
                      videoStyleTitle: item.title,
                    });
                    setIsOpen(false);
                  }}
                  value={contactFormData.videoStyle}
                />
              </div>
              <div style={{ width: `${isMobile ? "100%" : "570px"}` }}>
                <InputTitle>영상 촬영 기법</InputTitle>
                <SelectBox
                  style={{
                    width: "100%",
                  }}
                  optionList={optionList[1]}
                  isOpen={isOpen === 3}
                  title={contactFormData.shootingTechnicTitle}
                  onClickSelect={() => {
                    isOpen === 3 ? setIsOpen(false) : setIsOpen(3);
                  }}
                  onClickOption={(item) => {
                    setContactFormData({
                      ...contactFormData,
                      shootingTechnic: item.value,
                      shootingTechnicTitle: item.title,
                    });
                    setIsOpen(false);
                  }}
                  value={contactFormData.shootingTechnic}
                />
              </div>
              <div style={{ width: `${isMobile ? "100%" : "570px"}` }}>
                <InputTitle>희망 납품 기간</InputTitle>
                <SelectBox
                  style={{
                    width: "100%",
                  }}
                  optionList={optionList[3]}
                  isOpen={isOpen === 4}
                  title={contactFormData.periodTitle}
                  onClickSelect={() => {
                    isOpen === 4 ? setIsOpen(false) : setIsOpen(4);
                  }}
                  onClickOption={(item) => {
                    setContactFormData({
                      ...contactFormData,
                      period: item.value,
                      periodTitle: item.title,
                    });
                    setIsOpen(false);
                  }}
                  value={contactFormData.period}
                />
              </div>
              <div style={{ width: `${isMobile ? "100%" : "570px"}` }}>
                <InputTitle>지역</InputTitle>
                <SelectBox
                  style={{
                    width: "100%",
                  }}
                  optionList={menu2.subMenus}
                  isOpen={isOpen === 5}
                  title={contactFormData.locationTitle}
                  onClickSelect={() => {
                    isOpen === 5 ? setIsOpen(false) : setIsOpen(5);
                  }}
                  onClickOption={(item) => {
                    setContactFormData({
                      ...contactFormData,
                      location: item.value,
                      locationTitle: item.title,
                    });
                    setIsOpen(false);
                  }}
                  value={contactFormData.location}
                />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <InputTitle>기타</InputTitle>
              <TextArea
                placeholder={"기타 문의사항을 입력해 주세요."}
                style={{ height: `${isMobile ? "175px" : "252px"}` }}
                onChange={(e) => {
                  onContactChange("content", e.target.value);
                }}
                maxLength={5000}
              />
            </div>
            <div
              style={{ width: "100%", marginTop: "30px", marginBottom: "60px" }}
            >
              <InputTitle>BeStock 회원 여부</InputTitle>
              <div
                style={{ display: "flex", alignItems: "center", gap: "30px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setChecked(1);
                    onContactChange("isMember", true);
                  }}
                >
                  <RadioInput checked={checked === 1}></RadioInput>
                  <PretendardText
                    style={{
                      color: "#fff",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    회원입니다.
                  </PretendardText>
                </div>
                {sessionStorage.getItem("id") ? null : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setChecked(2);
                      onContactChange("isMember", false);
                    }}
                  >
                    <RadioInput checked={checked === 2}></RadioInput>
                    <PretendardText
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      회원이 아닙니다.
                    </PretendardText>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PrimaryBtn
                style={{
                  width: `${isMobile ? "146px" : "160px"}`,
                  height: `${isMobile ? "48px" : "54px"}`,
                  fontWeight: `${isMobile ? "600" : "700"}`,
                  fontSize: `${isMobile ? "16px" : "20px"}`,
                  lineHeight: `${isMobile ? "24px" : "30px"}`,
                }}
                disabled={
                  !emailCheck ||
                  nullCheck(contactFormData.name) ||
                  nullCheck(contactFormData.companyName) ||
                  nullCheck(contactFormData.phoneNumber) ||
                  nullCheck(contactFormData.email) ||
                  nullCheck(contactFormData.rank) ||
                  nullCheck(contactFormData.content) ||
                  nullCheck(contactFormData.isMember) ||
                  // nullCheck(contactFormData.maxBudjet) ||
                  nullCheck(contactFormData.shootingType) ||
                  nullCheck(contactFormData.videoStyle) ||
                  nullCheck(contactFormData.shootingTechnic) ||
                  nullCheck(contactFormData.location) ||
                  nullCheck(contactFormData.period)
                }
                title={"제출하기"}
                onClick={() => {
                  if (!regExpression.email.test(contactFormData.email)) {
                    toast("이메일 형식이 올바르지 않습니다");
                    return;
                  }
                  if (
                    nullCheck(contactFormData.name) ||
                    nullCheck(contactFormData.companyName) ||
                    nullCheck(contactFormData.phoneNumber) ||
                    nullCheck(contactFormData.email) ||
                    nullCheck(contactFormData.rank) ||
                    nullCheck(contactFormData.content) ||
                    nullCheck(contactFormData.isMember) ||
                    // nullCheck(contactFormData.maxBudjet) ||
                    nullCheck(contactFormData.shootingType) ||
                    nullCheck(contactFormData.videoStyle) ||
                    nullCheck(contactFormData.shootingTechnic) ||
                    nullCheck(contactFormData.location) ||
                    nullCheck(contactFormData.period)
                  ) {
                    return;
                  } else {
                    setContactModal(true);
                  }
                }}
              ></PrimaryBtn>
            </div>
          </div>
          {/* Blur */}
          {!isMobile ? (
            <Blur
              style={{
                width: "1342px",
                height: "200px",
                borderRadius: "1342px",
                background: "rgba(0, 237, 194, 0.15)",
                top: "-109px",
                left: "50%",
                transform: "translate(-50%, 0)",
                filter: "blur(150px)",
              }}
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: "2038px",
                position: "absolute",
                top: "0",
                background:
                  "linear-gradient(180deg, rgba(0, 228, 187, 0.08) 0%, rgba(0, 15, 12, 0.08) 14.6%)",
              }}
            ></div>
          )}
        </div>
        {/* section-3 */}
        <div
          style={{
            width: "100%",
            minHeight: `${isMobile ? "371px" : "648px"}`,
            padding: `${isMobile ? "90px 0" : "200px 19.53%"}`,
            backgroundColor: "#1e1e1e",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: `${isMobile ? "100%" : "1170px"}`,
              marginBottom: "90px",
            }}
          >
            <PretendardText
              style={{
                color: "#00E4BB",
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "14px" : "16px"}`,
                lineHeight: `${isMobile ? "21px" : "24px"}`,
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              FAQ
            </PretendardText>
            <PretendardText
              style={{
                color: "#fff",
                fontWeight: `${isMobile ? "600" : "700"}`,
                fontSize: `${isMobile ? "24px" : "36px"}`,
                lineHeight: `${isMobile ? "36px" : "54px"}`,
                marginBottom: `${isMobile ? "60px" : "90px"}`,
                textAlign: "center",
              }}
            >
              자주 묻는 질문
            </PretendardText>
            {faqlist.length > 0 ? (
              <div
                style={{
                  width: "100%",
                  borderTop: "2px solid #565656",
                }}
              >
                {faqlist.map((item, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          width: "100%",
                          height: "70px",
                          borderBottom: "1px solid #565656",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0 20px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          isOpen === index ? setIsOpen(-1) : setIsOpen(index)
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "30px",
                          }}
                        >
                          <MuktaText
                            style={{
                              color: "#fff",
                              fontWeight: "700",
                              fontSize: "18px",
                              lineHeight: "28.8px",
                            }}
                          >
                            Q
                          </MuktaText>
                          <PretendardText
                            style={{
                              width: `${isMobile ? "250px" : "895px"}`,
                              color: "#fff",
                              fontWeight: `${isMobile ? "600" : "700"}`,
                              fontSize: `${isMobile ? "16px" : "18px"}`,
                              lineHeight: `${isMobile ? "24px" : "28.8px"}`,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              wordBreak: "break-all",
                            }}
                          >
                            {item.title}
                          </PretendardText>
                        </div>
                        <div>
                          <img
                            src="/assets/icons/icon_arrow.svg"
                            style={{
                              transform: `${
                                isOpen === index ? "rotate(-180deg)" : ""
                              }`,
                            }}
                          />
                        </div>
                      </div>
                      {isOpen === index ? (
                        <div
                          style={{
                            minHeight: `${isMobile ? "58px" : "80px"}`,
                            borderBottom: "1px solid #565656",
                            background: "rgba(50, 50, 50, 0.70)",
                            padding: `${isMobile ? "16px 20px" : "25px 22px"}`,
                            display: "flex",
                            alignItems: "flex-start",
                            gap: `${isMobile ? "21px" : "31px"}`,
                          }}
                        >
                          <MuktaText
                            style={{
                              color: "#00E4BB",
                              fontWeight: "700",
                              fontSize: `${isMobile ? "16px" : "18px"}`,
                              lineHeight: `${isMobile ? "25.6px" : "28.8px"}`,
                            }}
                          >
                            A
                          </MuktaText>
                          <PretendardText
                            style={{
                              width: `${isMobile ? "80%" : "1083px"}`,
                              color: "#FFF",
                              fontWeight: "400",
                              fontSize: `${isMobile ? "14px" : "16px"}`,
                              lineHeight: "24px",
                            }}
                          >
                            {item.content}
                          </PretendardText>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}

                <Pagination
                  style={{ marginTop: "43px" }}
                  route={route.matching}
                  currentPage={page}
                  totalPages={pagination.totalPages}
                  rangeSize={pagination.rangeSize}
                  first={pagination.first}
                  last={pagination.last}
                />
              </div>
            ) : (
              <PretendardText
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  color: "#939393",
                  marginTop: "120px",
                  textAlign: "center",
                }}
              >
                등록된 내용이 없습니다.
              </PretendardText>
            )}
          </div>
        </div>
        {/* section-3 */}
        <div
          style={{
            width: "100%",
            height: `${isMobile ? "400px" : "110px"}`,
            background: "#151515",
            display: "flex",
            flexDirection: `${isMobile ? "column" : "row"}`,
            justifyContent: "center",
            alignItems: "center",
            gap: `${isMobile ? "23px" : "632px"}`,
            position: "relative",
            overflow: "hidden",
          }}
        >
          <PretendardText
            style={{
              color: "#fff",
              fontWeight: "700",
              fontSize: `${isMobile ? "24px" : "20px"}`,
              lineHeight: `${isMobile ? "36px" : "30px"}`,
              textAlign: `${isMobile ? "center" : ""}`,
            }}
          >
            BeStock에서 제공하는 {isMobile && <br />}영상이 궁금하신가요?
          </PretendardText>
          <PrimaryBtn
            title={"영상 둘러보기"}
            style={{
              width: `${isMobile ? "177px" : "160px"}`,
              height: `${isMobile ? "48px" : "54px"}`,
              fontWeight: `${isMobile ? "600" : "700"}`,
              fontSize: `${isMobile ? "16px" : "20px"}`,
              lineHeight: `${isMobile ? "24px" : "30px"}`,
              zIndex: 20,
            }}
            onClick={() => {
              history.push({
                pathname: route.home,
                state: { activePage: 1, selectedMenu: {} },
              });
            }}
          ></PrimaryBtn>
          {/* blur */}
          <Blur
            style={{
              width: `${isMobile ? "472px" : "330px"}`,
              height: `${isMobile ? "472px" : "330px"}`,
              borderRadius: `${isMobile ? "472px" : "330px"}`,
              background:
                "linear-gradient(227deg, rgba(0, 228, 187, 0.30) -16.45%, rgba(0, 228, 187, 0.00) 84.19%)",
              bottom: `${isMobile ? "164px" : "-104px"}`,
              right: `${isMobile ? "-314px" : "145px"}`,
              filter: "blur(7.5px)",
              zIndex: 10,
            }}
          />
          <Blur
            style={{
              width: `${isMobile ? "286.577px" : "285px"}`,
              height: `${isMobile ? "286.577px" : "285px"}`,
              borderRadius: `${isMobile ? "286.577px" : "285px"}`,
              transform: `${isMobile ? "rotate(179.561deg)" : "unset"}`,
              background: `${
                isMobile
                  ? "linear-gradient(227deg, rgba(0, 228, 187, 0.30) -16.45%, rgba(0, 228, 187, 0.00) 84.19%)"
                  : "linear-gradient(262deg, rgba(60, 241, 176, 0.15) -13.54%, rgba(0, 255, 209, 0.00) 72.83%)"
              }`,
              bottom: `${isMobile ? "-194.77px" : "-64px"}`,
              left: `${isMobile ? "2px" : "19px"}`,
              filter: `${isMobile ? "blur(17.5px)" : "blur(7.5px)"}`,
            }}
          />
          <Blur
            style={{
              width: `${isMobile ? "472px" : "330px"}`,
              height: `${isMobile ? "472px" : "330px"}`,
              borderRadius: `${isMobile ? "472px" : "330px"}`,
              transform: `${isMobile ? "rotate(-29.446deg)" : "unset"}`,
              background: `${
                isMobile
                  ? "linear-gradient(262deg, rgba(191, 219, 56, 0.15) -13.54%, rgba(0, 228, 187, 0.00) 72.83%)"
                  : "linear-gradient(227deg, rgba(0, 228, 187, 0.30) -16.45%, rgba(0, 228, 187, 0.00) 84.19%)"
              }`,
              top: `${isMobile ? "114px" : "62px"}`,
              left: `${isMobile ? "-394px" : "131px"}`,
              filter: "blur(7.5px)",
            }}
          />
        </div>
        {contactModal ? (
          <ContactModal
            isOpen={setContactModal}
            onClick={async () => {
              try {
                const obj = {
                  budget: parseInt(contactFormData.maxBudjet.replace(/,/g, "")),
                  businessName: contactFormData.companyName,
                  deadLine: contactFormData.periodTitle,
                  email: contactFormData.email,
                  etc: contactFormData.content,
                  isMember: contactFormData.isMember,
                  location: contactFormData.locationTitle,
                  name: contactFormData.name,
                  phoneNumber: contactFormData.phoneNumber,
                  position: contactFormData.rank,
                  videoStyle: contactFormData.videoStyleTitle,
                  videoTechnic: contactFormData.shootingTechnicTitle,
                  videoType: contactFormData.shootingTypeTitle,
                };
                await ContactApi.ContactMatching(obj);
                toast("문의가 제출되었습니다");
              } catch (error) {
                errorHandler(error);
              }
            }}
          />
        ) : null}

        <Footer />
      </Container>
    </>
  );
}

export default Matching;

const ProcessDiv = styled.div.attrs((props) => {})`
  width: 210px;
  height: 210px;
  padding-bottom: 30px;
  border-radius: 5px;
  background: #1e1e1e;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
`;

const InputTitle = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
  margin-bottom: 10px;

  &::after {
    content: " *";
    color: #00e4bb;
  }
`;

const InputTitleNonEssential = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
  margin-bottom: 10px;
`;

const infiniteAnimation = keyframes`
  0% {
    transform:translateY(-10%);
  }
  50%{
    transform:translateY(0%);
  }
  100%{
    transform:translateY(-10%);
  }
`;

const SwingImg = styled.img.attrs((props) => {})`
  animation: ${infiniteAnimation} infinite;
  animation-duration: 7s;
  animation-timing-function: ease-in-out'
`;
