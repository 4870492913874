import React from "react";
import PretendardText from "../../components/atoms/pretendardText";
import SearchInput from "../../components/atoms/input/searchInput";
import PrimaryBtn from "../../components/atoms/btn/primaryBtn";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import { nullCheck } from "../../utils/check";
import useDisplay from "../../hooks/useDisplay";
import MuktaText from "../../components/atoms/muktaText";
import {
  menu01StateAtom,
  menu02StateAtom,
  menu03StateAtom,
  menu04StateAtom,
  menu05StateAtom,
  menu06StateAtom,
} from "../../recoil/menuState";
import { useEffect } from "react";
import Container from "../../components/atoms/layout/container";
import NavM from "../../components/organisms/navM";
import Nav from "../../components/organisms/nav";
import Footer from "../../components/organisms/footer";
import { route } from "../../routes/route";

function Recommend() {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const history = useHistory();
  const menu01StateAtomData = useRecoilValue(menu01StateAtom);
  const menu02StateAtomData = useRecoilValue(menu02StateAtom);
  const menu03StateAtomData = useRecoilValue(menu03StateAtom);
  const menu04StateAtomData = useRecoilValue(menu04StateAtom);
  const menu05StateAtomData = useRecoilValue(menu05StateAtom);
  const menu06StateAtomData = useRecoilValue(menu06StateAtom);

  const [menus, setMenus] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedSubMenus, setSelectedSubMenus] = useState([]);
  const [isNext, setIsNext] = useState(false);

  useEffect(() => {
    setMenus([
      menu01StateAtomData,
      menu02StateAtomData,
      menu03StateAtomData,
      menu04StateAtomData,
      menu05StateAtomData,
      menu06StateAtomData,
    ]);
  }, [
    menu01StateAtomData,
    menu02StateAtomData,
    menu03StateAtomData,
    menu04StateAtomData,
    menu05StateAtomData,
    menu06StateAtomData,
  ]);

  const [keyword, setKeyword] = useState("");

  const recommendFunc = () => {
    history.push({
      pathname: route.home,
      search: `?keyword=${keyword}&filter=${selectedSubMenus.join()}`,
      state: {
        activePage: 1,
        selectedMenu: selectedMenu,
      },
    });
  };

  return (
    <>
      <Container>
        {isMobile ? <NavM /> : <Nav />}
        {isMobile || isTablet ? (
          <div
            style={{
              padding: "0px 20px",
              paddingTop: "118px",
              backgroundImage: 'url("/assets/images/recommend_bg_m.png")',
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingBottom: "70px",
              }}
            >
              <MuktaText
                style={{
                  fontWeight: "700",
                  fontSize: "36px",
                  lineHeight: "54px",
                  color: "#FFFFFF",
                }}
              >
                BeStock
              </MuktaText>
              <PretendardText
                style={{
                  marginTop: "20px",
                  background: "#333333",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  width: "136px",
                  height: "32px",
                  borderRadius: "10px",
                }}
              >
                AI 콘텐츠 추천
              </PretendardText>
              <PretendardText
                style={{
                  color: "#E6E6E6",
                  fontSize: "15px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                이제 해외무대가 아닌 한국 무대에서 <br />
                원하는 K-영상을 빠르게 검색하고 추천 받으세요.
              </PretendardText>
              <SearchInput
                placeholder={"키워드로 검색을 원하시나요?"}
                style={{
                  width: "100%",
                  maxWidth: "1170px",
                  marginTop: "40px",
                }}
                inputStyle={{
                  width: "100%",
                  fontSize: "14px",
                  lineHeight: "21px",
                  height: "43px",
                }}
                value={keyword}
                iconStyle={{
                  top: "10px",
                  right: "22px",
                }}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onBlur={() => {
                  setKeyword(keyword.trim());
                }}
              />

              <div
                style={{
                  marginTop: "40px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {isNext ? null : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "30px",
                    }}
                  >
                    {menus.map((item, index) => {
                      return index !== 2 ? (
                        <PretendardText
                          key={index}
                          style={{
                            color:
                              selectedMenu.value === item.value
                                ? "#00E4BB"
                                : "#FFFFFF",
                            fontSize: "22px",
                            fontWeight: "700",
                            lineHeight: "35.2px",
                            width: "211px",
                            height: "95px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundImage:
                              selectedMenu.value === item.value
                                ? `url("/assets/images/recommend_bg${
                                    index + 1
                                  }_selected.png")`
                                : `url("/assets/images/recommend_bg${
                                    index + 1
                                  }.png")`,
                            backgroundSize: "cover",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (selectedMenu.value === item.value) {
                              setSelectedMenu("");
                              setSelectedSubMenus([]);
                            } else {
                              setSelectedMenu(item);
                              setSelectedSubMenus([]);
                            }
                          }}
                        >
                          {item.title}
                        </PretendardText>
                      ) : null;
                    })}
                  </div>
                )}

                {isNext ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "21px",
                        flexWrap: "wrap",
                        // width: "95.8%",
                      }}
                    >
                      {selectedMenu.subMenus.map((item, index) => {
                        return (
                          <PretendardText
                            key={index}
                            style={{
                              color: selectedSubMenus.includes(item.value)
                                ? "#00E4BB"
                                : "#FFFFFF",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "24px",
                              width: "157px",
                              height: "70px",
                              borderRadius: "5px",
                              border: selectedSubMenus.includes(item.value)
                                ? "1px solid #00E4BB"
                                : "1px solid #565656",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: selectedSubMenus.includes(item.value)
                                ? ""
                                : "#333333",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (selectedSubMenus.includes(item.value)) {
                                const result = selectedSubMenus.filter(
                                  (value) => value !== item.value
                                );
                                setSelectedSubMenus([...result]);
                              } else {
                                selectedSubMenus.push(item.value);
                                setSelectedSubMenus([...selectedSubMenus]);
                              }
                            }}
                          >
                            {item.title}
                          </PretendardText>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>

              {isNext ? (
                <PrimaryBtn
                  disabled={selectedSubMenus.length <= 0}
                  title={"추천받기"}
                  style={{
                    marginTop: "40px",
                    width: "118px",
                    height: "48px",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  onClick={() => {
                    recommendFunc();
                  }}
                ></PrimaryBtn>
              ) : (
                <PrimaryBtn
                  title={"다음"}
                  style={{
                    marginTop: "40px",
                    width: "118px",
                    height: "48px",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  disabled={nullCheck(selectedMenu)}
                  onClick={() => {
                    setIsNext(true);
                  }}
                ></PrimaryBtn>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              minHeight: "1486px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "200px",
              backgroundImage: 'url("/assets/images/recommend_bg.png")',
            }}
          >
            <img src="/assets/images/recommend_logo.png" alt="" />
            <PretendardText
              style={{
                marginTop: "10px",
                background: "#333333",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#FFFFFF",
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "28.8px",
                width: "203px",
                height: "37px",
                borderRadius: "10px",
              }}
            >
              AI 콘텐츠 추천
            </PretendardText>
            <PretendardText
              style={{
                color: "#FFFFFF",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                marginTop: "20px",
              }}
            >
              회원님께서 찾으시는 영상 요소를 선택해주세요
            </PretendardText>
            <SearchInput
              placeholder={"키워드로 검색을 원하시나요?"}
              style={{
                width: "100%",
                maxWidth: "1170px",
                marginTop: "72px",
              }}
              inputStyle={{
                width: "100%",
              }}
              iconStyle={{
                right: "30px",
              }}
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              onBlur={() => {
                setKeyword(keyword.trim());
              }}
            />

            <div
              style={{
                marginTop: "40px",
                display: "flex",
                alignItems: "start",
                gap: "117px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                {menus.map((item, index) => {
                  return index !== 2 ? (
                    <PretendardText
                      key={index}
                      style={{
                        color:
                          selectedMenu.value === item.value
                            ? "#00E4BB"
                            : "#FFFFFF",
                        fontSize: "22px",
                        fontWeight: "700",
                        lineHeight: "35.2px",
                        width: "211px",
                        height: "95px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundImage:
                          selectedMenu.value === item.value
                            ? `url("/assets/images/recommend_bg${
                                index + 1
                              }_selected.png")`
                            : `url("/assets/images/recommend_bg${
                                index + 1
                              }.png")`,
                        backgroundSize: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (selectedMenu.value === item.value) {
                          setSelectedMenu("");
                          setSelectedSubMenus([]);
                        } else {
                          setSelectedMenu(item);
                          setSelectedSubMenus([]);
                        }
                      }}
                    >
                      {item.title}
                    </PretendardText>
                  ) : null;
                })}
              </div>
              {nullCheck(selectedMenu) ? (
                <div style={{ width: "35px" }}></div>
              ) : (
                <div style={{ width: "35px" }}>
                  <img
                    src="/assets/icons/icon_arrow_primary.svg"
                    alt=""
                    style={{
                      marginTop: "30px",
                    }}
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  flexWrap: "wrap",
                  width: "690px",
                }}
              >
                {nullCheck(selectedMenu) ? (
                  <div
                    style={{
                      borderRadius: "10px",
                      background: "rgba(51, 51, 51, 0.50)",
                      height: "460px",
                      width: "690px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src="/assets/icons/recommend_empty.svg" alt="" />
                    <PretendardText
                      style={{
                        color: "#BABABA",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        marginTop: "8px",
                      }}
                    >
                      아직 선택된 영상요소가 없습니다.
                    </PretendardText>
                  </div>
                ) : (
                  selectedMenu.subMenus.map((item, index) => {
                    return (
                      <PretendardText
                        key={index}
                        style={{
                          color: selectedSubMenus.includes(item.value)
                            ? "#00E4BB"
                            : "#FFFFFF",
                          fontSize: "18px",
                          fontWeight: "400",
                          lineHeight: "28.8px",
                          width: "210px",
                          height: "95px",
                          borderRadius: "5px",
                          border: selectedSubMenus.includes(item.value)
                            ? "1px solid #00E4BB"
                            : "1px solid #565656",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: selectedSubMenus.includes(item.value)
                            ? ""
                            : "#333333",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (selectedSubMenus.includes(item.value)) {
                            const result = selectedSubMenus.filter(
                              (value) => value !== item.value
                            );
                            setSelectedSubMenus([...result]);
                          } else {
                            selectedSubMenus.push(item.value);
                            setSelectedSubMenus([...selectedSubMenus]);
                          }
                        }}
                      >
                        {item.title}
                      </PretendardText>
                    );
                  })
                )}
              </div>
            </div>

            <PrimaryBtn
              title={"추천받기"}
              style={{
                marginTop: "80px",
              }}
              onClick={() => {
                recommendFunc();
              }}
            ></PrimaryBtn>
          </div>
        )}
        <Footer />
      </Container>
    </>
  );
}

export default Recommend;
