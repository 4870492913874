import api, { _axios } from "./api.js";

export default class CommonApi {
  static async visit(data) {
    return await api.post(`visitor`, data);
  }
  static async FileUpload(data) {
    return await api.fileForm(`file`, data, "post");
  }

  static async FileDownload(requestDto) {
    return await _axios.post(`/file/download`, requestDto, {
      responseType: "blob",
    });
  }
}
