import api from "./api.js";

export default class UserApi {
  static async Join(data) {
    return await api.post(`common_member`, data);
  }
  static async LoginNormal(data) {
    return await api.post(`login/normal`, data);
  }
  static async LoginSocial(data) {
    return await api.post(`login/social`, data);
  }

  static async changePw(data) {
    return await api.put(`common_member/password`, data);
  }
  static async updateAccount(data) {
    return await api.put(`user/member`, data);
  }
  static async setFilter(id, data) {
    return await api.put(`member/setFilter/${id}`, data);
  }

  static async sendSmsCode(params) {
    return await api.get(`common_member/check_sms`, params);
  }
  static async sendEmailCode(params) {
    return await api.get(`common_member/check_email`, params);
  }
  static async findEmail(params) {
    return await api.get(`common_member/email`, params);
  }
  static async checkRecommender(params) {
    return await api.get(`common_member/check_recommender`, params);
  }
  static async checkEmail(params) {
    return await api.get(`common_member/email/check`, params);
  }
  static async searchEmailCheckSms(params) {
    return await api.get(`search_email/check_sms`, params);
  }
  static async getById(params) {
    return await api.get(`user/member`, params);
  }

  static async resign(data) {
    return await api.post(`user/resign`, data);
  }

  // 구독 정보
  static async getSubscribe(params) {
    return await api.get(`user/subscribe/check`, params);
  }
  static async getSubscribeList(data) {
    return await api.get(`user/subscribes`, data);
  }
  static async subscribeCancel(id, data) {
    return await api.put(`user/subscribe/cancel/${id}`, data);
  }

  //결제내역
  static async getPayments(data) {
    return await api.get(`user/payments`, data);
  }

  //라이브러리
  static async getSaveVideo(data) {
    return await api.get(`user/video/stored`, data);
  }

  static async getdownVideo(data) {
    return await api.get(`/user/video/downloaded`, data);
  }
}
