import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import PretendardText from "../../atoms/pretendardText";

import { route } from "../../../routes/route";
import PrimaryBtn02 from "../../atoms/btn/primaryBtn02";
import useDisplay from "../../../hooks/useDisplay";

function DetailBox({ style, title, subTitle, content01, content02 }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  return (
    <>
      <div
        style={{
          width: isSmall ? "100%" : 390,
          height: isSmall ? 365 : 390,
          display: "flex",
          flexDirection: "column",
          border: "1px solid #565656",
          borderRadius: isSmall ? 8 : 0,
          ...style,
        }}
      >
        <div
          style={{
            background: "#1E1E1E",
            height: 116,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderTopLeftRadius: isSmall ? 8 : 0,
            borderTopRightRadius: isSmall ? 8 : 0,
          }}
        >
          <PretendardText
            style={{
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "36px",
              color: "#fff",
              marginBottom: isSmall ? 5 : 3,
            }}
          >
            {title}
          </PretendardText>
          <PretendardText
            style={{
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "21px",
              color: "#BABABA",
            }}
          >
            {subTitle}
          </PretendardText>
        </div>
        <div
          style={{
            height: isSmall ? 249 : 274,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 40,
          }}
        >
          <PretendardText
            style={{
              fontWeight: "700",
              fontSize: isSmall ? "16px" : "18px",
              lineHeight: isSmall ? "24px" : "28.8px",
              color: "#F1F1F1",
              marginBottom: isSmall ? 20 : 30,
              whiteSpace: "pre-wrap",
              textAlign: "center",
            }}
          >
            {content01}
          </PretendardText>
          <PretendardText
            style={{
              fontWeight: "400",
              fontSize: "15px",
              lineHeight: "24px",
              color: "#DFDFDF",
              whiteSpace: "pre-wrap",
              textAlign: "center",
            }}
          >
            {content02}
          </PretendardText>
        </div>
      </div>
    </>
  );
}

export default DetailBox;
