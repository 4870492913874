import { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./slider.css";
import PretendardText from "../../components/atoms/pretendardText";
import useDisplay from "../../hooks/useDisplay";
import VideoApi from "../../api/video";
import { errorHandler } from "../../utils/error-handler";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { route } from "../../routes/route";

const BestockSlider = () => {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [list, setList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  //슬라이더 영상 가져오기
  useEffect(() => {
    getVideo();
  }, []);

  const getVideo = async () => {
    try {
      const list = (await VideoApi.getVideos()).data.data.content;
      setList(list);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: `${isMobile ? "486px" : "450px"}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Swiper
        spaceBetween={30}
        slidesPerView={"auto"}
        centeredSlides={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={isMobile ? [Navigation, Autoplay, Pagination] : [Navigation, Autoplay]}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {list.map((item, index) => {
          return (
            <SwiperSlide key={index} onClick={() => history.push(route.videoDetail + `/${item.id}`)}>
              {/* 어둡게 만들기 */}
              <div></div>
              {/* 이미지 */}
              <div
                style={{
                  width: `${isMobile ? "335px" : "970px"}`,
                  height: "450px",
                  borderRadius: "10px",
                }}
              >
                <img
                  src={item.thumbFileUrl}
                  style={{
                    // width: `${isMobile ? "100%" : "970px"}`,
                    width: "100%",
                    height: "100%",
                    boxShadow: "0px 4px 20px 0px rgba(10, 13, 20, 0.15)",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
              </div>
              {/* 주제, 제목 */}
              <div
                style={{
                  position: "absolute",
                  bottom: "50px",
                  left: `${isMobile ? "17.27px" : "50px"}`,
                }}
              >
                <PretendardText
                  style={{
                    color: "#828282",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    marginBottom: "7px",
                  }}
                >
                  {item.subjectList?.title}
                </PretendardText>
                <PretendardText
                  style={{
                    color: "#fff",
                    fontSize: "22px",
                    fontWeight: "700",
                    lineHeight: "35.2px",
                  }}
                >
                  {item.title}
                </PretendardText>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default BestockSlider;
