import { useRecoilState } from "recoil";
import { modalState } from "../../recoil/modalState";

const useModal = () => {
  const [state, setState] = useRecoilState(modalState);

  const open = (openModal) => {
    setState(openModal);
  };

  const close = () => {
    setState("");
  };

  return { state, open, close };
};

export default useModal;
