export const contactMenu1 = {
  title: "촬영 유형",
  value: "1",
  subMenus: [
    {
      title: "인물 컨텐츠 촬영",
      value: "1",
    },
    {
      title: "자연 촬영",
      value: "2",
    },
    {
      title: "드론 촬영",
      value: "3",
    },
    {
      title: "접사 촬영",
      value: "4",
    },
    {
      title: "제품 촬영",
      value: "5",
    },
    {
      title: "행사 현장스케치",
      value: "6",
    },
    {
      title: "공간 촬영",
      value: "7",
    },
    {
      title: "야간 촬영",
      value: "8",
    },
    {
      title: "수중 촬영",
      value: "9",
    },
    {
      title: "스냅 촬영",
      value: "10",
    },
    {
      title: "웨딩 촬영",
      value: "11",
    },
  ],
};
